import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { addDays, format, addMonths, parseISO } from "date-fns";

import { toast } from "react-toastify";
import pt from "date-fns/locale/pt";
import {
  FaCalendarAlt,
  FaCreditCard,
  FaAngleRight,
  FaChartPie,
  FaDollarSign,
  FaSearch,
  FaIdCard,
  FaFlag
} from "react-icons/fa";
import { Form, Input, Select } from "@rocketseat/unform";
import * as Yup from "yup";

import api from '~/services/api';

import BuscarPos from "~/components/BuscarPos";

import {
  formatMoney,
  formatNum,
} from "~/scripts/index.js";

import { NewCardContainer } from "./styles";

import Title from "~/components/Title";

import {
  amex,
  aura,
  banescard,
  cooper,
  credsystem,
  diners,
  discover,
  elo,
  greencard,
  hipercard,
  jcb,
  mastercard,
  sodexo,
  sorocred,
  ticket,
  visa
} from "~/assets/";

function NewCard({ handlerCard, newCard, setNewCard, date, setEditCard, editCard, cards }) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [pos, setPos] = useState({
    code: "",
    name: "",
    number_pos: "",
    operator: {
    debit_rate: 0,
    credit_rate: 0,
    debit_days_to_credit: 0,
    credit_days_to_credit: 0,
    installment_fee_1: 0,
    installment_fee_2: 0,
    bank_id: null
    }
  });
  const [posSearch, setPOSSearch] = useState(false);
  const [finish, setFinish] = useState(true);

  let semana = [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado"
  ];

  const [dateNewCard, setDateNewCard] = useState({
    pos_id: null,
    created_by: profile.id,
    created_at: new Date(),
    date: date,
    bank_id: null,
    due_date: date,
    payment_id: null,
    flag: '',
    operation: "Crédito",
    parcels: 1,
    authorization: null,
    document: null,
    value: 0,
    payment_id_validate: true,
    filial_id: filial.filial,
    liquid_card: 0,
    qtd_parcels: []
  });

  async function handlerPOS(data) {
    setDateNewCard({
      ...dateNewCard,
      pos_id: data.id,
      pos: data
    });
    setPos(data);
    setPOSSearch(false);
  }

  async function verifyPayID(value) {
    if(!value) {
      toast.error(
        `Preencha o campo ID de Pagamento.`,
        { className: "error" }
      );
      return false;
    }
    try {
      await api.get(`card/payID/${dateNewCard.pos_id}/${value}`);
      return true;

    } catch(err) {
      toast.error(
        `ID de Pagamento já utilizado.`,
        { className: "error" }
      );
      return false;
    }

  }

  async function handleSubmit() {
    if(editCard !== null) {
      // if(await verifyPayID(dateNewCard.payment_id)) {
        handlerCard(dateNewCard, editCard)
      // }

    } else {
      if(await verifyPayID(dateNewCard.payment_id)) {
        handlerCard(dateNewCard, null)
      }
    }
  }

  useEffect(() => {
    let TOTAL1 = 0;

    if (dateNewCard.operation === "Crédito") {
      if (dateNewCard.parcels > 1 && dateNewCard.parcels < 7) {
        let VDESCONTO1 = parseFloat(
          dateNewCard.value * (pos.operator.installment_fee_1 / 100)
        );
        TOTAL1 = parseFloat(dateNewCard.value) - parseFloat(VDESCONTO1);
      } else if (dateNewCard.parcels > 6) {
        let VDESCONTO1 = parseFloat(
          dateNewCard.value * (pos.operator.installment_fee_2 / 100)
        );
        TOTAL1 = parseFloat(dateNewCard.value) - parseFloat(VDESCONTO1);
      } else {
        let VDESCONTO1 = parseFloat(
          dateNewCard.value * (pos.operator.credit_rate / 100)
        );
        TOTAL1 = parseFloat(dateNewCard.value) - parseFloat(VDESCONTO1);
      }
    } else {
      let VDESCONTO1 = parseFloat(
        dateNewCard.value * (pos.operator.debit_rate / 100)
      );
      TOTAL1 = parseFloat(dateNewCard.value) - parseFloat(VDESCONTO1);
    }

    let qtd_parcels = [];

    for (let i = 0; i < dateNewCard.parcels; i++) {
      qtd_parcels.push({
        parcel: i + 1,
        date,
        document: dateNewCard.document,
        authorization: dateNewCard.authorization,
        payment_id: dateNewCard.payment_id,
        value: dateNewCard.value / dateNewCard.parcels,
        liquid_value: TOTAL1 / dateNewCard.parcels,
        due_date: format(addMonths(parseISO(date), i), "yyyy-MM-dd"),
        filial_id: filial.filial,
        created_by: profile.id,
        created_at: new Date()
      });
    }

    const newdata = {
      ...dateNewCard,
      liquid_card: TOTAL1,
      qtd_parcels
    };

    setDateNewCard(newdata);
  }, [
    pos.operator.installment_fee_1,
    pos.operator.installment_fee_2,
    pos.operator.credit_rate,
    pos.operator.debit_rate,
    dateNewCard.value,
    dateNewCard.operation,
    dateNewCard.parcels,
    dateNewCard.authorization,
    dateNewCard.document,
    dateNewCard.payment_id,
    filial.filial,
    profile.id
  ]);

  useEffect(() => {
    let d = null;
    if (dateNewCard.operation === "Crédito") {
      d = addDays(parseISO(date), pos.operator.credit_days_to_credit);
    } else {
      d = addDays(parseISO(date), pos.operator.debit_days_to_credit);
    }
    setDateNewCard({ ...dateNewCard, due_date: format(d, 'yyyy-MM-dd'), bank_id: pos.operator.bank_id });
  }, [
    pos.operator.credit_days_to_credit,
    pos.operator.debit_days_to_credit,
    dateNewCard.operation
  ]);

  useEffect(() => {
    if(editCard !== null) {
      cards.map((card, index) => {
        if(index === editCard) {
          setDateNewCard(card);
          setPos(card.pos);
        }
      })
    }
  }, [editCard])

  useEffect(() => {
    const {
      pos_id,
      created_by,
      created_at,
      date,
      due_date,
      flag,
      operation,
      parcels,
      value,
      filial_id
    } = dateNewCard;

    console.log('2',dateNewCard)


    setFinish(
      pos_id &&
        created_by &&
        created_at &&
        date &&
        due_date &&
        flag &&
        operation &&
        parcels &&
        value &&
        filial_id
    );
  }, [dateNewCard]);

  const operation = [
    { id: "Débito", title: "Débito" },
    { id: "Crédito", title: "Crédito" }
  ];

  const flags = [
    { id: "Amex", title: "Amex", img: amex },
    { id: "Aura", title: "Aura", img: aura },
    { id: "Cooper", title: "Cooper", img: cooper },
    { id: "Cred System", title: "Cred System", img: credsystem },
    { id: "Banescard", title: "Banescard", img: banescard },
    { id: "Diners", title: "Diners", img: diners },
    { id: "Discover", title: "Discover", img: discover },
    { id: "Elo", title: "Elo", img: elo },
    { id: "Green Card", title: "Green Card", img: greencard },
    { id: "Hipercard", title: "Hipercard", img: hipercard },
    { id: "Jcb", title: "Jcb", img: jcb },
    { id: "Mastercard", title: "Mastercard", img: mastercard },
    { id: "Sodexo", title: "Sodexo", img: sodexo },
    { id: "Sorocred", title: "Sorocred", img: sorocred },
    { id: "Ticket", title: "Ticket", img: ticket },
    { id: "Visa", title: "Visa", img: visa }
  ];

  const qdtParcelsCredito = [
    { id: 1, title: "1x" },
    { id: 2, title: "2x" },
    { id: 3, title: "3x" },
    { id: 4, title: "4x" },
    { id: 5, title: "5x" },
    { id: 6, title: "6x" },
    { id: 7, title: "7x" },
    { id: 8, title: "8x" },
    { id: 9, title: "9x" },
    { id: 10, title: "10x" },
    { id: 11, title: "11x" },
    { id: 12, title: "12x" }
  ];

  const qdtParcelsDebito = [{ id: 1, title: "1" }];

  const newcard = Yup.object().shape({
    flag: Yup.string().required("Selecione uma bandeira"),
    pos_id: Yup.string().required("Selecione um PDV"),
    // document: Yup.string().required("Digite o N° do documento"),
    // authorization: Yup.string().required("Digite o N° da autorização")
  });


  return (
    <>
      <BuscarPos
        show={posSearch}
        handlerPOS={handlerPOS}
        setPOSSearch={setPOSSearch}
      />
      <div className="overlay"></div>
      <NewCardContainer style={posSearch ? { display: "none" } : null}>
        <div className="cardTitle">
          <Title
            back={true}
            func={() => { setNewCard(false); setEditCard(null); } }
            title={"Cancelar"}
          />
        </div>
        <main>
          <Form className="form" onSubmit={() => false} schema={newcard}>
            <div className="rowInputs">
              <label htmlFor="flag">
                <div className="icon">
                  {flags.map(flag => {
                    if (dateNewCard.flag === flag.title) {
                      return <img src={flag.img} key={flag.title} className="icone_flags" />;
                    } else {
                      return null;
                    }
                  })}
                  {!dateNewCard.flag && <FaFlag size={20} />}
                </div>
                <Select
                  options={flags}
                  name="flag"
                  onChange={e =>
                    setDateNewCard({
                      ...dateNewCard,
                      flag: e.target.value
                    })
                  }
                  value={dateNewCard.flag}
                  placeholder="Bandeira"
                />
              </label>
              <label htmlFor="operation">
                <div>
                  <FaCreditCard size={20} />
                  <br />
                  Oper.
                </div>
                <Select
                  options={operation}
                  name="operation"
                  onChange={e =>
                    setDateNewCard({
                      ...dateNewCard,
                      operation: e.target.value,
                      parcels: 1
                    })
                  }
                  value={dateNewCard.operation}
                  placeholder="Operação"
                />
              </label>
            </div>
            <hr />

            <div className="rowInputs">
              <label htmlFor="due_date">
                <div>
                  <FaCalendarAlt size={20} />
                  <br />
                  Data
                </div>
                <DatePicker
                  readOnly="readyonly"
                  disabled={"readyonly"}
                  name="date"
                  selected={parseISO(date)}
                  dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                  locale={pt}
                />
              </label>
              <label htmlFor="parcels">
                <div>
                  <FaChartPie size={20} />
                  <br />
                  Parcelas
                </div>
                <Select
                  name="parcels"
                  onChange={e =>
                    setDateNewCard({
                      ...dateNewCard,
                      parcels: e.target.value
                    })
                  }
                  value={dateNewCard.parcels}
                  placeholder="Parcelas"
                  options={
                    dateNewCard.operation === "Crédito"
                      ? qdtParcelsCredito
                      : qdtParcelsDebito
                  }
                />
              </label>

              <label htmlFor="value">
                <div>
                  <FaDollarSign size={20} />
                  <br />
                  Valor
                </div>
                <Input
                  name="value"
                  onChange={e =>
                    setDateNewCard({
                      ...dateNewCard,
                      value: formatNum(e.target.value)
                    })
                  }
                  value={formatMoney(
                    dateNewCard.value.toFixed(2),
                    filial.country.language
                  )}
                />
              </label>
            </div>
            <hr />

            <div className="rowInputs">
              <label htmlFor="pos_id">
                <div>
                  <FaIdCard size={20} />
                  <br />
                  PDV
                </div>
                <Input
                  name="pos_id"
                  readOnly={"readOnly"}
                  placeholder="PDV"
                  value={`${pos.name}`}
                />
              </label>
              <button
                type="button"
                className="btnFinish"
                onClick={() => setPOSSearch(true)}
              >
                Buscar <FaSearch size={14} />
              </button>
              <label htmlFor="payment_id">
                <div>
                  <FaCreditCard size={20} />
                  <br />
                  Pay. ID
                </div>
                <Input
                  name="payment_id"
                  value={dateNewCard.payment_id}
                  onChange={e => setDateNewCard({...dateNewCard,payment_id: e.target.value.toString().toUpperCase().replace(/[^a-zA-Z0-9]/gi,'')}) }
                />
              </label>
              {/* <label htmlFor="document">
                <div>
                  <FaCreditCard size={20} />
                  <br />
                  Docum.
                </div>
                <Input
                  name="document"
                  value={dateNewCard.document}
                  onChange={e =>
                    setDateNewCard({
                      ...dateNewCard,
                      document: e.target.value.toString().replace(/[^\d]+/g, "")
                    })
                  }
                  placeholder="Documento"
                />
              </label>

              <label htmlFor="authorization">
                <div>
                  <FaCreditCard size={20} />
                  <br />
                  Autorz
                </div>
                <Input
                  name="authorization"
                  value={dateNewCard.authorization}
                  onChange={e =>
                    setDateNewCard({
                      ...dateNewCard,
                      authorization: e.target.value
                        .toString()
                        .replace(/[^\d]+/g, "")
                    })
                  }
                  placeholder="Autorização"
                  maxLength={16}
                />
              </label> */}

              <button
                type="button"
                className="btnFinish"
                disabled={!finish}
                onClick={() => handleSubmit()}
              >
                { editCard !== null ? 'Atualizar' : 'Finalizar' }
                <FaAngleRight size={20} />
              </button>
            </div>
          </Form>
        </main>
        <footer></footer>
      </NewCardContainer>
    </>
  );
}

export default NewCard;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import allowAccess from "~/scripts/menuController";

import { format, parseISO } from "date-fns";
import {
  handleDialog,
  handleBlock,
  formatMoney,
  formatedDate,
  verifyAccess
} from "~/scripts/index.js";

import InvoiceParcels from "./InvoiceParcels/index";

import { toast } from "react-toastify";

import BgBlack from "~/components/BgBlack";

import "react-confirm-alert/src/react-confirm-alert.css";

import NavigationButtons from "~/components/NavigationButtons";

import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

import api from "~/services/api";
import { FaEye, FaEdit, FaCheck, FaTimes, FaRedo } from "react-icons/fa";
import {
  New
} from "./styles";
import FiltroData from "~/components/FiltroData";
import FiltroLancamento from "~/components/FiltroLancamento";

import CreateInvoice from "./CreateInvoice/index";
import MainFilter, { MainFilterButton } from "~/components/MainFilter";
import FilterComponent from "~/components/FilterComponent";
import CheckBox from "~/components/CheckBox";
import TextInputFilter from "~/components/TextInputFilter";
import BuscarFiliais from "~/components/BuscarFiliais";
import GreenSearchButton from "~/components/GreenSearchButton";
import BuscaFornecedor from "~/components/BuscarFornecedor";
import BuscarBanks from "~/components/BuscarBanks";
import DateFilter from "~/components/DateFilter";

export default function HomeboxInvoices() {
  const filial = useSelector(state => state.filial);
  const profile = useSelector(state => state.user.profile);
  const [filtro, setFiltro] = useState({
    date: format(new Date(), "yyyy-MM-dd 23:59:59")
  });
  const [invoice, setInvoice] = useState({});
  const [invoices, setInvoices] = useState([]);
  const { date } = filtro;
  const [openDate, setOpenDate] = useState(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [lancamento, setLancamento] = useState("");
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openInvoiceParcels, setOpenInvoiceParcels] = useState(false);
  const [opennedId, setOpennedId] = useState(null);
  const [block, setBlock] = useState(false);
  const [edit, setEdit] = useState(false);
  const [createInvoice] = useState(false);
  const accesses = useSelector(state => state.auth.newaccesses);
  const [showCoA, setShowCoA] = useState(false);
  const [reclassificar, setReclassificar] = useState(null);
  const [showFilter, setShowFilter] = useState(false)
  const [showFilterFilials, setShowFilterFilials] = useState(false)
  const [showFilterProvider, setShowFilterProvider] = useState(false)
  const [showFilterCoa, setShowFilterCoa] = useState(false)
  const [showFromBankFilter, setShowFromBankFilter] = useState(false)
  const [filtered, setFiltered] = useState(false)
  const [filterSettings, setFilterSettings] = useState({
    status: {
      approved: true,
      not_approved: true
    },
    from: false,
    to: false,
    invoice: '',
    expense: '',
    filial: {
      id: filial.filial,
      name: ''
    },
    bank_from: {
      id: false,
      name: ''
    },
    provider: {
      cgc: '',
      company_name: '',
      id: 0
    },
    coa: {
      id: 0,
      nome: '',
      tax_nature: ''
    },
    type: {
      nfe: true,
      nfse: true
    }
  })


  async function handleChartOfAccount(coa) {
    try {
      await api.put(`/reclassificar/invoice/${reclassificar}/${coa.id}`)
      toast.success("Reclassificado com sucesso!", {
        className: "success"
      });
      setShowCoA(false);
    } catch (err) {
      toast.error("Impossível reclassificar.", {
        className: "error"
      });
    }
  }

  function handleReclass(movement) {
    setShowCoA(true);
    setReclassificar(movement);
  }

  function handleLancamento() { }

  function handleOpenDate() {
    setOpenDate(!openDate);
  }

  function handleCreateInvoice(status, id) {
    setEdit(status);
    setOpennedId(id);
    setOpenInvoice(!openInvoice);
  }

  function handleOpenInvoice(id, edit) {
    setOpennedId(id);
    setEdit(edit);
    setOpenInvoice(!openInvoice);
  }

  function handleOpenInvoiceParcels(invoice) {
    setInvoice(invoice);
    setOpenInvoiceParcels(!openInvoiceParcels);
  }

  function handleData({ date }) {
    setFiltro({
      ...filtro,
      date: format(date, "yyyy-MM-dd 23:59:59")
    });
    setOpenDate(!openDate);
  }

  async function approveInvoice(id) {
    try {
      const response = await api.put("invoice/approve/" + id);
      if (response.data.approved_at) {
        toast.success("NFS aprovada com sucesso!", {
          className: "success"
        });
      } else {
        toast.success("NFS estornada com sucesso!", {
          className: "success"
        });
      }
      setBlock(true);
      return;
    } catch (err) {
      toast.error("Há parcelas baixadas nesta nota.", {
        className: "error"
      });
      return;
    }
  }

  useEffect(() => {
    async function loadInvoices() {
      const dateOrder = format(new Date(date), "yyyy-MM-dd");
      try {

        if (filtered) {

          const { data } = await api.get('invoice/invoice_filter/', {
            params: {
              page: page,
              ...filtered
            }
          })

          setPages(data.pages);
          setInvoices(data.invoices);

        } else {
          const response = await api.get(
            `/invoice/filial/${filial.filial}?page=${page}&dateOrder=${dateOrder}&lancamento=${lancamento}`
          );
          const { data } = response;
          setPages(data.pages);
          setInvoices(data.invoices);
        }

      } catch (err) {
        setInvoices([]);
      }

      setBlock(false);
    }

    loadInvoices();
  }, [filial, block, lancamento, date, page]);

  async function applyFilter() {
    try {

      if (filterSettings.from && filterSettings.to && filterSettings.from > filterSettings.to) {
        return toast.error("A data de emissão inicial não pode ser maior do que a final.", {
          className: "error"
        });
      }

      let from = filterSettings.from ? new Date(filterSettings.from) : new Date(1900, 0, 1)
      from = from.toISOString().substring(0, 10)

      let to = filterSettings.to ? new Date(filterSettings.to) : new Date(3000, 0, 1)
      to = to.toISOString().substring(0, 10)

      const params = {
        approved: filterSettings.status.approved,
        not_approved: filterSettings.status.not_approved,
        invoice: filterSettings.invoice,
        expense: filterSettings.expense,
        filial_id: filterSettings.filial.id,
        bank: filterSettings.bank_from.id,
        from_date: from,
        to_date: to,
        provider: filterSettings.provider.id,
        plano_de_contas: filterSettings.coa.id,
        nfe: filterSettings.type.nfe,
        nfse: filterSettings.type.nfse,
      }

      const { data } = await api.get('invoice/invoice_filter/', {
        params: {
          page: filtered ? page : 1,
          ...params
        }
      })

      setPage(1)

      setFiltered({
        ...params
      })

      setShowFilter(false)
      setPages(data.pages);
      setInvoices(data.invoices);

    } catch (err) {

    }
  }

  async function resetFilter() {
    try {
      setFilterSettings({
        status: {
          approved: true,
          not_approved: true
        },
        from: false,
        to: false,
        invoice: '',
        expense: '',
        filial: {
          id: filial.filial,
          name: ''
        },
        bank_from: {
          id: false,
          name: ''
        },
        provider: {
          cgc: '',
          company_name: '',
          id: 0
        },
        coa: {
          id: 0,
          nome: '',
          tax_nature: ''
        },
        type: {
          nfe: true,
          nfse: true
        }
      })

      setFiltered(false)
      setPage(1)

      setShowFilter(false)

      const dateOrder = format(new Date(date), "yyyy-MM-dd");
      try {
        const response = await api.get(
          `/invoice/filial/${filial.filial}?page=${page}&dateOrder=${dateOrder}&lancamento=${lancamento}`
        );
        const { data } = response;
        setPages(data.pages);
        setInvoices(data.invoices);
      } catch (err) {
        setInvoices([]);
      }

      setBlock(false);

    } catch (err) {

    }
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses, 'lancamento_nfs') ?
          <New type="button" onClick={() => { handleCreateInvoice(true, null); setShowFilter(false) }}>
            Novo +
          </New>
          : null}
        <MainFilterButton click={() => setShowFilter(!showFilter)} />
        {/* <FiltroData
          openDate={openDate}
          handleOpenDate={handleOpenDate}
          selected={parseISO(date)}
          handleData={handleData}
        />
        <FiltroLancamento
          handleLancamento={handleLancamento}
          setFind={setLancamento}
          placeholder="Nota Fiscal"
        /> */}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          {showFilter &&
            <MainFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <FilterComponent title="Status" >
                <CheckBox title="Não aprovado"
                  input={filterSettings.status.not_approved}
                  setInput={() => setFilterSettings({ ...filterSettings, status: { ...filterSettings.status, not_approved: !filterSettings.status.not_approved } })}
                />
                <CheckBox title="Aprovado"
                  input={filterSettings.status.approved}
                  setInput={() => setFilterSettings({ ...filterSettings, status: { ...filterSettings.status, approved: !filterSettings.status.approved } })}
                />
              </FilterComponent>

              <FilterComponent title="Data de Emissão">
                <DateFilter to={filterSettings.to} setTo={dt => setFilterSettings({ ...filterSettings, to: dt })} from={filterSettings.from} setFrom={dt => setFilterSettings({ ...filterSettings, from: dt })} />
              </FilterComponent>

              <FilterComponent title="Nota Fiscal" >
                <TextInputFilter input={filterSettings.invoice} placeholder="Digite apenas números." setInput={number => {
                  if (isNaN(number))
                    return
                  setFilterSettings({ ...filterSettings, invoice: number })
                }} />
              </FilterComponent>

              <FilterComponent title="Despesa" >
                <TextInputFilter input={filterSettings.expense} setInput={txt => { setFilterSettings({ ...filterSettings, expense: txt }) }} />
              </FilterComponent>

              <FilterComponent title="Filial" >
                <GreenSearchButton
                  input={filterSettings.filial.name}
                  searchFunction={() => { setShowFilterFilials(true) }}
                  resetFunction={() => setFilterSettings({ ...filterSettings, filial: { id: filial.filial, name: '' } })}
                />
                <BuscarFiliais
                  showFilials={showFilterFilials}
                  setShowFilials={setShowFilterFilials}
                  handleSearchFilials={
                    fil => {
                      setFilterSettings({ ...filterSettings, filial: { ...fil } })
                      setShowFilterFilials(false)
                    }
                  }
                />
              </FilterComponent>

              <FilterComponent title="Conta Débito" >
                <GreenSearchButton
                  input={filterSettings.bank_from.name}
                  searchFunction={() => { setShowFromBankFilter(true) }}
                  resetFunction={() => { setFilterSettings({ ...filterSettings, bank_from: { id: false, name: '' } }) }}
                />
                <BuscarBanks
                  showBanks={showFromBankFilter}
                  setShowBanks={setShowFromBankFilter}
                  handleSearchBanks={bank => {
                    setFilterSettings({ ...filterSettings, bank_from: { ...bank } })
                    setShowFromBankFilter(false)
                  }}
                />
              </FilterComponent>

              <FilterComponent title="Fornecedor" >
                <GreenSearchButton
                  input={filterSettings.provider.company_name}
                  searchFunction={() => { setShowFilterProvider(true) }}
                  resetFunction={() => setFilterSettings({ ...filterSettings, provider: { cgc: '', company_name: '', id: 0 } })}
                />
                <BuscaFornecedor
                  show={showFilterProvider}
                  setShowSearch={setShowFilterProvider}
                  handleProvider={provider => {
                    setFilterSettings({ ...filterSettings, provider: { ...provider } })
                    setShowFilterProvider(false)
                  }}
                />
              </FilterComponent>

              <FilterComponent title="Plano de Contas" >
                <GreenSearchButton
                  input={filterSettings.coa.nome}
                  searchFunction={() => { setShowFilterCoa(true) }}
                  resetFunction={() => setFilterSettings({ ...filterSettings, coa: { id: 0, nome: '', tax_nature: '' } })}
                />
                <BuscarPlanoDeContas
                  show={showFilterCoa}
                  setShowCoA={setShowFilterCoa}
                  handleChartOfAccount={coa => {
                    setFilterSettings({ ...filterSettings, coa: { ...coa } })
                    setShowFilterCoa(false)
                  }}
                />
              </FilterComponent>

              <FilterComponent title="Tipo" >
                <CheckBox title="NF-e"
                  input={filterSettings.type.nfe}
                  setInput={() => setFilterSettings({ ...filterSettings, type: { ...filterSettings.type, nfe: !filterSettings.type.nfe } })}
                />
                <CheckBox title="NFS-e"
                  input={filterSettings.type.nfse}
                  setInput={() => setFilterSettings({ ...filterSettings, type: { ...filterSettings.type, nfse: !filterSettings.type.nfse } })}
                />
              </FilterComponent>

            </MainFilter>
          }
          <tr>
            <td>Lançamento</td>
            <td>Nota Fiscal</td>
            <td>Série</td>
            <td>Data</td>
            <td>Status</td>
            <td>Despesa</td>
            <td>Fornecedor</td>
            <td>Valor</td>
            <td>Desconto</td>
            <td>Acréscimo</td>
            <td>Juros</td>
            <td>Total</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {invoices && invoices.length > 0 ? (
            invoices.map(invoice => (
              <tr
                key={invoice.id}
                onClick={() => handleDialog(invoice, "invoices")}
                className={
                  invoice.approved_at ? "invoices approved" : "invoices"
                }
                id={invoice.id}
              >
                <td>
                  {invoice.id}
                  <ul className="dialog">
                    {verifyAccess(accesses, 'baixar_nfs') && invoice.filial_id === filial.filial ? (
                      <li onClick={() => approveInvoice(invoice.id)}>
                        {!invoice.approved_at ? (
                          <>
                            <FaCheck size={12} /> Aprovar
                          </>
                        ) : (
                          <>
                            <FaTimes size={12} /> Estornar Aprovação
                          </>
                        )}
                      </li>
                    ) : null}
                    {verifyAccess(accesses, 'baixar_nfs')
                      && invoice.filial_id === filial.filial
                      && invoice.approved_at ? (
                      <li onClick={() => handleOpenInvoiceParcels(invoice)}>
                        <FaEdit size={12} /> Baixar Parcelas
                      </li>
                    ) : null}
                    <li onClick={() => handleOpenInvoice(invoice.id, false)}>
                      <FaEye size={12} /> Visualizar
                    </li>
                    {verifyAccess(accesses, 'lancamento_nfs')
                      && invoice.filial_id === filial.filial
                      && !invoice.approved_at ? (
                      <li onClick={() => handleOpenInvoice(invoice.id, true)}>
                        <FaEdit size={12} /> Alterar
                      </li>
                    ) : null}
                    {verifyAccess(accesses, 'exclusao_nfs')
                      && invoice.filial_id === filial.filial
                      && !invoice.approved_at ? (
                      <li
                        className="trash"
                        onClick={() =>
                          handleBlock(
                            invoice.id,
                            invoice.canceled_at,
                            `Nota Fiscal ${invoice.nota_fiscal}`,
                            "invoice",
                            setBlock
                          )
                        }
                      >
                        <FaTimes size={12} /> Excluir
                      </li>
                    ) : null}
                    {verifyAccess(accesses, 'reclassificar_nfs') ?
                      <li
                        className="reclassificar"
                        onClick={() =>
                          handleReclass(
                            invoice.id
                          )
                        }
                      >
                        <FaRedo size={12} /> Reclassificar
                      </li>
                      : null}
                  </ul>
                </td>
                <td>{invoice.nota_fiscal}</td>
                <td>{invoice.serie}</td>
                <td>{formatedDate(invoice.date)}</td>
                <td>{invoice.approved_at ? "Aprovado!" : "Pendente"}</td>
                <td>{invoice.reason}</td>
                <td>{invoice.provider.fantasy_name}</td>
                <td>
                  {formatMoney(
                    invoice.value.toFixed(2),
                    filial.country.language
                  )}
                </td>
                <td>
                  {formatMoney(
                    invoice.discount.toFixed(2),
                    filial.country.language
                  )}
                </td>
                <td>
                  {formatMoney(
                    invoice.addition.toFixed(2),
                    filial.country.language
                  )}
                </td>
                <td>
                  {formatMoney(
                    invoice.interest.toFixed(2),
                    filial.country.language
                  )}
                </td>
                <td>
                  {formatMoney(
                    invoice.total.toFixed(2),
                    filial.country.language
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12">Não há lançamentos.</td>
            </tr>
          )}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {openInvoice || createInvoice ? (
        <>
          <BgBlack />
          <CreateInvoice
            handleCreateInvoice={handleCreateInvoice}
            id={opennedId}
            edit={edit}
            setBlock={setBlock}
          />
        </>
      ) : null}

      {openInvoiceParcels ? (
        <>
          <BgBlack />
          <InvoiceParcels
            handleOpenInvoiceParcels={handleOpenInvoiceParcels}
            invoice={invoice}
            setBlock={setBlock}
            block={block}
          />
        </>
      ) : null}
      {showCoA ? (
        <>
          <BgBlack />
          <BuscarPlanoDeContas
            show={showCoA}
            setShowCoA={setShowCoA}
            handleChartOfAccount={handleChartOfAccount}
          />
        </>
      ) : null}
    </>
  );
}

import React, { useState, useEffect } from "react";

import { Content, Container, Scroll } from "./styles";

import {
  FaUser,
  FaRegAddressCard,
  FaFlagUsa,
  FaUniversity
} from "react-icons/fa";

import { Form, Input } from "@rocketseat/unform";

import { maskCGC } from "~/scripts/index.js";

import Title from "~/components/Title";

import api from "~/services/api";

export default function BuscaFornecedor({
  show,
  setSearch,
  initialData,
  handleProvider,
  setShowSearch
}) {
  const [providerSearch, setProviderSearch] = useState({ cgc: "", name: "" });

  const [providers, setProviders] = useState([]);

  useEffect(() => {
    async function getProviders() {
      try {
        // if(providerSearch.cgc || providerSearch.name) {
          const response = await api.get(
            "/providers/?cgc=" +
              providerSearch.cgc +
              "&name=" +
              providerSearch.name
          );
          const { data } = response;
          setProviders(data);
        // }

      } catch (err) {
        setProviders([]);
      }
    }
    getProviders();
  }, [show,providerSearch.cgc, providerSearch.name]);

  return (
    <Scroll style={!show ? { display: "none" } : { display: "block" }}>
      <Content style={{ maxWidth: "1100px", left: "calc(50% - 550px)" }}>
        <header>
          <Title back={true} func={setShowSearch} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Buscar Fornecedor</h1>
          <Form initialData={initialData}>
            <div className="rowInputs">
              <label htmlFor="cgc">
                <div>
                  <FaRegAddressCard size={20} />
                  <br />
                  Doc.
                </div>
                <Input
                  name="cgc"
                  placeholder="Documento do fornecedor"
                  maxLength={18}
                  onChange={e =>
                    setProviderSearch({
                      ...providerSearch,
                      cgc: e.target.value.replace(/\D/gim, "")
                    })
                  }
                  value={maskCGC(providerSearch.cgc)}
                />
              </label>
              <label htmlFor="name">
                <div>
                  <FaUser size={20} />
                  <br />
                  Razão
                </div>
                <Input
                  name="name"
                  maxLength={30}
                  placeholder="Razão do fornecedor"
                  value={providerSearch.name}
                  onChange={e =>
                    setProviderSearch({
                      ...providerSearch,
                      name: e.target.value
                    })
                  }
                />
              </label>
            </div>

            <hr />

            {providers.length > 0
              ? providers.map(p => (
                  <div className="rowInputs">
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() =>
                        handleProvider({
                          cgc: p.agente.cgc,
                          company_name: p.company_name,
                          id: p.id
                        })
                      }
                    >
                      Selecionar
                    </button>
                    <label htmlFor="cgc">
                      <div>
                        <FaRegAddressCard size={20} />
                        <br />
                        Doc.
                      </div>
                      <Input
                        readOnly
                        name="cgc"
                        value={maskCGC(p.agente.cgc)}
                      />
                    </label>
                    <label htmlFor="name">
                      <div>
                        <FaUser size={20} />
                        <br />
                        Razão
                      </div>
                      <Input readOnly name="name" value={p.company_name} />
                    </label>
                    <label htmlFor="name">
                      <div>
                        <FaUniversity size={20} />
                        <br />
                        Cidade
                      </div>
                      <Input readOnly name="name" value={p.agente.city} />
                    </label>
                    <label htmlFor="name">
                      <div>
                        <FaFlagUsa size={20} />
                        <br />
                        Estado
                      </div>
                      <Input readOnly name="name" value={p.agente.state} />
                    </label>
                  </div>
                ))
              : null}
          </Form>
        </Container>
      </Content>
    </Scroll>
  );
}

import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import api from "~/services/api";

import { format, parseISO } from 'date-fns';
import { formatMoney } from "~/scripts/index.js";
import pt from 'date-fns/locale/pt';

import Title from "~/components/Title";

import { Scroll,Content,Container,Section,Extrato,Filters,
  Conciliado,
  CardLine,
  Card,
  BoxConciliar,
  ButtonConciliar,
  Loading
   } from './styles';

import { FaSearch, FaAngleRight, FaPlusSquare } from 'react-icons/fa';

import { file_extension } from "~/assets/";

import { toast } from "react-toastify";

import BgBlack from "~/components/BgBlack";
import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

import Lancamento from './Lancamento';

function Conciliacao({
  handleConciliacao,
  period,
  bank_id,
  file,
  setFile,
  setNewFile,
  newFile
}) {
  const filial = useSelector(state => state.filial);
  const profile = useSelector(state => state.user.profile);
  const [bank,setBank] = useState(null);
  const [ofxs,setOfxs] = useState([]);
  const [updated,setUpdated] = useState(true);
  const [loading,setLoading] = useState(false);
  const [filters,setFilters] = useState({creditos: true, debitos: true, conciliados: true, naoConciliados: true, text: ''})
  const [totais,setTotais] = useState({creditos: 0, debitos: 0, conciliados: 0, naoConciliados: 0})
  const [option,setOption] = useState({});
  const [options,setOptions] = useState([]);
  const [lancamento,setLancamento] = useState(false)
  const [lancInfo,setLancInfo] = useState({});
  const [selectedOption,setSelectedOption] = useState(null);
  const [uploadFile, setUploadFile] = useState({
    status: false,
    name: "",
    url: ""
  });

  function setIconFileExtension(file) {
    const ext = file.split(".").pop();
    for (let i = 0; i < file_extension.length; i++) {
      if (ext === file_extension[i].title) {
        return file_extension[i].icon;
      }
    }
  }

  async function handleFile(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("s3files/" + profile.id, data);

    const { id: file_id, name } = response.data;

    let fileResponse = null;

    if(name.substr(-3).toUpperCase() === 'OFX' || name.substr(-3).toUpperCase() === 'QFX'){
      fileResponse = await api.get(`s3files/OFX/${file_id}`);
    } else {
      fileResponse = await api.get(`s3files/CSV/${file_id}`);
    }
    if(name.substr(-3).toUpperCase() === 'OFX' || name.substr(-3).toUpperCase() === 'QFX'){
      let foraPeriodo = false;
      let isCorrectBank = true;
      const bankData = [];
      bankData.push(fileResponse.data.bankData.BANKID);
      bankData.push(fileResponse.data.bankData.ACCTID.split("/"));
      if(bank.country.code === 'BR'){
        if(parseInt(bankData[0]) !== parseInt(bank.bank)) {
          isCorrectBank = false;
        }
        if(parseInt(bankData[1][0]) !== parseInt(bank.agency)) {
          isCorrectBank = false;
        }
        if(parseInt(bankData[1][1]) !== parseInt(bank.account)) {
          isCorrectBank = false;
        }
      } else {
        if(parseInt(bankData[1][0]) !== parseInt(bank.account)) {
          isCorrectBank = false;
        }
      }
      if(!isCorrectBank) {
        toast.error("A conta deste extrato não se refere à conta do banco da conciliação.", {
          className: "info",autoClose: 6000
        });
        return;
      }
      fileResponse.data.list.STMTTRN.map(trn => {
        if(trn.DTPOSTED.substring(0,4)+"-"+trn.DTPOSTED.substring(4,6) === format(period,'yyyy-MM')) {
          const date = trn.DTPOSTED.substring(0,4)+"-"+trn.DTPOSTED.substring(4,6)+"-"+trn.DTPOSTED.substring(6,8);
          api.post("conciliation/", { s3file_id: newFile ? newFile : file_id, created_by: profile.id, bank_id, date, value: parseFloat(trn.TRNAMT), historic: trn.MEMO ? trn.MEMO : trn.NAME });
        } else {
          foraPeriodo = true;
        }
      })
      if(foraPeriodo) {
        toast.error("Há lançamentos neste extrato fora do período. Os mesmos não serão importados.", {
          className: "info",autoClose: 8000
        });
      }
    } else {
      let dt = -1;
      let vl = -1;
      let hs = -1;
      fileResponse.data.map((trn,index) => {
        if(index === 0){
          trn[0].map((t,indexT) => {
            if(indexT === 0){
              hs = indexT;
            }
            if(dt === -1 && t.substr(2,1) === '/' && t.substr(5,1) === '/') {
              dt = indexT;
              if(hs === indexT) {
                hs = indexT+1;
              }
            }
            if(vl === -1 && t.substr(-3,1) === ',') {
              vl = indexT;
              if(hs === indexT) {
                hs = indexT+1;
              }
            }
          })
        }
        const date = trn[0][dt].substring(6,10)+"-"+trn[0][dt].substring(3,5)+"-"+trn[0][dt].substring(0,2);
        api.post("conciliation/", { s3file_id: newFile ? newFile : file_id, created_by: profile.id, bank_id, date, value: parseFloat(trn[0][vl]), historic: trn[0][hs] });
      })
    }
    setFile(newFile ? newFile : file_id);
    setLoading(true);
    setNewFile(false);
    setTimeout(() => { setUpdated(true); }, 1500)

  }

  async function handleInput(e) {
    handleFile(e);
    const { name, size } = e.target.files[0];
    setUploadFile({ status: true, name, size, e });
  }

  async function handleTypeSelection(index,e,date,value){
    const inputs = Array.from(document.querySelectorAll('.inputType'));
    inputs.forEach((input,indice) => {
      if(indice !== index) {
        inputs[indice].value = '';
      }
    })
    let response = "";
    try {
      if(e === 'TRF'){
        response = await api.get(`/transfers/conciliation/${bank_id}/${date}/${value >= 0 ? value : (value*-1)}`);
      } else if(e === 'EDZ') {
        response = await api.get(`/e-dizimos/conciliation/${bank_id}/${date}/${(value)}`);
      } else if(e === 'ORD') {
        response = await api.get(`/paymentOrders/conciliation/${bank_id}/${date}/${(value*-1)}`);
      } else if(e === 'NF') {
        response = await api.get(`/invoice/conciliation/${bank_id}/${date}/${(value*-1)}`);
      } else if(e === 'CF') {
        response = await api.get(`/fixedcost/conciliation/${bank_id}/${date}/${(value*-1)}`);
      } else if(e === 'CRD') {
        response = await api.get(`/card_conciliation/conciliation/${bank_id}/${date}/${(value)}`);
      }
    } catch(err) {
      return;
    }
    if(!response) {
      return;
    }
      setOption({index,type: e});
      setOptions(response.data);
      if(response.data.length > 0){
        setSelectedOption(response.data[0].id)
      }
  }

  async function handleConciliado(ofx, index) {
    let type = '';
    let page = '';
    let id = null;
    if(ofx.transfer_id){
      type = 'TRF';
      page = 'transfers';
      id = ofx.transfer_id;
    }
    if(ofx.dizimo_id){
      type = 'EDZ';
      page = 'e-dizimos';
      id = ofx.dizimo_id;
    }
    if(ofx.payment_order_id){
      type = 'ORD';
      page = 'paymentOrders';
      id = ofx.payment_order_id;
    }
    if(ofx.invoice_id){
      type = 'NF';
      page = 'invoice';
      id = ofx.invoice_id;
    }
    if(ofx.fixed_cost_id){
      type = 'CF';
      page = 'fixedcost';
      id = ofx.fixed_cost_id;
    }
    if(ofx.card_conciliation_date){
      type = 'CRD';
      page = 'card_conciliation';
      id = ofx.id;
    }
    setOption({index,type});
    try {
      if(type === 'CRD') {
        await api.put(`/${page}/conciliate/${ofx.id}`, { bank_id, id });
      } else {
        await api.put(`/${page}/conciliate/${id}/${ofx.id}`);
      }
      await api.put(`/conciliation/${ofx.id}/${type}/${id}`);
      toast.success(
        `Estornado com sucesso!`,
        { className: "success" }
      );
      setUpdated(true)
      setOption({});
      setOptions([]);
    } catch(err) {
      toast.error(
        `Erro ao estornar, tente novamente.`,
        { className: "error" }
      );
    }
  }

  async function handleConciliar(ofx) {
    let page = '';
    try {
      if(option.type === 'TRF'){
        page = 'transfers';
      }
      if(option.type === 'ORD'){
        page = 'paymentOrders';
      }
      if(option.type === 'NF'){
        page = 'invoice';
      }
      if(option.type === 'CF'){
        page = 'fixedcost';
      }
      if(option.type === 'CRD'){
        page = 'card_conciliation';
      }
      if(option.type === 'EDZ'){
        page = 'e-dizimos';
      }


      if(option.type === 'CRD'){
        await api.put(`/${page}/conciliate/${ofx.id}`, options[0]);
      } else {
        await api.put(`/${page}/conciliate/${selectedOption}/${ofx.id}`);
      }
      await api.put(`/conciliation/${ofx.id}/${option.type}/${selectedOption}`);
      toast.success(
        `Conciliado com sucesso!`,
        { className: "success" }
      );
      setUpdated(true)
    } catch(err) {
      toast.error(
        `Erro ao conciliar, tente novamente!`,
        { className: "error" }
      );
    }
  }

  function handleLancar(index){
    setLancamento(!lancamento)
    setLancInfo(ofxs[index])
  }

  async function handleRemoveConciliacao() {
    if(totais.conciliados === 0) {
      const response = await api.delete(`/conciliation/file/${file}`);
      setFile(null)
      handleConciliacao(false)
    } else {
      toast.error("Há lançamentos conciliados. Estorne-os para remover a conciliação.", {
        className: "error"
      });
    }
  }

  useEffect(() => {
    async function getBank() {
      const response = await api.get(`/banks/${bank_id}`)
      setBank(response.data);
    }
    getBank();
  },[bank_id])

  useEffect(() => {
    async function getOFX() {
      const response = await api.get(`/conciliation/${file}`)
      setOfxs(response.data);
    }
    if(updated) {
      getOFX();
      setUpdated(false);
      setLoading(false);
    }
  },[file,updated])

  useEffect(() => {
    if(ofxs.length > 0) {
      let creditos = 0;
      let debitos = 0;
      let conciliados = 0;
      let naoConciliados = 0;
      ofxs.map(ofx => {
        if(filters.text && !(ofx.historic.toUpperCase().indexOf(filters.text.toUpperCase()) !== -1 ||
        formatMoney(ofx.value.toFixed(2), filial.country.language).toUpperCase().indexOf(filters.text.toUpperCase()) !== -1 ||
        format(parseISO(ofx.date), profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy').toUpperCase().indexOf(filters.text.toUpperCase()) !== -1)) {
          return false
        }
        if(!filters.creditos && ofx.type === 'CREDIT') {
          return false;
        }
        if(!filters.debitos && ofx.type === 'DEBIT') {
          return false;
        }
        if(!filters.conciliados && (ofx.transfer_id || ofx.payment_order_id || ofx.invoice_id || ofx.fixed_cost_id || ofx.dizimo_id || ofx.card_conciliation_date)) {
          return false;
        }
        if(!filters.naoConciliados && !(ofx.transfer_id || ofx.payment_order_id || ofx.invoice_id || ofx.fixed_cost_id || ofx.dizimo_id || ofx.card_conciliation_date)) {
          return false;
        }
        if(ofx.type === 'CREDIT') {
          ++creditos
        } else if(ofx.type === 'DEBIT') {
          ++debitos
        }
        if(ofx.transfer_id || ofx.payment_order_id || ofx.invoice_id || ofx.fixed_cost_id || ofx.dizimo_id || ofx.card_conciliation_date) {
          ++conciliados
        } else {
          ++naoConciliados
        }
      });
      setTotais({creditos, debitos, conciliados, naoConciliados});
    }

  }, [ofxs,filters])

  return (
    <>
      <Scroll>
        <Content className={file ? 'uploadedContent' : null}>
          <header>
            <Title back={true} func={handleConciliacao} title={"Cancelar"} />
          </header>
          { bank ?
          <Container>
            <h1>{bank.name} - Conciliação Bancária {format(period,'MMMM/yyyy', { locale: pt })} { file && !newFile ? <div className="apagar_conciliacao" onClick={() => handleRemoveConciliacao()}>Apagar Conciliação</div> : null }</h1>

            <input
              type="file"
              id="file_ofx"
              name="files"
              accept=".ofx, .qfx"
              style={{ display: "none" }}
              onChange={e => handleInput(e)}
            />
            <input
              type="file"
              id="file_csv"
              name="files"
              accept=".csv"
              style={{ display: "none" }}
              onChange={e => handleInput(e)}
            />
            <Section>
              { !file || newFile ? (
                <>
                  <button
                  type="button"
                  className="fadeColor"
                  onClick={() => document.getElementById("file_ofx").click()}
                  >
                  <img
                    src={setIconFileExtension("ofx")}
                    alt=""
                    style={{ height: "128px", width: "128px" }}
                  />
                </button>
                <button
                  type="button"
                  className="fadeColor"
                  onClick={() => document.getElementById("file_csv").click()}
                    >
                <img
                  src={setIconFileExtension("csv")}
                  alt=""
                  style={{ height: "128px", width: "128px" }}
                />
                </button>
                </>
              ):
              ofxs ?
              <Extrato>
                <Filters>
                  <li className={filters.creditos ? 'active' : null } onClick={() => setFilters({...filters, creditos: !filters.creditos})}>Créditos <small>{totais.creditos}</small></li>
                  <li className={filters.debitos ? 'active' : null } onClick={() => setFilters({...filters, debitos: !filters.debitos})}>Débitos <small>{totais.debitos}</small></li>
                  <li className={filters.conciliados ? 'active' : null } onClick={() => setFilters({...filters, conciliados: !filters.conciliados})}>Conciliados {totais.conciliados ? <small>{totais.conciliados}</small> : null}</li>
                  <li className={filters.naoConciliados ? 'active' : null } onClick={() => setFilters({...filters, naoConciliados: !filters.naoConciliados})}>Não conciliados <small>{totais.naoConciliados}</small></li>
                  <input className="search_box" type='text' placeholder="Buscar por..." onChange={e => setFilters({...filters, text: e.target.value})} />
                </Filters>
                  { loading ? <Loading>Aguarde, carregando...</Loading> : null}
                  { ofxs.map((ofx,index) => {
                      return (
                        <div key={index}>
                        {
                        (
                        ofx.type === 'CREDIT' && filters.creditos ||
                        ofx.type === 'DEBIT' && filters.debitos
                        )
                        &&
                        (
                        (ofx.transfer_id || ofx.dizimo_id || ofx.payment_order_id || ofx.invoice_id || ofx.fixed_cost_id || ofx.card_conciliation_date) && filters.conciliados ||
                        (!ofx.transfer_id && !ofx.dizimo_id && !ofx.payment_order_id && !ofx.invoice_id && !ofx.fixed_cost_id && !ofx.card_conciliation_date) && filters.naoConciliados
                        )
                        &&
                        (ofx.historic.toUpperCase().indexOf(filters.text.toUpperCase()) !== -1 ||
                        formatMoney(ofx.value.toFixed(2), filial.country.language).toUpperCase().indexOf(filters.text.toUpperCase()) !== -1 ||
                        format(parseISO(ofx.date), profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy').toUpperCase().indexOf(filters.text.toUpperCase()) !== -1
                        )
                        ?
                        <CardLine key={index}>
                          <Card>
                            <h2>
                              {format(parseISO(ofx.date), profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}
                              {/* {format(parseISO(ofx.date),'dd/MM/yyyy')} */}
                              <span className={ofx.type}>{bank.country.currency}{" "}{formatMoney(ofx.value.toFixed(2), filial.country.language)}</span>
                            </h2>
                            <p>{ofx.historic}</p>
                          </Card>
                          <ButtonConciliar>
                            <FaAngleRight size={20} />
                          </ButtonConciliar>
                          { ofx.transfer_id || ofx.dizimo_id || ofx.payment_order_id || ofx.invoice_id || ofx.fixed_cost_id || ofx.card_conciliation_date ?
                          <Conciliado>Conciliado!<br/><div className='small' onClick={() => { handleConciliado(ofx,index) }}>Estornar</div></Conciliado>
                          :
                          <BoxConciliar>
                            <ul>
                              <li className="active"><FaSearch size={16} />
                              &nbsp;&nbsp; Buscar
                              </li>
                              <li onClick={() => handleLancar(index)}>
                                <FaPlusSquare size={16} />&nbsp;&nbsp; Lançar
                              </li>
                            </ul>
                            <div>
                              <select className='inputType' onChange={e => handleTypeSelection(index,e.target.value,ofx.date,parseFloat(ofx.value))}>
                                <option value="">Selecione...</option>
                                { ofx.type === 'CREDIT' ?
                                <>
                                  { option.index === index && option.type === 'TRF' ?
                                    <option value="TRF" selected>Transferências</option>
                                  :
                                    <option value="TRF">Transferências</option>
                                  }
                                  { option.index === index && option.type === 'EDZ' ?
                                    <option value="EDZ" selected>e-Dízimo</option>
                                  :
                                    <option value="EDZ">e-Dízimo</option>
                                  }
                                  { option.index === index && option.type === 'CRD' ?
                                    <option value="CRD" selected>Cartão de Crédito</option>
                                  :
                                    <option value="CRD">Cartão de Crédito</option>
                                  }
                                </>
                                :
                                <>
                                { option.index === index && option.type === 'TRF' ?
                                  <option value="TRF" selected>Transferências</option>
                                :
                                  <option value="TRF">Transferências</option>
                                }
                                { option.index === index && option.type === 'ORD' ?
                                  <option value="ORD" selected>Ordem de Pagamento</option>
                                :
                                  <option value="ORD">Ordem de Pagamento</option>
                                }
                                { option.index === index && option.type === 'NF' ?
                                  <option value="NF" selected>Nota Fiscal</option>
                                :
                                  <option value="NF">Nota Fiscal</option>
                                }
                                { option.index === index && option.type === 'CF' ?
                                  <option value="CF" selected>Custo Fixo</option>
                                :
                                  <option value="CF">Custo Fixo</option>
                                }
                                </>
                                }
                              </select>
                              { option.index === index && options.length > 0 ?
                              <select onChange={e => setSelectedOption(e.target.value)}>
                                {
                                option.index === index ? options.map(opt => {
                                  return <option key={opt.id} value={opt.id}>Data: {format(
                                    parseISO(opt.extract_at),
                                    profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                  )} - Valor: {formatMoney(opt.total.toFixed(2),bank.country.language)} - Hist: {opt.hist}</option>
                                }) : null
                                }
                              </select>
                              : null }
                              { option.index === index && options.length > 0 ?
                              <button className="buttonConciliar" onClick={() => handleConciliar(ofx)}>
                                Conciliar
                              </button>
                              : null }
                            </div>
                          </BoxConciliar>
                          }
                        </CardLine>
                        : null }
                        </div>
                      )
                    })}
              </Extrato>
              : null }

            </Section>
          </Container>
          : null }
        </Content>
      </Scroll>
      { lancamento ?
      <>
      <BgBlack zIndex={13}/>
      <Lancamento lancamento={lancamento} setLancamento={setLancamento} lancInfo={lancInfo} setUpdated={setUpdated} />
      </> : null }
    </>
  );
}

export default Conciliacao;

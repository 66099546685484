import { takeLatest, call, put, all } from "redux-saga/effects";

import { updateSelectedFilialsSuccess } from "./actions";

export function* updateFilials({ payload }) {
  try {
   
    yield put(updateSelectedFilialsSuccess(payload.filials));

  } catch (err) {
    
  }
}

export default all([takeLatest("@selectedFilials/UPDATE_SELECTED_FILIALS_REQUEST", updateFilials)]);

import styled from "styled-components";

export const Container = styled.div``;

export const Forgot = styled.button`
  margin: 11px 0 0 !important;
  height: auto !important;
  background: transparent !important;
  font-size: 14px !important;
`;

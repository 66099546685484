import React from 'react';

import { Container } from './styles';

import { FaAngleRight,FaAngleLeft } from 'react-icons/fa';

export default function NavigationButtons({ setPage, page, pages }) {
  return (
    <>
    <Container>
      { page > 1 ?
      <div style={{display: "flex"}}>
        <button onClick={() => setPage(1)} style={{display: "inline-flex", marginRight: "15px"}}>Primeira</button>
        <button onClick={() => setPage(page-1)} style={{display: "inline-flex"}}><FaAngleLeft size={16} /> Anterior</button>
      </div>
      :
        <div></div>
      }

      {/* <div>{ page } / { pages }</div> */}

      { page < pages ?

        <div style={{display: "flex"}}>
          <button onClick={() => setPage(page+1)} style={{display: "inline-flex", marginRight: "15px"}}>Próxima <FaAngleRight size={16} /></button>
          <button onClick={() => setPage(pages)} style={{display: "inline-flex"}}>Última</button>
        </div>
      :
        <div></div>
      }
    </Container>
    </>
  );
}

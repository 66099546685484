import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL
      :
      // "http://192.168.15.23:8097"
      "https://localhost:8097/"
        // "https://bkendsiaf.siaffiliais.com.br/"
});

export default api;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;

  .rowInputs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  label {
    display: flex;
    text-align: left;
    align-items: center;
    margin: 0 3px 10px;
    flex: 1;
    position: relative;

    span {
      position: absolute;
      bottom: -27px;
      background: rgb(155,213,49);
      padding: 2px 0px;
      color: #fff;
      width: calc(100% - 6px);
      text-align: center;
      font-style: normal;
      font-size: 11px;
    }

    div.react-datepicker-wrapper {
      width: 100%;
      background: #fff;
      height: 54px;
      display: flex;
      align-items: center;
      margin: 0 5px;
      padding: 15px;

      input {
        border: none;
      }
    }

    img {
      border-radius: 4px;
      overflow: hidden;
    }
  }

  label > div:first-child {
    display: inline-block;
    width: 54px;
    height: 54px;
    text-align: center;
    font-size: 10px;
    color: #555;
    background: #fff;
    padding: 13px 8px 0;
    border-radius: 4px;

    &.fileInput {
      display: flex;
      width: 100%;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 0;
      font-size: 12px;

      svg {
        margin-left: 15px;
      }
    }
  }
`;

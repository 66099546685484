import React, { useState, useRef, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { updateProfileRequest } from '~/store/modules/user/actions';

import api from '~/services/api';

import { useField } from '@rocketseat/unform';

import { Container } from './styles';

export default function AvatarInput() {
  const { defaultValue, registerField } = useField('avatar');
  const profile = useSelector(state => state.user.profile);


  const [file,setFile ] = useState(defaultValue && defaultValue.id);
  const [preview,setPreview ] = useState(defaultValue && defaultValue.url);

  const ref = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'avatar_id',
        ref: ref.current,
        path: 'dataset.file',
      });
    }
  }, [ref.current]);

  async function handleChange(e) {
    const data = new FormData();

    data.append('file', e.target.files[0]);

    const response = await api.post('s3files/'+profile.id, data);

    const { id, url, key } = response.data;

    setPreview(url)
    if(profile && profile.avatar) {
      /* await api.delete('s3files/'+profile.avatar.id); */
    }
    dispatch(updateProfileRequest({id: profile.id, avatar_id: id}));

    setFile(id);
  }

  return (
    <Container>
      <label htmlFor="avatar">
        <img width="120" height="120" src={preview || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrdEvvb_A3fTRZ9FTzpCkDacPDXBYoWgsoxbFbcYnB-jWigqa6w&s'} alt="" />
        <input type="file" id="avatar" accept="image/*" data-file={file} ref={ref} onChange={handleChange} />
      </label>
    </Container>
  );
}

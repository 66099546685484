import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import * as yup from "yup";

import api from "~/services/api";
import { handleBlock } from "~/scripts/handleBlock.js";

import { toast } from "react-toastify";

import { Container } from "./styles";

import { getAccountTypes, getBanks } from "~/scripts";

import { Form, Input, Select } from "@rocketseat/unform";
import {
  FaTimes,
  FaAngleRight,
  FaUserAlt,
  FaLandmark,
  FaInfo,
  FaPager,
  FaMoneyCheckAlt
} from "react-icons/fa";

export default function FormFornecedor({
  cgc,
  agentId,
  agent,
  setAgent,
  edit
}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [block, setBlock] = useState(false);
  const [fornecedor, setFornecedor] = useState({
    agent_id: agent.id,
    created_by: profile.id,
    cgc,
    company_name: agent.name,
    fantasy_name: agent.name,
    new: true
  });
  const account_types = getAccountTypes(agent.country);
  const banks = getBanks(agent.country);

  useEffect(() => {
    async function loadFornecedor() {
      try {
        const response = await api.get("provider/?agentId=" + agentId, {
          agentId
        });
        const { data } = response;
        setFornecedor({ ...data, new: false });
      } catch(err) {

      }
    }
    loadFornecedor();
    setBlock(false);
  }, [agentId, setBlock, block]);

  async function handleSubmit() {
    try {
      const response = fornecedor.new
        ? await api.post("provider/", fornecedor)
        : await api.put("provider/", fornecedor);
      toast.success(`Dados de Fornecedor atualizado com sucesso!`, {
        className: "success"
      });
      setAgent({
        ...agent,
        type: agent.type + ",1",
        new: false,
        agent_id: response.data.id
      });
      setFornecedor({...fornecedor, new: false})
      return;
    } catch (err) {
      toast.error("Por favor, revise os dados!", { className: "error" });
    }
  }

  const agentSchema = yup.object().shape({
    company_name: yup.string().required("Razão Social obrigatório"),
    fantasy_name: yup.string().required("Nome fantasia obrigatório"),
    // bank: yup.string().required("Banco obrigatório"),
    // account_type: yup.string().required("Tipo de conta obrigatório"),
    // agency: yup.string().required(agent.country === "BR" ? "Agência obrigatória" : "R.N. Obrigatório" ),
    // account: yup.string().required(agent.country === "BR" ? "Conta obrigatória" : "Account Number Obrigatório" ),
  });

  return (
    <Container>
      <h1>Dados de Fornecedor</h1>
      <Form
        initialData={fornecedor}
        onSubmit={handleSubmit}
        schema={agentSchema}
      >
        <div className="rowInputs">
          <label htmlFor="company_name">
            <div>
              <FaUserAlt size={20} />
              <br />
              R.Social
            </div>
            <Input
              name="company_name"
              readOnly={!edit ? "readonly" : ""}
              onChange={e =>
                setFornecedor({ ...fornecedor, company_name: e.target.value })
              }
              value={fornecedor.company_name}
              // placeholder={agent.name}
              maxLength="60"
            />
          </label>
          <label htmlFor="fantasy_name">
            <div>
              <FaUserAlt size={20} />
              <br />
              Fantasia
            </div>
            <Input
              name="fantasy_name"
              readOnly={!edit ? "readonly" : ""}
              onChange={e =>
                setFornecedor({ ...fornecedor, fantasy_name: e.target.value })
              }
              value={fornecedor.fantasy_name}
              maxLength="40"
            />
          </label>
        </div>

        <hr />

        <div className="rowInputs">
          {/* <label htmlFor="accounting_code">
            <div>
              <FaBalanceScale size={20} />
              <br/>C.Contáb.
            </div>
            <Input name="accounting_code" readOnly={ !edit ? 'readonly' : '' } onChange={e => setFornecedor({ ...fornecedor, accounting_code: e.target.value })} maxLength="18"  />
          </label>
          <label htmlFor="tax_nature" >
            <div>
              <FaCube size={20} />
              <br/>Natureza
            </div>
            <Input name="tax_nature" readOnly={ !edit ? 'readonly' : '' } onChange={e => setFornecedor({ ...fornecedor, tax_nature: e.target.value })} />
          </label> */}
          <label htmlFor="bank">
            <div>
              <FaLandmark size={20} />
              <br />
              Banco
            </div>
            <Select
              options={banks}
              disabled={!edit ? "disabled" : ""}
              name="bank"
              value={fornecedor.bank}
              onChange={e =>
                setFornecedor({ ...fornecedor, bank: e.target.value })
              }
            />
          </label>
          <label htmlFor="account_type">
            <div>
              <FaInfo size={20} />
              <br />
              Tipo
            </div>
            <Select
              options={account_types}
              disabled={!edit ? "disabled" : ""}
              name="account_type"
              onChange={e =>
                setFornecedor({ ...fornecedor, account_type: e.target.value })
              }
              value={fornecedor.account_type}
            />
          </label>
        </div>
        <hr />

        <div className="rowInputs">
          <label htmlFor="agency">
            <div>
              <FaPager size={20} />
              <br />
              { agent.country === "BR" ? "Agencia" : "R.N" }
            </div>
            <Input
              name="agency"
              readOnly={!edit ? "readonly" : ""}
              placeholder=""
              onChange={e =>
                setFornecedor({
                  ...fornecedor,
                  agency: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
              value={fornecedor.agency}
              maxLength="40"
            />
          </label>
          {agent.country === "BR" ?
          <label htmlFor="agency_code" className="mini_input">
            <Input
              name="agency_code"
              readOnly={!edit ? "readonly" : ""}
              placeholder="Dígito"
              maxLength="1"
              value={fornecedor.agency_code}
              onChange={e =>
                setFornecedor({
                  ...fornecedor,
                  agency_code: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
            />
          </label>
          : null}
          <label htmlFor="account">
            <div>
              <FaMoneyCheckAlt size={20} />
              <br />
              { agent.country === "BR" ? "Conta" : "Acc." }
            </div>
            <Input
              name="account"
              readOnly={!edit ? "readonly" : ""}
              placeholder=""
              maxLength="40"
              value={fornecedor.account}
              onChange={e =>
                setFornecedor({
                  ...fornecedor,
                  account: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
            />
          </label>
          { agent.country === "BR" ?
          <label htmlFor="account_code" className="mini_input">
            <Input
              name="account_code"
              readOnly={!edit ? "readonly" : ""}
              placeholder="Dígito"
              maxLength="1"
              value={fornecedor.account_code}
              onChange={e =>
                setFornecedor({
                  ...fornecedor,
                  account_code: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
            />
          </label>
          : null }
        </div>
        <hr />

        <div className="rowInputs">
          <label htmlFor="state_registration">
            <div>
              <FaPager size={20} />
              <br />
              Insc.
            </div>
            <Input
              name="state_registration"
              readOnly={!edit ? "readonly" : ""}
              placeholder="Inscrição Estadual"
              onChange={e =>
                setFornecedor({
                  ...fornecedor,
                  state_registration: e.target.value
                })
              }
              maxLength="40"
            />
          </label>
          <label htmlFor="city_registration">
            <div>
              <FaMoneyCheckAlt size={20} />
              <br />
              Insc.
            </div>
            <Input
              name="city_registration"
              readOnly={!edit ? "readonly" : ""}
              placeholder="Inscrição municipal"
              maxLength="40"
              onChange={e =>
                setFornecedor({
                  ...fornecedor,
                  city_registration: e.target.value
                })
              }
            />
          </label>
        </div>

        <hr />

        <div
          className="rowInputs"
          style={{ "justify-content": "space-between" }}
        >
          {edit ? (
            <>
              <button
                type="button"
                onClick={() =>
                  handleBlock(
                    fornecedor.id,
                    fornecedor.canceled_at,
                    "fornecedor",
                    "provider",
                    setBlock
                  )
                }
                class="btnFinish trash"
                id="btnBlock"
              >
                {fornecedor.canceled_at ? (
                  <>
                    <FaAngleRight size={16} />
                    &nbsp; Desbloquear Fornecedor
                  </>
                ) : (
                  <>
                    <FaTimes size={16} />
                    &nbsp; Bloquear Fornecedor
                  </>
                )}
              </button>
              <button type="submit" class="btnFinish" id="btnFinish">
                <>
                  {fornecedor.new ? "Gravar" : "Atualizar"}{" "}
                  <FaAngleRight size={20} />
                </>
              </button>
            </>
          ) : null}
        </div>
      </Form>
    </Container>
  );
}

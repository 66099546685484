import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { saveAs } from 'file-saver';

import { toast } from "react-toastify";

import { Select } from "@rocketseat/unform";

import allowAccess from "~/scripts/menuController";

import "react-confirm-alert/src/react-confirm-alert.css";

import { format, parseISO, addMonths, isBefore, addDays } from "date-fns";

import { updatefunctionalities } from "~/store/modules/functionalities/actions";

import CardParcels from './CardParcels';

import api from "~/services/api";

import pt from "date-fns/locale/pt";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
  GradeTotals,
  New,
  DataFilter,
  BankFilter,
  ToolTipe,
  CardsReview
} from "./styles";

import { formatMoney, verifyAccess } from "~/scripts/index.js";

import {
  FaEquals,
  FaMinus,
  FaPlus,
  FaHistory,
  FaLock,
  FaLockOpen,
  FaCalendarAlt,
  FaUniversity,
  FaTimes,
  FaLaptopCode,
  FaPrint,
  FaSearchDollar
} from "react-icons/fa";

import BgBlack from "~/components/BgBlack";

import Conciliacao from "./Conciliacao/index.js";

export default function HomeboxBalanceBanks() {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const dispatch = useDispatch();
  let bankList = [{id: '', title: 'Selecionar banco...'}];
  const [bank,setBank] = useState(null);
  const [banks, setBanks] = useState([]);
  const [minDate, setMinDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [initalBalance, setInitalBalance] = useState(0);
  const [filtros, setFiltros] = useState({ date: new Date(), bank: null });
  const [balance, setBalance] = useState({ period_start: null });
  const [before, setBefore] = useState({ approved_at: true });
  const [after, setAfter] = useState({ approved_at: false });
  const [newFile,setNewFile] = useState(false);
  const [file, setFile] = useState(null);
  const [block, setBlock] = useState(false);
  const [pdf,setPDF] = useState(null);
  const [conciliacao, setConciliacao] = useState(false);
  const [bankBalances, setBankBalances] = useState([]);
  const [isConciliado,setIsConciliado] = useState(true);
  const [open_card_parcels, setOpen_card_parcels] = useState(null);
  const tooltipe = useSelector(state => state.functionalities.tootipe);
  const [totals, setTotals] = useState({
    inicial: 0,
    debitos: 0,
    creditos: 0,
    final: 0
  });
  const accesses = useSelector(state => state.auth.newaccesses);

  function setTootipe() {
    dispatch(updatefunctionalities(false));
  }

  useEffect(() => {
    setFiltros({...filtros,bank: ''})
  },[filial.filial])

  useEffect(() => {

    async function getBanks() {
      const response = await api.get("/banks/user/"+profile.id);

      const { data } = response;

      let promises = [];
      data.map(d => {
        if(d.filial_id === filial.filial) {
          return promises.push(
            new Promise((resolve, reject) => {
                resolve(bankList.push({ id: d.id, title: d.name, language: d.language, currency: d.currency }));
            })
          );
        }
      });

      Promise.all(promises).then(async results => {
        setBanks(bankList);
      });
    }

    async function getMinDate() {
      const { data } = await api.get(
        `min_date_bank?data=${filtros.date}&bank_id=${filtros.bank}`
      );
      setMinDate(format(addDays(parseISO(data.period_end),1),'yyyy-MM-dd'));
    }

    async function getInitalBalance() {
      const { data } = await api.get(
        `initial_balance?data=${format(filtros.date,'yyyy-MM-dd')}&bank_id=${filtros.bank}`
      );
      if(data) {
        setInitalBalance(data.balance || 0);
      }
    }

    getBanks();

    if (filtros.bank) {
      getMinDate();
      getInitalBalance();
    }
  }, [filial.filial, filtros, profile.id]);

  useEffect(() => {
    let total = 0;
    let debitos = 0;
    let creditos = 0;

    for (let i = 0; i < bankBalances.length; i++) {
      if (bankBalances[i].origin === "Transferência") {
        if (bankBalances[i].transaction == "Crédito") {
          debitos += parseFloat(bankBalances[i].value);
          total -= parseFloat(bankBalances[i].value);
        } else {
          creditos += parseFloat(bankBalances[i].value);
          total += parseFloat(bankBalances[i].value);
        }
      } else {
        if (bankBalances[i].transaction == "Débito") {
          debitos += parseFloat(bankBalances[i].value);
          total -= parseFloat(bankBalances[i].value);
        } else {
          creditos += parseFloat(bankBalances[i].value);
          total += parseFloat(bankBalances[i].value);
        }
      }
    }
    setTotals({
      inicial: initalBalance,
      debitos: parseFloat(debitos.toFixed(2)),
      creditos: parseFloat(creditos.toFixed(2)),
      final: parseFloat((initalBalance + total).toFixed(2))
    });
  }, [bankBalances]);

  useEffect(() => {
    async function verify() {
      const { data } = await api.get(
        `get_bank_balance?bank_id=${filtros.bank}`
      );

      if (isBefore(new Date(data.period_start), new Date())) {
        var date = new Date();
        var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
        var data1 = new Date(data.period_start);
        var data2 = new Date(primeiroDia);
        var total =
          (data2.getFullYear() - data1.getFullYear()) * 12 +
          (data2.getMonth() - data1.getMonth()) -
          1;
        const arraysDates = [];
        for (let i = 0; i < total; i++) {
          const add = i + 2;
          var date = new Date(addMonths(new Date(data.period_start), add));
          var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
          arraysDates.push({
            period_start: format(new Date(primeiroDia), "yyyy-MM-dd"),
            period_end: format(new Date(ultimoDia), "yyyy-MM-dd"),
            bank_id: filtros.bank,
            balance: 0,
            initial_balance: 0,
            filial_id: filial.filial
          });
        }
        await api.post("bank_balance", arraysDates);
      }
    }

    async function getThisMonth_And_Before_After() {
      var dates = new Date(filtros.date);
      var primeiroDia = new Date(dates.getFullYear(), dates.getMonth(), 1);
      var ultimoDia = new Date(dates.getFullYear(), dates.getMonth() + 1, 0);

      const { data } = await api.get(
        `bank_balance/${format(new Date(ultimoDia), "yyyy-MM-dd")}?bank_id=${
          filtros.bank
        }`
      );

      if (data.thisPeriod) {
        if(data.thisPeriod.file) {
          setPDF(data.thisPeriod.file.url);
        }
        setBefore(data.before);
        setBalance(data.thisPeriod);
        setAfter(data.after);
      } else {
        const { data: newDate } = await api.get(
          `bank_balance/${format(new Date(ultimoDia), "yyyy-MM-dd")}?bank_id=${
            filtros.bank
          }`
        );
        if(newDate && newDate.thisPeriod && newDate.thisPeriod.file) {
          setPDF(newDate.thisPeriod.file.url);
        }
        setBefore(newDate.before);
        setBalance(newDate.thisPeriod);
        setAfter(newDate.after);
      }
    }

    async function getBalances() {
      let dates = new Date(filtros.date);
      // let conciliado = true;
      let primeiroDia = new Date(dates.getFullYear(), dates.getMonth(), 1);
      let ultimoDia = new Date(dates.getFullYear(), dates.getMonth() + 1, 0);
      const { data: dataDate } = await api.get(
        `bank_balance/all?bank_id=${
          filtros.bank
        }&startDate=${format(primeiroDia, "yyyy-MM-dd")}&endDate=${format(
          new Date(ultimoDia),
          "yyyy-MM-dd"
        )}`
      );

      // dataDate.map(balances => {
      //   if(!balances.concialized_at && !balances.conciliation) {
      //     conciliado = false;
      //   }
      // });
      // if(!conciliado) {
      //   setIsConciliado(false)
      // }

      setBankBalances(dataDate);
    }

    async function getInitalBalance() {
      var dates = new Date(filtros.date);
      // var primeiroDia = new Date(dates.getFullYear(), dates.getMonth(), 1);
      // var ultimoDia = new Date(dates.getFullYear(), dates.getMonth() + 1, 0);
      const { data } = await api.get(
        `initial_balance?data=${filtros.date}&bank_id=${filtros.bank}`
      );
      setInitalBalance(data);
    }

    async function getConciliacao() {
      try {
        const response = await api.get(
          `/conciliation/file/${filtros.bank}/${format(
            filtros.date,
            "yyyy-MM"
          )}`
        );
        const { s3file_id } = response.data;
        setFile(s3file_id);

        const response2 = await api.get('/conciliation/isConcialized/'+s3file_id);
          setIsConciliado(response2.data.isConcialized)
      } catch (err) {
        setFile(null);
      }
    }

    async function getBankInfo() {
      const response = await api.get(`banks/${filtros.bank}`);
      setBank(response.data);
    }

    if (filtros.bank) {
      verify();
      setTimeout(() => {
        getThisMonth_And_Before_After();
        getBalances();
        getConciliacao();
        getBankInfo();
        setBlock(false);
      }, 200);
    }
  }, [filtros.bank, filtros.date, block, filial, conciliacao]);



  async function handleFile(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("s3files/" + profile.id, data);

    const { id: s3file_id, name } = response.data;

    await api.put(`bank_balance/pdf/${balance.id}`, { s3file_id });

    approveBalance(balance);

  }
  async function handlerBankBalance(isConcialized) {
    // if(totals.final < 0) {
    //   toast.info(
    //     `Impossível encessar`,
    //     { className: "info" }
    //   );
    //   return;
    // }
    const response = await api.get(`get_bank_balance/?balance=${balance.id}`);
    const { data } = response;

    // if(!isConcialized) {
    //   toast.error(
    //     `Finalize a conciliação para fechar o período.`,
    //     { className: "error" }
    //   );
    //   return;
    // }

    if(!data.approved_at) {
        toast.info(
          `Por favor, anexe o PDF do Extrato Bancário para continuar.`,
          { className: "info" }
        );
      document.getElementById("extract_pdf").click();
    } else {
      approveBalance(balance);
    }

    return;
  }

  async function approveBalance(balance) {
    try {
      await api.put(`bank_balance/approve/${balance.id}`, {
        approved_by: profile.id,
        total: totals.final
      });
      setBlock(true);
      toast.success(
        `Período ${balance.approved_at ? "reaberto" : "fechado"} com sucesso!`,
        { className: "success" }
      );
    } catch (err) {
      toast.error(
        `Impossível ${
          balance.approved_at ? "reabrir" : "fechar"
        } este Período.`,
        { className: "error" }
      );
    }
  }

  function handleCardParcels(bankBalance) {
    setOpen_card_parcels(bankBalance);
  }

  function handleConciliacao(hasFile, file) {
    setNewFile(file)
    setConciliacao(!conciliacao)
    setIsConciliado(true)
    setBlock(true)
  }

  async function MakeReporting() {
    const id = filtros.bank;
    const response = await api.post(`/pdf/bank_balance/${id}`, { balance, bankBalances, totals });
    const response2 = await api.get(`/pdf/bank_balance/${id}`, { responseType: 'blob'});
    const pdfBlob = new Blob([response2.data], { type: 'application/pdf' });
    saveAs(pdfBlob, response.data.name);
    await api.delete(`/pdf/bank_balance/${id}`);
  }

  return (
    <>
      <div className="gradeFiltros">
        <p>Filtros:</p>
        <BankFilter>
          {tooltipe && !filtros.bank ? (
            <ToolTipe>
              <button onClick={() => setTootipe(false)}>
                <FaTimes size={16} color="#686868cc" />
              </button>
              Escolha um banco!
            </ToolTipe>
          ) : null}

          <FaUniversity size={16} />
          <Select
            name="bank"
            options={banks}
            defaultValue={filtros.bank ? filtros.bank : ''}
            placeholder="Selecionar banco..."
            style={{ width: "200px" }}
            onChange={e =>
              setFiltros({ ...filtros, bank: e.target.value, date: new Date() })
            }
          />
        </BankFilter>
        <DataFilter>
          <FaCalendarAlt size={16} />
          <DatePicker
            name="date"
            showMonthYearPicker
            selected={filtros.date}
            placeholderText="Escolha data"
            dateFormat="MMMM/yyyy"
            onChange={date => setFiltros({ ...filtros, date: new Date(date) })}
            minDate={parseISO(minDate)}
            maxDate={new Date()}
            locale={pt}
          />
        </DataFilter>
        { verifyAccess(accesses,'relatorio_bancario_mensal') && filtros.date && filtros.bank ?
        <New
          type="button"
          onClick={() =>
            MakeReporting()
          }
        >
          <FaPrint size={14} style={{ marginRight: "15px" }} /> Imprimir Período
        </New>
        : null }

            {verifyAccess(accesses,'fechamento_bancario') && balance.id &&
              before &&
              before.approved_at &&
              !after.approved_at &&
              (balance.approved_at ? (
                <>
                  <New type="button" onClick={() => handlerBankBalance(true)}>
                    <FaLockOpen size={14} style={{ marginRight: "15px" }} />
                    Reabrir Período
                  </New>
                  <New type="button" onClick={() => window.open(pdf)}>
                    <FaPrint size={14} style={{ marginRight: "15px" }} />
                    Extrato Importado
                  </New>
                </>
              ) : (
                <>
                  <New type="button" onClick={() => handlerBankBalance(isConciliado)}>
                    <FaLock size={14} style={{ marginRight: "15px" }} /> Fechar
                    Período
                  </New>
                  { file && verifyAccess(accesses,'conciliacao_bancaria') ?
                  <>
                    <New type="button" onClick={() => handleConciliacao(true)}>
                      <FaLaptopCode size={20} style={{ marginRight: "15px" }} /> Abrir Conciliação
                    </New>
                    <New type="button" onClick={() => handleConciliacao(false, file)}>
                      <FaLaptopCode size={20} style={{ marginRight: "15px" }} /> Somar arquivo
                    </New>
                  </>
                  :
                  verifyAccess(accesses,'conciliacao_bancaria') && !file ?
                  <New type="button" onClick={() => handleConciliacao(false)}>
                    <FaLaptopCode size={20} style={{ marginRight: "15px" }} /> Conciliar
                  </New>
                  : null
                  }
                </>
              ))}
      </div>
        { bank ?
        <>
      <GradeTotals>
        <input
          type="file"
          id="extract_pdf"
          name="files"
          accept=".pdf"
          style={{ display: "none" }}
          onChange={e => handleFile(e)}
        />
        <div>
          <span>Saldo Inicial</span>
          <FaHistory size={20} color="#555" /> {bank.country.currency}{" "}
          {totals.inicial
            ? formatMoney(totals.inicial.toFixed(2), bank.country.language)
            : formatMoney(0, bank.country.language)}
        </div>
        <div className="sum">
          <span>Créditos</span>
          <FaPlus size={20} color="#555" /> {bank.country.currency}{" "}
          {formatMoney(totals.creditos.toFixed(2), bank.country.language)}
        </div>
        <div className="sub">
          <span>Débitos</span>
          <FaMinus size={20} color="#555" /> {bank.country.currency}{" "}
          {formatMoney(totals.debitos.toFixed(2), bank.country.language)}
        </div>
        <div>
          <span>Saldo Final</span>
          <FaEquals size={20} color="#555" /> {bank.country.currency}{" "}
          {totals.final
            ? formatMoney(totals.final.toFixed(2), bank.country.language)
            : formatMoney(0, bank.country.language)}
        </div>
      </GradeTotals>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            {/* <td>Lançamento</td> */}
            <td>Descrição</td>
            <td>Valor</td>
            <td>Data de Baixa</td>
            <td>Débito/Crédito</td>
            <td>Origem</td>
            <td>Filial</td>
            <td>Conciliado em</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {bankBalances.map((bankBalance, index) => (
            <tr key={index} className={bankBalance.concialized_at || bankBalance.conciliation && bankBalance.conciliation.card_conciliation_date ? 'conciliated' : ''}>
              <td>
                {
                  bankBalance.invoice ? (
                    <>
                      {bankBalance.invoice.reason}{" "}
                      {bankBalance.invoice.parcels > 0
                        ? " | Parcela " +
                          bankBalance.parcel +
                          " / " +
                          bankBalance.invoice.parcels
                        : null}
                    </>
                  ) : bankBalance.cards ? (
                    <>
                      Lançamento de Dízimos e Ofertas
                      {bankBalance.cards.parcels > 0
                        ? " | Parcela " +
                          bankBalance.parcel +
                          " / " +
                          bankBalance.cards.parcels
                        : null}
                    </>
                  ) : bankBalance.card_parcel ? (
                    <>
                      {bankBalance.conciliation ? bankBalance.conciliation.historic : 'Crédito em Cartão de Crédito'} <CardsReview onClick={() => bankBalance.card_parcel ? handleCardParcels(bankBalance) : null}><FaSearchDollar size={16} color="#fff" /> Visualizar cartões</CardsReview>
                    </>
                  ) : bankBalance.fixedCost ? (
                    <>
                      {bankBalance.fixedCost.description}{" "}
                      {bankBalance.fixedCost.validity_quantity > 0
                        ? " | Parcela " +
                          bankBalance.parcel +
                          " / " +
                          bankBalance.fixedCost.validity_quantity
                        : null}
                    </>
                ) : (
                  bankBalance.number ||
                  bankBalance.observation ||
                  bankBalance.reason
                )}
              </td>
              <td>
              {bank.country.currency}{" "}{formatMoney(
                  bankBalance.value.toFixed(2),
                  bank.country.language
                )}
              </td>
              <td>
              {format(parseISO(bankBalance.origin === 'Ordem de Pagamento'
                    ? bankBalance.due_date
                    : bankBalance.origin === 'Transferência'
                    ? bankBalance.extract_at
                    : bankBalance.origin === 'Custo Fixo'
                    ? bankBalance.approved_at
                    : bankBalance.origin === 'Rel. de Dizimos e Ofertas'
                    ? bankBalance.date
                    : bankBalance.origin === 'Cartão de Crédito'
                    ? bankBalance.card_parcel.due_date
                    : bankBalance.conciliation
                    ? bankBalance.conciliation.date
                    : bankBalance.date
                    ? bankBalance.date
                    : bankBalance.extract_at || bankBalance.approved_at),profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}
              </td>
              <td>
                {bankBalance.origin === 'Transferência' && bankBalance.bank_from_id ?
                (<div className="balance_debit">DÉBITO</div>) :
                bankBalance.origin === 'Transferência' && !bankBalance.bank_from_id ?
                (<div className="balance_credit">CRÉDITO</div>)
                :
                 bankBalance.transaction === "Débito" ? (
                  <div className="balance_debit">DÉBITO</div>
                ) : (
                  <div className="balance_credit">CRÉDITO</div>
                )}
              </td>
              <td>
                {bankBalance.invoice
                  ? bankBalance.invoice.type
                  : bankBalance.cards
                  ? "Dízimos e Ofertas"
                  : bankBalance.origin}
              </td>
              <td>{bankBalance.fixedCost && bankBalance.fixedCost.to_filial ? bankBalance.fixedCost.to_filial.name : bankBalance.invoice && bankBalance.invoice.to_filial ? bankBalance.invoice.to_filial.name : bankBalance.to_filial ? bankBalance.to_filial.name : bankBalance.filial ? bankBalance.filial.name : bankBalance.card_parcel ? bankBalance.card_parcel.filial.name : ''}</td>
              <td>
              {bankBalance.concialized_at ? format(parseISO(bankBalance.concialized_at.substring(0,10)),profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy') : bankBalance.conciliation && bankBalance.conciliation.card_conciliation_date ? format(parseISO(bankBalance.conciliation.card_conciliation_date.substring(0,10)),profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy') : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </>
      : null }
      {conciliacao ? (
        <>
          <BgBlack />
          <Conciliacao
            handleConciliacao={handleConciliacao}
            period={filtros.date}
            bank_id={filtros.bank}
            setNewFile={setNewFile}
            newFile={newFile}
            file={file}
            setFile={setFile}
          />
        </>
      ) : null}
      {open_card_parcels ? (
        <>
        <BgBlack />
        <CardParcels
        open_card_parcels={open_card_parcels}
        setOpen_card_parcels={setOpen_card_parcels}
        bank={bank}
        />
        </>
      ) : null}
    </>
  );
}

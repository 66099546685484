import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addHours } from "date-fns";
import api from "~/services/api";
import * as Yup from "yup";

import pt from "date-fns/locale/pt";

import { toast } from "react-toastify";

import { formatMoney, formatNum } from "~/scripts/index.js";

import {
  FaCalendarAlt,
  FaMoneyBillWave,
  FaAngleRight,
  FaUniversity,
  FaCommentDots
} from "react-icons/fa";

import { Form, Input, Select } from "@rocketseat/unform";
import { Content, Container, Scroll } from "./styles";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Title from "~/components/Title";

export default function CreateTransfer({
  id,
  edit,
  handleCreateTransfer,
  setBlock,
  MakeReporting
}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  let [banksFrom, setBanksFrom] = useState([]);
  let [banksTo, setBanksTo] = useState([]);
  let bankFrom = [{ id: 0, title: "Caixa Filial" }];
  let bankTo = [{ id: 0, title: "Caixa Filial" }];
  // let [finish, setFinish] = useState(false);
  const [load, setLoad] = useState(false);
  let [transfer, setTransfer] = useState({
    created_by: profile.id,
    filial_id: filial.filial,
    bank_to_id: null,
    bank_from_id: null,
    value: "",
    date: new Date(),
    extract_at: new Date(),
    created_at: new Date()
  });

  const schemaTransf = Yup.object().shape({
    money: Yup.string().required("Valor obrigatório."),
    // bank_from: Yup.mixed().test({
    //   test: (value) => value !== transfer.bank_id, message: "Contas devem ser diferentes.",
    // }),
    // bank: Yup.mixed().test({
    //   test: (value) => value !== transfer.bank_from_id, message: "Contas devem ser diferentes.",
    // }),
    observation: Yup.string().required("Observação obrigatória.")
  });

  useEffect(() => {
    async function getBanks() {
      const response = await api.get("/banks/all/");
      const { data } = response;
      let promises = [];
      data.map(d => {
        return promises.push(
          new Promise((resolve, reject) => {
            if (
              !profile.filial.is_regional &&
              d.filial_id === profile.filial_id
            ) {
              resolve(bankFrom.push({ id: d.id, title: d.name }));
            }
            if (
              profile.filial.is_regional &&
              (profile.filial_id === d.filial_id || d.filial.regional_id)
            ) {
              resolve(bankFrom.push({ id: d.id, title: d.name }));
            }
            resolve(bankTo.push({ id: d.id, title: d.name }));
          })
        );
      });
      Promise.all(promises).then(async results => {
        setBanksFrom(bankFrom);
        setBanksTo(bankTo);
      });
    }
    async function loadTransfer() {
      const response = await api.get("/transfers/id/" + id);
      const { data } = response;
      setTransfer(data);
    }
    if (id) {
      loadTransfer();
    }
    getBanks();
  }, [id, profile.filial_id]);

  // useEffect(() => {
  //   const { value, bank_id } = transfer;
  //   if (value && bank_id) {
  //     setFinish(true);
  //   } else {
  //     setFinish(false);
  //   }
  // }, [transfer]);

  function setDate(date) {
    setTransfer({ ...transfer, date, extract_at: date });
  }

  async function handleSubmit() {
    setLoad(true);
    if (
      transfer.bank_from_id === transfer.bank_to_id ||
      (!transfer.bank_from_id && !transfer.bank_to_id)
    ) {
      setLoad(false);
      toast.error("As contas devem ser diferentes.", {
        className: "error"
      });
      return;
    }

    try {
      const response = id
        ? await api.put("/transfers/edit/" + id, transfer)
        : await api.post("/transfers", transfer);
      toast.success(
        `Transferência ${id ? "alterado" : "incluído"} com sucesso!`,
        { className: "success" }
      );
      MakeReporting(response.data.id);

      setBlock(true);
      handleCreateTransfer(false);
      setLoad(false);
      return;
    } catch (err) {
      toast.error("Há algum erro, por favor, revise o lançamento.", {
        className: "error"
      });
      setLoad(false);
    }
  }

  return (
    <Scroll>
      <Content>
        {load && (
          <div className="content-load">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <header>
          <Title back={true} func={handleCreateTransfer} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Nova transferência</h1>
          <Form
            schema={schemaTransf}
            initialData={transfer}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="rowInputs">
              <label htmlFor="nome">
                <img
                  src={
                    profile.avatar
                      ? profile.avatar.url
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrdEvvb_A3fTRZ9FTzpCkDacPDXBYoWgsoxbFbcYnB-jWigqa6w&s"
                  }
                  width="54"
                  height="54"
                  alt=""
                />
                <Input
                  name="nome"
                  readOnly="readonly"
                  value={profile.nome}
                  placeholder="Data"
                />
              </label>
              <label htmlFor="date">
                <div>
                  <FaCalendarAlt size={20} />
                  <br />
                  Data
                </div>
                <DatePicker
                  name="date"
                  minDate={
                    new Date(
                      filial.open_date.substring(0, 4),
                      parseInt(filial.open_date.substring(5, 7)) - 1,
                      filial.open_date.substring(8, 10)
                    )
                  }
                  maxDate={new Date()}
                  selected={addHours(new Date(transfer.date), 3)}
                  onChange={date => setDate(date)}
                  dateFormat={
                    profile.language === "pt-BR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                  }
                  locale={pt}
                />
              </label>
              <label htmlFor="money">
                <div>
                  <FaMoneyBillWave size={20} />
                  <br />
                  Dinheiro
                </div>
                <Input
                  name="money"
                  readOnly={edit ? null : "readonly"}
                  placeholder="0,00"
                  onChange={e =>
                    setTransfer({
                      ...transfer,
                      value: formatNum(e.target.value)
                    })
                  }
                  value={
                    transfer.value
                      ? formatMoney(
                          transfer.value.toFixed(2),
                          filial.country.language
                        )
                      : ""
                  }
                />
              </label>
            </div>
            <hr />

            <div className="rowInputs">
              <label htmlFor="bank_from">
                <div>
                  <FaUniversity size={20} />
                  <br />
                  De
                </div>
                <Select
                  name="bank_from"
                  disabled={!edit}
                  placeholder="Caixa Filial"
                  readOnly={edit ? null : "readonly"}
                  options={banksFrom}
                  value={transfer.bank_from_id ? transfer.bank_from_id : ""}
                  onChange={e =>
                    setTransfer({ ...transfer, bank_from_id: e.target.value })
                  }
                />
              </label>
              <label htmlFor="bank_to">
                <div>
                  <FaUniversity size={20} />
                  <br />
                  Para
                </div>
                <Select
                  name="bank_to"
                  disabled={!edit}
                  placeholder="Caixa Filial"
                  readOnly={edit ? null : "readonly"}
                  options={banksTo}
                  value={transfer.bank_to_id ? transfer.bank_to_id : ""}
                  onChange={e =>
                    setTransfer({ ...transfer, bank_to_id: e.target.value })
                  }
                />
              </label>
              <label htmlFor="observation">
                <div>
                  <FaCommentDots size={20} />
                  <br />
                  Observ.
                </div>
                <Input
                  name="observation"
                  readOnly={edit ? null : "readonly"}
                  value={transfer.observation}
                  onChange={e =>
                    setTransfer({ ...transfer, observation: e.target.value })
                  }
                />
              </label>
              {edit ? (
                <>
                  <button type="submit" className="btnFinish">
                    {id ? "Alterar" : "Finalizar"} <FaAngleRight size={20} />
                  </button>
                </>
              ) : null}
            </div>
          </Form>
        </Container>
      </Content>
    </Scroll>
  );
}

import { takeLatest, put, all } from "redux-saga/effects";
import { updatefunctionalities } from "./actions";

export function update({ payload }) {
  const { status } = payload.functionalities;
  put(updatefunctionalities(status));
}

export default all([
  takeLatest("@functionalities/FUNCTIONALITIES_UPDATE", update)
]);

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaAngleDown, FaAngleRight, FaTrash, FaSignature } from "react-icons/fa";
import { Container, ListMoedas } from './styles';
import SignatureCanvas from 'react-signature-canvas';

function TabelaConferencias({ conferencias, setConferencias }) {
  const [tableOpened, setTableOpened] = useState(true);
  function handleTable() {
    setTableOpened(!tableOpened);
  }
  function removeConf(i) {
    let newConf = [];

    for(let c = 0; c < conferencias.length; c++) {
      if(c !== i) {
        newConf.push({...conferencias[c]})
      }
    }
    setConferencias(newConf)
  }
  return (
  <Container>

    <h1 onClick={handleTable}>
      {tableOpened ? <FaAngleDown size={20} /> : <FaAngleRight size={20} />}
      Responsáveis pela Conferência
    </h1>


      {tableOpened ? (
        <ListMoedas>
          <li>
            <table>
              <thead>
                <tr>
                  <td>Nome</td>
                  <td>Assinatura</td>
                  <td>Remover</td>
                </tr>
              </thead>
              <tbody>
                {conferencias.length > 0 ? (
                  conferencias.map((conf, index) =>
                      <tr key={index}>
                        <td>{conf.responsavel}</td>
                        {/* <td>{conf.sign ? <FaSignature size={20} /> : 'Assinatura não coletada.' }</td> */}
                        <td><img src={conf.sign} height="30" /></td>
                        <td><FaTrash size={16} onClick={() => removeConf(index)} /></td>
                      </tr> )
                  )
                  :  (<tr>
                    <td colSpan={3} style={{ textAlign: "inherit" }}>
                      Não há responsáveis pela conferência.
                    </td>
                  </tr>) }
              </tbody>
            </table>
          </li>
        </ListMoedas>
      ) : null }
  </Container>
  );
}

export default TabelaConferencias;

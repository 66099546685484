import styled from "styled-components";

import { lighten } from "polished";

export const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 64px;
  background: #888;
  padding: 0 30px;
  border-bottom: 2px solid #777;
  z-index: 10;
`;

export const Content = styled.div`
  height: 64px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;

    svg {
      margin-right: 15px;
    }

    img {
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid ${lighten(0.08, "#555")};
    }

    a {
      color: #fff;
      font-weight: bold;
      font-size: 14px;
    }
  }

  aside {
    display: flex;
    align-items: center;
  }
`;

export const Profile = styled.div`
  display: flex;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid ${lighten(0.08, "#555")};

  div {
    text-align: right;
    margin-right: 10px;

    strong {
      display: block;
      color: #fefefe;
    }

    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: #fefefe;
    }
  }

  img {
    height: 32px;
    border-radius: 4px;
  }
`;

export const Search = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin-left: 40px;
  border-radius: 17px;
  border: 1px solid rgba(255, 255, 255, 0.2);

  input {
    background: transparent;
    border: none;
    padding: 0 20px;
    font-size: 13px;
    height: 34px;
    color: #fff;
    width: 100%;
  }

  svg {
    margin-right: 10px;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.2);
  }
`;

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import * as Yup from "yup";

import { Form, Input, Select } from "@rocketseat/unform";
import { formatMoney, formatNum } from "~/scripts/index.js";

import {
  FaFile,
  FaTimesCircle,
  FaAngleRight,
  FaSearch,
  FaPercent,
  FaCalendarCheck,
  FaChartPie,
  FaUniversity
} from "react-icons/fa";

import Title from "~/components/Title";

import { Scroll, Content, Container } from "./styles";

import BuscarBanco from "~/components/BuscarBanco";

import api from "~/services/api";

function CreateSector({ setCreateOperator, edit, id, setBlock }) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [operator, setOperator] = useState({
    code: null,
    name: "",
    debit_rate: 0,
    debit_days_to_credit: null,
    installment_fee_1: 0,
    installment_fee_2: 0,
    credit_rate: 0,
    bank_id: null,
    created_by: profile.id,
    filial_id: filial.filial,
    created_at: new Date()
  });
  const [buscarBanco, setBuscarBanco] = useState(false);
  let [finish, setFinish] = useState(false);
  let [bank, setBank] = useState({ name: "" });

  useEffect(() => {
    async function confirmOperator() {
      let {
        name,
        debit_rate,
        debit_days_to_credit,
        installment_fee_1,
        installment_fee_2,
        credit_rate,
        bank_id,
        credit_days_to_credit
      } = operator;

      name &&
      debit_rate &&
      debit_days_to_credit &&
      installment_fee_1 &&
      installment_fee_2 &&
      bank_id &&
      credit_days_to_credit &&
      credit_rate
        ? setFinish(true)
        : setFinish(false);
    }
    confirmOperator();
  }, [operator]);

  useEffect(() => {
    async function getOperator() {
      const response = await api.get(`operator/${id}`);
      setOperator(response.data);
      setBank(response.data.bank);
    }
    if (id) {
      getOperator();
      // setBank(operator.bank);
    }
  }, [id]);

  async function handleSubmit() {
    try {
      !id
        ? await api.post("operator", operator)
        : await api.put(`operator/${operator.id}`, operator);

      toast.success(
        `Operadora ${id ? "Atualizada" : "inserida"} com sucesso!`,
        {
          className: "success"
        }
      );
      setBlock(true);
      setCreateOperator(false);
    } catch (err) {
      toast.error(`Erro ao ${id ? "Atualizar" : "inserir"} Operadora!`, {
        className: "error"
      });
    }
  }

  function fecharBuscaBanco() {
    setBuscarBanco(false);
  }

  function setIdBank(value) {
    setBuscarBanco(false);
    setBank(value);
    setOperator({ ...operator, bank_id: value.id });
  }

  const options = [
    { id: 1, title: "1 dia" },
    { id: 30, title: "Mês" }
  ];

  const schema = Yup.object().shape({});

  return (
    <>
      <Scroll>
        <div className="overlay"></div>

        <Content>
          <header>
            <Title
              back={true}
              func={buscarBanco ? fecharBuscaBanco : setCreateOperator}
              title={"Cancelar"}
            />
          </header>
          <Container>
            <h1>{buscarBanco ? "Buscar Banco" : "Nova operadora"}</h1>
            {buscarBanco ? (
              <BuscarBanco handleBank={setIdBank} />
            ) : (
              <Form
                schema={schema}
                initialData={operator}
                onSubmit={handleSubmit}
              >
                <div className="rowInputs">
                  <label htmlFor="name">
                    <div>
                      <FaFile size={20} />
                      <br />
                      Nome
                    </div>
                    <Input
                      name="name"
                      placeholder="Nome"
                      readOnly={edit ? null : "readonly"}
                      onChange={e =>
                        setOperator({ ...operator, name: e.target.value })
                      }
                      value={operator.name}
                    />
                  </label>
                </div>
                <h1>Operações Débito a Vista</h1>
                <div className="rowInputs">
                  <label htmlFor="debit_rate">
                    <div>
                      <FaPercent size={18} />
                      <br />
                      Taxa.
                    </div>
                    <Input
                      name="debit_rate"
                      placeholder="Taxa de operação de débito"
                      readOnly={edit ? null : "readonly"}
                      onChange={e =>
                        setOperator({
                          ...operator,
                          debit_rate: formatNum(e.target.value)
                        })
                      }
                      value={formatMoney(
                        operator.debit_rate.toFixed(2),
                        filial.country.language
                      )}
                    />
                  </label>
                  <label htmlFor="debit_days_to_credit">
                    <div>
                      <FaCalendarCheck size={20} />
                      <br />
                      Dias.
                    </div>
                    <Select
                      name="debit_days_to_credit"
                      placeholder="Dias para creditar"
                      disabled={edit ? false : true}
                      onChange={e =>
                        setOperator({
                          ...operator,
                          debit_days_to_credit: e.target.value
                        })
                      }
                      options={options}
                      value={operator.debit_days_to_credit}
                    />
                  </label>
                </div>
                <h1>Operações Crédito a Vista e Parcelado</h1>
                <div className="rowInputs">
                  <label htmlFor="credit_rate">
                    <div>
                      <FaChartPie size={20} />
                      <br />
                      Cred. 1x
                    </div>
                    <Input
                      name="credit_rate"
                      placeholder="Taxa de Crédito a vista"
                      readOnly={edit ? null : "readonly"}
                      onChange={e =>
                        setOperator({
                          ...operator,
                          credit_rate: formatNum(e.target.value)
                        })
                      }
                      value={formatMoney(
                        operator.credit_rate.toFixed(2),
                        filial.country.language
                      )}
                    />
                  </label>
                  <label htmlFor="installment_fee_1">
                    <div>
                      <FaChartPie size={20} />
                      <br />2 a 6x
                    </div>
                    <Input
                      name="installment_fee_1"
                      placeholder="Taxa de parcelamento 2 a 6"
                      readOnly={edit ? null : "readonly"}
                      onChange={e =>
                        setOperator({
                          ...operator,
                          installment_fee_1: formatNum(e.target.value)
                        })
                      }
                      value={formatMoney(
                        operator.installment_fee_1.toFixed(2),
                        filial.country.language
                      )}
                    />
                  </label>
                  <label htmlFor="installment_fee_2">
                    <div>
                      <FaChartPie size={20} />
                      <br />7 a 12x
                    </div>
                    <Input
                      name="installment_fee_2"
                      placeholder="Taxa de parcelamento 7 a 12"
                      readOnly={edit ? null : "readonly"}
                      onChange={e =>
                        setOperator({
                          ...operator,
                          installment_fee_2: formatNum(e.target.value)
                        })
                      }
                      value={formatMoney(
                        operator.installment_fee_2.toFixed(2),
                        filial.country.language
                      )}
                    />
                  </label>
                  <label htmlFor="credit_days_to_credit">
                    <div>
                      <FaCalendarCheck size={20} />
                      <br />
                      Dias
                    </div>
                    <Select
                      name="credit_days_to_credit"
                      placeholder="Dia para creditar"
                      disabled={edit ? false : true}
                      onChange={e =>
                        setOperator({
                          ...operator,
                          credit_days_to_credit: e.target.value
                        })
                      }
                      options={options}
                      value={operator.credit_days_to_credit}
                    />
                  </label>
                </div>
                <h1>Banco de Recebimento</h1>
                <div className="rowInputs">
                  <label htmlFor="bank">
                    <div>
                      <FaUniversity size={20} />
                      <br />
                      Banco
                    </div>
                    <Input
                      name="bank"
                      value={bank.name}
                      placeholder="Banco"
                      readOnly={"readonly"}
                    />
                  </label>
                  {edit && (
                    <label htmlFor="search" style={{ maxWidth: "min-content" }}>
                      <button
                        type="button"
                        className="btnFinish"
                        onClick={() => setBuscarBanco(true)}
                      >
                        Buscar
                        <FaSearch size={20} />
                      </button>
                    </label>
                  )}
                  {edit ? (
                    <button
                      type="submit"
                      className="btnFinish"
                      finish={finish.toString()}
                      disabled={!finish}
                      title={
                        !finish
                          ? "Há campos obrigatórios não preenchidos!"
                          : "Finalizar"
                      }
                    >
                      {finish ? (
                        <>
                          {!id ? "Finalizar" : "Atualizar"}
                          <FaAngleRight size={20} />
                        </>
                      ) : (
                        <>
                          ... <FaTimesCircle size={20} />
                        </>
                      )}
                    </button>
                  ) : null}
                </div>
              </Form>
            )}
          </Container>
        </Content>
      </Scroll>
    </>
  );
}

export default CreateSector;

import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  align-items: center;
  color:#FFF;
  font-size:14px;
`;


export const Back = styled.button`
display:flex;
align-items:center;
background:rgba(255,255,255,.7);
color:#333;
margin:0 15px;
padding:7px 8px;
border:none;
border-radius:3px;

`;

export default function retLanguage(lang = "pt-BR") {
  if (!lang) {
    lang = "pt-BR";
  }
  const ptBR = {
    header: {
      h1: "Painel",
      h2: "Notificações",
      h3: "Trocar Filial",
      h4: "Meu Perfil"
    },
    menu: {
      h1: "Abrir / Fechar",
      h2: "Contas a Receber",
      h3: "Contas a Pagar",
      h4: "Cadastros",
      h5: "Configurador",
      h6: "Config. PDV",
      h7: "Extrato de Movimentação",
      breadcrumbs: {
        h2: {
          p1: "Dízimos e Ofertas",
          p2: "e-Dízimos",
          p3: "Carteira de Cheques",
          p4: "Carteira de Cartões"
        },
        h3: {
          p1: "Transferências",
          p2: "Ordem de Pagamento",
          p3: `NF's de Entrada`,
          p4: "Custos Fixos"
        },
        h4: {
          p1: "Agentes",
          p2: "Filiais",
          p3: `Usuários`,
          p4: "Bancos",
          p5: "Plano de Contas"
        },
        h5: {
          p1: "Grupo de Usuários",
          p2: "Config. E-mail",
          p3: `Setores`
        },
        h6: {
          p1: "Operadoras",
          p2: `PDV's`,
          p3: "Conciliação de Cartões"
        },
        h7: {
          p1: "Caixa da Filial",
          p2: `Extrato Bancário`
        }
      }
    },
    grade: {
      buttons: {
        b1: "Novo",
        b2: "Filtros",
        b3: "Buscar",
        b4: "Hoje",
        b5: "Lançamento"
      },
      pages: {
        p1: {
          header: {
            h1: "Lançamento",
            h2: "Data",
            h3: "Status",
            h4: "Setor",
            h5: "Período",
            h6: "Dinheiro",
            h7: "Cheques",
            h8: "Cartão de Débito",
            h9: "Cartão de Crédito",
            h10: "Total"
          }
        },
        p2: {
          header: {
            h1: "Lançamento",
            h2: "Data",
            h3: "Valor",
            h4: "Observação",
            h5: "Setor",
            h6: "Banco",
            h7: "Status"
          }
        }
      }
    },
    profile: {
      p1: "Nome Completo",
      p2: "Seu endereço de e-mail",
      p3: "Selecione um idioma",
      p4: "Sua senha atual",
      p5: "Sua nova senha",
      p6: "Confirme a nova senha",
      p7: "Atualizar perfil",
      p8: "Sair",
      p9: "Perfil atualizado com sucesso!"
    },
    accounts: {
      name: "Plano de Contas",
      header: {
        h1: "Novo +",
        h2: "Buscar",
        h3: "Nome"
      },
      titles: {
        t1: "Código",
        t2: "Nome",
        t3: "Natureza",
        t4: "Grupo"
      },
      buttons: {
        b1: "Próxima",
        b2: "Última",
        b3: "Anterior",
        b4: "Primeira"
      }
    },
    dashboard: {
      createUser: {
        a1:
          "Apenas filiais selecionadas podem ser escolhidas como filial principal.",
        a2:
          "Não é possível desmarcar a filial principal. Selecione outra filial como principal para prosseguir.",
        b1: "Definir filial principal",
        b2: "Filial principal"
      }
    },
    buttons: {
      b1: "Aprovar",
      b2: "Relatório",
      b3: "Visualizar",
      b4: "Editar",
      b5: "Excluir",
      b6: "Reclassificar",
      b7: "Estornar Aprovação"
    },
    errors: {
      e1: "Erro ao atualizar perfil, confira seus dados.",
      e2: "Este endereço de e-mail já está sendo utilizado por outro usuário.",
      e3: "Usuário não existe.",
      e4:
        "A nova senha não é igual a confirmação, ou não atende os requisitos.",
      e5: "Senha incorreta."
    },
    success: {
      s1: "Usuário inserido com sucesso!",
      s2: "Usuário alterado com sucesso!"
    }
  };

  const enUS = {
    header: {
      h1: "Dashboard",
      h2: "Notifications",
      h3: "Switch Filial",
      h4: "My Profile"
    },
    menu: {
      h1: "Open / Close",
      h2: "Inbounds",
      h3: "Outbounds",
      h4: "Registrations",
      h5: "Configurator",
      h6: "POS Config",
      h7: "Cashflow",
      breadcrumbs: {
        h2: {
          p1: "Tithes and Offerings",
          p2: "e-Tithes",
          p3: "Check Wallet",
          p4: "Card Wallet"
        },
        h3: {
          p1: "Transfers",
          p2: "Payment Orders",
          p3: `Invoices`,
          p4: "Fixed Costs"
        },
        h4: {
          p1: "Agents",
          p2: "Filials",
          p3: `Users`,
          p4: "Banks",
          p5: "Chart of accounts"
        },
        h5: {
          p1: "User Groups",
          p2: "E-mail Config.",
          p3: `Sectors`
        },
        h6: {
          p1: "Card Operator",
          p2: `POS`,
          p3: "Card Conciliation"
        },
        h7: {
          p1: "Cashflow",
          p2: `Bank Extract`
        }
      }
    },
    grade: {
      buttons: {
        b1: "New",
        b2: "Filters",
        b3: "Search",
        b4: "Today",
        b5: "ID"
      },
      pages: {
        p1: {
          header: {
            h1: "ID",
            h2: "Date",
            h3: "Status",
            h4: "Sector",
            h5: "Period",
            h6: "Money",
            h7: "Check",
            h8: "Debit Card",
            h9: "Credit Card",
            h10: "Total"
          }
        },
        p2: {
          header: {
            h1: "ID",
            h2: "Date",
            h3: "Value",
            h4: "Observations",
            h5: "Sector",
            h6: "Bank",
            h7: "Status"
          }
        }
      }
    },
    profile: {
      p1: "Full Name",
      p2: "Your e-mail address",
      p3: "Select and idiom",
      p4: "Your current password",
      p5: "A new password",
      p6: "Confirm your new password",
      p7: "Update profile",
      p8: "Exit",
      p9: "Profile updated!"
    },
    accounts: {
      name: "Chart of Accounts",
      header: {
        h1: "New +",
        h2: "Search",
        h3: "Name"
      },
      titles: {
        t1: "Code",
        t2: "Name",
        t3: "Nature",
        t4: "Group"
      },
      buttons: {
        b1: "Next",
        b2: "Last",
        b3: "Prev",
        b4: "First"
      }
    },
    dashboard: {
      createUser: {
        a1: "Only selected filials can be chosen as the main filial.",
        a2:
          "It is not possible to uncheck the main filial. Please select another filial as main to proceed.",
        b1: "Set as main filial",
        b2: "Main filial"
      }
    },
    buttons: {
      b1: "Approve",
      b2: "Report",
      b3: "View",
      b4: "Edit",
      b5: "Delete",
      b6: "Reclassify",
      b7: "Disapprove"
    },
    errors: {
      e1: "Error on update profile, please check your info.",
      e2: "This email address is already in use by another user.",
      e3: "User does not exist.",
      e4:
        "The new password does not equal confirmation, or does not meet the requirements.",
      e5: "Wrong password."
    },
    success: {
      s1: "User profile created successfully!",
      s2: "User profile changed successfully!"
    }
  };

  return eval(lang.replace("-", ""));
}

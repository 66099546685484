import React, { useState, useEffect } from "react";
import api from "~/services/api";
import { useSelector } from "react-redux";
import allowAccess from "~/scripts/menuController";

import "react-confirm-alert/src/react-confirm-alert.css";

import {
  New
} from "./styles";

import FiltroLancamento from "~/components/FiltroLancamento";
import CreateAgent from "./CreateAgent";

import "react-confirm-alert/src/react-confirm-alert.css";

import NavigationButtons from "~/components/NavigationButtons";

import { handleDialog, handleBlock, maskCGC, verifyAccess } from "~/scripts";

import { FaEye, FaEdit, FaTimes } from "react-icons/fa";

import BgBlack from "~/components/BgBlack";

export default function HomeboxAgents() {
  const profile = useSelector(state => state.user.profile);
  const [agents, setAgents] = useState([]);
  const [createAgent, setCreateAgent] = useState(false);
  const [openAgent, setOpenAgent] = useState(false);
  const [edit, setEdit] = useState(false);
  const [opennedCGC, setOpennedCGC] = useState();
  const [block, setBlock] = useState(false);
  const [find, setFind] = useState('');
  const [loading,setLoading] = useState(false);
  const [lancamento, setLancamento] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [countries, setCountries] = useState([]);
  const accesses = useSelector(state => state.auth.newaccesses);

  useEffect(() => {
    async function loadAgents() {
      try {
        const response = await api.get(`agents/?lancamento=${find}&page=${page}`);
        const { data } = response;
        setPages(data.pages);
        setAgents(lancamento ? data : data.agentExists);
      } catch(err) {

      }
    }
    loadAgents();

  }, [block,page, find, setAgents]);

  useEffect(() => {
    if(countries.length > 0) {
      let newAgents = agents;
      if(newAgents.length > 0){
        newAgents.map(ag => {
          countries.map(cn => {
            if(parseInt(ag.country) === cn.id) {
              ag.countryCode = cn.code;
            }
          })
        })
        setTimeout(() => {
          setAgents(newAgents);
          setLoading(true)
        },200)
      }
    }
  },[countries])

  function handleCreateAgent() {
    setEdit(!createAgent);
    setCreateAgent(!createAgent);
    if(createAgent){
      setBlock(true)
    }
  }

  function handleLancamento(e) {
    e.preventDefault();
    setLancamento(find);
  }

  function handleOpenAgent(cgc, edit) {
    setOpennedCGC(cgc);
    setEdit(edit);
    setOpenAgent(!openAgent);
    if(openAgent){
      setBlock(true)
    }
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses,'cadastro_agentes') ?
          <New type="button" onClick={() => handleCreateAgent()}>
            Novo +
          </New>
        : null}
        <p>Filtros:</p>
        <FiltroLancamento
          handleLancamento={handleLancamento}
          setFind={setFind}
          placeholder="Documento ou Nome"
        />
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>Código</td>
            <td>Documento</td>
            <td>Nome</td>
            <td>E-mail</td>
            <td>Telefone</td>
            <td>Endereço</td>
            {/* <td>País</td> */}
          </tr>
        </thead>
        <tbody className="gradeBody">
          { agents.length > 0 ? (
            agents.map((ag, index) => {
              return (
                <tr
                  key={ag.id}
                  id={ag.id}
                  className={`agents ${ag.canceled_at ? "canceled" : null}`}
                  onClick={() => handleDialog(ag, "agents")}
                >
                  <td style={{ width: "80px" }}>
                    {ag.id}
                    {verifyAccess(accesses,'cadastro_agentes') ? ag.canceled_at ? (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleBlock(
                              ag.cgc,
                              ag.canceled_at,
                              "agente",
                              "agents",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Desbloquear
                        </li>
                      </ul>
                    ) : (
                      <ul className="dialog">
                          <li onClick={() => handleOpenAgent(ag.cgc, false)}>
                            <FaEye size={12} /> Visualizar
                          </li>
                          <li onClick={() => handleOpenAgent(ag.cgc, true)}>
                            <FaEdit size={12} /> Alterar
                          </li>
                          <li
                            className="trash"
                            onClick={() =>
                              handleBlock(
                                ag.cgc,
                                ag.canceled_at,
                                "agente",
                                "agents",
                                setBlock
                              )
                            }
                          >
                            <FaTimes size={12} /> Bloquear
                          </li>
                      </ul>
                    ) : null}
                  </td>
                  <td>{maskCGC(ag.cgc)}</td>
                  <td>{ag.name}</td>
                  <td>{ag.email}</td>
                  <td>{ag.phone}</td>
                  <td>
                    {ag.country === "BR" ? ag.address +
                      ", " +
                      ag.number +
                      " - " +
                      ag.neighborhood +
                      " - " +
                      ag.city +
                      "," +
                      ag.state
                      :
                      ag.number + " "+ag.address + ", " + ag.city + ", "+ag.state}
                  </td>
                  {/* <td>
                    {ag.countryCode ? (
                      <>
                      <img
                        src={`/images/${ag.countryCode}.svg`}
                        width="26"
                      />
                      </>
                    ) : null}
                  </td> */}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6}>Não há agentes cadastrados.</td>
            </tr>
          )}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {openAgent ? (
        <>
          <BgBlack />
          <CreateAgent
            handleCreateAgent={handleOpenAgent}
            edit={edit}
            setAgents={setAgents}
            agents={agents}
            opennedCGC={opennedCGC}
            setOpennedCGC={setOpennedCGC}
          />
        </>
      ) : null}
      {createAgent ? (
        <>
          <BgBlack />
          <CreateAgent
            handleCreateAgent={handleCreateAgent}
            edit={true}
            setAgents={setAgents}
            agents={agents}
            opennedCGC={opennedCGC}
            setOpennedCGC={setOpennedCGC}
          />
        </>
      ) : null}
    </>
  );
}

import React, { useState, useEffect } from "react";

import api from "~/services/api";

import { Content, Container, Scroll, Title, Back } from "./styles";

import { formatMoney } from "~/scripts/index.js";

import { Form, Input } from "@rocketseat/unform";

import {
  FaRegAddressCard,
  FaArrowLeft,
} from "react-icons/fa";

export default function CardParcels({open_card_parcels, setOpen_card_parcels, bank}) {
  const [parcels,setParcels] = useState([]);

  useEffect(() => {
    async function getParcels() {
      const response = await api.get(`/get_card_parcels_from_bank_balance/${open_card_parcels.bank_id}/${open_card_parcels.card_parcel.filial_id}/${open_card_parcels.card_parcel.date}`)
      setParcels(response.data)
    }
    getParcels();
  }, [open_card_parcels])
  return (
    <Scroll>
      <Content style={{ maxWidth: "1100px", left: "calc(50% - 550px)" }}>
        <header>
          <Title>
            <Back type="button" onClick={() => setOpen_card_parcels(null)}>
              <FaArrowLeft size={14} />
            </Back>
            Voltar
          </Title>
        </header>
        <Container>
          <h1>Parcelas de Cartão de Crédito aglutinadas</h1>
          <Form>
          {parcels.map((parcel,index) =>
            (<div className="rowInputs" key={index}>
              <label htmlFor="operation">
                <div>
                  <FaRegAddressCard size={20} />
                  <br />
                  Oper.
                </div>
                <Input
                  name="operation"
                  readOnly={true}
                  value={parcel.cards.operation}
                />
              </label>
              <label htmlFor="flag">
                <div>
                  <FaRegAddressCard size={20} />
                  <br />
                  Band.
                </div>
                <Input
                  name="flag"
                  readOnly={true}
                  value={parcel.cards.flag}
                />
              </label>
              <label htmlFor="payment_id">
                <div>
                  <FaRegAddressCard size={20} />
                  <br />
                  Pay. ID
                </div>
                <Input
                  name="payment_id"
                  readOnly={true}
                  value={parcel.payment_id}
                />
              </label>
              <label htmlFor="value">
                <div>
                  <FaRegAddressCard size={20} />
                  <br />
                  Valor
                </div>
                <Input
                  name="value"
                  readOnly={true}
                  value={formatMoney(
                    parcel.value.toFixed(2),
                    bank.country.language
                  )}
                />
              </label>
            </div>)
          )}
          </Form>
        </Container>
      </Content>
    </Scroll>);
}

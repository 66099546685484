import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedFilialsRequest } from "~/store/modules/selectedFilials/actions";
import retLanguage from "~/config/language";
import { toast } from "react-toastify";
import "./style.css";

export default function GroupAccessSelectBox({
  children,
  name,
  id,
  regional_id,
  filials_info
}) {
  const selectedFilials = useSelector(state => state.selectedFilials.filials);
  const profile = useSelector(state => state.user.profile);
  const lang = retLanguage(profile.language);
  const dispatch = useDispatch();

  function handleMainFilial(e) {
    e.preventDefault();
    if (selectedFilials.selected.includes(id)) {
      dispatch(
        updateSelectedFilialsRequest({
          selected: selectedFilials.selected,
          main: id,
          changed: true
        })
      );
    } else {
      return toast.error(lang.dashboard.createUser.a1, { className: "error" });
    }
  }

  function handleSelectedFilials() {
    let temp = [...selectedFilials.selected];
    if (temp.includes(id)) {
      let filialsToUnselect = [id];
      let filialsToAnalise = [];
      filials_info.forEach(val => {
        if (id === val.regional_id) {
          filialsToUnselect.push(val.id);
          filialsToAnalise.push(val.id);
        }
      });
      while (filialsToAnalise.length > 0) {
        let tempFilialsToAnalise = [...filialsToAnalise];
        filialsToAnalise = [];
        filials_info.forEach(val => {
          if (tempFilialsToAnalise.includes(val.regional_id)) {
            filialsToUnselect.push(val.id);
            filialsToAnalise.push(val.id);
          }
        });
      }
      temp = temp.filter(val => {
        return filialsToUnselect.includes(val) ? false : true
      })
      if (filialsToUnselect.includes(selectedFilials.main)) {
        return toast.error(lang.dashboard.createUser.a2, { className: "error" });
      }
    } else {
      let filialsToAdd = [id];
      if (!temp.includes(regional_id) && regional_id !== null) {
        let tempInfo = { id: id, regional_id };
        while (tempInfo.regional_id !== null) {
          filials_info.forEach(val => {
            if (val.id === tempInfo.regional_id) {
              filialsToAdd.push(val.id);
              tempInfo = { id: val.id, regional_id: val.regional_id };
            }
          });
        }
      }
      temp.push(...filialsToAdd);
    }
    dispatch(
      updateSelectedFilialsRequest({
        selected: [...temp],
        main: selectedFilials.main,
        changed: true
      })
    );
  }

  return (
    <div className="group-access-select-box-container">
      <div className="group-access-select-box-area">
        <div
          className="group-access-select-box-checkbox"
          onClick={e => {
            handleSelectedFilials();
          }}
          style={{
            backgroundColor: selectedFilials.selected.includes(id)
              ? "rgb(155, 213, 49)"
              : "#fff"
          }}
        />
        <p>{name}</p>
        <button onClick={e => handleMainFilial(e)} className={selectedFilials.main === id ? 'group-access-select-box-button-active' : 'group-access-select-box-button-inactive'}>{selectedFilials.main === id ? lang.dashboard.createUser.b2 : lang.dashboard.createUser.b1}</button>
      </div>
      {children.length > 0 ?
        <div className="group-access-select-box-children">
          {children}
        </div>
        : null}
    </div>
  );
}

export default function allowAccess(access,component,permission) {
  let allow = false;
  if(permission === 'read'){
    allow = access.name === component && access.permissions.read;
  } else if(permission === 'create'){
    allow = access.name === component && access.permissions.create;
  } else if(permission === 'edit'){
    allow = access.name === component && access.permissions.edit;
  } else if(permission === 'delete'){
    allow = access.name === component && access.permissions.delete;
  } else if(permission === 'approve'){
    allow = access.name === component && access.permissions.approve;
  }

  return allow;
}

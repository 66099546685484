import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import Title from "~/components/Title";

import { toast } from "react-toastify";

import api from "~/services/api";

import { format, parseISO, startOfMonth, endOfMonth } from 'date-fns';
import pt from "date-fns/locale/pt";
import { formatMoney, formatNum } from "~/scripts/index.js";

import { Input, Select } from "@rocketseat/unform";

import { Scroll, Content, Container, DivFiles } from './styles';

import {
  FaCalendarAlt,
  FaMoneyBillWave,
  FaAngleRight,
  FaBuffer,
  FaIndustry,
  FaTrashAlt,
  FaUser,
  FaFileUpload,
  FaSearch,
  FaUniversity,
  FaCommentDots
} from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Despesas({handleDespesa, conciliationInfo, setBlock}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [chartOfAccount, setchartOfAccount] = useState({ code: "", nome: "" });
  const [minDate,setMinDate] = useState(parseISO(format(startOfMonth(parseISO(conciliationInfo[0].order_date)),'yyyy-MM-dd')));
  const [maxDate,setMaxDate] = useState(parseISO(format(endOfMonth(parseISO(conciliationInfo[0].order_date)),'yyyy-MM-dd')));
  const [form,setForm] = useState({
    date: minDate,
    value: 0
  })

  useEffect(() => {
    try {
      let provider_payment_order = {};
      let coa = {};
      let promises = [];
      promises.push(
        new Promise(async (resolve,reject) => {
          resolve(provider_payment_order = await api.get('/providers/?cgc=&name=CUPOM FISCAL'))
        })
      )
      promises.push(
        new Promise(async (resolve,reject) => {
          resolve(coa = await api.get('/planodecontas/id/16'))
        })
      )
      Promise.all(promises).then(async results => {
        setchartOfAccount(coa.data);
        setForm({ ...form,
          provider_id: provider_payment_order.data[0].id,
          plano_de_contas_id: 16,
          bank_id: conciliationInfo[0].bank.id,
          created_by: profile.id,
          filial_id: filial.filial,
          s3file_id: null,
          coo: '001',
          discount: 0,
          addition: 0,
          interest: 0,
          card_expense: true,
          deduct: true,
          date: minDate,
          due_date: minDate,
          approved_at: minDate,
          total: form.value,
          cupom_fiscal: format(minDate,'yyyyMMdd'),
          payment_method: 'Transferência',
          reason: "Despesa Cartões: "+conciliationInfo[0].month,
          created_at: new Date() })
      });
    } catch(err) {
      toast.error("Fornecedor Cupom Fiscal não está ativo. Ative-o para utilizar esta função.", {
        className: "error"
      });
    }
  }, [])

  function handleDate(date) {
    setForm({...form,
      date,
      cupom_fiscal: format(date,'yyyyMMdd'),
      due_date: format(date,'yyyy-MM-dd'),
      approved_at: format(date,'yyyy-MM-dd'),
    });
  }
  function handleValue(value) {
    setForm({...form,value: formatNum(value), total: formatNum(value)})
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if(!form.plano_de_contas_id) {
        toast.error("Por favor preencha o plano de contas!", {
          className: "error"
        });
        return;
      }
      if(!form.reason) {
        toast.error("Por favor preencha a despesa!", {
          className: "error"
        });
        return;
      }
      const response = await api.post("/paymentOrders", form);
      const { data } = response;
      toast.success(
        `Tarifa Bancária incluída com sucesso!`,
        { className: "success" }
      );
      handleDespesa();
      setBlock(true)
    } catch (err) {
      toast.error("Há algum erro, por favor, revise o lançamento.", {
        className: "error"
      });
    }
  }

  return (
    <>
    <Scroll>
      <Content>
        <header>
          <Title back={true} func={handleDespesa} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Lançamento por Conciliação</h1>
          <form onSubmit={e => handleSubmit(e)}>

            <div className="rowInputs">

              <label htmlFor="date">
                <div className='iconInput'>
                  <FaCalendarAlt size={20} />
                  <br />
                  Data
                </div>
                <DatePicker
                  name="date"
                  selected={form.date}
                  onChange={date => handleDate(date)}
                  minDate={minDate}
                  maxDate={maxDate}
                  dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                  locale={pt}
                />
              </label>
            </div>

            <hr />

            <div className="rowInputs">
              <label htmlFor="money">
                <div className='iconInput'>
                  <FaMoneyBillWave size={20} />
                  <br />
                  Valor
                </div>
                <Input
                  name="money"
                  value={form.value ? formatMoney(form.value.toFixed(2)) : 0}
                  onChange={e => handleValue(e.target.value)}
                />
              </label>
            </div>

            <hr />

            <div className="rowInputs">
              <label htmlFor="money">
                <div className='iconInput'>
                  <FaBuffer size={20} />
                  <br />
                  Tipo
                </div>
                <select onChange={e => setForm({...form,deduct: e.target.value})}>
                  <option disabled={true} selected={true}>Selecione...</option>
                  <option value={true}>Deduzir do saldo no dia</option>
                  <option value={false}>Lançamento avulso</option>
                </select>
              </label>
            </div>

            <hr />

            <div className="rowInputs">
              <label htmlFor="charts_of_account" style={{ flexGrow: "2" }}>
                <div className='iconInput'>
                  <FaUser size={20} />
                  <br />
                  Pla.Con
                </div>
                <Input
                  name="charts_of_account"
                  readOnly={"readonly"}
                  value={chartOfAccount ? `${profile.language === 'pt-BR' ? chartOfAccount.pt_name : chartOfAccount.en_name}` : ""}
                />
              </label>
            </div>

            <hr />

            <div className="rowInputs">
              <label htmlFor="observation">
                <div className='iconInput'>
                  <FaCommentDots size={20} />
                  <br />
                  Despesa
                </div>
                <Input
                  name="observation"
                  value={form.reason}
                  onChange={e => setForm({...form,reason: e.target.value})}
                />
              </label>
            </div>
            <hr/>

            <button
              type="submit"
              className="btnFinish"
            > Lançar <FaAngleRight size={20} />
            </button>
          </form>
        </Container>
      </Content>
    </Scroll>
    </>
  )
}

export default Despesas;

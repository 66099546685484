import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import allowAccess from "~/scripts/menuController";

import { FaEye, FaTimes, FaEdit } from "react-icons/fa";

import { handleBlock, handleDialog, verifyAccess } from "~/scripts/index.js";

import api from "~/services/api";

import CreatePOS from "./CreatePOS";

import { New } from "./styles";

function POS() {
  const profile = useSelector(state => state.user.profile);
  const accesses = useSelector(state => state.auth.newaccesses);
  const [pos, setPos] = useState([]);
  const [block, setBlock] = useState(false);
  const [createPos, setCreatePos] = useState(false);
  const [newPos, setNewPos] = useState(false);
  const [editPos, setEditePos] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    async function initPos() {
      const response = await api.get("pos");
      setPos(response.data);
      setBlock(false);
    }
    initPos();
  }, [block]);

  function handleOpenCreatePos(id, open, edite) {
    setCreatePos(open);
    setId(id);
    setEditePos(edite);
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses, "cadastro_pdv") ? (
          <New
            type="button"
            onClick={() => handleOpenCreatePos(null, true, true)}
          >
            Novo +
          </New>
        ) : null}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>Id</td>
            <td>N° PDV</td>
            <td>Nome</td>
            <td>Filial</td>
            <td>Operadora</td>
            <td>Setor</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {pos && pos.length > 0 ? (
            pos.map(poss => {
              return (
                <tr
                  key={poss.id}
                  id={poss.id}
                  className={`pos ${
                    poss.situation === "Inativo" ? "canceled" : null
                  }`}
                  onClick={() => handleDialog(poss, "pos")}
                >
                  <td>
                    {poss.id}
                    {verifyAccess(accesses, "exclusao_pdv") &&
                    poss.canceled_at ? (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleBlock(
                              poss.id,
                              poss.canceled_at,
                              "P.O.S",
                              "pos",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Desbloquear
                        </li>
                      </ul>
                    ) : null}
                    {!poss.canceled_at ? (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleOpenCreatePos(poss.id, true, false)
                          }
                        >
                          <FaEye size={12} /> Visualizar
                        </li>
                        <li
                          onClick={() =>
                            handleOpenCreatePos(poss.id, true, true)
                          }
                        >
                          <FaEdit size={12} /> Alterar
                        </li>
                        {verifyAccess(accesses, "exclusao_pdv") ? (
                          <li
                            className="trash"
                            onClick={() =>
                              handleBlock(
                                poss.id,
                                poss.canceled_at,
                                "P.O.S",
                                "pos",
                                setBlock
                              )
                            }
                          >
                            <FaTimes size={12} /> Bloquear
                          </li>
                        ) : null}
                      </ul>
                    ) : null}
                  </td>
                  <td>{poss.number_pos}</td>
                  <td>{poss.name}</td>
                  <td>{poss.filial_date.name}</td>
                  <td>{poss.operator.name}</td>
                  <td>{poss.sector.name}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6}>Não há PDV's cadastradas.</td>
            </tr>
          )}
        </tbody>
      </table>
      {createPos && (
        <CreatePOS
          setCreatePos={setCreatePos}
          edit={editPos}
          id={id}
          setBlock={setBlock}
        />
      )}
    </>
  );
}

export default POS;

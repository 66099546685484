import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Content, Container, Scroll } from "./styles";

import { FaAddressCard } from "react-icons/fa";

import { Form, Input } from "@rocketseat/unform";

import Title from "~/components/Title";

import api from "~/services/api";

import BgBlack from "~/components/BgBlack";

export default function BuscarPlanoDeContas({
  show,
  setShowCoA,
  initialData,
  handleChartOfAccount
}) {
  const profile = useSelector(state => state.user.profile);
  // const [chartOfAccountSearch, setChartOfAccoutSearch] = useState({
  //   nome: ""
  // });

  const [chartOfAccouts, setChartOfAccouts] = useState([]);

  useEffect(() => {
    async function getCharts() {
      try {
          const response = await api.get(
            `planodecontas_search/${profile.language}`
          );

          const { data } = response;

          setChartOfAccouts(data);

      } catch (err) {
        setChartOfAccouts([]);
      }
    }
    getCharts();
  }, []);

  return (
    <>
    { show ? <BgBlack zIndex={15} /> : null }
    <Scroll style={!show ? { display: "none" } : null}>
      <Content style={{ maxWidth: "1100px", left: "calc(50% - 550px)" }}>
        <header>
          <Title back={true} func={setShowCoA} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Buscar Plano de Contas</h1>
          <Form initialData={initialData}>

            {chartOfAccouts.length > 0
              ? chartOfAccouts.map((p,index) => (
                  <div className="rowInputs" key={index}>
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() =>
                        handleChartOfAccount({
                          id: p.id,
                          nome: p.title,
                          tax_nature: p.tax_nature
                        })
                      }
                    >
                      Selecionar
                    </button>
                    <label htmlFor="nome">
                      <div>
                        <FaAddressCard size={20} />
                        <br />
                        Nome
                      </div>
                      <Input readOnly name="nome" value={p.title} />
                    </label>
                  </div>
                ))
              : null}
          </Form>
        </Container>
      </Content>
    </Scroll>
    </>
  );
}

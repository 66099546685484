import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import retLanguage from "~/config/language";
import allowAccess from "~/scripts/menuController";

import { logout } from "~/store/modules/auth/actions";

import logo from "~/assets/images/logo_siaf.png";

import Filials from "~/components/Filials";
import Notifications from "~/components/Notifications";

import { Container, Content, Profile } from "./styles";

export default function Header() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const accesses = useSelector(state => state.auth.accesses);
  const lang = retLanguage(profile.language);

  if (!accesses) {
    return dispatch(logout());
  }

  return (
    <Container>
      <Content>
        <nav>
          <Link to="/dashboard">
            <img src={logo} alt="WeTrust" height="40" />
          </Link>
          <Link to="/dashboard">{lang.header.h1}</Link>
          {/* <Search onSubmit={e => e.preventDefault()}>
            <input type="text" placeholder="O que procura?" />
            <FaSearch color="#FFFFFF" size={15} />
          </Search> */}
        </nav>

        <aside>
          <Notifications title={lang.header.h2} />
          <Filials title={lang.header.h3} />
          <Profile>
            <div>
              <strong>{profile.nome}</strong>
              <Link to="/profile">{lang.header.h4}</Link>
            </div>
            <img
              width="32"
              height="32"
              src={
                profile.avatar && profile.avatar.url
                  ? profile.avatar.url
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrdEvvb_A3fTRZ9FTzpCkDacPDXBYoWgsoxbFbcYnB-jWigqa6w&s"
              }
              alt="Avatar do usuario"
            />
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import api from "~/services/api";
import { useSelector } from "react-redux";
import allowAccess from "~/scripts/menuController";

import {
  New
} from "./styles";

import { FaEye, FaEdit, FaTimes } from "react-icons/fa";
import { handleBlock, handleDialog, verifyAccess } from "~/scripts/index.js";

import NavigationButtons from "~/components/NavigationButtons";
import CreateUser from "./CreateUser";

import FiltroLancamento from "~/components/FiltroLancamento";
import BgBlack from "~/components/BgBlack";

export default function Users() {
  const profile = useSelector(state => state.user.profile);
  const [users, setUsers] = useState([]);
  const [createUser, setCreateUser] = useState(false);
  const [opennedID, setOpennedID] = useState(null);
  const [find, setFind] = useState(null);
  const [edit, setEdit] = useState(false);
  const [block, setBlock] = useState(false);
  const [lancamento, setLancamento] = useState(null);
  const [page, setPage] = useState(1);
  const [pages] = useState(1);
  const accesses = useSelector(state => state.auth.newaccesses);

  useEffect(() => {
    async function loadUsers() {
      try {
      const response = lancamento
        ? await api.get("users/groups/?groups=" + lancamento)
        : await api.get("users/groups/");
        setUsers(response.data);
      } catch(err) {

      }
    }
    loadUsers();
  }, [setUsers, block, lancamento, page, createUser]);
  function handleCreateUser() {
    setOpennedID(null);
    setEdit(!createUser);
    setCreateUser(!createUser);
  }
  function handleOpenUser(id, edit) {
    setOpennedID(id);
    setEdit(edit);
    setCreateUser(!createUser);
  }
  function handleLancamento(e) {
    e.preventDefault();
    setLancamento(find);
  }
  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses,'cadastro_usuarios') ?
          <New type="button" onClick={() => handleCreateUser()}>
            Novo +
          </New>
        : null}
        <p>Filtros:</p>
        <FiltroLancamento
          handleLancamento={handleLancamento}
          setFind={setFind}
          placeholder="Função"
        />
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>Código</td>
            <td>Função</td>
            <td>Nome</td>
            <td>E-mail</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {users.length > 0 ? (
            users.map((usr, index) => {
              return (
                <tr
                  key={usr.id}
                  id={usr.id}
                  className={`users ${usr.canceled_at ? "canceled" : null}`}
                  onClick={() => handleDialog(usr, "users")}
                >
                  <td style={{ width: "80px" }}>
                    {usr.id}
                    {verifyAccess(accesses,'cadastro_usuarios') ? usr.canceled_at ? (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleBlock(
                              usr.id,
                              usr.canceled_at,
                              "user",
                              "users",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Desbloquear
                        </li>
                      </ul>
                    ) : (
                      <ul className="dialog">
                          <li onClick={() => handleOpenUser(usr.id, false)}>
                            <FaEye size={12} /> Visualizar
                          </li>
                          <li onClick={() => handleOpenUser(usr.id, true)}>
                            <FaEdit size={12} /> Alterar
                          </li>
                          <li
                            className="trash"
                            onClick={() =>
                              handleBlock(
                                usr.id,
                                usr.canceled_at,
                                "filial",
                                "filiais",
                                setBlock
                              )
                            }
                          >
                            <FaTimes size={12} /> Bloquear
                          </li>
                      </ul>
                    ) : null}
                  </td>
                  <td>{usr.group.nome}</td>
                  <td>{usr.nome}</td>
                  <td>{usr.email}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="3">Não há usuários cadastrados</td>
            </tr>
          )}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {createUser ? (
        <>
          <BgBlack />
          <CreateUser
            handleCreateUser={handleCreateUser}
            opennedID={opennedID}
            edit={edit}
            setUsers={setUsers}
            users={users}
          />
        </>
      ) : null}
    </>
  );
}

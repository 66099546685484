import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import {
  FaDollarSign,
  FaAngleRight,
  FaCoins
} from "react-icons/fa";
import { Form, Input, Select } from "@rocketseat/unform";

import api from '~/services/api';

import {
  formatMoney,
  formatNum,
} from "~/scripts/index.js";

import { NewMoedaContainer } from "./styles";

import Title from "~/components/Title";

function NewMoeda({ setNewMoeda,newMoeda,moedas,handlerMoeda }) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [dispMoedas,setDispMoedas] = useState([{ id: null, title: null}]);

  const [moeda, setMoeda] = useState({
    id: null,
    value: 0
  });

  useEffect(() => {
    async function getMoedas() {
      const { data } = await api.get('/moedas/select');
      setDispMoedas(data)
    }
    getMoedas();
  },[])

  function handleSubmit() {
    handlerMoeda(moeda)
  }

  function handleMoeda(id) {
    for(let m = 0; m < dispMoedas.length; m++) {
      if(dispMoedas[m].id === parseInt(id)) {
        let { title } = dispMoedas[m];
        setMoeda({...moeda,id, name: title, moeda_id: id});
      }
    }
  }

  return (
    <>
    <div className="overlay"></div>
      <NewMoedaContainer>
        <div className="cardTitle">
          <Title
            back={true}
            func={() => setNewMoeda(!newMoeda)}
            title={"Cancelar"}
          />
        </div>
        <main>
          <Form className="form" onSubmit={() => false}>
            <div className="rowInputs">
              <label htmlFor="flag">
                <div>
                  <FaCoins size={20} />
                  <br />
                  Moeda
                </div>
                <Select
                  options={dispMoedas}
                  name="flag"
                  placeholder="Selecione..."
                  onChange={e => handleMoeda(e.target.value)}
                />
              </label>
              <label htmlFor="value">
                <div>
                  <FaDollarSign size={20} />
                  <br />
                  Valor
                </div>
                <Input
                  name="value"
                  onChange={e =>
                    setMoeda({
                      ...moeda,
                      value: formatNum(e.target.value)
                    })
                  }
                  value={formatMoney(
                    moeda.value.toFixed(2),
                    filial.country.language
                  )}
                />
              </label>

              <button
                type="button"
                className="btnFinish"
                onClick={() => handleSubmit()}
              >
                Adicionar
                <FaAngleRight size={20} />
              </button>
            </div>
          </Form>
        </main>
      </NewMoedaContainer>
    </>
  );
}

export default NewMoeda;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Content, Container, Scroll } from "./styles";
import { FaAddressCard, FaUniversity } from "react-icons/fa";
import { Form, Input } from "@rocketseat/unform";
import Title from "~/components/Title";
import api from "~/services/api";
import BgBlack from "~/components/BgBlack";

export default function BuscarBanks({
  showBanks,
  setShowBanks,
  initialData = '',
  handleSearchBanks,
  caixa_filial = true
}) {

  const profile = useSelector(state => state.user.profile);
  // const [chartOfAccountSearch, setChartOfAccoutSearch] = useState({
  //   nome: ""
  // });

  const [banks, setBanks] = useState([]);

  useEffect(() => {
    async function getCharts() {
      try {
        const { data } = await api.get(
          `/banks/all`
        );
        if (caixa_filial) {
          setBanks([{ id: 0, name: 'Caixa Filial' }, ...data]);
        } else {
          setBanks([...data]);
        }
      } catch (err) {
        setBanks([]);
      }
    }
    getCharts();
  }, []);

  return (
    <>
      {showBanks ? <BgBlack zIndex={15} /> : null}
      <Scroll style={!showBanks ? { display: "none" } : null}>
        <Content style={{ maxWidth: "1100px", left: "calc(50% - 550px)" }}>
          <header>
            <Title back={true} func={setShowBanks} title={"Cancelar"} />
          </header>
          <Container>
            <h1>Buscar Bancos</h1>
            <Form initialData={initialData}>

              {banks.length > 0
                ? banks.map((p, index) => (
                  <div className="rowInputs" key={index}>
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() =>
                        handleSearchBanks({
                          id: p.id,
                          name: p.name,
                        })
                      }
                    >
                      Selecionar
                    </button>
                    <label htmlFor="nome">
                      <div>
                        <FaUniversity size={20} />
                        <br />
                        Conta
                      </div>
                      <Input readOnly name="nome" value={p.name} />
                    </label>
                  </div>
                ))
                : null}
            </Form>
          </Container>
        </Content>
      </Scroll>
    </>
  );
}

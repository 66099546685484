import styled from 'styled-components';

import { darken } from 'polished';

export const Container = styled.div`
  width:100%;
  max-width: 600px;
  margin:50px auto;
  text-align:center;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    input {
      background: #fff;
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color:#888;
      margin:0 0 10px;

      &::placeholder {
        color:#bbb;
      }
    }

    select {
      background: #fff;
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color:#888;
      margin:0 0 10px;

      &::placeholder {
        color:#bbb;
      }

      option {
        color: #222;
      }
    }

    span {
      color: #ccc;
      align-self: flex-start;
      margin:0 0 10px;
      font-style: italic;
      font-size:12px;
    }

    hr {
      border:0;
      height:1px;
      background: rgba(0,0,0,.1);
      margin:10px 0 20px;
    }

    button {
      margin: 5px 0 0;
      height:44px;
      background: rgb(155,213,49);
      color:#fff;
      font-weight:bold;
      border:0;
      border-radius:4px;
      font-size:16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, 'rgb(155,213,49)')}
      }
    }

    a {
      color:#FFF;
      margin-top:15px;
      font-size:16px;
      opacity:.8;

      &:hover {
        opacity:1;
      }
    }
  }



  > button {
    width:100%;
      margin: 5px 0 0;
      height:44px;
      background: rgba(215,47,47,1);
      color:#fff;
      font-weight:bold;
      border:0;
      border-radius:4px;
      font-size:16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.08, 'rgba(215,47,47,1)')}
      }
    }

    a {
      color:#FFF;
      margin-top:15px;
      font-size:16px;
      opacity:.8;

      &:hover {
        opacity:1;
      }
    }


`;

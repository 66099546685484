import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import api from "~/services/api";

import { format, addMonths, parseISO } from "date-fns";

import pt from "date-fns/locale/pt";

import { toast } from "react-toastify";

import BuscarFornecedor from "~/components/BuscarFornecedor";

import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

import BuscarNatureza from "~/components/BuscarNatureza";

import {
  FaCalendarAlt,
  FaUser,
  FaSearch,
  FaAngleRight,
  FaFileInvoice,
  FaCommentDots,
  FaDollarSign,
  FaChartPie,
  FaAngleDown,
  FaTrashAlt,
  FaFileUpload,
  FaFileInvoiceDollar,
  FaTimesCircle,
  FaCheckCircle,
  FaUniversity
} from "react-icons/fa";

import { Form, Input, Select } from "@rocketseat/unform";
import { Content, Container, Scroll, ListParcels, DivFiles } from "./styles";

import {
  formatMoney,
  formatNum,
  getAccountsByPaymentMethod,
  getPaymentMethods
} from "~/scripts/index.js";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { file_extension } from "~/assets/";

import { FaFileTimes, FaFileSuccess } from "~/assets/Icons";

import Title from "~/components/Title";

export default function CreateInvoice({
  id,
  edit,
  handleCreateFixedCost,
  setBlock
}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [showSearch, setShowSearch] = useState(false);
  const [showCoA, setShowCoA] = useState(false);
  const [showNature, setShowNature] = useState(false);
  const [complementDate, setComplementDate] = useState({
    provider: { id: null, company_name: null },
    charts_of_account: { id: null, nome: null },
    nature: ""
  });
  const [tableOpened, setTableOpened] = useState(true);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [finish, setFinish] = useState(false);
  const [filiaisRegional, setFiliaisRegional] = useState([]);
  const [removeFile, setRemoveFile] = useState(null);
  const [chartOfAccount, setChartOfAccount] = useState({ code: "", nome: "" });
  const [fixedCost, setFixedCost] = useState({
    plano_de_contas_id: null,
    description: "",
    provider_id: 0,
    bank_id: "nenhum selecionado",
    parcel_value: 0,
    emission: format(new Date(), "yyyy-MM-dd"),
    payment_condition: "Dinheiro",
    validity_start_date: format(new Date(), "yyyy-MM-dd"),
    validity_end_date: format(new Date(), "yyyy-MM-dd"),
    validity_quantity: 1,
    s3file_id: null,
    file: null,
    // payment_for: "Filial",
    parcels: 1,
    // payment_for: "",
    approved_at: null,
    parcels_array: [],
    created_by: profile.id,
    filial_id: filial.filial,
    to_filial_id: filial.filial,
    created_at: new Date()
  });

  useEffect(() => {
    if (
      edit &&
      fixedCost.parcels &&
      fixedCost.parcel_value &&
      fixedCost.validity_start_date
    ) {
      const newParcels = [];

      // const due_date = subMonths(parseISO(fixedCost.validity_start_date), 1);

      for (let i = 0; i < fixedCost.parcels; i++) {
        newParcels.push({
          parcel: i + 1,
          document: null,
          value: 0,
          gross_value: fixedCost.parcel_value,
          liquid_value: fixedCost.parcel_value,
          date: format(
            addMonths(parseISO(fixedCost.validity_start_date), i),
            "yyyy-MM-dd"
          ),
          due_date: format(
            addMonths(parseISO(fixedCost.validity_start_date), i),
            "yyyy-MM-dd"
          ),
          // date: fixedCost.validity_start_date,
          // due_date: fixedCost.validity_start_date,
          created_by: profile.id,
          filial_id: filial.filial,
          created_at: new Date()
        });
      }

      setFixedCost({
        ...fixedCost,
        validity_end_date: format(
          addMonths(
            parseISO(fixedCost.validity_start_date),
            fixedCost.parcels - 1
          ),
          "yyyy-MM-dd"
        ),
        parcels_array: newParcels
      });
    }
  }, [
    fixedCost.parcels,
    fixedCost.validity_start_date,
    fixedCost.parcel_value
  ]);

  useEffect(() => {
    getAccountsByPaymentMethod(
      fixedCost.payment_condition,
      setFixedCost,
      fixedCost,
      setPaymentAccounts,
      filial.filial
    );
  }, [fixedCost.payment_condition]);

  useEffect(() => {
    async function getFixedCost() {
      try {
        const { data } = await api.get(`fixedCost/${id}`);

        const {
          provider: provider_fixedCost,
          charts_of_account: chartOfAccout_fixedCost,
          parcel: parcels_array
        } = data;

        if (data.charts_of_account) {
          setChartOfAccount({
            ...data.charts_of_account,
            title:
              profile.language === "pt-BR"
                ? data.charts_of_account.pt_name
                : data.charts_of_account.en_name
          });
        }
        setFixedCost({
          ...data,
          parcels_array,
          plano_de_contas_id: chartOfAccout_fixedCost
            ? chartOfAccout_fixedCost.id
            : null
        });

        setComplementDate({
          ...complementDate,
          provider: provider_fixedCost,
          charts_of_account: chartOfAccout_fixedCost
        });
      } catch (err) {
        toast.error("Não foi possível localizar o custo fixo.", {
          className: "error"
        });
      }
    }

    async function getFiliaisRegional(filial_id) {
      const response = await api.get("/filiais/myregionais/" + filial_id);
      const { data } = response;
      let filiais = [{ id: filial_id, title: filial.filial_name }];
      data.map(filial => {
        filiais.push({ id: filial.id, title: filial.name });
      });

      setFiliaisRegional(filiais);
    }

    if (id) {
      getFixedCost();
    }
    getFiliaisRegional(filial.filial);
    getAccountsByPaymentMethod(
      fixedCost.payment_condition,
      setFixedCost,
      fixedCost,
      setPaymentAccounts,
      filial.filial
    );
  }, [id]);

  useEffect(() => {
    let {
      plano_de_contas_id,
      description,
      provider_id,
      bank_id,
      parcel_value,
      payment_condition,
      parcels,
      s3file_id
      // payment_for
    } = fixedCost;

    plano_de_contas_id &&
    description &&
    provider_id &&
    parcel_value &&
    bank_id >= 0 &&
    payment_condition &&
    parcels &&
    s3file_id
      ? setFinish(true)
      : setFinish(false);
  }, [
    fixedCost.plano_de_contas_id,
    fixedCost.description,
    fixedCost.provider_id,
    fixedCost.bank_id,
    fixedCost.payment_condition,
    fixedCost.parcels,
    fixedCost.parcel_value,
    fixedCost.s3file_id
  ]);

  function handleTable() {
    setTableOpened(!tableOpened);
  }

  function handleProvider(data) {
    setComplementDate({ ...complementDate, provider: data });
    setFixedCost({ ...fixedCost, provider_id: data.id });
    setShowSearch(false);
  }

  function handleChartOfAccount(data) {
    setComplementDate({
      ...complementDate,
      charts_of_account: data
    });
    setFixedCost({ ...fixedCost, plano_de_contas_id: data.id });
    const { id, nome: title, tax_nature } = data;
    setChartOfAccount({ id, title, tax_nature });
    setShowCoA(false);
  }

  function handleSearch(type, value) {
    if (type === "chart_of_account") {
      setShowCoA(value);
    } else if (type === "nature") {
      setShowNature(value);
    } else {
      setShowSearch(value);
    }
  }

  async function handlerFiles(e) {
    const datas = new FormData();

    datas.append("file", e.target.files[0]);

    const { data } = await api.post("s3files/" + profile.id, datas);

    setFixedCost({ ...fixedCost, s3file_id: data.id, file: data });
  }

  function setIconFileExtension(file) {
    const ext = file.split(".").pop();
    for (let i = 0; i < file_extension.length; i++) {
      if (ext === file_extension[i].title) {
        return file_extension[i].icon;
      }
    }
  }

  function removerFiles(object) {
    setRemoveFile(fixedCost.s3file_id);
    setFixedCost({ ...fixedCost, s3file_id: null, file: null });
  }

  async function handleSubmit() {
    try {
      if (removeFile) {
        await api.delete("s3files/" + removeFile);
      }
      const response = id
        ? await api.put(`fixedcost/${id}`, fixedCost)
        : await api.post("fixedcost", fixedCost);
      toast.success(`Custo Fixo ${id ? "alterado" : "incluído"} com sucesso!`, {
        className: "success"
      });
      setBlock(true);
      handleCreateFixedCost(false);
      return;
    } catch (err) {
      toast.error("Há algum erro, por favor, revise o lançamento.", {
        className: "error"
      });
    }
  }

  const option_payment_condition = getPaymentMethods();

  const option_parcels = [
    { id: 1, title: 1 },
    { id: 2, title: 2 },
    { id: 3, title: 3 },
    { id: 4, title: 4 },
    { id: 5, title: 5 },
    { id: 6, title: 6 },
    { id: 7, title: 7 },
    { id: 8, title: 8 },
    { id: 9, title: 9 },
    { id: 10, title: 10 },
    { id: 11, title: 11 },
    { id: 12, title: 12 }
  ];

  const option_payment_for = [
    { id: "Filial", title: "Filial" }
    // { id: "Matriz", title: "Matriz" }
  ];

  return (
    <>
      <Scroll
        style={showSearch || showCoA || showNature ? { display: "none" } : null}
      >
        <Content>
          <header>
            <Title
              back={true}
              func={handleCreateFixedCost}
              title={"Cancelar"}
            />
          </header>
          <Container>
            <h1>Novo Custo Fixo</h1>
            <Form onSubmit={handleSubmit} autoComplete="off">
              <div className="rowInputs">
                <label htmlFor="nome">
                  <img
                    src={
                      profile.avatar
                        ? profile.avatar.url
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrdEvvb_A3fTRZ9FTzpCkDacPDXBYoWgsoxbFbcYnB-jWigqa6w&s"
                    }
                    width="54"
                    height="54"
                    alt=""
                  />
                  <Input
                    name="nome"
                    readOnly="readonly"
                    value={profile.nome}
                    placeholder="Data"
                  />
                </label>
                <label htmlFor="emission">
                  <div>
                    <FaCalendarAlt size={20} />
                    <br />
                    Emissão
                  </div>
                  <DatePicker
                    name="emission"
                    readOnly={edit ? null : "readonly"}
                    placeholder="Emissão"
                    selected={parseISO(fixedCost.emission)}
                    maxDate={new Date()}
                    minDate={
                      new Date(
                        filial.open_date.substring(0, 4),
                        parseInt(filial.open_date.substring(5, 7)) - 1,
                        filial.open_date.substring(8, 10)
                      )
                    }
                    onChange={date =>
                      setFixedCost({
                        ...fixedCost,
                        emission: format(date, "yyyy-MM-dd")
                      })
                    }
                    dateFormat={
                      profile.language === "pt-BR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                    }
                    locale={pt}
                  />
                </label>
                <label htmlFor="provider" style={{ flexGrow: "1" }}>
                  <div>
                    <FaUser size={20} />
                    <br />
                    Fornec.
                  </div>
                  <Input
                    name="provider"
                    readOnly={"readonly"}
                    value={
                      complementDate.provider.company_name
                        ? complementDate.provider.company_name
                        : ""
                    }
                  />
                  {edit && (
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() => handleSearch("provider", true)}
                    >
                      Buscar <FaSearch size={14} />
                    </button>
                  )}
                </label>
              </div>
              <hr />
              <div className="rowInputs">
                <label htmlFor="plano_de_contas_id" style={{ flexGrow: "1" }}>
                  <div>
                    <FaFileInvoiceDollar size={20} />
                    <br />
                    Pla.Cont
                  </div>
                  <Input
                    name="plano_de_contas_id"
                    readOnly={"readonly"}
                    value={
                      chartOfAccount.title ? `${chartOfAccount.title}` : ""
                    }
                  />

                  {edit && (
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() => handleSearch("chart_of_account", true)}
                    >
                      Buscar <FaSearch size={14} />
                    </button>
                  )}
                </label>
                <label htmlFor="value">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Valor
                  </div>
                  <Input
                    name="value"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setFixedCost({
                        ...fixedCost,
                        parcel_value: parseFloat(formatNum(e.target.value))
                      })
                    }
                    value={formatMoney(
                      fixedCost.parcel_value.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
              </div>
              <hr />
              <div className="rowInputs">
                <label htmlFor="to_filial_id">
                  <div>
                    <FaUniversity size={20} />
                    <br />
                    P/ Filial
                  </div>
                  <Select
                    name="to_filial_id"
                    options={filiaisRegional}
                    selected={fixedCost.to_filial_id}
                    value={fixedCost.to_filial_id}
                    disabled={!edit}
                    onChange={e =>
                      setFixedCost({
                        ...fixedCost,
                        to_filial_id: e.target.value ? e.target.value : null
                      })
                    }
                  />
                </label>
                <label htmlFor="payment_condition">
                  <div>
                    <FaFileInvoice size={20} />
                    <br />
                    Pago em
                  </div>
                  <Select
                    name="payment_condition"
                    placeholder="Condição de pagamento"
                    options={option_payment_condition}
                    disabled={edit ? null : "readonly"}
                    onChange={e =>
                      setFixedCost({
                        ...fixedCost,
                        payment_condition: e.target.value
                      })
                    }
                    selected={fixedCost.payment_condition || ""}
                    value={fixedCost.payment_condition || ""}
                  />
                </label>
                <label htmlFor="paymentAccount">
                  <div>
                    <FaUniversity size={20} />
                    <br />
                    Conta
                  </div>
                  <Select
                    name="paymentAccount"
                    placeholder={
                      fixedCost.bank ? fixedCost.bank.name : "Caixa Filial"
                    }
                    options={paymentAccounts}
                    selected={fixedCost.bank_id || 0}
                    value={fixedCost.bank_id || 0}
                    disabled={!edit}
                    onChange={e =>
                      setFixedCost({
                        ...fixedCost,
                        bank_id: e.target.value ? e.target.value : null
                      })
                    }
                  />
                </label>
              </div>
            </Form>

            <h1>Vigência</h1>
            <Form onSubmit={handleSubmit}>
              <div className="rowInputs">
                <label htmlFor="parcels">
                  <div>
                    <FaChartPie size={20} />
                    <br />
                    Parcelas
                  </div>
                  <Select
                    name="parcels"
                    options={option_parcels}
                    disabled={edit ? null : "readonly"}
                    placeholder="Quantidade de meses"
                    onChange={e =>
                      setFixedCost({
                        ...fixedCost,
                        parcels: e.target.value
                      })
                    }
                    value={fixedCost.parcels || ""}
                  />
                </label>
                {/* <label htmlFor="payment_for">
                  <div>
                    <FaBuilding size={20} />
                    <br />
                    Pag.Por
                  </div>
                  <Select
                    name="payment_for"
                    placeholder="Pago por"
                    options={option_payment_for}
                    disabled={edit ? null : "readonly"}
                    onChange={e =>
                      setFixedCost({
                        ...fixedCost,
                        payment_for: e.target.value
                      })
                    }
                    value={fixedCost.payment_for || ""}
                  />
                </label> */}
                <label htmlFor="validity_start_date">
                  <div>
                    <FaCalendarAlt size={20} />
                    <br />
                    Início
                  </div>
                  <DatePicker
                    name="validity_start_date"
                    readOnly={edit ? null : "readonly"}
                    selected={parseISO(fixedCost.validity_start_date)}
                    minDate={parseISO(fixedCost.emission)}
                    onChange={date =>
                      setFixedCost({
                        ...fixedCost,
                        validity_start_date: format(date, "yyyy-MM-dd")
                      })
                    }
                    dateFormat={
                      profile.language === "pt-BR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                    }
                    locale={pt}
                  />
                </label>
                <label htmlFor="validity_end_date">
                  <div>
                    <FaCalendarAlt size={20} />
                    <br />
                    Fim
                  </div>
                  <DatePicker
                    name="validity_end_date"
                    readOnly="readonly"
                    selected={parseISO(fixedCost.validity_end_date)}
                    dateFormat={
                      profile.language === "pt-BR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                    }
                    locale={pt}
                  />
                </label>
              </div>
            </Form>
            <input
              type="file"
              name="files"
              onChange={e => handlerFiles(e)}
              id="files"
              accept="image/jpeg,image/gif,image/png,application/pdf"
              style={{ display: "none" }}
            />
            <h1 className="title_section_upload_file">
              Contratos e documentos eletrônicos
              {edit && (
                <button type="button" className="upload_file">
                  <FaFileUpload
                    size={18}
                    onClick={() => document.getElementById("files").click()}
                  />
                </button>
              )}
            </h1>
            <DivFiles>
              <li>
                <table>
                  <thead>
                    <tr>
                      <td>Arquivo</td>
                      <td style={{ width: "100px" }}>Baixar</td>
                      <td style={{ width: "100px" }}>Excluir</td>
                    </tr>
                  </thead>
                  <tbody>
                    {fixedCost.s3file_id ? (
                      <tr>
                        <td>{fixedCost.file.name}</td>
                        <td>
                          <a
                            href={fixedCost.file.url}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={setIconFileExtension(fixedCost.file.key)}
                              alt=""
                              style={{ height: "35px", width: "35px" }}
                            />
                          </a>
                        </td>
                        <td>
                          {edit && (
                            <FaTrashAlt
                              size={20}
                              color="#c47f7f"
                              onClick={() => removerFiles(fixedCost.file.id)}
                            />
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>Nenhum arquivo</td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </li>
            </DivFiles>
            <Form onSubmit={handleSubmit}>
              <div className="rowInputs">
                <label htmlFor="description">
                  <div>
                    <FaCommentDots size={20} />
                    <br />
                    Desc
                  </div>

                  <Input
                    name="description"
                    placeholder="Descrição"
                    readOnly={edit ? null : "readOnly"}
                    onChange={e =>
                      setFixedCost({
                        ...fixedCost,
                        description: e.target.value
                      })
                    }
                    value={fixedCost.description || ""}
                  />
                </label>
                {edit ? (
                  <>
                    <button
                      type="submit"
                      className="btnFinish"
                      finish={finish.toString()}
                      disabled={!finish}
                      title={
                        !finish
                          ? "Há campos obrigatórios não preenchidos!"
                          : "Finalizar"
                      }
                    >
                      {finish ? (
                        <>
                          {!id ? "Finalizar" : "Atualizar"}
                          <FaAngleRight size={20} />
                        </>
                      ) : (
                        <>
                          ... <FaTimesCircle size={20} />
                        </>
                      )}
                    </button>
                  </>
                ) : null}
              </div>
            </Form>
            <div className="rowInputs fixed_cost_parcels_list">
              <h1 onClick={handleTable}>
                {tableOpened ? (
                  <FaAngleDown size={20} />
                ) : (
                  <FaAngleRight size={20} />
                )}
                Provisionamento de parcelas
              </h1>
              {tableOpened ? (
                <ListParcels>
                  <li>
                    <table>
                      <thead>
                        <tr>
                          <td>Parcela</td>
                          <td>Documento</td>
                          <td>Valor Bruto</td>
                          <td>Valor Líquido</td>
                          <td>Natureza</td>
                          <td>Emissão</td>
                          <td>Vencimento</td>
                          <td>Baixado Em</td>
                          {!id && edit ? <td>NF</td> : null}
                          {id && !edit ? <td>NF</td> : null}
                          <td>Baixa</td>
                        </tr>
                      </thead>

                      <tbody>
                        {fixedCost.parcels_array.length > 0 ? (
                          fixedCost.parcels_array.map((parcels_item, index) => (
                            <tr key={index}>
                              <td>{parcels_item.parcel}</td>
                              <td>{parcels_item.document}</td>
                              <td>
                                {formatMoney(
                                  parcels_item.liquid_value.toFixed(2),
                                  filial.country.language
                                )}
                              </td>
                              <td>
                                {formatMoney(
                                  parcels_item.gross_value.toFixed(2),
                                  filial.country.language
                                )}
                              </td>
                              <td>{complementDate.nature}</td>
                              <td>
                                {format(
                                  parseISO(fixedCost.emission),
                                  profile.language === "pt-BR"
                                    ? "dd/MM/yyyy"
                                    : "MM/dd/yyyy"
                                )}
                              </td>
                              <td>
                                {format(
                                  parseISO(parcels_item.due_date),
                                  profile.language === "pt-BR"
                                    ? "dd/MM/yyyy"
                                    : "MM/dd/yyyy"
                                )}
                              </td>
                              <td>
                                {parcels_item.approved_at
                                  ? format(
                                      parseISO(parcels_item.approved_at),
                                      "dd'/'MM'/'yyyy"
                                    )
                                  : "Não baixado"}
                              </td>
                              {!id && edit ? (
                                <>
                                  <td>
                                    <img
                                      src={FaFileTimes}
                                      style={{ width: "10px" }}
                                      alt="FaFileTimes"
                                    />
                                  </td>
                                </>
                              ) : null}
                              {id && !edit ? (
                                <>
                                  <td>
                                    {parcels_item.nf ? (
                                      <img
                                        src={FaFileSuccess}
                                        style={{ width: "10px" }}
                                        alt=" FaFileSuccess"
                                      />
                                    ) : (
                                      <img
                                        src={FaFileTimes}
                                        style={{ width: "10px" }}
                                        alt="FaFileTimes"
                                      />
                                    )}
                                  </td>
                                </>
                              ) : null}
                              <td style={{ textAlign: "center" }}>
                                {parcels_item.approved_at ? (
                                  <FaCheckCircle color="green" />
                                ) : (
                                  <FaTimesCircle color="#c47f7f" />
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={10} style={{ textAlign: "start" }}>
                              Nenhuma parcela
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </li>
                </ListParcels>
              ) : null}
            </div>
          </Container>
        </Content>
      </Scroll>

      {showSearch && (
        <BuscarFornecedor
          show={showSearch}
          setShowSearch={setShowSearch}
          handleProvider={handleProvider}
        />
      )}

      <BuscarPlanoDeContas
        show={showCoA}
        setShowCoA={setShowCoA}
        handleChartOfAccount={handleChartOfAccount}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import allowAccess from "~/scripts/menuController";
import './style.css'

import { format, parseISO } from "date-fns";
import {
  handleDialog,
  handleBlock,
  formatMoney,
  formatedDate,
  verifyAccess
} from "~/scripts/index.js";

import { toast } from "react-toastify";

import BgBlack from "~/components/BgBlack";

import "react-confirm-alert/src/react-confirm-alert.css";

import NavigationButtons from "~/components/NavigationButtons";

import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

import api from "~/services/api";
import { FaEye, FaEdit, FaCheck, FaTimes, FaClipboard, FaRedo, FaCalendarAlt } from "react-icons/fa";
import {
  New
} from "./styles";
import FiltroData from "~/components/FiltroData";
import FiltroLancamentoValueDefault from "~/components/FiltroLancamentoValueDefault";

import CreateTransfer from "./CreateTransfer/index";
import MainFilter, { MainFilterButton } from "~/components/MainFilter";
import FilterComponent from "~/components/FilterComponent";
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';
import TextInputFilter from "~/components/TextInputFilter";
import BuscarBanks from "~/components/BuscarBanks";
import GreenSearchButton from "~/components/GreenSearchButton";
import BuscarFiliais from "~/components/BuscarFiliais";
import DateFilter from "~/components/DateFilter";
import CheckBox from "~/components/CheckBox";

export default function HomeboxTransferencias() {
  const filial = useSelector(state => state.filial);
  const profile = useSelector(state => state.user.profile);
  const [filtro, setFiltro] = useState({
    date: format(new Date(), "yyyy-MM-dd 23:59:59"),
    lancamento: ""
  });
  const [transfers, setTransfers] = useState([]);
  const [openDate, setOpenDate] = useState(null);
  const [page, setPage] = useState(0);
  const [pages] = useState(0);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [opennedId, setOpennedId] = useState(null);
  const [block, setBlock] = useState(false);
  const [edit, setEdit] = useState(false);
  const [createTransfer] = useState(false);
  const accesses = useSelector(state => state.auth.newaccesses);
  const [showCoA, setShowCoA] = useState(false);
  const [reclassificar, setReclassificar] = useState(null);
  const [showFilter, setShowFilter] = useState(false)
  const [showFromBankFilter, setShowFromBankFilter] = useState(false)
  const [showToBankFilter, setShowToBankFilter] = useState(false)
  const [showFilterFilials, setShowFilterFilials] = useState(false)
  const [filterSettings, setFilterSettings] = useState(
    {
      status: {
        approved: true,
        not_approved: true
      },
      obs: '',
      bank_from: {
        id: false,
        name: ''
      },
      bank_to: {
        id: false,
        name: ''
      },
      filial: {
        id: filial.filial,
        name: ''
      },
      from: false,
      to: false,
    }
  )


  async function handleChartOfAccount(coa) {
    try {
      await api.put(`/reclassificar/transf/${reclassificar}/${coa.id}`)
      toast.success("Reclassificado com sucesso!", {
        className: "success"
      });
      setShowCoA(false);
    } catch (err) {
      toast.error("Impossível reclassificar.", {
        className: "error"
      });
    }
  }

  function handleReclass(movement) {
    setShowCoA(true);
    setReclassificar(movement);
  }

  function handleLancamento(data) {
    setFiltro({
      ...filtro,
      lancamento: data.toString().replace(/[^\d.,]+/g, "")
    });
  }

  function handleOpenDate() {
    setOpenDate(!openDate);
  }

  function handleCreateTransfer() {
    setOpennedId(null);
    setEdit(!createTransfer);
    setOpenTransfer(!openTransfer);
  }

  function handleOpenTransfer(id, edit) {
    setOpennedId(id);
    setEdit(edit);
    setOpenTransfer(!openTransfer);
  }

  function handleData({ date }) {
    setFiltro({
      ...filtro,
      date: format(date, "yyyy-MM-dd 23:59:59")
    });
    setOpenDate(!openDate);
  }

  async function MakeReporting(id) {
    // const { data } = await api.post(
    //   `reporting_transfer/${id}/${filial.filial}`
    // );
    // data.map(urls => {
    //   window.open(urls);
    // });
    // setBlock(true);
  }

  async function approveTransfer(id) {
    try {
      const response = await api.put("transfers/approve/" + id)
      if (response.data.approved_at) {
        toast.success("Transferência aprovada com sucesso!", {
          className: "success"
        });
      } else {
        toast.success("Transferência estornada com sucesso!", {
          className: "success"
        });
      }
      setBlock(true);
      return;
    } catch (err) {
      toast.error("Esta transferência não pode ser aprovada no momento.", {
        className: "error"
      });
    }
  }

  useEffect(() => {
    async function getFilters() {
      const filterData = format(new Date(filtro.date), "yyyy-MM-dd");

      try {
        const response = await api.get(
          `transfers/filial/${filial.filial}/${filterData}?lancamento=${filtro.lancamento}`
        );
        setTransfers(response.data);
      } catch (err) {

      }

    }
    getFilters();
  }, [filtro, filial.filial]);

  useEffect(() => {
    async function loadTransfers() {
      const filterData = format(new Date(filtro.date), "yyyy-MM-dd");
      try {
        const response = await api.get(
          `transfers/filial/${filial.filial}/${filterData}`
        );

        setTransfers(response.data);
      } catch (err) {

      }

      setBlock(false);
    }
    loadTransfers();
  }, [filial, block, filtro.date]);

  async function applyFilter() {

    try {

      if (filterSettings.from && filterSettings.to && filterSettings.from > filterSettings.to) {
        return toast.error("A data de emissão inicial não pode ser maior do que a final.", {
          className: "error"
        });
      }

      let from = filterSettings.from ? new Date(filterSettings.from) : new Date(1900, 0, 1)
      from = from.toISOString().substring(0, 10)

      let to = filterSettings.to ? new Date(filterSettings.to) : new Date(3000, 0, 1)
      to = to.toISOString().substring(0, 10)

      const { data } = await api.get(`transfers/filters/`, {
        params: {
          approved: filterSettings.status.approved,
          not_approved: filterSettings.status.not_approved,
          obs: filterSettings.obs,
          from: filterSettings.bank_from.id,
          to: filterSettings.bank_to.id,
          filial_id: filterSettings.filial.id,
          from_date: from,
          to_date: to
        }
      })
      setTransfers(data)
      setShowFilter(false)

    } catch (err) {

    }
  }

  async function resetFilter() {

    setFilterSettings({
      status: {
        approved: true,
        not_approved: true
      },
      obs: '',
      bank_from: {
        id: false,
        name: ''
      },
      bank_to: {
        id: false,
        name: ''
      },
      filial: {
        id: filial.filial,
        name: ''
      },
      from: false,
      to: false,
    }
    )

    const filterData = format(new Date(filtro.date), "yyyy-MM-dd");
    try {
      const response = await api.get(
        `transfers/filial/${filial.filial}/${filterData}`
      );

      setTransfers(response.data);
      setShowFilter(false)
    } catch (err) {

    }

    setBlock(false);

  }



  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses, 'lancamento_transferencia') ?
          <New type="button" onClick={() => { handleCreateTransfer(); setShowFilter(false) }}>
            Novo +
          </New>
          : null}
        <MainFilterButton click={() => { setShowFilter(!showFilter) }} />
        {/* <FiltroData
          openDate={openDate}
          handleOpenDate={handleOpenDate}
          selected={parseISO(filtro.date)}
          handleData={handleData}
        />
        <FiltroLancamentoValueDefault
          setFind={handleLancamento}
          value={filtro.lancamento}
          placeholder="Lançamento"
        /> */}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          {showFilter &&
            <MainFilter applyFilter={applyFilter} resetFilter={resetFilter}>

              <FilterComponent title="Status" >
                <CheckBox title="Não aprovado"
                  input={filterSettings.status.not_approved}
                  setInput={() => setFilterSettings({ ...filterSettings, status: { ...filterSettings.status, not_approved: !filterSettings.status.not_approved } })}
                />
                <CheckBox title="Aprovado"
                  input={filterSettings.status.approved}
                  setInput={() => setFilterSettings({ ...filterSettings, status: { ...filterSettings.status, approved: !filterSettings.status.approved } })}
                />
              </FilterComponent>

              <FilterComponent title="Data de Emissão">
                <DateFilter to={filterSettings.to} setTo={dt => setFilterSettings({ ...filterSettings, to: dt })} from={filterSettings.from} setFrom={dt => setFilterSettings({ ...filterSettings, from: dt })} />
              </FilterComponent>

              <FilterComponent title="Observação">
                <TextInputFilter input={filterSettings.obs} setInput={(txt) => { setFilterSettings({ ...filterSettings, obs: txt }) }} />
              </FilterComponent>

              <FilterComponent title="Conta Débito">
                <GreenSearchButton
                  input={filterSettings.bank_from.name}
                  searchFunction={() => { setShowFromBankFilter(true) }}
                  resetFunction={() => { setFilterSettings({ ...filterSettings, bank_from: { id: false, name: '' } }) }}
                />
                <BuscarBanks
                  showBanks={showFromBankFilter}
                  setShowBanks={setShowFromBankFilter}
                  handleSearchBanks={bank => {
                    setFilterSettings({ ...filterSettings, bank_from: { ...bank } })
                    setShowFromBankFilter(false)
                  }}
                />
              </FilterComponent>
              <FilterComponent title="Conta Crédito">
                <GreenSearchButton
                  input={filterSettings.bank_to.name}
                  searchFunction={() => { setShowToBankFilter(true) }}
                  resetFunction={() => { setFilterSettings({ ...filterSettings, bank_to: { id: false, name: '' } }) }}
                />
                <BuscarBanks
                  showBanks={showToBankFilter}
                  setShowBanks={setShowToBankFilter}
                  handleSearchBanks={bank => {
                    setFilterSettings({ ...filterSettings, bank_to: { ...bank } })
                    setShowToBankFilter(false)
                  }}
                />
              </FilterComponent>
              <FilterComponent title="Filial">
                <GreenSearchButton
                  input={filterSettings.filial.name}
                  searchFunction={() => { setShowFilterFilials(true) }}
                  resetFunction={() => setFilterSettings({ ...filterSettings, filial: { id: filial.filial, name: '' } })}
                />
                <BuscarFiliais
                  showFilials={showFilterFilials}
                  setShowFilials={setShowFilterFilials}
                  handleSearchFilials={
                    fil => {
                      setFilterSettings({ ...filterSettings, filial: { ...fil } })
                      setShowFilterFilials(false)
                    }
                  }
                />
              </FilterComponent>
            </MainFilter>
          }
          <tr>
            <td>Lançamento</td>
            <td>Data</td>
            <td>Status</td>
            <td>Total</td>
            <td>Observação</td>
            <td>Conta Débito</td>
            <td>Conta Crédito</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {transfers && transfers.length > 0 ? (
            transfers.map(transfer => (
              <tr
                key={transfer.id}
                onClick={() => handleDialog(transfer, "transfer")}
                className={
                  transfer.conciliation_id ? "transfer conciliated" : transfer.approved_at ? "transfer approved" : "transfer "
                }
                id={transfer.id}
              >
                <td>
                  {transfer.id}
                  <ul className="dialog">
                    {verifyAccess(accesses, 'aprovacao_transferencia') &&
                      transfer.date >= filial.open_date && !transfer.concialized_at ? (
                      <li onClick={() => approveTransfer(transfer.id)}>
                        {!transfer.approved_at ? (
                          <>
                            <FaCheck size={12} /> Aprovar
                          </>
                        ) : (
                          <>
                            <FaTimes size={12} /> Estornar Aprovação
                          </>
                        )}
                      </li>
                    ) : null}
                    {verifyAccess(accesses, 'relatorios_transferencia') ?
                      <li onClick={() => MakeReporting(transfer.id)}>
                        {transfer.report ? (
                          <>
                            <FaClipboard size={12} /> Ver relátorio
                          </>
                        ) : (
                          <>
                            <FaClipboard size={12} /> Gerar relátorio
                          </>
                        )}
                      </li>
                      : null}
                    <li onClick={() => handleOpenTransfer(transfer.id, false)}>
                      <FaEye size={12} /> Visualizar
                    </li>
                    {verifyAccess(accesses, 'lancamento_transferencia') && !transfer.approved_at ? (
                      <li onClick={() => handleOpenTransfer(transfer.id, true)}>
                        <FaEdit size={12} /> Alterar
                      </li>
                    ) : null}
                    {verifyAccess(accesses, 'exclusao_transferencia') && !transfer.approved_at ? (
                      <li
                        className="trash"
                        onClick={() =>
                          handleBlock(
                            transfer.id,
                            transfer.canceled_at,
                            `transferência ${transfer.id}`,
                            "transfers",
                            setBlock
                          )
                        }
                      >
                        <FaTimes size={12} /> Excluir
                      </li>
                    ) : null}
                    {verifyAccess(accesses, 'reclassificar_transferencia') ?
                      <li
                        className="reclassificar"
                        onClick={() =>
                          handleReclass(
                            transfer.id
                          )
                        }
                      >
                        <FaRedo size={12} /> Reclassificar
                      </li>
                      : null}
                  </ul>
                </td>
                <td>{formatedDate(transfer.date)}</td>
                <td className={transfer.conciliation_id ? "conciliated" : transfer.approved_at ? "approved" : ""}>{transfer.conciliation_id ? "Conciliado" : transfer.approved_at ? "Aprovado" : "Pendente"}</td>
                <td>
                  {formatMoney(
                    transfer.value.toFixed(2),
                    filial.country.language
                  )}
                </td>
                <td>{transfer.observation}</td>
                <td>
                  {transfer.bankFrom ? transfer.bankFrom.name : "Caixa Filial"}
                </td>
                <td>
                  {transfer.bankTo ? transfer.bankTo.name : "Caixa Filial"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">Não há lançamentos.</td>
            </tr>
          )}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {openTransfer || createTransfer ? (
        <>
          <BgBlack />
          <CreateTransfer
            handleCreateTransfer={handleCreateTransfer}
            id={opennedId}
            edit={edit}
            setBlock={setBlock}
            MakeReporting={MakeReporting}
          />
        </>
      ) : null}
      {showCoA ? (
        <>
          <BgBlack />
          <BuscarPlanoDeContas
            show={showCoA}
            setShowCoA={setShowCoA}
            handleChartOfAccount={handleChartOfAccount}
          />
        </>
      ) : null}

    </>
  );
}

import React, { useState, useMemo, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { FaBell, FaRegBell,FaRegBellSlash } from "react-icons/fa";
import { format,parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';

import api from "~/services/api";

import {
  Container,
  Badge,
  NotificationList,
  Scroll,
  Notification,
  LeftSide,
  RightSide
} from "./styles";

export default function Notifications({title}) {
  const [visible, setVisible] = useState(false);
  const profile = useSelector(state => state.user.profile);
  const [notifications, setNotifications] = useState([]);

  const hasUnread = useMemo(
    () => !!notifications.find(notification => notification.read === false),
    [notifications]
  );

  function handleToggleVisible() {
    setVisible(!visible);
  }

  async function handleMarkAsRead(id) {
    // console.log(id)
    await api.put(`notifications/${id}`);

    setNotifications(
      notifications.map(notification =>
        notification.id === id ? { ...notification, read: true } : notification
      )
    );
  }

  useEffect(() => {
    async function getNotifications() {
      const { data } = await api.get(`notifications/${profile.id}`)
      setNotifications(data)
    }
    getNotifications();
  }, [])

  return (
    <Container title={title}>
      <Badge onClick={handleToggleVisible} hasUnread={hasUnread}>
        {hasUnread ? (
          <FaBell color="#FFFFFF" size={20} />
        ) : (
          <FaRegBellSlash color="#FFFFFF" size={20} />
        )}
      </Badge>
      <NotificationList visible={visible}>
        <Scroll>
          {notifications.map(notification => (
            <Notification key={notification.id} unread={!notification.read}>
              <LeftSide>
              <p><strong>{profile.language === 'pt-BR' ? notification.pt_title : notification.en_title }</strong><br/>{profile.language === 'pt-BR' ? notification.pt_content : notification.en_content}</p>
              <p style={{marginBottom: 0}}><time style={{marginBottom: 0}}>{profile.language === 'pt-BR' ? format(parseISO(notification.created_at),"dd 'de' MMMM ' às ' HH:mm", {locale: pt}) : format(parseISO(notification.created_at),`MMMM do, yyyy`, {locale: en})}</time></p>
              </LeftSide>
              <RightSide>
              { !notification.read ? (
                <button
                  type="button"
                  title="Marcar como lido"
                  onClick={() => handleMarkAsRead(notification.id)}
                  onMouseOver={(a) => console.log(a)}
                ><FaRegBell color="#9cd72e" size={20} /></button>
              ) : <FaRegBellSlash color="#888888" size={20} /> }

              </RightSide>
            </Notification>
          ))}
        </Scroll>
      </NotificationList>
    </Container>
  );
}

import React, { useState, useEffect } from "react";

import { FaFile, FaSearch, FaUniversity, FaIdCard } from "react-icons/fa";

import { Input, Form } from "@rocketseat/unform";

import { Container } from "./styles";

import api from "~/services/api";

function BuscarBanco({ handleBank }) {
  const [bank, setBank] = useState({ code: "", name: "" });
  const [listBank, setListBank] = useState([]);

  useEffect(() => {
    async function getBank() {
      const response = await api.get(
        `banksearch?code=${bank.code}&name=${bank.name}`
      );

      setListBank(response.data);
    }
    getBank();
  }, [bank]);

  async function handleSubmit() {
    const response = await api.get(`bank/${bank}`);

    setListBank(response.data);
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="rowInputs">
          <label htmlFor="search">
            <div>
              <FaIdCard size={20} />
              <br />
              Código
            </div>
            <Input
              name="search"
              placeholder="Código do Banco"
              onChange={e => setBank({ ...bank, code: e.target.value })}
            />
          </label>
          <label htmlFor="search">
            <div>
              <FaUniversity size={20} />
              <br />
              Nome
            </div>
            <Input
              name="search"
              placeholder="Nome do banco"
              onChange={e => setBank({ ...bank, name: e.target.value })}
            />
          </label>
        </div>
      </Form>
      <Container>
        {listBank.map(banks => {
          return (
            <div className="rowInputs">
              <button
                type="button"
                className="btnFinish"
                onClick={() => handleBank(banks)}
              >
                Selecionar
              </button>
              <label htmlFor="bank">
                <div>
                  <FaIdCard size={20} />
                  <br />
                  Código
                </div>
                <Input readOnly name="bank" value={banks.bank} />
              </label>
              <label htmlFor="name">
                <div>
                  <FaUniversity size={20} />
                  <br />
                  Nome
                </div>
                <Input readOnly name="name" value={banks.name} />
              </label>

              <label htmlFor="agency">
                <div>
                  <FaUniversity size={20} />
                  <br />
                  Agência
                </div>
                <Input readOnly name="agency" value={banks.agency} />
              </label>
              <label htmlFor="account_code">
                <div>
                  <FaFile size={20} />
                  <br />
                  Cód.Con
                </div>
                <Input
                  readOnly
                  name="account_code"
                  value={banks.account_code}
                />
              </label>
            </div>
          );
        })}
      </Container>
    </>
  );
}

export default BuscarBanco;

import styled from 'styled-components';

import PerfectScrollbar from 'react-perfect-scrollbar';

export const Content = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 480px);
  padding-bottom:200px;

  &:before {
    content:'';
    background: rgba(0,0,0,.4);
    width:100%;
    height:100%;
    position:fixed;
    z-index:1;
    left:0;
    top:0;
    display: ${props => props.NewCheck ? 'block' : 'none' };
  }

  header {
    width:100%;
    padding:15px 0;
    background: #555555;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index:12;
`;

export const BtnType = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;

  button {
    border:1px solid rgb(155,213,49);
    padding:8px 16px;
    border-radius:4px;
    background:#222;
    color:#FFF;
    font-weight:bold;
    font-size:14px;
    margin:16px 8px;
    transition:all .2s;
    position:relative;

    &:hover {
      background: #efefef;
      color:#222;
    }

    &.active {
      border-color: #339e2b;
    }

    &.openned {
      background: rgb(155,213,49);
    }

    &.openned:before {
      content:'';
      position:absolute;
      left:10px;
      bottom: -9px;
      width:0;
      height:0;
      border-left:9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid rgb(155,213,49);
      overflow:hidden;
    }
  }
`;


export const Container = styled.div`
  background: #efefef;
  width:100%;
  text-align:center;

  h1 {
    display:flex;
    align-items: center;
    font-size:21px;
    color:#333;
    text-align:left;
    background:#ccc;
    padding:15px;
    border-bottom:1px solid #ddd;

    svg {
      margin-right:5px;
      border-radius:4px;
      transition:background .2s;
    }

    &:hover svg {
      background:rgba(255,255,255,.5);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding:15px;

    .rowInputs {
      display:flex;
      flex-direction:row;
      justify-content: space-around;

    }

    label {
      display:flex;
      text-align:left;
      align-items: center;
      margin:0 3px 10px;
      flex:1;

      div.react-datepicker-wrapper {
        width: 100%;
        background: #FFF;
        height: 54px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        padding: 15px;

        input {
          border:none;
        }
      }

      img {
        border-radius:4px;
        overflow:hidden;
      }
    }

    label > div:first-child {
      display:inline-block;
      width:54px;
      height:54px;
      text-align:center;
      font-size:10px;
      color:#555;
      background:#FFF;
      padding:13px 8px 0;
      border-radius:4px;
    }
  }

  .canceled label {
    opacity:.6;
  }


`;

export const AccessTable = styled.div`
  width:100%;
  .rowAccessTable {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .colAccessTable {
      height:60px;
      padding:10px;
      align-items: center;
      flex-grow: 1;
      border-bottom: 1px solid rgba(0,0,0,.1);
          line-height: 40px;

      &:first-child {
        width:200px;
        text-align:left;
      }

      &:last-child {
        width:130px;
      }

      label {
        position:relative;
        display:flex;
        align-items: center;
        justify-content: center;
        input {
          display:none;
          /* height:0; */
        }
        &::after {
          content: '';
          width: 30px;
          height: 30px;
          line-height: 30px;
          border: 2px solid #efefef;
          background: #fff;
          border-radius: 4px;
          text-align: center;
        }

        &.active::after {
          content: '✔';
            width: 30px;
            height: 30px;
            border: 2px solid #efefef;
            line-height: 30px;
            background: #fff;
            border-radius: 4px;
            font-weight: bold;
            font-size: 2em
        }
      }
    }

    &:first-child {
      td {
        font-weight:bold;
        height:30px;
        line-height:30px;
        text-align:right;

        &:first-child {
          text-align:left;
        }
      }
    }
  }
`;

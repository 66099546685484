import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import Title from "~/components/Title";

import { toast } from "react-toastify";

import api from "~/services/api";

import { file_extension } from "~/assets/";

import { format, parseISO } from 'date-fns';
import pt from "date-fns/locale/pt";
import { formatMoney } from "~/scripts/index.js";

import { Input, Select } from "@rocketseat/unform";

import { Scroll, Content, Container, DivFiles } from './styles';

import BuscaFilial from "~/components/BuscaFilial";

import BuscarPos from "~/components/BuscarPos";

import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

import {
  FaCalendarAlt,
  FaMoneyBillWave,
  FaAngleRight,
  FaIndustry,
  FaTrashAlt,
  FaUser,
  FaFileUpload,
  FaSearch,
  FaUniversity,
  FaCommentDots
} from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Lancamento({lancamento, setLancamento, lancInfo, setUpdated}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [type,setType] = useState(null);
  const [banksFrom, setBanksFrom] = useState([]);
  const [banksTo, setBanksTo] = useState([]);
  const bankFrom = [{ id: 0, title: "Caixa Filial" }];
  const bankTo = [{ id: 0, title: "Caixa Filial" }];
  const [form,setForm] = useState({});
  const [showCoA, setShowCoA] = useState(false);
  const [setores,setSetores] = useState([]);
  const [preview, setPreview] = useState(null);
  const [chartOfAccount, setchartOfAccount] = useState({ code: "", nome: "" });
  const [filialSearch, setFilialSearch] = useState(false);
  const [posSearch, setPOSSearch] = useState(false);
  const [filiaisRegional, setFiliaisRegional] = useState([]);
  const [uploadFile, setUploadFile] = useState({
    status: false,
    name: "",
    url: ""
  });


  useEffect(() => {
    async function getBanks() {
      const response = await api.get("/banks/user/"+profile.id);
      const { data } = response;
      let promises = [];
      data.map(d => {
        return promises.push(
          new Promise((resolve, reject) => {
            if(lancInfo.type === 'CREDIT') {
              if (d.filial_id && d.id !== lancInfo.bank_id) {
                resolve(bankFrom.push({ id: d.id, title: d.name }));
              }
              resolve(bankTo.push({ id: d.id, title: d.name }));
            } else {
              if (d.id === lancInfo.bank_id) {
                resolve(bankFrom.push({ id: d.id, title: d.name }));
              } else {
                resolve(bankTo.push({ id: d.id, title: d.name }));
              }
            }

          })
        );
      });

      Promise.all(promises).then(async results => {
        setBanksFrom(bankFrom);
        setBanksTo(bankTo);
      });
    }

    async function getFiliaisRegional(filial_id) {
      const response = await api.get('/filiais/myregionais/'+filial_id);
      const { data } = response;
      let filiais = [{id: filial_id, title: filial.filial_name}];
      data.map(filial => {
        return filiais.push({ id: filial.id, title: filial.name })
      })

      setFiliaisRegional(filiais);
    }
    getFiliaisRegional(filial.filial)
    getBanks();
  }, [lancInfo]);

  useEffect(() => {
    async function loadType() {
      let promises = [];
      if(type === 'TRF'){
        setForm({
          created_by: profile.id,
          filial_id: filial.filial,
          filial: filial,
          bank_to_id: lancInfo.type === 'CREDIT' ? lancInfo.bank_id : 0,
          bank_from_id: lancInfo.type === 'DEBIT' ? lancInfo.bank_id : 0,
          value: lancInfo.value >= 0 ? lancInfo.value : lancInfo.value * -1,
          plano_de_contas_id: 49,
          date: parseISO(lancInfo.date),
          approved_at: parseISO(lancInfo.date),
          extract_at: parseISO(lancInfo.date),
          created_at: new Date(),
          observation: lancInfo.historic,
          conciliation_id: lancInfo.id,
          concialized_at: new Date()
        })
      } else if(type === 'EDZ'){


        async function getSetores(){
          const { data } = await api.get('sector/');
          if(!data[0]) {
            toast.error("Necessário cadastrar ao menos um setor.", {
              className: "error"
            });
            return;
          }
          setSetores(data);
          setForm({
            created_by: profile.id,
            plano_de_contas_id: 48,
            filial_id: filial.filial,
            bank_id: lancInfo.bank_id,

            date: parseISO(lancInfo.date),
            reason: data[0].id,
            money: lancInfo.value,
            total: lancInfo.value,
            electronic: true,

            checks: [],
            cards: [],
            observation: lancInfo.historic,

            approved_at: parseISO(lancInfo.date),
            created_at: new Date(),
            conciliation_id: lancInfo.id,
            concialized_at: new Date()
          })
        }
        getSetores();
      } else if(type === 'CRD'){
        setForm({
          dizimo_id: null,
          created_by: profile.id,
          filial_id: 1,
          bank_id: lancInfo.bank_id,
          pos_id: 1,
          operation: 'Crédito',
          parcels: 1,
          parcel: 1,
          flag: 'Undefined',
          date: parseISO(lancInfo.date),
          due_date: parseISO(lancInfo.date),
          document: null,
          authorization: null,
          value: lancInfo.value,

          created_at: new Date(),
        })
      } else if(type === 'ORD'){
        try {
          let provider_payment_order = {}
          promises.push(
            new Promise(async (resolve,reject) => {
              resolve(provider_payment_order = await api.get('/providers/?cgc=&name=CUPOM FISCAL'))
            })
          )
          Promise.all(promises).then(async results => {
            // setchartOfAccount();
            setForm({ ...form,provider_id: provider_payment_order.data[0].id,
              bank_id: lancInfo.bank_id,
              created_by: profile.id,
              filial_id: filial.filial,
              to_filial_id: filial.filial,
              plano_de_contas_id: null,
              s3file_id: null,
              cupom_fiscal: format(parseISO(lancInfo.date),'yyyyMMdd'),
              coo: null,
              value: lancInfo.value >= 0 ? lancInfo.value : lancInfo.value * -1,
              discount: 0,
              addition: 0,
              interest: 0,
              total: lancInfo.value >= 0 ? lancInfo.value : lancInfo.value * -1,
              payment_method: 'Transferência',
              date: parseISO(lancInfo.date),
              due_date: parseISO(lancInfo.date),
              approved_at: parseISO(lancInfo.date),
              extract_at: format(parseISO(lancInfo.date),'yyyyMMdd'),
              reason: lancInfo.historic,
              created_at: new Date(),
              conciliation_id: lancInfo.id,
              concialized_at: new Date() })
          });
        } catch(err) {
          toast.error("Fornecedor Cupom Fiscal não está ativo. Ative-o para utilizar esta função.", {
            className: "error"
          });
        }
      } else if (type === 'TFB') {
        try {
          let provider_payment_order = {};
          let coa = {};
          promises.push(
            new Promise(async (resolve,reject) => {
              resolve(provider_payment_order = await api.get('/providers/?cgc=&name=CUPOM FISCAL'))
            })
          )
          promises.push(
            new Promise(async (resolve,reject) => {
              resolve(coa = await api.get('/planodecontas/id/16'))
            })
          )
          Promise.all(promises).then(async results => {
            setchartOfAccount(coa.data);
            setForm({ ...form,
              provider_id: provider_payment_order.data[0].id,
              plano_de_contas_id: 16,
              bank_id: lancInfo.bank_id,
              created_by: profile.id,
              filial_id: filial.filial,
              s3file_id: null,
              cupom_fiscal: format(parseISO(lancInfo.date),'yyyyMMdd'),
              coo: null,
              value: lancInfo.value >= 0 ? lancInfo.value : lancInfo.value * -1,
              discount: 0,
              addition: 0,
              interest: 0,
              total: lancInfo.value >= 0 ? lancInfo.value : lancInfo.value * -1,
              payment_method: 'Transferência',
              date: parseISO(lancInfo.date),
              due_date: parseISO(lancInfo.date),
              approved_at: parseISO(lancInfo.date),
              extract_at: parseISO(lancInfo.date),
              reason: lancInfo.historic,
              created_at: new Date(),
              conciliation_id: lancInfo.id,
              concialized_at: new Date() })
          });
        } catch(err) {
          toast.error("Fornecedor Cupom Fiscal não está ativo. Ative-o para utilizar esta função.", {
            className: "error"
          });
        }
      }
    }

    loadType();
  },[type])

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if(type === 'TRF'){
        if(!form.observation) {
          toast.error("Por favor preencha a observação!", {
            className: "error"
          });
          return;
        }
        const response = await api.post("/transfers", form);
        const { data } = response;
        toast.success(
          `Transferência incluída com sucesso!`,
          { className: "success" }
        );
        await api.put(`/conciliation/${lancInfo.id}/TRF/${data.id}`);
        toast.success(
          `Conciliado com sucesso!`,
          { className: "success" }
        );
      } else if(type === 'EDZ'){
        if(!form.filial_id) {
          toast.error("Por favor defina a filial de origem!", {
            className: "error"
          });
          return;
        }
        const response = await api.post("/e-dizimos", form);
        const { data } = response;
        toast.success(
          `e-Dízimo incluído com sucesso!`,
          { className: "success" }
        );
        await api.put(`/conciliation/${lancInfo.id}/EDZ/${data.id}`);
        toast.success(
          `Conciliado com sucesso!`,
          { className: "success" }
        );
      } else if(type === 'CRD'){
        try {
        await api.post("/callback", form);
        toast.success(
          `Cartão de Crédito incluído com sucesso!`,
          { className: "success" }
        );
        } catch(err) {
          toast.error(
            `Não foi possível lançar cartão de crédito.`,
            { className: "error" }
          );
        }
      } else if(type === 'ORD'){
        if(!form.plano_de_contas_id) {
          toast.error("Por favor preencha o plano de contas!", {
            className: "error"
          });
          return;
        }
        if(!form.s3file_id) {
          toast.error("Por favor anexe o documento!", {
            className: "error"
          });
          return;
        }
        if(!form.reason) {
          toast.error("Por favor preencha a despesa!", {
            className: "error"
          });
          return;
        }
        const response = await api.post("/paymentOrders", form);
        const { data } = response;
        toast.success(
          `Ordem de Pagamento incluído com sucesso!`,
          { className: "success" }
        );
        await api.put(`/conciliation/${lancInfo.id}/ORD/${data.id}`);
        toast.success(
          `Conciliado com sucesso!`,
          { className: "success" }
        );
      } else if(type === 'TFB'){
        if(!form.plano_de_contas_id) {
          toast.error("Por favor preencha o plano de contas!", {
            className: "error"
          });
          return;
        }
        if(!form.reason) {
          toast.error("Por favor preencha a despesa!", {
            className: "error"
          });
          return;
        }
        const response = await api.post("/paymentOrders", form);
        const { data } = response;
        toast.success(
          `Tarifa Bancária incluída com sucesso!`,
          { className: "success" }
        );
        await api.put(`/conciliation/${lancInfo.id}/ORD/${data.id}`);
        toast.success(
          `Conciliado com sucesso!`,
          { className: "success" }
        );
      } else {
        return;
      }

      setLancamento(!lancamento)
      setUpdated(true)
      return;
    } catch (err) {
      toast.error("Há algum erro, por favor, revise o lançamento.", {
        className: "error"
      });
    }
  }

  function handleSearch(type, value) {
    setShowCoA(value);
  }

  function handleChartOfAccount(coa) {
    setForm({ ...form, plano_de_contas_id: coa.id });
    // console.log(coa)
    setchartOfAccount(coa);
    setShowCoA(false);
  }

  async function handleFile(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("s3files/" + profile.id, data);

    const { id: s3file_id, url } = response.data;
    setForm({ ...form, s3file_id });
    setPreview(url);
  }

  async function handleInput(e) {
    handleFile(e);
    const { name, size } = e.target.files[0];

    handleFile(e);
    setUploadFile({ status: true, name, size, e });
  }

  function setIconFileExtension(file) {
    const ext = file.split(".").pop();
    for (let i = 0; i < file_extension.length; i++) {
      if (ext === file_extension[i].title) {
        return file_extension[i].icon;
      }
    }
  }

  function removerFiles() {
    setForm({ ...form, s3file_id: null });
    setUploadFile({ status: false, name: "" });
    setPreview(null);
  }

  function handleFilial(date) {
    setForm({ ...form, filial: date, filial_id: date.id });
    setFilialSearch(false);
  }

  async function handlerPOS(data) {
    setForm({ ...form, pos_id: data.id, pos_name: data.name });
    setPOSSearch(false);
  }

  return (
    <>
    <Scroll>
      <Content>
        <header>
          <Title back={true} func={setLancamento} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Lançamento por Conciliação</h1>
          <form onSubmit={e => handleSubmit(e)}>
            <select onChange={e => setType(e.target.value)}>
              <option value=''>Selecione...</option>
              { lancInfo.type === 'CREDIT' ?
              <>
                <option value='TRF'>Transferência</option>
                <option value='EDZ'>e-Dízimo</option>
                <option value='CRD'>Cartão de Crédito</option>
              </>
              :
              <>
                <option value='ORD'>Ordem de Pagamento</option>
                <option value='TRF'>Transferência</option>
                <option value='TFB'>Tarifa Bancária</option>
              </>
              }
            </select>
            { type === 'TRF' && lancInfo.type === 'CREDIT' ?
              <>
              <hr/>

              <div className="rowInputs">
                  <label htmlFor="filial">
                    <div className='iconInput'>
                      <FaUniversity size={18} />
                      <br />
                      Filial
                    </div>
                    <Input
                      name="filial"
                      readOnly="readonly"
                      value={ form.filial && form.filial.name ? form.filial.name : ''}
                      placeholder="Buscar Filial"
                    />
                      <button
                        type="button"
                        className="btnFinish"
                        onClick={() => setFilialSearch(true)}
                      >
                        Buscar <FaSearch size={14} />
                      </button>
                  </label>
              </div>

              <hr/>

              <div className="rowInputs">

                <label htmlFor="date">
                  <div className='iconInput'>
                    <FaCalendarAlt size={20} />
                    <br />
                    Data
                  </div>
                  <DatePicker
                    name="date"
                    selected={form.date || ''}
                    disabled={true}
                    dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    locale={pt}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="money">
                  <div className='iconInput'>
                    <FaMoneyBillWave size={20} />
                    <br />
                    Valor
                  </div>
                  <Input
                    name="money"
                    disabled={true}
                    value={form.value ? formatMoney(form.value.toFixed(2)) : 0}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="bank_from">
                  <div className='iconInput'>
                    <FaUniversity size={20} />
                    <br />
                    De
                  </div>
                  <Select
                    name="bank_from"
                    placeholder="Caixa Filial"
                    options={banksFrom}
                    onChange={e => setForm({...form, bank_from_id: e.target.value !== lancInfo.bank_id ? e.target.value : 0 })}
                    value={form.bank_from_id}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="bank_to">
                  <div className='iconInput'>
                    <FaUniversity size={20} />
                    <br />
                    Para
                  </div>
                  <Select
                    name="bank_to"
                    placeholder="Caixa Filial"
                    options={banksTo}
                    disabled={true}
                    value={form.bank_to_id}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="observation">
                  <div className='iconInput'>
                    <FaCommentDots size={20} />
                    <br />
                    Observ.
                  </div>
                  <Input
                    name="observation"
                    value={form.observation || ''}
                    onChange={e => setForm({...form,observation: e.target.value})}
                  />
                </label>
              </div>
              <hr/>

              <button
                type="submit"
                className="btnFinish"
              > Lançar <FaAngleRight size={20} />
              </button>
              </>
            : null}
            { type === 'EDZ' && lancInfo.type === 'CREDIT' ?
              <>
              <hr/>

              <div className="rowInputs">
                  <label htmlFor="filial">
                    <div className='iconInput'>
                      <FaUniversity size={18} />
                      <br />
                      Filial
                    </div>
                    <Input
                      name="filial"
                      readOnly="readonly"
                      value={filial.filial_name}
                      placeholder="Buscar Filial"
                    />
                  </label>
              </div>

              <hr/>

              <div className="rowInputs">
                <label htmlFor="date">
                  <div className='iconInput'>
                    <FaCalendarAlt size={20} />
                    <br />
                    Data
                  </div>
                  <DatePicker
                    name="date"
                    selected={form.date}
                    disabled={true}
                    dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    locale={pt}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="reason">
                  <div className='iconInput'>
                    <FaIndustry size={20} />
                    <br />
                    Setor
                  </div>
                  <Select
                    options={setores}
                    name="reason"
                    defaultValue={form.reason ? form.reason : ''}
                    onChange={e =>
                      setForm({ ...form, reason: e.target.value })
                    }
                    placeholder="Selecione..."
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="money">
                  <div className='iconInput'>
                    <FaMoneyBillWave size={20} />
                    <br />
                    Valor
                  </div>
                  <Input
                    name="money"
                    disabled={true}
                    value={form.total ? formatMoney(form.total.toFixed(2)) : 0}
                  />
                </label>
              </div>

              <button
                type="submit"
                className="btnFinish"
              > Lançar <FaAngleRight size={20} />
              </button>
              </>
            : null}
            { type === 'CRD' && lancInfo.type === 'CREDIT' ?
              <>
              <hr/>

              {/* <div className="rowInputs">
                  <label htmlFor="filial">
                    <div className='iconInput'>
                      <FaUniversity size={18} />
                      <br />
                      Filial
                    </div>
                    <Input
                      name="filial"
                      readOnly="readonly"
                      value={ form.filial ? form.filial.name : null}
                      placeholder="Buscar Filial"
                    />
                      <button
                        type="button"
                        className="btnFinish"
                        onClick={() => setFilialSearch(true)}
                      >
                        Buscar <FaSearch size={14} />
                      </button>
                  </label>
              </div>

              <hr/> */}

              {/* <div className="rowInputs">
                  <label htmlFor="pos">
                    <div className='iconInput'>
                      <FaUniversity size={18} />
                      <br />
                      P.D.V.
                    </div>
                    <Input
                      name="pos"
                      readOnly="readonly"
                      value={ form.pos_name}
                      placeholder="Buscar P.D.V."
                    />
                      <button
                        type="button"
                        className="btnFinish"
                        onClick={() => setPOSSearch(true)}
                      >
                        Buscar <FaSearch size={14} />
                      </button>
                  </label>
              </div>

              <hr/> */}

              <div className="rowInputs">

                <label htmlFor="date">
                  <div className='iconInput'>
                    <FaCalendarAlt size={20} />
                    <br />
                    Data
                  </div>
                  <DatePicker
                    name="date"
                    selected={form.date}
                    disabled={true}
                    dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    locale={pt}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="money">
                  <div className='iconInput'>
                    <FaMoneyBillWave size={20} />
                    <br />
                    Valor
                  </div>
                  <Input
                    name="money"
                    disabled={true}
                    value={form.value ? formatMoney(form.value.toFixed(2)) : 0}
                  />
                </label>
              </div>

              <button
                type="submit"
                className="btnFinish"
              > Lançar <FaAngleRight size={20} />
              </button>
              </>
            : null}

            { type === 'TRF' && lancInfo.type === 'DEBIT' ?
              <>
              <hr/>

              <div className="rowInputs">

                <label htmlFor="date">
                  <div className='iconInput'>
                    <FaCalendarAlt size={20} />
                    <br />
                    Data
                  </div>
                  <DatePicker
                    name="date"
                    selected={form.date}
                    disabled={true}
                    dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    locale={pt}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="money">
                  <div className='iconInput'>
                    <FaMoneyBillWave size={20} />
                    <br />
                    Valor
                  </div>
                  <Input
                    name="money"
                    disabled={true}
                    value={form.value ? formatMoney(form.value.toFixed(2)) : 0}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="bank_from">
                  <div className='iconInput'>
                    <FaUniversity size={20} />
                    <br />
                    De
                  </div>
                  <Select
                    name="bank_from"
                    placeholder="Caixa Filial"
                    options={banksFrom}
                    disabled={true}
                    value={form.bank_from_id}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="bank_to">
                  <div className='iconInput'>
                    <FaUniversity size={20} />
                    <br />
                    Para
                  </div>
                  <Select
                    name="bank_to"
                    placeholder="Caixa Filial"
                    options={banksTo}
                    onChange={e => setForm({...form, bank_to_id: e.target.value !== lancInfo.bank_id ? e.target.value : '' })}
                    defaultValue={form.bank_to_id}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="observation">
                  <div className='iconInput'>
                    <FaCommentDots size={20} />
                    <br />
                    Observ.
                  </div>
                  <Input
                    name="observation"
                    value={form.observation || ''}
                    onChange={e => setForm({...form,observation: e.target.value})}
                  />
                </label>
              </div>
              <hr/>

              <button
                type="submit"
                className="btnFinish"
              > Lançar <FaAngleRight size={20} />
              </button>
              </>
            : null}

            { type === 'ORD' && lancInfo.type === 'DEBIT' ?
              <>
              <hr/>

              <div className="rowInputs">

                <label htmlFor="date">
                  <div className='iconInput'>
                    <FaCalendarAlt size={20} />
                    <br />
                    Data
                  </div>
                  <DatePicker
                    name="date"
                    selected={form.date}
                    disabled={true}
                    dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    locale={pt}
                  />
                </label>
              </div>

              <hr/>

              <div className="rowInputs">
                <label htmlFor="to_filial_id">
                  <div className='iconInput'>
                    <FaUniversity size={20} />
                    <br />
                    Filial
                  </div>
                  <Select
                    name="to_filial_id"
                    options={filiaisRegional}
                    selected={form.to_filial_id}
                    value={form.to_filial_id}
                    onChange={e =>
                      setForm({
                        ...form,
                        to_filial_id: e.target.value ? e.target.value : null
                      })
                    }
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="money">
                  <div className='iconInput'>
                    <FaMoneyBillWave size={20} />
                    <br />
                    Valor
                  </div>
                  <Input
                    name="money"
                    disabled={true}
                    value={form.value ? formatMoney(form.value.toFixed(2)) : 0}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="charts_of_account" style={{ flexGrow: "2" }}>
                  <div className='iconInput'>
                    <FaUser size={20} />
                    <br />
                    Pla.Con
                  </div>
                  <Input
                    name="charts_of_account"
                    readOnly={"readonly"}
                    value={chartOfAccount && chartOfAccount.nome ? chartOfAccount.nome : ""}
                  />
                  <button
                    type="button"
                    className="btnFinish"
                    onClick={() => handleSearch("chart_of_account", true)}
                  >
                    Buscar <FaSearch size={14} />
                  </button>
                </label>
              </div>

              <hr />


              <input
                type="file"
                id="files"
                name="files"
                accept="image/*"
                style={{ display: "none" }}
                onChange={e => handleInput(e)}
              />
              <h1 className="title_section_upload_file">
                Anexo
              </h1>
              <DivFiles>
                <li>
                  <table>
                    <thead>
                      <tr>
                        <td>Arquivo</td>
                        <td style={{ width: "100px" }}>Baixar</td>
                        <td style={{ width: "100px" }}>Excluir</td>
                      </tr>
                    </thead>
                    <tbody>
                      {uploadFile.status ? (
                        <tr>
                          <td>{uploadFile.name}</td>
                          <td>
                            <a
                              href={`${preview}`}
                              download
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={setIconFileExtension(uploadFile.name)}
                                alt=""
                                style={{ height: "35px", width: "35px" }}
                              />
                            </a>
                          </td>
                          <td>
                            {(
                              <FaTrashAlt
                                size={20}
                                color="#c47f7f"
                                onClick={() => removerFiles()}
                              />
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan='3'>
                            <button type="button" className="upload_file"
                                onClick={() => document.getElementById("files").click()}>
                              <FaFileUpload
                                size={18}
                              /> Selecionar arquivo...
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </li>
              </DivFiles>

              <hr />

              <div className="rowInputs">
                <label htmlFor="observation">
                  <div className='iconInput'>
                    <FaCommentDots size={20} />
                    <br />
                    Despesa
                  </div>
                  <Input
                    name="observation"
                    value={form.reason || ''}
                    onChange={e => setForm({...form,reason: e.target.value})}
                  />
                </label>
              </div>
              <hr/>

              <button
                type="submit"
                className="btnFinish"
              > Lançar <FaAngleRight size={20} />
              </button>
              </>
            : null}

            { type === 'TFB' && lancInfo.type === 'DEBIT' ?
              <>
              <hr/>

              <div className="rowInputs">

                <label htmlFor="date">
                  <div className='iconInput'>
                    <FaCalendarAlt size={20} />
                    <br />
                    Data
                  </div>
                  <DatePicker
                    name="date"
                    selected={form.date}
                    disabled={true}
                    dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    locale={pt}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="money">
                  <div className='iconInput'>
                    <FaMoneyBillWave size={20} />
                    <br />
                    Valor
                  </div>
                  <Input
                    name="money"
                    disabled={true}
                    value={form.value ? formatMoney(form.value.toFixed(2)) : 0}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="charts_of_account" style={{ flexGrow: "2" }}>
                  <div className='iconInput'>
                    <FaUser size={20} />
                    <br />
                    Pla.Con
                  </div>
                  <Input
                    name="charts_of_account"
                    readOnly={"readonly"}
                    value={chartOfAccount ? `${profile.language === 'pt-BR' ? chartOfAccount.pt_name : chartOfAccount.en_name}` : ""}
                  />
                </label>
              </div>

              <hr />

              <div className="rowInputs">
                <label htmlFor="observation">
                  <div className='iconInput'>
                    <FaCommentDots size={20} />
                    <br />
                    Despesa
                  </div>
                  <Input
                    name="observation"
                    value={form.reason}
                    onChange={e => setForm({...form,reason: e.target.value})}
                  />
                </label>
              </div>
              <hr/>

              <button
                type="submit"
                className="btnFinish"
              > Lançar <FaAngleRight size={20} />
              </button>
              </>
            : null}
          </form>
        </Container>
      </Content>
    </Scroll>

      <BuscaFilial
        handleFilial={handleFilial}
        setFilialSearch={setFilialSearch}
        show={filialSearch}
      />

      <BuscarPlanoDeContas
        show={showCoA}
        setShowCoA={setShowCoA}
        handleChartOfAccount={handleChartOfAccount}
      />

      <BuscarPos
        show={posSearch}
        handlerPOS={handlerPOS}
        setPOSSearch={setPOSSearch}
      />
    </>
  )
}

export default Lancamento;

import React, { useState } from "react";

import { Link } from "react-router-dom";

import { Form, Input } from "@rocketseat/unform";

import * as Yup from "yup";

import { toast } from "react-toastify";

import { Container } from "./styles";

import logo from "~/assets/images/logo_siaf.png";

import api from "~/services/api";

import history from "~/services/history";

const schema = Yup.object().shape({
  token: Yup.string().required("O e-mail é obrigatório."),
  email: Yup.string()
    .email("Insira um e-mail válido!")
    .required("O e-mail é obrigatório."),
  password: Yup.string().min(6, "A senha precisa ser maior que 6 digitos!"),
  confirmPassword: Yup.string().when("password", (password, field) =>
    password
      ? field.required().oneOf([Yup.ref("password")], "Senhas diferentes!")
      : field
  )
});

export default function Forgot_password({ match }) {
  const [dados, setDados] = useState({ token: match.params.token, email: "" });

  async function handleSubmit(data) {
    try {
      await api.put(`forgot_password`, data);

      toast.success("Senha alterada com successo", { className: "success" });

      history.push("/");
    } catch (err) {
      toast.error("Não foi possivel alterar sua senha!", {
        className: "error"
      });
    }
  }

  return (
    <Container>
      <img src={logo} width="315" alt="WeTrust" />
      <Form initialData={dados} schema={schema} onSubmit={handleSubmit}>
        <Input name="token" disabled readOnly placeholder="token" />
        <Input
          name="email"
          autoComplete="email"
          type="email"
          placeholder="Seu e-mail"
        />
        <Input name="password" type="password" placeholder="Sua nova senha" />
        <Input
          name="confirmPassword"
          type="password"
          placeholder="Confirme sua senha"
        />

        <button type="submit" style={{ background: "rgb(155,213,49)" }}>
          Alterar
        </button>

        <Link to="/">Iniciar sessão</Link>
      </Form>
    </Container>
  );
}

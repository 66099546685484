import styled from "styled-components";

import recycled_paper from "~/assets/images/recycled_paper.jpg";

export const NewCheck = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 15px auto;
  position: absolute;
  top: 117px;
  height: 380px;
  left: calc(50% - 500px);
  background: #efefef;
  padding: 15px;
  background: url(${recycled_paper});
  z-index: 1;

  & > .checkTitle {
    position: absolute;
    top: -53px;
    padding: 13px 0;
    background: #333;
    width: 100%;
    left: 0;
  }

  header {
    display: flex;
    align-items: center;
    height: 54px;
    justify-content: space-around;

    label {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      border-right: 2px dotted rgba(0, 0, 0, 0.15);
      padding: 0 10px;
      height: 56px;
      position: relative;

      span {
        position: absolute;
        bottom: -27px;
        background: rgb(155,213,49);
        padding: 2px 0px;
        color: #fff;
        width: calc(100% - 6px);
        text-align: center;
        font-style: normal;
        font-size: 11px;
      }

      &:first-child {
        border-left: 2px dotted rgba(0, 0, 0, 0.15);
      }

      &:nth-child(2),
      &:nth-child(4) {
        width: 200px;
      }

      &:nth-child(3),
      &:nth-child(5) {
        width: 100px;
      }

      &:last-child {
        flex-grow: 1;
      }

      input {
        background: none;
        height: 40px;
        padding: 0 5px;
        border: none;
        font-size: 16px;
        flex: 1;
        width: 100%;
      }
      select {
        background: none;
        height: 40px;
        padding: 0 5px;
        border: none;
        font-size: 14px;
        flex: 1;
        width: 100%;
      }
    }
  }

  main {
    min-height: 54px;
    display: flex;
    flex-flow: row wrap;

    p {
      font-weight: bold;
      color: #444;
      padding: 15px 5px;
      line-height: 25px;

      span {
        font-weight: normal;
        color: #444;
        border-bottom: 1px solid #444;
        font-size: 21px;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .bank {
      width: 50%;
      padding: 10px;
    }
    .client {
      flex-grow: 1;
      padding: 10px;

      label {
        display: flex;
        width: 100%;

        & > input {
          background: none;
          border: none;
          color: #444;
          font-size: 18px;
          width: 100%;
          border-bottom: 1px solid #444;
          padding-bottom: 5px;
          text-align: center;
        }

        &.check_date > input {
          width: 190px;
        }

        &.check_client {
          margin-top: 20px;
        }

        div {
          flex-grow: 1;

          input {
            width: 100%;
            background: none;
            border: none;
            color: #444;
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }
  }

  .addCheck,
  .editCheck {
    border: none;
    height: 38px;
    background: #333;
    padding: 15px 10px;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    position: absolute;
    top: 100%;
    right: 0;
    background: #339e2b;
    width: 100%;

    svg {
      margin-right: 7px;
    }

    &:hover {
      background: #333;
      color: #fff;
    }
  }

  .editCheck {
    background: #555;
  }
`;

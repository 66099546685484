import styled from "styled-components";

import { lighten } from "polished";

import PerfectScrollbar from "react-perfect-scrollbar";

export const Content = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 480px);

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    display: none;
  }

  header {
    width: 100%;
    padding: 15px 0;
    background: #555555;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 12;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #00000036;
    left: 0;
    top: 0;
  }
`;

export const Container = styled.div`
  background: #efefef;
  width: 100%;
  text-align: center;
  min-height:120px;

  h1 {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #333;
    text-align: left;
    background: #ccc;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;

    span {
      display: flex;

      .apagar_conciliacao {
        right: 15px;
        font-size: 13px;
        border: 1px solid #555;
        border-radius: 4px;
        padding: 5px 10px;
        color: #555;
        transition: all .2s ease-in;
        cursor: pointer;
        margin-left: 10px;

        &:hover {
          background: #555;
          color: #FFF;
        }
      }
    }

    svg {
      margin-right: 5px;
      border-radius: 4px;
      transition: background 0.2s;
    }

    &:hover svg {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    ${"" /* padding: 15px; */}

    .rowInputs {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0px 15px;
      margin-top: 9px;
    }

    label {
      display: flex;
      text-align: left;
      align-items: center;
      margin: 0 3px 10px;
      flex: 1;

      div.react-datepicker-wrapper {
        width: 100%;
        background: #fff;
        height: 54px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        padding: 15px;

        input {
          border: none;
        }
      }

      img {
        border-radius: 4px;
        overflow: hidden;
      }
    }

    label > div:first-child {
      display: inline-block;
      width: 54px;
      height: 54px;
      text-align: center;
      font-size: 10px;
      color: #555;
      background: #fff;
      padding: 13px 8px 0;
      border-radius: 4px;
    }

    button.btnAdd svg {
      margin-right: 7px;
    }

    button.btnFinish svg {
      margin-left: 7px;
    }

    button.inputFile {
      background: transparent;
      border: none;
      width: 70px;
      height:54px;
    }
  }
`;

export const GradeTable = styled.table`
  width: 100%;
  background: #eee;
`;

export const GradeHeader = styled.thead`
  background: ${lighten(0.4, "rgb(155,213,49)")};
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);

  td {
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  td.iconSize {
    width: 25px;
  }
`;

export const Dialog = styled.ul`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 100%;
  width: 200px;
  background: rgb(155,213,49);
  border-radius: 4px;
  display: none;
  cursor: auto;

  &::before {
    content: "";
    position: absolute;
    left: 7px;
    top: -7px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgb(155,213,49);
    overflow: hidden;
  }

  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
    color: #fff;

    &:hover {
      color: #ccc;
    }

    &.trash:hover {
      cursor: pointer;
      background: rgba(215,47,47,1);
    }
  }
`;

export const GradeBody = styled.tbody`
  tr {
    width: 100%;
    cursor: pointer;
    position: relative;

    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.05);
    }

    &:hover {
      background: ${lighten(0.5, "rgb(155,213,49)")};
    }

    &.active ul {
      display: block;
    }

    &.canceled {
      background: #888;
    }

    &.approved {
      background: rgba(0, 255, 0, 0.2);
    }

    td {
      padding: 10px;
      font-size: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;

      span {
        color: #333;
      }
    }
  }
`;

export const Filtros = styled.div`
  background: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 39px;

  p {
    padding: 0 20px;
  }
`;

export const New = styled.button`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 39px;
  background: rgb(155,213,49);
  color: #fff;
  font-weight: bold;
`;

export const Loading = styled.div`
  padding:7px 15px;
  margin:15px  auto;
  color: #555;
  border:1px solid #aaa;
  border-radius:4px;
  width:170px;
`;

export const Extrato = styled.div`
  width: 100%;
  position:relative;
`;

export const Filters = styled.ul`
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  max-width:280px;
  height:30px;
  margin:20px 10px 10px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border:1px solid #aaa;
    color: #555;
    border-color: #555;
    border-radius:4px;
    padding:0 8px;
    transition: all .2s ease-in;
    cursor: pointer;
    transition: all .2s ease-in;

    @keyframes newNumber {
      from{ transform: scale(2) }
      to{ transform: scale(1) }
    }

    :nth-child(3) small {
      animation: newNumber;
      animation-duration: .4s;
    }

    small {
      background-color:#ffffff;
      color:rgb(155,213,49);
      border-color: rgb(155,213,49);
      font-weight:bold;
      padding: 2px 4px;
      border-radius:4px;
      margin-left:10px;
    }

    &.active, &:hover {
      color: #ffffff;
      background-color: rgb(155,213,49);
      border-color: rgb(155,213,49);
    }
  }
`;

export const CardLine = styled.div`
  width: 100%;
  margin:20px 0;
  height:120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Card = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
  align-items: center;
  margin:10px;
  border:1px solid rgba(0,0,0,.15);
  background-color: rgba(0,0,0,.05);
  border-radius:4px;

  .flex-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 80px;
    width: 100%;
  padding: 10px;
  }

  .flex-row {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }

  .flex-column {
    display: flex;
  }

  h2 {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    background: rgba(0,0,0,.05);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      font-size:14px;
      margin:0;
      color: rgba(170, 0, 0,1);

      &.CREDIT {
        color: rgba(0, 170, 0,1);
      }
    }
  }

  p {
    padding: 10px;
    text-align:left;
    width:100%;
    flex-grow: 1;
  }
`;

export const ExpenseLine = styled.div`
  width: 100%;
  margin:20px 0;
  height:120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Expense = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
  align-items: center;
  margin:0 10px;
  border:1px solid rgba(170,0,0,.9);
  background-color: rgba(170,0,0,.5);
  color: #FFF;
  border-radius:4px;

  &.conciliado {
  border:1px solid #5e5074;
  background-color: rgb(155,213,49);
  }

  .flex-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 80px;
    width: 100%;
  padding: 10px;
  }

  .flex-row {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }

  .flex-column {
    display: flex;
  }

  h2 {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    background: rgba(0,0,0,.05);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    span {
      font-size:14px;
      margin:0;
      color: rgba(170, 0, 0,1);

      &.CREDIT {
        color: rgba(0, 170, 0,1);
      }
    }
  }

  p {
    padding: 10px;
    text-align:left;
    width:100%;
    flex-grow: 1;
  }
`;

export const ButtonConciliar = styled.button`
  width: auto !important;
  color: #555;
  display: flex;
  cursor: auto;
`;

export const BoxConciliar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin:10px;
  border:1px solid rgba(0,0,0,.15);
  background-color: rgba(0,0,0,.05);
  border-radius: 4px;
  height:100%;
  overflow: hidden;

  ul {
      width: 150px;
      height:100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

    li {
      height:50%;
      width: 100%;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition:all .2s ease-in;
      border-right:1px solid rgba(0,0,0,.1);
      border-bottom:1px solid rgba(0,0,0,.1);
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: rgba(0,0,0,.1);
      }

      &.active {
        border-right: 1px solid transparent;
      }
    }

  }

  div {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    select {
      width: 90%;
      padding:5px;
      margin: 5px 0;
    }

    @keyframes newNumber {
      from{ transform: scale(1.5) }
      to{ transform: scale(1) }
    }

    button {
      color: rgb(155,213,49);
      animation: newNumber;
      animation-duration: .4s;
      border-radius: 4px;
      padding: 4px 10px;
      background: transparent;
      border: 1px solid rgb(155,213,49);
    }
  }
`;

export const Conciliado = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin:10px;
  border:1px solid rgba(0,0,0,.15);
  background-color: rgba(0,0,0,.05);
  border-radius: 4px;
  height:100%;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  color: #555;

  .small {
    cursor: pointer;
    margin-top:10px;
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 4px 8px;
    background: #ccc;
    color: #555;
    font-size: 12px;
    transition: all .2s ease-in;

    &:hover {
      background: #ddd;
      color: #444;
    }
  }
  `;

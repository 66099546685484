import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Select } from "@rocketseat/unform";
import extenso from "extenso";
import pt from "date-fns/locale/pt";
import DatePicker from "react-datepicker";
import { isAfter, format, parseISO } from "date-fns";
import { FaCheck, FaEdit } from "react-icons/fa";

import { NewCheck } from "./styles";
import {
  maskPhone,
  formatMoney,
  formatNum,
  handleBlock,
  getBankName,
  getBanks,
  n_extenso
} from "~/scripts/index.js";
import Title from "~/components/Title";
import Itau from "~/assets/images/banks/itau.png";
import Bradesco from "~/assets/images/banks/bradesco.png";

function NewChecks({ setNewCheck, newCheck, handlerChecks, editCheck, setEditCheck, checks }) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [checkCorrect, setCheckCorrect] = useState(false);
  const code = useSelector(state => state.filial.country.code);
  const [check, setCheck] = useState({
    date: format(new Date(),'yyyy-MM-dd'),
    bank: null,
    bank_name: null,
    agency: null,
    agency_code: 0,
    account: null,
    account_code: 0,
    city: null,
    benef: null,
    phone: "",
    number: null,
    value: 0,
    pre: false,
    filial_id: filial.filial,
    created_at: new Date(),
    created_by: profile.id
  });
  // console.log(check)

  useEffect(() => {
    const { bank, agency, account, value, benef, date, city, number } = check;

    setCheckCorrect(
      bank &&
        agency &&
        account &&
        formatNum(value) > 0 &&
        benef &&
        date &&
        city &&
        number
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);

  useEffect(() => {
    async function checkePre() {
      isAfter(parseISO(check.date), new Date())
        ? setCheck({ ...check, pre: true })
        : setCheck({ ...check, pre: false });
    }
    checkePre();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check.date]);

  const banks = [
    { id: 341, title: "341 - Itaú", logo: Itau },
    { id: 237, title: "237 - Bradesco", logo: Bradesco }
  ];

  useEffect(() => {
    if(editCheck !== null) {
      checks.map((chk, index) => {
        if(index === editCheck) {
          setCheck(chk);
        }
      })
    }
  }, [editCheck])

  return (
    <NewCheck>
      <div className="checkTitle">
        <Title
          back={true}
          func={() => { setNewCheck(false); setEditCheck(null); }}
          title={"Cancelar"}
        />
      </div>
      <header>
        <label htmlFor="bank">
          Banco
          <Select
            options={getBanks(code)}
            name="bank"
            onChange={e => setCheck({ ...check, bank: parseInt(e.target.value.substr(0,3)) })}
            value={check.bank && check.bank_name ? check.bank.toString().padStart(3, '0') + " - "+check.bank_name : null}
            placeholder="..."
          />
        </label>
        <label htmlFor="agency">
          {code === "BR" ? "Agência" : "R.N."}
          <input
            type="text"
            name="agency"
            maxLength="6"
            onChange={e => setCheck({ ...check, agency: e.target.value })}
            value={check.agency}
            placeholder="0000"
          />
        </label>
        {code === "BR" && (
          <label htmlFor="agency_code">
            C1
            <input
              type="text"
              name="agency_code"
              maxLength="1"
              onChange={e =>
                setCheck({ ...check, agency_code: e.target.value })
              }
              value={check.agency_code}
              placeholder="0"
            />
          </label>
        )}

        <label htmlFor="account" style={{ width: "200px" }}>
          {code === "BR" ? "Conta" : "Accounting"}
          <input
            type="text"
            name="account"
            maxLength="10"
            onChange={e => setCheck({ ...check, account: e.target.value })}
            value={check.account}
            placeholder="0000000000"
          />
        </label>
        {code === "BR" && (
          <label htmlFor="account_code">
            C2
            <input
              type="text"
              name="account_code"
              maxLength="1"
              onChange={e =>
                setCheck({ ...check, account_code: e.target.value })
              }
              value={check.account_code}
              placeholder="0"
            />
          </label>
        )}

        <label htmlFor="value">
          {filial.country.currency}
          <input
            type="text"
            name="value"
            onChange={e =>
              setCheck({ ...check, value: formatNum(e.target.value) })
            }
            value={formatMoney(check.value.toFixed(2), filial.country.language)}
          />
        </label>
      </header>
      <main>
        <p>
          Pague por este
          <br />
          cheque a quantia de
          <span>{" " + n_extenso(filial.country.code, check.value) + "."}</span>
        </p>
      </main>
      <footer>
        <div className="bank">
          {/* {banks.map(item =>
            parseInt(item.id) === parseInt(check.bank) ? (
              <img
                src={item.logo}
                key={item.id}
                title={item.title}
                alt={item.title}
              />
            ) : null
          )} */}
        </div>
        <div className="client">
          <label htmlFor="check_date" className="check_date">
            <input
              type="text"
              name="check_date"
              onChange={e => setCheck({ ...check, city: e.target.value })}
              value={check.city}
              placeholder="Cidade"
            />
            <DatePicker
              name="date"
              onChange={date => setCheck({ ...check, date: format(date,'yyyy-MM-dd') })}
              dateFormat="dd' de 'MMMM' de 'yyyy"
              placeholderText="data do cheque"
              selected={parseISO(check.date)}
              value={parseISO(check.date)}
              locale={pt}
            />
          </label>
          <label htmlFor="benef" className="check_client">
            <input
              type="text"
              name="benef"
              placeholder="Nome do Responsável"
              onChange={e => setCheck({ ...check, benef: e.target.value })}
              value={check.benef}
            />
          </label>
          <label htmlFor="phone_client" className="check_client">
            <input
              type="text"
              name="phone_client"
              placeholder="Telefone"
              onChange={e => setCheck({ ...check, phone: e.target.value })}
              value={maskPhone(check.phone, code)}
            />
            <input
              type="text"
              name="number"
              placeholder="Numero do Cheque"
              onChange={e => setCheck({ ...check, number: e.target.value })}
              value={check.number}
            />
          </label>
        </div>
      </footer>
      {checkCorrect ? (
        <button
          type="button"
          className="addCheck"
          onClick={() => handlerChecks(check,editCheck)}
        >
          <FaCheck size={20} /> Gravar
        </button>
      ) : (
        <button type="button" className="editCheck">
          <FaEdit size={16} /> Preencha todos os campos
        </button>
      )}
    </NewCheck>
  );
}

export default NewChecks;

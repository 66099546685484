import { all } from "redux-saga/effects";

import auth from "./auth/sagas";
import user from "./user/sagas";
import homebox from "./homebox/sagas";
import category from "./category/sagas";
import filial from "./filial/sagas";
import functionalities from "./functionalities/sagas";
import selectedFilials from './selectedFilials/sagas'

export default function* rootSaga() {
  return yield all([auth, user, homebox, category, filial, functionalities, selectedFilials]);
}

import React from "react";

import { Switch } from "react-router-dom";
import Route from "./Route";

import Login from "~/pages/Login";
import Forgot from "~/pages/Forgot";
import Register from "~/pages/Register";
import Dashboard from "~/pages/Dashboard";
import Profile from "~/pages/Profile";

export default function Routes() {
  return (
    <>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/forgot_password/:token" component={Forgot} />
      <Route path="/register" component={Register} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  align-self:center;
  margin-bottom:30px;

  label {
    cursor: pointer;

    &:hover {
      opacity:.7;
    }

    img {
      width:120px;
      height:120px;
      border-radius:4px;
      box-shadow:0 0 3px rgba(0,0,0,.1);
    }

    input {
      display:none;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
  background:#FFF;

  ul li {
    color: #555;
  }

  button {
    display:flex;
    flex-direction: row;
    padding:8px 20px;
    align-items: center;
    border:none;
    border-left:1px solid rgba(0,0,0,.1);
    border-right:1px solid rgba(0,0,0,.1);
    height:39px;
    background:rgb(155,213,49);
    color:#FFF;
    font-weight:bold;
    border-radius: 4px;
  }
`;

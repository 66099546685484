import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Content, Container, Scroll } from "./styles";

import { FaAddressCard } from "react-icons/fa";

import { Form, Input } from "@rocketseat/unform";

import Title from "~/components/Title";

import api from "~/services/api";

import BgBlack from "~/components/BgBlack";

export default function BuscarFiliais({
  showFilials,
  setShowFilials,
  initialData = '',
  handleSearchFilials
}) {
  const profile = useSelector(state => state.user.profile);
  // const [chartOfAccountSearch, setChartOfAccoutSearch] = useState({
  //   nome: ""
  // });

  const [filials, setFilials] = useState([]);

  useEffect(() => {
    async function getCharts() {
      try {
        const { data } = await api.get(
          `/users/filials/${profile.id}`
        );

        setFilials(data);

      } catch (err) {
        setFilials([]);
      }
    }
    getCharts();
  }, []);

  return (
    <>
      {showFilials ? <BgBlack zIndex={15} /> : null}
      <Scroll style={!showFilials ? { display: "none" } : null}>
        <Content style={{ maxWidth: "1100px", left: "calc(50% - 550px)" }}>
          <header>
            <Title back={true} func={setShowFilials} title={"Cancelar"} />
          </header>
          <Container>
            <h1>Buscar Filiais</h1>
            <Form initialData={initialData}>

              {filials.length > 0
                ? filials.map((p, index) => (
                  <div className="rowInputs" key={index}>
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() =>
                        handleSearchFilials({
                          name: p.filial.name,
                          id: p.filial_id,
                        })
                      }
                    >
                      Selecionar
                    </button>
                    <label htmlFor="nome">
                      <div>
                        <FaAddressCard size={20} />
                        <br />
                        Filial
                      </div>
                      <Input readOnly name={p.filial.name} value={p.filial.name} />
                    </label>
                  </div>
                ))
                : null}
            </Form>
          </Container>
        </Content>
      </Scroll>
    </>
  );
}

import styled from "styled-components";

import recycled_paper from "~/assets/images/recycled_paper.jpg";

import PerfectScrollbar from "react-perfect-scrollbar";

export const Content = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 480px);

  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .content-load {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000a6;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    display: ${props => (props.NewCheck ? "block" : "none")};
  }

  header {
    width: 100%;
    padding: 15px 0;
    background: #555555;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 12;
`;

export const Container = styled.div`
  background: #efefef;
  width: 100%;
  text-align: center;

  h1 {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #333;
    text-align: left;
    background: #ccc;
    padding: 15px;
    border-bottom: 1px solid #ddd;

    svg {
      margin-right: 5px;
      border-radius: 4px;
      transition: background 0.2s;
    }

    &:hover svg {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .rowInputs {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    label {
      display: flex;
      text-align: left;
      align-items: center;
      margin: 0 3px 10px;
      flex: 1;
      position:relative;

      span {
        position: absolute;
        bottom: -27px;
        background: rgb(155,213,49);
        padding: 2px 0px;
        color: #FFF;
        width: calc(100% - 6px);
        text-align: center;
        font-style: normal;
        font-size: 11px;
      }

      div.react-datepicker-wrapper {
        width: 100%;
        background: #fff;
        height: 54px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        padding: 15px;

        input {
          border: none;
        }
      }

      img {
        border-radius: 4px;
        overflow: hidden;
      }
    }

    label > div:first-child {
      display: inline-block;
      width: 54px;
      height: 54px;
      text-align: center;
      font-size: 10px;
      color: #555;
      background: #fff;
      padding: 13px 8px 0;
      border-radius: 4px;
    }

    button.btnAdd svg {
      margin-right: 7px;
    }

    button.btnFinish svg {
      margin-left: 7px;
    }
  }
`;

export const NewCheck = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 15px auto;
  position: absolute;
  top: 117px;
  height: 380px;
  left: calc(50% - 500px);
  background: #efefef;
  padding: 15px;
  background: url(${recycled_paper});
  z-index: 1;

  & > .checkTitle {
    position: absolute;
    top: -53px;
    padding: 13px 0;
    background: #333;
    width: 100%;
    left: 0;
  }

  header {
    display: flex;
    align-items: center;
    height: 54px;
    justify-content: space-around;

    label {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      border-right: 2px dotted rgba(0, 0, 0, 0.15);
      padding: 0 10px;
      height: 56px;

      &:first-child {
        border-left: 2px dotted rgba(0, 0, 0, 0.15);
      }

      &:nth-child(2),
      &:nth-child(4) {
        width: 200px;
      }

      &:nth-child(3),
      &:nth-child(5) {
        width: 100px;
      }

      &:last-child {
        flex-grow: 1;
      }

      input {
        background: none;
        height: 40px;
        padding: 0 5px;
        border: none;
        font-size: 16px;
        flex: 1;
      }
      select {
        background: none;
        height: 40px;
        padding: 0 5px;
        border: none;
        font-size: 14px;
        flex: 1;
      }
    }
  }

  main {
    min-height: 54px;
    display: flex;
    flex-flow: row wrap;

    p {
      font-weight: bold;
      color: #444;
      padding: 15px 5px;
      line-height: 25px;

      span {
        font-weight: normal;
        color: #444;
        border-bottom: 1px solid #444;
        font-size: 21px;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .bank {
      width: 50%;
      padding: 10px;
    }
    .client {
      flex-grow: 1;
      padding: 10px;

      label {
        display: flex;
        width: 100%;

        & > input {
          background: none;
          border: none;
          color: #444;
          font-size: 18px;
          width: 100%;
          border-bottom: 1px solid #444;
          padding-bottom: 5px;
          text-align: center;
        }

        &.check_date > input {
          width: 190px;
        }

        &.check_client {
          margin-top: 20px;
        }

        div {
          flex-grow: 1;

          input {
            width: 100%;
            background: none;
            border: none;
            color: #444;
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }
  }

  .addCheck,
  .editCheck {
    border: none;
    height: 38px;
    background: #333;
    padding: 15px 10px;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    position: absolute;
    top: 100%;
    right: 0;
    background: #339e2b;
    width: 100%;

    svg {
      margin-right: 7px;
    }

    &:hover {
      background: #333;
      color: #fff;
    }
  }

  .editCheck {
    background: #555;
  }
`;

import produce from 'immer';

const INITIAL_STATE = {
  filials: {
    selected: [],
    main: 0,
    changed: false
  },
}

export default function selectedFilials(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@selectedFilials/UPDATE_SELECTED_FILIALS_SUCCESS': {
        draft.filials = action.payload.filials;
        break;
      }
      default:
    }
  });
}

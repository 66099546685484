import produce from "immer";

const INITIAL_STATE = {
  tootipe: true
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case "@auth/LOGIN_SUCCESS": {
        draft.tootipe = true;
        break;
      }
      case "@functionalities/FUNCTIONALITIES_UPDATE": {
        draft.tootipe = false;
        break;
      }
      default:
    }
  });
}

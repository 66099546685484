import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Title from "~/components/Title";
import { Input, Choice } from "@rocketseat/unform";

import { FaAngleRight, FaUserAlt } from "react-icons/fa";

import { Content, Container, Scroll, GroupAccess } from "./styles";
import api from "~/services/api";

function EditAccesses({groupId, setEditAccesses, setBlock}) {

  const [hierarchies, setHierarchies] = useState(null);
  const [isAlterado, setIsAlterado] = useState(false);
  const [name, setName] = useState(null);

  useEffect(() => {
    async function loadHierarchies() {
      try {
        const { data } = await api.get(`/GroupHierarchy/Group/${groupId}`);

        setHierarchies(data.groupHierarchy);
        setName(data.groupHierarchy.nome)

      } catch (err) {

      }
    }
    if (groupId) {
      loadHierarchies();
    }
  }, [groupId]);

  function handleAllow(alias,id) {
    if(groupId === 1) {
      return;
    }
    let alterar = null;
    let nivel = 0;
    let nivel1allowQtd = 0;
    let nivel2allowQtd = 0;
    let nivel3allowQtd = 0;
    let active = false;
    setIsAlterado(true);
    if(document.getElementById(alias+id.toString())) {
      document.getElementById(alias+id.toString()).classList.toggle('active');
      active = document.getElementById(alias+id.toString()).classList.contains('active');
    }
    hierarchies.hierarchies && hierarchies.hierarchies.map(h => {
      if(h.id === id) {
        h.allow = !h.allow;
        alterar = h;
        nivel = 1;
      }
      nivel1allowQtd = h.subGroup ? h.subGroup.length : 0;
      h.subGroup && h.subGroup.map(sg => {
        if(alterar) {
          sg.allow = alterar.allow;
          document.getElementById(sg.alias+sg.id.toString()).classList.remove('active');
          if(active) {
            document.getElementById(sg.alias+sg.id.toString()).classList.add('active');
          }
        }
        if(sg.id === id) {
          sg.allow = !sg.allow;
          alterar = sg;
          nivel = 2;
          if(sg.allow) {
            h.allow = true;
            document.getElementById(h.alias+h.id.toString()).classList.add('active');
          }
        }
        if(sg.id === id && hierarchies.default_homebox === sg.alias) {
          handlePrimeiroAcesso('',{alias: '', id: null, allow: true})
        }
        nivel2allowQtd = sg.subGroup ? sg.subGroup.length : 0;
        sg.subGroup && sg.subGroup.map(sgg => {
          if(alterar) {
            sgg.allow = alterar.allow;
            document.getElementById(sgg.alias+sgg.id.toString()).classList.remove('active');
            if(active) {
              document.getElementById(sgg.alias+sgg.id.toString()).classList.add('active');
            }
          }
          if(sgg.id === id) {
            sgg.allow = !sgg.allow;
            alterar = sgg;
            nivel = 3;
            if(sgg.allow) {
              h.allow = true;
              sg.allow = true;
              document.getElementById(sg.alias+sg.id.toString()).classList.add('active');
              document.getElementById(h.alias+h.id.toString()).classList.add('active');
            }
          }
          nivel3allowQtd = sgg.subGroup ? sgg.subGroup.length : 0;
          sgg.subGroup && sgg.subGroup.map(sggg => {
            if(alterar) {
              sggg.allow = alterar.allow;
              document.getElementById(sggg.alias+sggg.id.toString()).classList.remove('active');
              if(active) {
                document.getElementById(sggg.alias+sggg.id.toString()).classList.add('active');
              }
            }
            if(sggg.id === id) {
              sggg.allow = !sggg.allow;
              alterar = sggg;
              nivel = 4;
              if(sggg.allow) {
                h.allow = true;
                sg.allow = true;
                sgg.allow = true;
                document.getElementById(sgg.alias+sgg.id.toString()).classList.add('active');
                document.getElementById(sg.alias+sg.id.toString()).classList.add('active');
                document.getElementById(h.alias+h.id.toString()).classList.add('active');
              }
            }
            if(nivel == 4) {
              alterar = null;
            }
            if(!sggg.allow) {
              nivel3allowQtd--;
            }
            if(nivel3allowQtd === 0) {
              sgg.allow = false;
              document.getElementById(sgg.alias+sgg.id.toString()).classList.remove('active');
            }
          })
          if(nivel == 3) {
            alterar = null;
          }
          if(!sgg.allow) {
            nivel2allowQtd--;
          }
          // if(nivel2allowQtd === 0) {
          //   sg.allow = false;
          //   document.getElementById(sg.alias+sg.id.toString()).classList.remove('active');
          // }
        })
        if(nivel == 2) {
          alterar = null;
        }
        if(!sg.allow) {
          nivel1allowQtd--;
        }
        if(nivel1allowQtd === 0) {
          h.allow = false;
          document.getElementById(h.alias+h.id.toString()).classList.remove('active');
        }
      })
      if(nivel == 1) {
        alterar = null;
      }
    });


  }

  async function handleSubmit() {
    if(!name) {
      return toast.error(`Por favor preencha o nome do grupo.`, { className: "error" });
    }
    if(!hierarchies.default_category || !hierarchies.default_homebox) {
      return toast.error(`Por favor defina um primeiro acesso.`, { className: "error" });
    }
    hierarchies.nome = name;
    try {
      const response = await api.post(`/GroupHierarchy/Group/${groupId}`, hierarchies);
      setHierarchies(response.data.hierarchy);
      toast.error(`Alterações gravadas com sucesso!`, { className: "success" });
      setBlock(true);
      setEditAccesses(null);
    } catch(err) {
      toast.error(`Erro ao atualizar, tente novamente`, { className: "error" });
    }
    setIsAlterado(false);
  }

  function handlePrimeiroAcesso(h,sg) {
    if(hierarchies.default_homebox === sg.alias || !sg.allow) {
      return;
    }
    hierarchies.default_category = h;
    hierarchies.default_homebox = sg.alias;
    setIsAlterado(true);
    const buttons = Array.from(document.getElementsByClassName('primeiro_acesso'));
    buttons.map(button => {
      button.classList.remove('active');
    })
    if(sg && document.getElementById('pa_'+sg.alias)) {
      document.getElementById('pa_'+sg.alias).classList.add('active');
    }
  }

  return (
  <Scroll>
    <Content>
      <header>
        <Title back={true} func={() => setEditAccesses(null)} title={"Cancelar"} />
      </header>
      <Container>
        <h1>Grupo de Usuários</h1>
        <form>
          <div className="rowInputs">
            <label htmlFor="nome">
              <div>
                <FaUserAlt size={20} />
                <br />
                Nome
              </div>
              <input
                name="nome"
                placeholder="Nome do Grupo"
                maxLength="40"
                readOnly={groupId <= 5 ? true : false}
                onChange={e => { setName(e.target.value); setIsAlterado(true); }}
                value={name && name !== 'Extra' ? name : null}
              />
              <button
                type='button'
                className="btnFinish"
                onClick={() => handleSubmit()}
                disabled={!isAlterado}
                style={{margin: 0, width: '210px',justifyContent: 'center'}}>{ isAlterado ? (<>Gravar Alterações <FaAngleRight size={20} /></>) : 'Grupo Atualizado' }</button>
            </label>
          </div>
          <GroupAccess>
            { hierarchies && hierarchies.hierarchies.map((h,index) =>
              (
              <div className={`group_page ${h.allow ? 'active' : ''}`} id={h.name} key={index}>
                <div className="group_page_info">
                  <div onClick={() => handleAllow(h.alias,h.id)} className={`status ${h.allow ? 'active' : ''}`} id={h.alias+h.id.toString()}></div>
                  <strong onClick={() => handleAllow(h.alias,h.id)}>{ h.name }</strong>
                </div>
                {h.subGroup && h.subGroup.map((sg,index) => (
                <>
                  <div key={index} className={`group_page_item`}>
                    <div className="group_page_item_info">
                      <div onClick={() => handleAllow(sg.alias,sg.id)} className={`status ${sg.allow ? 'active' : ''}`} id={sg.alias+sg.id.toString()}></div>
                      <strong onClick={() => handleAllow(sg.alias,sg.id)}>{sg.name}</strong>
                      <div className="group_page_item_info_right"><button id={`pa_${sg.alias}`} className={`primeiro_acesso ${hierarchies.default_homebox === sg.alias ? 'active' : ''}`} onClick={() => handlePrimeiroAcesso(h.alias,sg)} type='button'>{hierarchies.default_homebox === sg.alias ? 'Primeiro Acesso' : 'Definir primeiro acesso'}</button></div>
                    </div>

                    {sg.subGroup && sg.subGroup.map((sgg,index) => (
                      <div key={index} className={`group_page_item_item`}>
                        <div className="group_page_item_item_info">
                        <div onClick={() => handleAllow(sgg.alias,sgg.id)} className={`status ${sgg.allow ? 'active' : ''}`} id={sgg.alias+sgg.id.toString()}></div>
                          <div onClick={() => handleAllow(sgg.alias,sgg.id)}>{sgg.name}</div>
                        </div>

                        {sgg.subGroup && sgg.subGroup.map((sggg,index) => (
                          <div key={index} className={`group_page_item_item_item`}>
                            <div className="group_page_item_item_item_info">
                            <div onClick={() => handleAllow(sggg.alias,sggg.id)} className={`status ${sggg.allow ? 'active' : ''}`} id={sggg.alias+sggg.id.toString()}></div>
                              <div onClick={() => handleAllow(sggg.alias,sggg.id)}>{sggg.name}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </>
                ))}
                <hr />
              </div>)
            )}
          </GroupAccess>
        </form>
      </Container>
    </Content>
  </Scroll>);
}

export default EditAccesses;

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import * as Yup from "yup";

import { Form, Input, Select } from "@rocketseat/unform";

import {
  FaTimesCircle,
  FaAngleRight,
  FaDollarSign,
  FaThumbsUp,
  FaThumbsDown,
  FaIndustry,
  FaFile,
  FaBuilding,
  FaSearch
} from "react-icons/fa";

import BuscaOperator from "~/components/BuscaOperator";

import BuscaSector from "~/components/BuscaSector";

import BuscaFilial from "~/components/BuscaFilial";

import Title from "~/components/Title";

import { Scroll, Content, Container } from "./styles";

import { formatMoney, formatNum } from "~/scripts/index.js";

import api from "~/services/api";

function CreatePOS({ setCreatePos, edit, id, setBlock }) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [pos, setPos] = useState({
    code: null,
    name: null,
    number_pos: null,
    situation: null,
    cost: 0,
    operator_id: null,
    sector_id: null,
    observation: null,
    created_by: profile.id,
    filial: null,
    filial_id: filial.filial,
    created_at: new Date()
  });
  const [finish, setFinish] = useState(false);
  const [complementDate, setComplementDate] = useState({
    sector: { code: "", name: "" },
    operator: { code: "", name: "" },
    filial: { id: "", name: "" }
  });
  const [sectorSearch, setSectorSearch] = useState(false);
  const [operatorSearch, setOperatorSearch] = useState(false);
  const [filialSearch, setFilialSearch] = useState(false);

  useEffect(() => {
    const { name, number_pos, situation, operator_id, sector_id } = pos;

    name && number_pos && situation && operator_id && sector_id
      ? setFinish(true)
      : setFinish(false);
  }, [pos]);

  useEffect(() => {
    async function getPos() {
      const response = await api.get(`pos/${id}`);
      setPos(response.data);
      setComplementDate({
        sector: response.data.sector,
        operator: response.data.operator,
        filial: response.data.filial_date
      });
    }
    if (id) {
      getPos();
    }
  }, [id]);

  function handleSearch(type, status) {
    if (type === "sector") {
      setSectorSearch(status);
    } else if (type === "filial") {
      setFilialSearch(status);
    } else {
      setOperatorSearch(status);
    }
  }

  function handlerSector(date) {
    setPos({ ...pos, sector_id: date.id });
    setSectorSearch(false);
    setComplementDate({ ...complementDate, sector: date });
  }

  function handlerOperator(date) {
    setPos({ ...pos, operator_id: date.id });
    setOperatorSearch(false);
    setComplementDate({ ...complementDate, operator: date });
  }

  function handleFilial(date) {
    setComplementDate({ ...complementDate, filial: date });
    setPos({ ...pos, filial: date.id });
    setFilialSearch(false);
  }

  async function handleSubmit() {
    try {
      !id ? await api.post("pos", pos) : await api.put(`pos/${pos.id}`, pos);

      toast.success(`P.O.S ${id ? "Atualizada" : "inserida"} com sucesso!`, {
        className: "success"
      });
      setBlock(true);
      setCreatePos(false);
    } catch (err) {
      toast.error(`Erro ao ${id ? "Atualizar" : "inserir"} P.O.S!`, {
        className: "error"
      });
    }
  }

  const options = [
    { id: "Ativo", title: "Ativo" },
    { id: "Inativo", title: "Inativo" }
  ];

  const schema = Yup.object().shape({});

  return (
    <>
      <Scroll
        style={
          sectorSearch || operatorSearch || filialSearch
            ? { display: "none" }
            : null
        }
      >
        <div className="overlay"></div>

        <Content>
          <header>
            <Title back={true} func={setCreatePos} title={"Cancelar"} />
          </header>
          <Container>
            <h1>Novo PDV</h1>
            <Form schema={schema} initialData={pos} onSubmit={handleSubmit}>
              <div className="rowInputs">
                <label htmlFor="number_pos">
                  <div>
                    <FaFile size={20} />
                    <br />
                    N° PDV
                  </div>
                  <Input
                    name="number_pos"
                    placeholder="N° PDV"
                    readOnly={(edit && id) || id ? "readonly" : null}
                    onChange={e =>
                      setPos({
                        ...pos,
                        number_pos: e.target.value
                          .toString().toUpperCase().replace(/[^a-zA-Z0-9-]/gi,'')
                      })
                    }
                    value={pos.number_pos}
                  />
                </label>
                <label htmlFor="situation">
                  <div>
                    {pos.situation === "Ativo" ? (
                      <FaThumbsUp size={20} />
                    ) : (
                      <FaThumbsDown size={20} />
                    )}
                    <br />
                    Situa.
                  </div>
                  <Select
                    name="situation"
                    placeholder="Situação"
                    disabled={edit ? false : true}
                    options={options}
                    onChange={e =>
                      setPos({ ...pos, situation: e.target.value })
                    }
                    value={pos.situation}
                  />
                </label>
              </div>
              <hr />

              <div className="rowInputs">
                <label htmlFor="cost">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Custo
                  </div>
                  <Input
                    name="cost"
                    placeholder="Nome"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setPos({ ...pos, cost: formatNum(e.target.value) })
                    }
                    value={formatMoney(
                      pos.cost.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="filial">
                  <div>
                    <FaBuilding size={20} />
                    <br />
                    filial
                  </div>
                  <Input
                    name="filial"
                    placeholder="filial"
                    readOnly={"readonly"}
                    value={complementDate.filial.name}
                  />
                </label>
                {edit && (
                  <button
                    type="button"
                    className="btnFinish"
                    onClick={() => handleSearch("filial", true)}
                  >
                    Buscar <FaSearch size={14} />
                  </button>
                )}
                <label htmlFor="name">
                  <div>
                    <FaFile size={20} />
                    <br />
                    Nome
                  </div>
                  <Input
                    name="name"
                    placeholder="Nome"
                    readOnly={edit ? null : "readonly"}
                    onChange={e => setPos({ ...pos, name: e.target.value })}
                    value={pos.name}
                  />
                </label>
              </div>
              <hr />

              <div className="rowInputs">
                <label htmlFor="operator">
                  <div>
                    <FaFile size={20} />
                    <br />
                    Oper.
                  </div>
                  <Input
                    name="operator"
                    placeholder="Operadora"
                    readOnly={"readonly"}
                    value={`${complementDate.operator.name}`}
                  />
                </label>
                {edit && (
                  <button
                    type="button"
                    className="btnFinish"
                    onClick={() => handleSearch("operator", true)}
                  >
                    Buscar <FaSearch size={14} />
                  </button>
                )}

                <label htmlFor="sector">
                  <div>
                    <FaIndustry size={20} />
                    <br />
                    Setor
                  </div>
                  <Input
                    name="sector"
                    placeholder="Setor"
                    readOnly={"readonly"}
                    value={`${complementDate.sector.name}`}
                  />
                </label>
                {edit && (
                  <button
                    type="button"
                    className="btnFinish"
                    onClick={() => handleSearch("sector", true)}
                  >
                    Buscar <FaSearch size={14} />
                  </button>
                )}
                {edit ? (
                  <button
                    type="submit"
                    className="btnFinish"
                    finish={finish.toString()}
                    disabled={!finish}
                    title={
                      !finish
                        ? "Há campos obrigatórios não preenchidos!"
                        : "Finalizar"
                    }
                  >
                    {finish ? (
                      <>
                        {!id ? "Finalizar" : "Atualizar"}
                        <FaAngleRight size={20} />
                      </>
                    ) : (
                      <>
                        ... <FaTimesCircle size={20} />
                      </>
                    )}
                  </button>
                ) : null}
              </div>
            </Form>
          </Container>
        </Content>
      </Scroll>

      <BuscaOperator
        handlerOperator={handlerOperator}
        setOperatorSearch={setOperatorSearch}
        show={operatorSearch}
      />

      <BuscaSector
        handlerSector={handlerSector}
        setSectorSearch={setSectorSearch}
        show={sectorSearch}
      />

      <BuscaFilial
        handleFilial={handleFilial}
        setFilialSearch={setFilialSearch}
        show={filialSearch}
      />
    </>
  );
}

export default CreatePOS;

import styled from "styled-components";

import PerfectScrollbar from "react-perfect-scrollbar";

export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 600px);
  padding-bottom: 200px;

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    display: ${props => (props.NewCheck ? "block" : "none")};
  }

  header {
    width: 100%;
    padding: 15px 0;
    background: #555555;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 12;
`;

export const BtnType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border: 1px solid rgb(155, 213, 49);
    padding: 8px 16px;
    border-radius: 4px;
    background: #222;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    margin: 16px 8px;
    transition: all 0.2s;
    position: relative;

    &:hover {
      background: #efefef;
      color: #222;
    }

    &.active {
      border-color: #339e2b;
    }

    &.openned {
      background: rgb(155, 213, 49);
    }

    &.openned:before {
      content: "";
      position: absolute;
      left: 10px;
      bottom: -9px;
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid rgb(155, 213, 49);
      overflow: hidden;
    }
  }
`;

export const Container = styled.div`
  background: #efefef;
  width: 100%;
  text-align: center;

  h1 {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #333;
    text-align: left;
    background: #ccc;
    padding: 15px;
    border-bottom: 1px solid #ddd;

    svg {
      margin-right: 5px;
      border-radius: 4px;
      transition: background 0.2s;
    }

    &:hover svg {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .pricipal_form {
    padding: 15px 0px;

    > hr {
      width: 96%;
      margin-left: 16px;
    }

    > div {
      padding: 0px 15px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .rowInputs {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    label {
      display: flex;
      text-align: left;
      align-items: center;
      margin: 0 3px 10px;
      flex: 1;
      position: relative;

      span {
        position: absolute;
        bottom: -27px;
        background: rgb(155, 213, 49);
        padding: 2px 0px;
        color: #fff;
        width: calc(100% - 6px);
        text-align: center;
        font-style: normal;
        font-size: 11px;
      }

      div.react-datepicker-wrapper {
        width: 100%;
        background: #fff;
        height: 54px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        padding: 15px;

        input {
          border: none;
        }
      }

      img {
        border-radius: 4px;
        overflow: hidden;
      }
    }

    button.remove {
      background: rgba(215, 47, 47, 1);
      color: #fff;
    }

    label > div:first-child {
      display: inline-block;
      width: 54px;
      height: 54px;
      text-align: center;
      font-size: 10px;
      color: #555;
      background: #fff;
      padding: 13px 8px 0;
      border-radius: 4px;
    }
  }

  .canceled label {
    opacity: 0.6;
  }
`;

import styled from 'styled-components';

import PerfectScrollbar from 'react-perfect-scrollbar';

export const Content = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 480px);
  padding-bottom:200px;

  &:before {
    content:'';
    background: rgba(0,0,0,.4);
    width:100%;
    height:100%;
    position:fixed;
    z-index:1;
    left:0;
    top:0;
    display: ${props => props.NewCheck ? 'block' : 'none' };
  }

  header {
    width:100%;
    padding:15px 0;
    background: #555555;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index:12;
`;

export const BtnType = styled.div`
  display:flex;
  justify-content: flex-start;
  align-items: center;

  button {
    border:1px solid #222;
    padding:8px 16px;
    border-radius:4px;
    background:#333;
    color:#FFF;
    font-weight:bold;
    font-size:14px;
    margin:16px 8px;
    transition:all .2s;
    position:relative;

    &:hover {
      background: rgb(155,213,49);
      border-color: #222;
      color:#fff;
    }

    &.active {
      border-color: rgb(155,213,49);
    }

    &.openned {
      background: rgb(155,213,49);
    }

    &.openned:hover {
      background: rgb(155,213,49);
      border-color: #222;
      color:#fff;
    }

    &.openned:before {
      content:'';
      position:absolute;
      left:10px;
      bottom: -9px;
      width:0;
      height:0;
      border-left:9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid rgb(155,213,49);
      overflow:hidden;
    }
  }
`;


export const Container = styled.div`
  background: #efefef;
  width:100%;
  text-align:center;

  h1 {
    display:flex;
    align-items: center;
    font-size:21px;
    color:#333;
    text-align:left;
    background:#ccc;
    padding:15px;
    border-bottom:1px solid #ddd;

    svg {
      margin-right:5px;
      border-radius:4px;
      transition:background .2s;
    }

    &:hover svg {
      background:rgba(255,255,255,.5);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding:15px;

    .rowInputs {
      display:flex;
      flex-direction:row;
      justify-content: space-around;

    }

    label {
      display:flex;
      text-align:left;
      align-items: center;
      margin:0 3px 10px;
      flex:1;
      position:relative;

      div {
        display: inline-block;
        width: 54px;
        height: 54px;
        text-align: center;
        font-size: 10px;
        color: #555;
        background: #FFF;
        padding: 13px 8px 0;
        border-radius: 4px;
      }

      span {
        position: absolute;
        bottom: -27px;
        background: rgb(155,213,49);
        padding: 2px 0px;
        color: #FFF;
        width: calc(100% - 6px);
        text-align: center;
        font-style: normal;
        font-size: 11px;


      div.react-datepicker-wrapper {
        width: 100%;
        background: #FFF;
        height: 54px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        padding: 15px;

        input {
          border:none;
        }
      }

      img {
        border-radius:4px;
        overflow:hidden;
      }
    }

    label > div:first-child {
      display:inline-block;
      width:54px;
      height:54px;
      text-align:center;
      font-size:10px;
      color:#555;
      background:#FFF;
      padding:13px 8px 0;
      border-radius:4px;
    }

    .btnFind {
      border:none;
      background: rgb(155,213,49);
      color:#FFF;
      width:54px;
      height:54px;
      display:flex;
      align-items:center;
      border-radius:4px;
      justify-content: center;
      cursor:pointer;

      &:hover {

      }
    }

    label > input, label > select, label > .input {
      width:calc(100% - 60px);
      border: 0;
      background:#FFF;
      height:54px;
      padding: 0 15px;
      color:#333;
      margin:0 5px;
      display:Flex;
      align-items:center;
    }

    span {
      color: #ccc;
      align-self: flex-start;
      margin:0 0 10px;
      font-style: italic;
      font-size:12px;
    }

    hr {
      border:0;
      height:1px;
      background: rgba(0,0,0,.1);
      margin:10px 0 20px;
    }

    button.btnAdd, button.btnFinish {
      margin: 5px 5px 0;
      height:44px;
      background: transparent;
      padding:0 15px;
      color:rgb(155,213,49);
      border:0;
      border-radius:4px;
      transition: background 0.2s;
      border:1px solid rgb(155,213,49);
      display:flex;
      align-items:center;
    }

    button.btnAdd:hover {
        background: rgb(155,213,49);
        color:#fff;
      }
    button.btnFinish {
      background: transparent;
      color: rgb(155,213,49);
      border:1px solid rgb(155,213,49);
      cursor:pointer;

      &:hover {
        background: rgb(155,213,49);
        color: #fff;
      }

      &:disabled {
        background: #ccc;
        color: #777;
        cursor:auto;

        &:hover {
          background: #ccc;
          color:#777;
        }
      }
    }

    button.btnAdd svg {
      margin-right:7px;
    }

    button.btnFinish svg {
      margin-left:7px;
    }
  }

  }
`;

import styled from "styled-components";

import { lighten } from "polished";

import PerfectScrollbar from "react-perfect-scrollbar";

export const GradeTable = styled.table`
  width: 100%;
  background: #eee;
`;

export const GradeHeader = styled.thead`
  background: ${lighten(0.4, "rgb(155,213,49)")};
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);

  td {
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  td.iconSize {
    width: 25px;
  }
`;

export const Dialog = styled.ul`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 100%;
  width: 200px;
  background: rgb(155,213,49);
  border-radius: 4px;
  display: none;
  cursor: auto;

  &::before {
    content: "";
    position: absolute;
    left: 7px;
    top: -7px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgb(155,213,49);
    overflow: hidden;
  }

  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
    color: #fff;

    &:hover {
      color: #ccc;
    }

    &.trash:hover {
      cursor: pointer;
      background: rgba(215,47,47,1);
    }
  }
`;

export const GradeBody = styled.tbody`
  .active_dialog {
    display: block;

    input {
      border: none;
    }
  }
  tr {
    width: 100%;
    cursor: pointer;
    position: relative;

    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.05);
    }

    &:hover {
      background: ${lighten(0.5, "rgb(155,213,49)")};
    }

    &.active ul {
      display: block;
    }

    &.canceled {
      background: #888;
    }

    &.approved {
      background: rgba(0, 255, 0, 0.2);
    }

    td {
      padding: 10px;
      font-size: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;

      span {
        color: #333;
        font-style: inherit;
        margin: 0;
        font-size: 14px;
        max-width: 219px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
      }
    }
  }
`;

export const Filtros = styled.div`
  background: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 39px;

  p {
    padding: 0 20px;
  }
`;

export const New = styled.button`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 39px;
  background: rgb(155,213,49);
  color: #fff;
  font-weight: bold;
`;

export const ModalDateBaixa = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 480px);

  header {
    width: 100%;
    padding: 15px 0;
    background: #555555;
  }

  > .container {
    background: #efefef;
    width: 100%;
    text-align: center;

    h1 {
      display: flex;
      align-items: center;
      font-size: 21px;
      color: #333;
      text-align: left;
      background: #ccc;
      padding: 15px;
      border-bottom: 1px solid #ddd;

      svg {
        margin-right: 5px;
        border-radius: 4px;
        transition: background 0.2s;
      }

      &:hover svg {
        background: rgba(255, 255, 255, 0.5);
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      padding: 15px;

      .rowInputs {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }

      label {
        display: flex;
        text-align: left;
        align-items: center;
        margin: 0 3px 10px;
        flex: 1;

        div.react-datepicker-wrapper {
          width: 100%;
          background: #fff;
          height: 54px;
          display: flex;
          align-items: center;
          margin: 0 5px;
          padding: 15px;

          input {
            border: none;
          }
        }

        img {
          border-radius: 4px;
          overflow: hidden;
        }
      }

      label > div:first-child {
        display: inline-block;
        width: 54px;
        height: 54px;
        text-align: center;
        font-size: 10px;
        color: #555;
        background: #fff;
        padding: 13px 8px 0;
        border-radius: 4px;
      }

      button.btnAdd svg {
        margin-right: 7px;
      }

      button.btnFinish svg {
        margin-left: 7px;
      }
    }
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 12;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #00000036;
    left: 0;
    top: 0;
  }
`;

export const ChequeSelected = styled.div`
  li {
    width: 100%;
    background: #efefef;
    display: flex;
    height: auto;
    align-items: center;

    table {
      width: 100%;

      thead {
        font-weight: bold;

        tr {
          background: #d7d2de;

          td {
            padding: 5px 10px;
            text-align: center;
            vertical-align: middle;
          }
        }
      }

      tbody tr {
        &:nth-child(odd) {
          background: #fff;
        }

        td {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          padding: 5px 10px;
          text-align: center;
          vertical-align: middle;

          > span {
            color: #333;
            font-style: inherit;
            margin: 0;
            font-size: 14px;
            max-width: 219px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
          }
        }
      }
    }
  }
`;

import React from 'react'
import './style.css'
import { FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im"

export default function GreenSearchButton({ input, searchFunction = () => { }, resetFunction = () => { } }) {

  function handleSearch() {
    searchFunction()
  }

  function handleReset() {
    resetFunction()
  }

  return (
    <div className="green-search-button-container">
      {
        input === "" ?
          <div className="green-search-button gsb-empty" onClick={handleSearch}><FaSearch className="green-search-button-icon" /></div>
          : <>
            <div className="green-search-button gsb-filled" onClick={handleReset}><ImCross className="green-search-button-icon" /></div>
            <p>{input}</p>
          </>
      }
    </div>
  )
}
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import allowAccess from "~/scripts/menuController";
import retLanguage from "~/config/language";

import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt";
import en from "date-fns/locale/en-US";
// import pt from 'date-fns/locale/pt';
// import ReactPDF from '@react-pdf/renderer';

import { toast } from "react-toastify";

import "react-confirm-alert/src/react-confirm-alert.css";

import NavigationButtons from "~/components/NavigationButtons";

import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

import api from "~/services/api";
import {
  FaEye,
  FaEdit,
  FaCheck,
  FaTimes,
  FaClipboard,
  FaRedo,
  FaPrint
} from "react-icons/fa";

import { New } from "./styles";
import {
  handleDialog,
  handleBlock,
  formatMoney,
  verifyAccess,
  getPeriod,
  getStatus
} from "~/scripts/index.js";

import { saveAs } from "file-saver";

// import Dizimos from '~/Printers/dizimos';

import CreateDizimo from "./CreateDizimo";
import BgBlack from "~/components/BgBlack";

import "react-datepicker/dist/react-datepicker.css";

import MainFilter, { MainFilterButton } from "~/components/MainFilter";
import FilterComponent from "~/components/FilterComponent";
import BuscarFiliais from "~/components/BuscarFiliais";
import GreenSearchButton from "~/components/GreenSearchButton";
import { Select } from "@rocketseat/unform";
import DateFilter from "~/components/DateFilter";

export default function HomeboxDizimos() {
  const [dizimos, setDizimos] = useState([]);
  const filial = useSelector(state => state.filial);
  const profile = useSelector(state => state.user.profile);
  const [openDate, setOpenDate] = useState(false);
  const [block, setBlock] = useState(false);
  const [lancamento, setLancamento] = useState(null);
  const [openDizimo, setOpenDizimo] = useState(false);
  const [createDizimo, setCreateDizimo] = useState(false);
  const [opennedId, setOpennedId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [showCoA, setShowCoA] = useState(false);
  const [reclassificar, setReclassificar] = useState(null);
  const accesses = useSelector(state => state.auth.newaccesses);
  const lang = retLanguage(profile.language);
  const [setores, setSetores] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showFilterFilials, setShowFilterFilials] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [filterSettings, setFilterSettings] = useState({
    from: false,
    to: false,
    filial: {
      id: filial.filial,
      name: ""
    },
    setor: false
  });
  // const [printSetup, setPrintSetup] = useState({
  //   openned: false
  // });

  const [filtro, setFiltro] = useState({
    date: format(new Date(), "yyyy-MM-dd 23:59:59")
  });

  const { date } = filtro;

  useEffect(() => {
    async function loadDizimos() {
      if (filtered) {
        const { data } = await api.get("dizimos/dizimos_filter/", {
          params: {
            page: page,
            ...filtered
          }
        });

        setPages(data.pages);
        setDizimos(data.dizimos);
      } else {
        const response = lancamento
          ? await api.get("dizimos/id/" + lancamento)
          : await api.get(
              "dizimos/filial/" +
                filial.filial +
                "?page=" +
                page +
                "&dateDizimo=" +
                date
            );

        const { data } = response;
        setPages(data.pages);
        setDizimos(
          lancamento
            ? JSON.stringify(data) === "[null]"
              ? []
              : data
            : data.dizimos
        );
      }
    }
    loadDizimos();
    setBlock(false);
  }, [filial, date, page, block, lancamento, createDizimo]);

  async function approveDizimo(id) {
    try {
      await api.put("dizimos/approve/" + id);
      toast.success("Atualizado com sucesso!", {
        className: "success"
      });
      setBlock(true);
      return;
    } catch (err) {
      toast.error("Verifique se há cheques baixados ou cartões conciliados.", {
        className: "error"
      });
    }
  }

  // async function MakeReporting(id) {
  //   try {
  //     const { data } = await api.post(`reporting_dizimosandofferts/${id}`);

  //     data.map(urls => {
  //       window.open(urls);
  //     });

  //     setBlock(true);
  //   } catch (err) {
  //     // console.tron.log(err);
  //   }
  // }

  async function MakeReporting(id) {
    const response = await api.post(`/pdf/dizimo/${id}`);
    const response2 = await api.get(`/pdf/dizimo/${id}`, {
      responseType: "blob"
    });
    const pdfBlob = new Blob([response2.data], { type: "application/pdf" });
    saveAs(pdfBlob, response.data.name);
    await api.delete(`/pdf/dizimo/${id}`);
  }

  function handleOpenDizimo(id, edit) {
    setOpennedId(id);
    setEdit(edit);
    setOpenDizimo(!openDizimo);
  }

  function handleCreateDizimo() {
    setEdit(!createDizimo);
    setCreateDizimo(!createDizimo);
  }

  async function handleChartOfAccount(coa) {
    try {
      await api.put(`/reclassificar/dizimos/${reclassificar}/${coa.id}`);
      toast.success("Reclassificado com sucesso!", {
        className: "success"
      });
      setShowCoA(false);
    } catch (err) {
      toast.error("Impossível reclassificar.", {
        className: "error"
      });
    }
  }

  function handleReclass(movement) {
    setShowCoA(true);
    setReclassificar(movement);
  }

  useEffect(() => {
    async function getSetores() {
      const { data } = await api.get("sector/");
      setSetores(data);
    }
    getSetores();
  }, []);

  async function applyFilter() {
    try {
      if (
        filterSettings.from &&
        filterSettings.to &&
        filterSettings.from > filterSettings.to
      ) {
        return toast.error(
          "A data de emissão inicial não pode ser maior do que a final.",
          {
            className: "error"
          }
        );
      }

      let from = filterSettings.from
        ? new Date(filterSettings.from)
        : new Date(1900, 0, 1);
      from = from.toISOString().substring(0, 10);

      let to = filterSettings.to
        ? new Date(filterSettings.to)
        : new Date(3000, 0, 1);
      to = to.toISOString().substring(0, 10);

      const params = {
        from_date: from,
        to_date: to,
        filial_id: filterSettings.filial.id,
        reason: filterSettings.setor
      };

      const { data } = await api.get("dizimos/dizimos_filter/", {
        params: {
          page: filtered ? page : 1,
          ...params
        }
      });

      setPage(1);

      setFiltered({
        ...params
      });

      setPages(data.pages);
      setDizimos(data.dizimos);

      setShowFilter(false);
    } catch (err) {}
  }

  async function resetFilter() {
    try {
      setFilterSettings({
        from: false,
        to: false,
        filial: {
          id: filial.filial,
          name: ""
        },
        setor: false
      });

      setFiltered(false);
      setPage(1);

      const response = await api.get(
        "dizimos/filial/" +
          filial.filial +
          "?page=" +
          page +
          "&dateDizimo=" +
          date
      );

      const { data } = response;
      setPages(data.pages);
      setDizimos(data.dizimos);
      setShowFilter(false);
    } catch (err) {}
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses, "lancamento_dizimos") ? (
          <New
            type="button"
            onClick={() => {
              handleCreateDizimo();
              setShowFilter(false);
            }}
          >
            {lang.grade.buttons.b1} +
          </New>
        ) : null}
        <MainFilterButton click={() => setShowFilter(!showFilter)} />
        {/* <New className="print" onClick={() => handlePrintSetup()} type="button">
          <FaPrint size={14} style={{ marginRight: "15px" }} /> Imprimir Período
        </New> */}
        {/* <FiltroData
          openDate={openDate}
          handleOpenDate={handleOpenDate}
          selected={parseISO(date)}
          handleData={handleData}
        />
        <FiltroLancamento
          handleLancamento={handleLancamento}
          placeholder={lang.grade.buttons.b5}
          setFind={setLancamento}
        /> */}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          {showFilter && (
            <MainFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <FilterComponent title="Data de Emissão">
                <DateFilter
                  to={filterSettings.to}
                  setTo={dt => setFilterSettings({ ...filterSettings, to: dt })}
                  from={filterSettings.from}
                  setFrom={dt =>
                    setFilterSettings({ ...filterSettings, from: dt })
                  }
                />
              </FilterComponent>

              <FilterComponent title="Filial">
                <GreenSearchButton
                  input={filterSettings.filial.name}
                  searchFunction={() => {
                    setShowFilterFilials(true);
                  }}
                  resetFunction={() =>
                    setFilterSettings({
                      ...filterSettings,
                      filial: { id: filial.filial, name: "" }
                    })
                  }
                />
                <BuscarFiliais
                  showFilials={showFilterFilials}
                  setShowFilials={setShowFilterFilials}
                  handleSearchFilials={fil => {
                    setFilterSettings({
                      ...filterSettings,
                      filial: { ...fil }
                    });
                    setShowFilterFilials(false);
                  }}
                />
              </FilterComponent>

              <FilterComponent title="Setor">
                <Select
                  options={setores}
                  name="setor"
                  value={filterSettings.setor ? filterSettings.setor : ""}
                  onChange={e => {
                    setFilterSettings({
                      ...filterSettings,
                      setor: e.target.value
                    });
                  }}
                  placeholder="Selecione..."
                  style={{
                    width: 150,
                    height: 27,
                    border: "none",
                    borderRadius: 4
                  }}
                />
              </FilterComponent>
            </MainFilter>
          )}
          <tr>
            <td>{lang.grade.pages.p1.header.h1}</td>
            <td>{lang.grade.pages.p1.header.h2}</td>
            <td>{lang.grade.pages.p1.header.h3}</td>
            <td>{lang.grade.pages.p1.header.h4}</td>
            <td>{lang.grade.pages.p1.header.h5}</td>
            <td>{lang.grade.pages.p1.header.h6}</td>
            <td>{lang.grade.pages.p1.header.h7}</td>
            <td>{lang.grade.pages.p1.header.h8}</td>
            <td>{lang.grade.pages.p1.header.h9}</td>
            <td>{lang.grade.pages.p1.header.h10}</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {dizimos && dizimos.length > 0 ? (
            dizimos.map(dizimo => (
              <tr
                key={dizimo.id}
                onClick={() => handleDialog(dizimo, "dizimos")}
                className={dizimo.approved_at ? "dizimos approved" : "dizimos"}
                id={dizimo.id}
              >
                <td>
                  {dizimo.id}
                  {dizimo.canceled_at ? (
                    <ul className="dialog">
                      {verifyAccess(accesses, "exclusao_dizimos") &&
                      !dizimo.approved_at ? (
                        <li
                          className="trash"
                          onClick={() =>
                            handleBlock(
                              dizimo.id,
                              dizimo.canceled_at,
                              `lançamento de dízimo ${dizimo.id}`,
                              "dizimos",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> {lang.buttons.b5}
                        </li>
                      ) : null}
                    </ul>
                  ) : (
                    <ul className="dialog">
                      {dizimo.date >= filial.open_date &&
                      verifyAccess(accesses, "aprovacao_dizimos") ? (
                        <>
                          <li onClick={() => approveDizimo(dizimo.id)}>
                            {!dizimo.approved_at ? (
                              <>
                                <FaCheck size={12} /> {lang.buttons.b1}
                              </>
                            ) : (
                              <>
                                <FaTimes size={12} /> {lang.buttons.b7}
                              </>
                            )}
                          </li>
                        </>
                      ) : null}
                      {verifyAccess(accesses, "relatorios_dizimos") ? (
                        <li onClick={() => MakeReporting(dizimo.id)}>
                          {dizimo.report ? (
                            <>
                              <FaClipboard size={12} /> {lang.buttons.b2}
                            </>
                          ) : (
                            <>
                              <FaClipboard size={12} /> {lang.buttons.b2}
                            </>
                          )}
                        </li>
                      ) : null}
                      <li onClick={() => handleOpenDizimo(dizimo.id, false)}>
                        <FaEye size={12} /> {lang.buttons.b3}
                      </li>
                      {verifyAccess(accesses, "lancamento_dizimos") &&
                      !dizimo.approved_at ? (
                        <li onClick={() => handleOpenDizimo(dizimo.id, true)}>
                          <FaEdit size={12} /> {lang.buttons.b4}
                        </li>
                      ) : null}
                      {!dizimo.approved_at &&
                      verifyAccess(accesses, "exclusao_dizimos") ? (
                        <li
                          className="trash"
                          onClick={() =>
                            handleBlock(
                              dizimo.id,
                              dizimo.canceled_at,
                              `lançamento de dízimo ${dizimo.id}`,
                              "dizimos",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> {lang.buttons.b5}
                        </li>
                      ) : null}

                      {verifyAccess(accesses, "reclassificar_dizimos") ? (
                        <li
                          className="reclassificar"
                          onClick={() => handleReclass(dizimo.id)}
                        >
                          <FaRedo size={12} /> {lang.buttons.b6}
                        </li>
                      ) : null}
                    </ul>
                  )}
                </td>
                {profile.language === "pt-BR" ? (
                  <td>
                    {format(parseISO(dizimo.date), "dd MMMM, yyyy", {
                      locale: pt
                    })}
                  </td>
                ) : (
                  <td>
                    {format(parseISO(dizimo.date), `MMMM do, yyyy`, {
                      locale: en
                    })}
                  </td>
                )}

                <td className={dizimo.approved_at ? "approved" : ""}>
                  {getStatus(dizimo.approved_at ? "Aprovado" : "Pendente")}
                </td>
                <td>{dizimo.setor ? dizimo.setor.name : ""}</td>
                <td>{getPeriod(dizimo.period)}</td>
                <td>
                  {formatMoney(dizimo.money.toFixed(2), profile.language)}
                </td>
                <td>
                  {formatMoney(
                    dizimo.check ? dizimo.check.toFixed(2) : 0,
                    profile.language
                  )}
                </td>
                <td>
                  {formatMoney(
                    dizimo.debit ? dizimo.debit.toFixed(2) : 0,
                    profile.language
                  )}
                </td>
                <td>
                  {formatMoney(
                    dizimo.credit ? dizimo.credit.toFixed(2) : 0,
                    profile.language
                  )}
                </td>
                <td>
                  {formatMoney(dizimo.total.toFixed(2), profile.language)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">Não há lançamentos.</td>
            </tr>
          )}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {openDizimo ? (
        <>
          <BgBlack />
          <CreateDizimo
            handleCreateDizimo={handleOpenDizimo}
            id={opennedId}
            edit={edit}
            setBlock={setBlock}
            MakeReporting={MakeReporting}
          />
        </>
      ) : null}
      {createDizimo ? (
        <>
          <BgBlack />
          <CreateDizimo
            handleCreateDizimo={handleCreateDizimo}
            edit={edit}
            setBlock={setBlock}
            MakeReporting={MakeReporting}
          />
        </>
      ) : null}
      {showCoA ? (
        <>
          <BgBlack />
          <BuscarPlanoDeContas
            show={showCoA}
            setShowCoA={setShowCoA}
            handleChartOfAccount={handleChartOfAccount}
          />
        </>
      ) : null}
      {/* <button onClick={() => PrintAll()}>Imprimir</button
      <embed
        src="https://localhost:8097/reporting/dizimoseofertas/90f8329847766133701c75620e737ff78e236211_cartoes.pdf"
        width="800px"
        height="1000px"
        id="teste"
      /> */}
    </>
  );
}

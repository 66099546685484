import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaAngleDown, FaAngleRight, FaTrash } from "react-icons/fa";
import { formatMoney } from "~/scripts/index.js";
import { Container, ListMoedas } from './styles';

function TabelaMoedaEstrangeira({ setMoedas,moedas }) {
  const filial = useSelector(state => state.filial);
  const [tableOpened, setTableOpened] = useState(true);
  function handleTable() {
    setTableOpened(!tableOpened);
  }
  function removeMoeda(i) {
    let newMoedas = [];

    for(let c = 0; c < moedas.length; c++) {
      if(c !== i) {
        newMoedas.push({...moedas[c]})
      }
    }
    setMoedas(newMoedas);
  }
  return (
  <Container>

    <h1 onClick={handleTable}>
      {tableOpened ? <FaAngleDown size={20} /> : <FaAngleRight size={20} />}
      Moedas Estrangeiras
    </h1>


      {tableOpened ? (
        <ListMoedas>
        <li>
          <table>
            <thead>
              <tr>
                <td>Moeda</td>
                <td>Valor</td>
                <td>Remover</td>
              </tr>
            </thead>
            <tbody>
              {moedas.length > 0 ? (
                moedas.map((moeda, index) =>
                    <tr key={index}>
                      <td>{moeda.name ? moeda.name : moeda.moeda ? moeda.moeda.name : null}</td>
                      <td>{formatMoney(
                            moeda.value.toFixed(2),
                            filial.country.language
                          )}</td>
                      <td><FaTrash size={16} onClick={() => removeMoeda(index)} /></td>
                    </tr> )
                )
                :  (<tr>
                  <td colSpan={3} style={{ textAlign: "inherit" }}>
                    Não há moedas estrangeiras.
                  </td>
                </tr>) }
            </tbody>
          </table>
        </li>
        </ListMoedas>
      ) : null }
  </Container>
  );
}

export default TabelaMoedaEstrangeira;

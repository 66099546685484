import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import * as Yup from "yup";

import { Form, Input } from "@rocketseat/unform";

import {
  FaTimesCircle,
  FaAngleRight,
  FaIndustry
} from "react-icons/fa";

import Title from "~/components/Title";

import { Scroll, Content, Container } from "./styles";

import api from "~/services/api";

function CreateSector({ setCreateSector, edit, id, setBlock }) {
  const profile = useSelector(state => state.user.profile);
  const [sector, setSector] = useState({
    // code: null,
    nome: "",
    created_by: profile.id,
    created_at: new Date()
  });
  let [finish, setFinish] = useState(false);

  useEffect(() => {
    async function confirmSector() {
      let { name } = sector;
      await setFinish(!!name);
    }
    confirmSector();
  }, [sector.name]);

  useEffect(() => {
    async function getSector() {
      const response = await api.get(`sector/${id}`);
      setSector(response.data);
    }
    if (id) {
      getSector();
    }
  }, [id]);

  function handleCreateDizimo() {}

  async function handleSubmit() {
    try {
      !id
        ? await api.post("sector", sector)
        : await api.put(`sector/${sector.id}`, sector);

      toast.success(`Setor inserido com sucesso!`, { className: "success" });
      setBlock(true);
      setCreateSector(false);
    } catch (err) {
      toast.error(`Erro ao inserir setor!`, { className: "error" });
    }
  }

  const schema = Yup.object().shape({});

  return (
    <>
      <Scroll>
        <div className="overlay"></div>
        <Content>
          <header>
            <Title back={true} func={setCreateSector} title={"Cancelar"} />
          </header>
          <Container>
            <h1>Novo setor</h1>
            <Form schema={schema} initialData={sector} onSubmit={handleSubmit}>
              <div className="rowInputs">
                {/* <label htmlFor="code">
                  <div>
                    <FaFile size={20} />
                    <br />
                    Código
                  </div>
                  <Input
                    name="code"
                    readOnly={edit ? null : "readonly"}
                    placeholder="Código"
                    onChange={e =>
                      setSector({ ...sector, code: e.target.value })
                    }
                    value={sector.code}
                  />
                </label> */}
                <label htmlFor="name">
                  <div>
                    <FaIndustry size={20} />
                    <br />
                    Nome
                  </div>
                  <Input
                    name="name"
                    placeholder="Nome"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setSector({ ...sector, name: e.target.value })
                    }
                    value={sector.name || ''}
                  />
                </label>
                {edit ? (
                  <button
                    type="submit"
                    className="btnFinish"
                    finish={finish.toString()}
                    disabled={!finish}
                    title={
                      !finish
                        ? "Há campos obrigatórios não preenchidos!"
                        : "Finalizar"
                    }
                  >
                    {finish ? (
                      <>
                        {!id ? "Finalizar" : "Atualizar"}
                        <FaAngleRight size={20} />
                      </>
                    ) : (
                      <>
                        ... <FaTimesCircle size={20} />
                      </>
                    )}
                  </button>
                ) : null}
              </div>
            </Form>
          </Container>
        </Content>
      </Scroll>
    </>
  );
}

export default CreateSector;

import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import * as yup from "yup";

import api from "~/services/api";

import { handleBlock } from "~/scripts/handleBlock.js";

import { toast } from "react-toastify";

import { Container } from "./styles";

import { Form, Input, Select } from "@rocketseat/unform";

import { getAccountTypes, getBanks } from "~/scripts";


import {
  FaTimes,
  FaAngleRight,
  FaUserAlt,
  FaLandmark,
  FaInfo,
  FaPager,
  FaMoneyCheckAlt,
  FaUsers,
  FaCalendar
} from "react-icons/fa";

export default function FormPastor({ cgc, agentId, agent, setAgent, edit }) {
  const profile = useSelector(state => state.user.profile);
  const [block, setBlock] = useState(false);
  const [pastor, setPastor] = useState({
    agent_id: agentId,
    created_by: profile.id,
    cgc,
    new: true,
    full_name: agent.name,
    short_name: agent.name,
    dependents: 0
  });
  const account_types = getAccountTypes(agent.country)
  const banks = getBanks(agent.country)

  useEffect(() => {
    async function loadPastor() {
      try {
      const response = await api.get("pastor/?agentId=" + agentId, { agentId });
      const { data } = response;
      setPastor({ ...data, new: false });
      } catch(err) {

      }
    }
    loadPastor();
    setBlock(false);
  }, [agentId, setBlock, block]);

  async function handleSubmit() {
    if(!agent.email) {
      toast.error(`Para cadastrar pastor, o e-mail em Agente é obrigatório.`, { className: "error" });
      return;
    }
    try {
      const response = pastor.new
        ? await api.post("pastor/", pastor)
        : await api.put("pastor/", pastor);

      toast.success(`Dados de Pastor atualizado com sucesso!`, {
        className: "success"
      });
      setPastor({...pastor, new: false})
      setAgent({
        ...agent,
        type: agent.type + ",3",
        new: false,
        agent_id: response.data.id
      });
      return;
    } catch (err) {
      toast.error("Por favor, revise os dados!", { className: "error" });
    }
  }

  const agentSchema = yup.object().shape({
    full_name: yup.string().required("Nome obrigatório"),
    short_name: yup.string().required("Apelido obrigatório"),
    // bank: yup.string().required("Banco obrigatório"),
    // account_type: yup.string().required("Tipo de conta obrigatório"),
    // agency: yup.string().required("Agência obrigatório"),
    // account: yup.string().required("Conta obrigatório"),
    // pay_day: yup.string().required("Dia para pagar obrigatório"),
    // inss: yup.string().required("Selecione uma opção")
  });

  return (
    <Container>
      <h1>Dados de Pastor</h1>
      <Form initialData={pastor} onSubmit={handleSubmit} schema={agentSchema}>
        <div className="rowInputs">
          <label htmlFor="full_name">
            <div>
              <FaUserAlt size={20} />
              <br />
              Nome
            </div>
            <Input
              name="full_name"
              readOnly={!edit ? "readonly" : ""}
              onChange={e =>
                setPastor({ ...pastor, full_name: e.target.value })
              }
              value={pastor.full_name}
              // placeholder={agent.name}
              maxLength="60"
            />
          </label>
          <label htmlFor="short_name">
            <div>
              <FaUserAlt size={20} />
              <br />
              Apelido
            </div>
            <Input
              name="short_name"
              readOnly={!edit ? "readonly" : ""}
              onChange={e =>
                setPastor({ ...pastor, short_name: e.target.value })
              }
              value={pastor.short_name}
              maxLength="40"
            />
          </label>
        </div>
        <hr />

        <div className="rowInputs">
          {/* <label htmlFor="accounting_code">
            <div>
              <FaBalanceScale size={20} />
              <br />
              C.Contáb.
            </div>
            <Input
              name="accounting_code"
              readOnly={!edit ? "readonly" : ""}
              onChange={e =>
                setPastor({ ...pastor, accounting_code: e.target.value })
              }
              maxLength="18"
            />
          </label>
          <label htmlFor="tax_nature">
            <div>
              <FaCube size={20} />
              <br />
              Natureza
            </div>
            <Input
              name="tax_nature"
              readOnly={!edit ? "readonly" : ""}
              onChange={e =>
                setPastor({ ...pastor, tax_nature: e.target.value })
              }
            />
          </label> */}
          <label htmlFor="bank">
            <div>
              <FaLandmark size={20} />
              <br />
              Banco
            </div>
            <Select
              options={banks}
              name="bank"
              value={pastor.bank}
              disabled={!edit ? "disabled" : ""}
              onChange={e => setPastor({ ...pastor, bank: e.target.value })}
            />
          </label>
          <label htmlFor="account_type">
            <div>
              <FaInfo size={20} />
              <br />
              Tipo
            </div>
            <Select
              options={account_types}
              name="account_type"
              disabled={!edit ? "disabled" : ""}
              onChange={e =>
                setPastor({ ...pastor, account_type: e.target.value })
              }
              value={pastor.account_type}
            />
          </label>
        </div>
        <hr />

        <div className="rowInputs">
          <label htmlFor="agency">
            <div>
              <FaPager size={20} />
              <br />
              { agent.country === "BR" ? "Agencia" : "R.N" }
            </div>
            <Input
              name="agency"
              readOnly={!edit ? "readonly" : ""}
              placeholder=""
              value={pastor.agency}
              onChange={e =>
                setPastor({
                  ...pastor,
                  agency: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
              maxLength="40"
            />
          </label>
          { agent.country === "BR" ?
          <label htmlFor="agency_code" className="mini_input">
            <Input
              name="agency_code"
              readOnly={!edit ? "readonly" : ""}
              placeholder="Dígito"
              maxLength="1"
              value={pastor.agency_code}
              onChange={e =>
                setPastor({
                  ...pastor,
                  agency_code: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
            />
          </label>
          : null }
          <label htmlFor="account">
            <div>
              <FaMoneyCheckAlt size={20} />
              <br />
              { agent.country === "BR" ? "Conta" : "Acc." }
            </div>
            <Input
              name="account"
              readOnly={!edit ? "readonly" : ""}
              placeholder=""
              maxLength="40"
              value={pastor.account}
              onChange={e =>
                setPastor({
                  ...pastor,
                  account: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
            />
          </label>
          { agent.country === "BR" ?
          <label htmlFor="account_code" className="mini_input">
            <Input
              name="account_code"
              readOnly={!edit ? "readonly" : ""}
              placeholder="Dígito"
              maxLength="1"
              value={pastor.account_code}
              onChange={e =>
                setPastor({
                  ...pastor,
                  account_code: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
            />
          </label>
          : null }
        </div>
        <hr />

        <div className="rowInputs">
          <label htmlFor="dependents">
            <div>
              <FaUsers size={20} />
              <br />
              Depend.
            </div>
            <Input
              name="dependents"
              readOnly={!edit ? "readonly" : ""}
              placeholder="Nº de dependentes"
              onChange={e =>
                setPastor({
                  ...pastor,
                  dependents: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
              value={pastor.dependents}
              maxLength="2"
            />
          </label>
          <label htmlFor="pay_day">
            <div>
              <FaCalendar size={20} />
              <br />
              Pagto.
            </div>
            <Input
              name="pay_day"
              readOnly={!edit ? "readonly" : ""}
              placeholder="Dia para pagamento"
              maxLength="2"
              value={pastor.pay_day}
              onChange={e =>
                setPastor({
                  ...pastor,
                  pay_day: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
            />
          </label>
          <label htmlFor="inss">
            <div>
              <FaInfo size={20} />
              <br />
              INSS
            </div>
            <Select
              options={[
                { id: 1, title: "Sim" },
                { id: 2, title: "Não" }
              ]}
              disabled={!edit ? "disabled" : ""}
              name="inss"
              onChange={e => setPastor({ ...pastor, inss: e.target.value })}
              value={pastor.inss}
            />
          </label>
        </div>
        <hr />

        <div
          className="rowInputs"
          style={{ "justify-content": "space-between" }}
        >
          {edit ? (
            <>
              <button
                type="button"
                onClick={() =>
                  handleBlock(
                    pastor.id,
                    pastor.canceled_at,
                    "pastor",
                    "pastor",
                    setBlock
                  )
                }
                class={`btnFinish trash ${pastor.canceled_at ? 'errorColor' : null }`}
                id="btnBlock"
              >
                {pastor.canceled_at ? (
                  <>
                    <FaAngleRight size={16} />
                    &nbsp; Desbloquear pastor
                  </>
                ) : (
                  <>
                    <FaTimes size={16} />
                    &nbsp; Bloquear pastor
                  </>
                )}
              </button>
              <button type="submit" class="btnFinish" id="btnFinish">
                <>
                  {pastor.new ? "Gravar" : "Atualizar"}{" "}
                  <FaAngleRight size={20} />
                </>
              </button>
            </>
          ) : null}
        </div>
      </Form>
    </Container>
  );
}

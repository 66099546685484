import styled from "styled-components";

import { lighten } from "polished";

export const GradeTable = styled.table`
  width: 100%;
  background: #eee;
`;

export const GradeHeader = styled.thead`
  background: ${lighten(0.4, "rgb(155,213,49)")};
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);

  td {
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  td.iconSize {
    width: 25px;
  }
`;

export const FiltroMes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  svg {
    margin-right: -10px;
  }
  div {
    div {
      input {
        padding: 5px;
        width: 133px;
        background: none;
        border: none;
        text-align: right;
        cursor: pointer;
        color: #FFF;
      }
    }
  }
`;

export const Dialog = styled.ul`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 100%;
  width: 200px;
  background: #fff;
  border-radius: 4px;
  display: none;
  cursor: auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.1s;

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  &::before {
    content: "";
    position: absolute;
    left: 5px;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
  }

  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: #777;
    }

    &.trash:hover {
      cursor: pointer;
      color: #f00;
    }
  }
`;

export const GradeBody = styled.tbody`
  tr {
    width: 100%;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in;

    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.05);
    }

    &.approved {
      background: rgba(0, 255, 0, 0.2);
    }

    &:hover {
      background: ${lighten(0.2, "rgb(155,213,49)")};
    }

    &.active ul {
      display: block;
    }

    td {
      padding: 10px;
      font-size: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      div.balance_credit {
        background-color: #366936;
        color: #ffffff;
        padding: 4px 8px;
        width: 70px;
        text-align: center;
        font-weight: bold;
        font-size: 10px;
      }

      div.balance_debit {
        background-color: #693636;
        color: #ffffff;
        padding: 4px 8px;
        width: 70px;
        text-align: center;
        font-weight: bold;
        font-size: 10px;
      }
    }
  }
`;

export const Filtros = styled.div`
  background: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 39px;

  p {
    padding: 0 20px;
  }
`;

export const New = styled.button`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
  border: none;
  margin-left: 2px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 39px;
  background: #555;
  color: #fff;
  font-weight: bold;
`;

export const GradeTotals = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px;
  justify-content: space-evenly;

  div {
    position: relative;
    padding: 20px 45px 10px 15px;
    font-size: 24px;
    color: #555;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0 0 5px rgba(0,0,0,.1);

    &.sum {
      border-bottom: 2px solid rgb(155,213,49);
    }

    &.sub {
      border-bottom: 2px solid rgba(215,47,47,1);
    }

    span {
      position: absolute;
      top: 6px;
      left: 15px;
      font-size: 12px;
      color: #333;
    }

    &:last-child {
      border-bottom: 2px solid #555;
      color: #555;

      span {
        color: #555;
      }
    }
  }
`;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import SignatureCanvas from 'react-signature-canvas';

import {
  FaSignature,
  FaAngleRight,
  FaUserCheck,
  FaEraser,
} from "react-icons/fa";
import { Form, Input } from "@rocketseat/unform";


import { NewConfContainer } from "./styles";

import Title from "~/components/Title";

function NewConferencia({ setNewConferencia,conferencias,handlerConferencia }) {
  const [sigPad,setSigPad] = useState({});

  const [conferencia, setConferencia] = useState({
    responsavel: null,
    sign: null
  });

  function handleSubmit() {
    handlerConferencia(conferencia);
    setNewConferencia(false)
  }
  function startCanvas() {
    const scrolls = Array.from(document.getElementsByClassName('scrollbar-container'));
    scrolls.map(scroll => {
      scroll.classList.remove('ps');
    })
  }
  function endCanvas() {
    setConferencia({...conferencia,sign: sigPad.toDataURL()});
    const scrolls = Array.from(document.getElementsByClassName('scrollbar-container'));
    scrolls.map(scroll => {
      scroll.classList.add('ps');
    })
  }

  return (
    <>
    <div className="overlay"></div>
      <NewConfContainer>
        <div className="cardTitle">
          <Title
            back={true}
            func={() => setNewConferencia(false)}
            title={"Cancelar"}
          />
        </div>
        <main>
          <Form className="form" onSubmit={() => false}>
            <div className="rowInputs">
              <label htmlFor="responsavel">
                <div>
                  <FaUserCheck size={20} />
                  <br />
                  Nome
                </div>
                <Input
                  name="responsavel"
                  onChange={e =>
                    setConferencia({
                      ...conferencia,
                      responsavel: e.target.value
                    })
                  }
                />
              </label>
            </div>

            <hr/>

            <div className="rowInputs">
              <label htmlFor="responsavel">
                <div style={{marginRight:'5px'}}>
                  <FaSignature size={20} />
                  <br />
                  Assinat.
                </div>
                <SignatureCanvas penColor='#222' id='Sign' onBegin={e => {startCanvas()}} onEnd={() => endCanvas()} ref={(ref) => { setSigPad(ref) }} backgroundColor='#fff' canvasProps={{width: 900, height: 200, className: 'sigCanvas'}} />
              </label>
            </div>

            <hr/>

            <div className="rowInputs">

              <button
                type="button"
                className="btnFinish"
                onClick={() => { sigPad.clear()}}
              >
                Limpar
                <FaEraser size={20} />
              </button>

              <button
                type="button"
                className="btnFinish"
                onClick={() => handleSubmit()}
              >
                Salvar
                <FaAngleRight size={20} />
              </button>
            </div>
          </Form>
        </main>
      </NewConfContainer>
    </>
  );
}

export default NewConferencia;

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: "weTrust",
      storage,
      whitelist: [
        "auth",
        "user",
        "homebox",
        "category",
        "accesses",
        "filial",
        "functionalities",
        "selectedFilials"
      ]
    },
    reducers
  );
  return persistedReducer;
};

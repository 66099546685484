import React from 'react';

import { Container } from './styles';

import { useSelector } from "react-redux";
import retLanguage from '~/config/language';

import { FaCalendarAlt,FaAngleDown } from 'react-icons/fa';

import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';

import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";


export default function FiltroData({ handleData,selected,handleOpenDate,openDate }) {
  const profile = useSelector(state => state.user.profile);
  const lang = retLanguage(profile.language)

  return (
    <Container openDate={openDate}>
      <button onClick={handleOpenDate} className="filterCase" type="button" ><FaCalendarAlt size={12} /> {format(selected,profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')} <FaAngleDown size={16} /></button>
      <ul>
        <li><FaCalendarAlt size={12} /> <DatePicker selected={selected} dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy' } onChange={date => handleData({date})} locale={profile.language === 'pt-BR' ? pt : en } /></li>
        <li><button type="button" onClick={() => handleData({date: new Date()})}>{lang.grade.buttons.b4}</button></li>
      </ul>


    </Container>
  );
}

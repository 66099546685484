// eslint-disable react/jsx-props-no-spreading
import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import AuthLayout from "~/pages/_layout/auth";
import DefaultLayout from "~/pages/_layout/default";

import { Container } from './styles';

import { store } from '~/store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const { signed } = store.getState().auth;

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }
  if (signed && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }
  function bringLayout(props) {
    if (signed) {
      return (
        <Layout>
          <Container>
              <Component {...props} />
          </Container>
        </Layout>
      );
    } else {
      return (
        <Layout>
            <Component {...props} />
        </Layout>
      );
    }
  }

  const Layout = signed ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={props => (
        bringLayout(props)
        )

      }
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
  isPrivate: false
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import api from "~/services/api";
import {
  FaCalendarAlt,
  FaMoneyBillWave,
  FaAngleRight,
  FaTimesCircle,
  FaIndustry,
  FaUniversity,
  FaComment,
} from "react-icons/fa";
import { Form, Input, Select } from "@rocketseat/unform";

import {
  formatMoney,
  formatNum,
} from "~/scripts/index.js";
import { Content, Container, Scroll } from "./styles";
import Title from "~/components/Title";

export default function CreateDizimo({
  id,
  edit,
  handleCreateDizimo,
  setBlock
}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [load, setLoad] = useState(false);
  const [setores,setSetores] = useState([]);
  const [finish, setFinish] = useState(false);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [dizimo, setDizimo] = useState({
    created_by: profile.id,
    filial_id: filial.filial,
    date: format(new Date(),'yyyy-MM-dd'),
    plano_de_contas_id: 48,
    electronic: true,
    period: '',
    reason: '',
    observation: '',
    money: 0,
    check: 0,
    debit: 0,
    credit: 0,
    total: 0,
    checks: [],
    cards: []
  });


  async function handleSubmit() {
    // setLoad(true);

    try {
      const { data } = id
        ? await api.put(`e-dizimos/${id}`, dizimo)
        : await api.post("e-dizimos/", dizimo);
      toast.success("Relatório inserido com sucesso!", {
        className: "success"
      });
      handleCreateDizimo(false);
      setBlock(true);

      return;
    } catch (err) {
      toast.error("Há algum erro, por favor, revise o lançamento.", {
        className: "error"
      });
    }
  }
  useEffect(() => {
    async function getDizimoAndOfrtas() {
      const { data } = await api.get(`e-dizimos/id/${id}`);

      setDizimo(data[0]);
    }

    async function getSetores(){
      const { data } = await api.get('sector/');
      setSetores(data);
    }

    async function getBanks() {
      const response = await api.get("/banks/all/");
      const { data } = response;
      let promises = [];
      let banks = [];
      data.map(d => {
        return promises.push(
          new Promise((resolve, reject) => {
            resolve(banks.push({ id: d.id, title: d.name }));
          })
        );
      });
      Promise.all(promises).then(async results => {
        setPaymentAccounts(banks);
      });
    }

    if (id) {
      getDizimoAndOfrtas();
    }
    getSetores();
    getBanks();
  }, [id]);

  useEffect(() => {
    setDizimo({
      ...dizimo,
      total: parseFloat(
        parseFloat(dizimo.money)
      )
    });
  }, [dizimo.money]);

  useEffect(() => {
    let { date, total, reason, bank_id } = dizimo;

    setFinish(
      date && reason && parseInt(bank_id) > 0 &&
        formatNum(total) > 0
    );
  }, [dizimo.date, dizimo.total,dizimo.reason,dizimo.bank_id]);

  return (
    <Scroll>
      <Content>
        {load && (
          <div className="content-load">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <header>
          <Title back={true} func={handleCreateDizimo} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Novo lançamento de dízimos e ofertas</h1>
          <Form initialData={dizimo} onSubmit={handleSubmit}>
            <div className="rowInputs">
              <label htmlFor="nome">
                {id && dizimo.user ? (
                  <>
                    <img
                      src={
                        dizimo.user.avatar
                          ? dizimo.user.avatar.url
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrdEvvb_A3fTRZ9FTzpCkDacPDXBYoWgsoxbFbcYnB-jWigqa6w&s"
                      }
                      width="54"
                      height="54"
                      alt=""
                    />
                    <Input
                      name="nome"
                      readOnly="readonly"
                      value={dizimo.user.nome}
                      placeholder="Data"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={
                        profile.avatar
                          ? profile.avatar.url
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrdEvvb_A3fTRZ9FTzpCkDacPDXBYoWgsoxbFbcYnB-jWigqa6w&s"
                      }
                      width="54"
                      height="54"
                      alt=""
                    />
                    <Input
                      name="nome"
                      readOnly="readonly"
                      value={profile.nome}
                      placeholder="Data"
                    />
                  </>
                )}
              </label>
              <label htmlFor="date">
                <div>
                  <FaCalendarAlt size={20} />
                  <br />
                  Data
                </div>
                <DatePicker
                  name="date"
                  disabled={edit ? null : "disabled"}
                  maxDate={new Date()}
                  minDate={
                    new Date(
                      filial.open_date.substring(0, 4),
                      parseInt(filial.open_date.substring(5, 7)) - 1,
                      filial.open_date.substring(8, 10)
                    )
                  }
                  selected={parseISO(dizimo.date)}
                  onChange={date => setDizimo({ ...dizimo, date: format(date,'yyyy-MM-dd') })}
                  dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                  locale={pt}
                />
              </label>
              <label htmlFor="reason">
                <div>
                  <FaIndustry size={20} />
                  <br />
                  Setor
                </div>
                <Select
                  options={setores}
                  disabled={edit ? null : "disabled"}
                  name="reason"
                  value={dizimo.reason ? dizimo.reason : ''}
                  onChange={e =>
                    setDizimo({ ...dizimo, reason: e.target.value })
                  }
                  placeholder="Selecione..."
                />
              </label>
            </div>
            <hr />

            <div className="rowInputs">
              <label htmlFor="money">
                <div>
                  <FaMoneyBillWave size={20} />
                  <br />
                  Valor
                </div>
                <Input
                  name="money"
                  readOnly={edit ? null : "readonly"}
                  onChange={e =>
                    setDizimo({ ...dizimo, money: formatNum(e.target.value) })
                  }
                  value={formatMoney(
                    dizimo.money.toFixed(2),
                    filial.country.language
                  )}
                />
              </label>
              <label htmlFor="bank_id">
                <div>
                  <FaUniversity size={20} />
                  <br />
                  Conta
                </div>
                <Select
                  name="bank_id"
                  placeholder={dizimo.bank ? dizimo.bank.name : "Selecione..."}
                  options={paymentAccounts}
                  value={dizimo.bank_id}
                  selected={dizimo.bank_id}
                  disabled={!edit}
                  onChange={e =>
                    setDizimo({
                      ...dizimo,
                      bank_id: e.target.value ? e.target.value : null
                    })
                  }
                />
              </label>
              </div>

              <hr />

            <div className="rowInputs">
              <label htmlFor="observation">
                <div>
                  <FaComment size={20} />
                  <br />
                  Obs.
                </div>
                <Input
                  name="observation"
                  readOnly={edit ? null : "readonly"}
                  onChange={e =>
                    setDizimo({ ...dizimo, observation: e.target.value })
                  }
                  value={dizimo.observation}
                />
              </label>
              {edit ? (
                <>
                  <button
                    type="submit"
                    className="btnFinish"
                    // finish={finish.toString()}
                    disabled={!finish}
                    title={
                      !finish
                        ? "Há campos obrigatórios não preenchidos!"
                        : "Finalizar"
                    }
                  >
                    {finish ? (
                      <>
                        {id ? "Alterar" : "Finalizar"}{" "}
                        <FaAngleRight size={20} />
                      </>
                    ) : (
                      <>
                        {id ? "Alterar" : "Finalizar"}{" "}
                        <FaTimesCircle size={20} />
                      </>
                    )}
                  </button>
                </>
              ) : null}
            </div>
          </Form>
        </Container>
      </Content>
    </Scroll>
  );
}

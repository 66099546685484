import React, { useState, useEffect } from "react";

import { Content, Container, Scroll } from "./styles";

import {
  FaRegAddressCard,
  FaIndustry
} from "react-icons/fa";

import Title from "~/components/Title";

import { Form, Input } from "@rocketseat/unform";

import api from "~/services/api";

export default function BuscaSector({ show, handlerSector, setSectorSearch }) {
  const [sectorDate, setSectorDate] = useState({ id: "", name: "" });

  const [sectors, setSector] = useState([]);

  useEffect(() => {
    async function getSectors() {
      try {
        const response = await api.get(
          "/sectorsearch?id=" + sectorDate.id + "&name=" + sectorDate.name
        );
        const { data } = response;
        setSector(data);
      } catch (err) {
        setSector([]);
      }
    }
    getSectors();
  }, [sectorDate.id, sectorDate.name]);

  return (
    <Scroll style={!show ? { display: "none" } : null}>
      <Content style={{ maxWidth: "1100px", left: "calc(50% - 550px)" }}>
        <header>
          <Title back={true} func={setSectorSearch} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Buscar Setor</h1>
          <Form>
            <div className="rowInputs">
              <label htmlFor="code">
                <div>
                  <FaRegAddressCard size={20} />
                  <br />
                  Código
                </div>
                <Input
                  name="code"
                  placeholder="Código do setor"
                  onChange={e =>
                    setSectorDate({
                      ...sectorDate,
                      id: e.target.value
                    })
                  }
                  value={sectorDate.id}
                />
              </label>
              <label htmlFor="name">
                <div>
                  <FaIndustry size={20} />
                  <br />
                  Nome
                </div>
                <Input
                  name="name"
                  placeholder="Nome do setor"
                  maxLength={30}
                  value={sectorDate.name}
                  onChange={e =>
                    setSectorDate({
                      ...sectorDate,
                      name: e.target.value
                    })
                  }
                />
              </label>
            </div>

            {sectors.length > 0
              ? sectors.map(p => (
                  <div className="rowInputs">
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() =>
                        handlerSector({
                          id: p.id,
                          code: p.code,
                          name: p.name
                        })
                      }
                    >
                      Selecionar
                    </button>
                    <label htmlFor="code">
                      <div>
                        <FaRegAddressCard size={20} />
                        <br />
                        Código
                      </div>
                      <Input readOnly name="code" value={p.id} />
                    </label>
                    <label htmlFor="name">
                      <div>
                        <FaIndustry size={20} />
                        <br />
                        Nome.
                      </div>
                      <Input readOnly name="name" value={p.name} />
                    </label>
                  </div>
                ))
              : null}
          </Form>
        </Container>
      </Content>
    </Scroll>
  );
}

import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select } from "@rocketseat/unform";
import retLanguage from "~/config/language";
import api from "~/services/api";

import { logout } from "~/store/modules/auth/actions";
import { updateProfileRequest } from "~/store/modules/user/actions";

import AvatarInput from "./AvatarInput";

import { Container } from "./styles";

export default function Profile() {
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const lang = retLanguage(profile.language);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(profile.email);

  async function handleSubmit(data) {
    dispatch(
      updateProfileRequest({
        ...data,
        email,
        id: profile.id,
        oldEmail: profile.email,
        avatar: profile.avatar
      })
    );
  }

  function handleLogout() {
    dispatch(logout());
  }

  useEffect(() => {
    async function loadCountries() {
      try {
        const response = await api.get("countries/actives/");
        const { data } = response;
        let arrayCountries = [];
        data.map(p => {
          arrayCountries.push({ id: p.language, title: p.name });
          return null;
        });
        setCountries(arrayCountries);
      } catch (err) {}
    }
    loadCountries();
  }, []);

  return (
    <Container>
      <Form initialData={profile} autoComplete="off" onSubmit={handleSubmit}>
        <AvatarInput name="avatar_id" />
        <Input name="nome" placeholder={lang.profile.p1} />
        <Input
          name="email"
          type="email"
          onChange={e => {
            setEmail(e.target.value);
          }}
          value={email}
          placeholder={lang.profile.p2}
        />

        <hr />

        {countries.length > 0 ? (
          <Select
            key={countries.id}
            options={countries}
            placeholder={lang.profile.p3}
            name="language"
          />
        ) : null}

        <hr />

        <Input
          name="oldPassword"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder={lang.profile.p4}
        />
        <Input name="password" type="password" placeholder={lang.profile.p5} />
        <Input
          name="confirmPassword"
          type="password"
          placeholder={lang.profile.p6}
        />

        <button type="submit">{lang.profile.p7}</button>
      </Form>
      <button type="button" onClick={handleLogout}>
        {lang.profile.p8}
      </button>
    </Container>
  );
}

import produce from 'immer';

const INITIAL_STATE = {
  homebox: null,
}

export default function homebox(state = INITIAL_STATE, action) {
  return produce(state, draft => {
  switch (action.type) {
    case '@auth/LOGIN_SUCCESS': {
      draft.category = action.payload.user.group.default_category;
      break;
    }
    case '@homebox/UPDATE': {
      draft.homebox = action.payload.homebox;
      break;
    }
    case '@homebox/DESTROY': {
      draft.homebox = null;
      break;
    }
    case '@auth/LOGOUT': {
      draft.homebox = null;
      break;
    }
    default:
  }
});
}

import styled from "styled-components";

export const Container = styled.form`
  width: 290px;
  height: 39px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  label {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    input {
      border: 1px solid #ccc;
      height: 28px;
      padding: 0 5px;
      color: #333;
      border-radius: 4px;
      font-size: 12px;
      line-height: 28px;
      width: 190px;
    }
  }
`;

import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { FaUser, FaArrowLeft, FaIdCard } from "react-icons/fa";

import { Form, Input } from "@rocketseat/unform";
import { Content, Container, Scroll, Title, Back } from "./styles";

import api from "~/services/api";

export default function BuscaPOS({ show, handlerPOS, setPOSSearch }) {
  const [postDate, setPostDate] = useState({
    code: "",
    name: "",
    number_pos: ""
  });

  const filial = useSelector(state => state.filial);

  const [poss, setPoss] = useState([]);

  useEffect(() => {
    async function getPoss() {
      try {
        const response = await api.get(
          `/possearch?name=${postDate.name}&number_pos=${postDate.number_pos}&filial_id=${filial.filial}`
        );
        const { data } = response;
        setPoss(data);
      } catch (err) {
        setPoss([]);
      }
    }
    getPoss();
  }, [postDate.number_pos, postDate.name, filial.filial]);

  return (
    <Scroll style={!show ? { display: "none" } : null}>
      <Content style={{ maxWidth: "1100px", left: "calc(50% - 550px)" }}>
        <header>
          <Title>
            <Back type="button" onClick={() => setPOSSearch(false)}>
              <FaArrowLeft size={14} />
            </Back>
            Cancelar
          </Title>
        </header>
        <Container>
          <h1>Buscar PDV</h1>
          <Form>
            <div className="rowInputs">
              <label htmlFor="number_pos">
                <div>
                  <FaIdCard size={20} />
                  <br />
                  N° PDV
                </div>
                <Input
                  name="number_pos"
                  maxLength={30}
                  value={postDate.number_pos}
                  placeholder="Código"
                  onChange={e =>
                    setPostDate({
                      ...postDate,
                      number_pos: e.target.value
                    })
                  }
                />
              </label>
              <label htmlFor="name">
                <div>
                  <FaUser size={20} />
                  <br />
                  Nome
                </div>
                <Input
                  name="name"
                  maxLength={30}
                  placeholder="Nome"
                  value={postDate.name}
                  onChange={e =>
                    setPostDate({
                      ...postDate,
                      name: e.target.value
                    })
                  }
                />
              </label>
            </div>

            {poss.length > 0
              ? poss.map((p,index) => (
                  <div className="rowInputs" key={index}>
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() =>
                        handlerPOS(p)
                      }
                    >
                      Selecionar
                    </button>
                    <label htmlFor="number_pos">
                      <div>
                        <FaIdCard size={20} />
                        <br />
                        N° PDV
                      </div>
                      <Input readOnly name="number_pos" value={p.number_pos} />
                    </label>
                    <label htmlFor="name">
                      <div>
                        <FaUser size={20} />
                        <br />
                        Nome
                      </div>
                      <Input readOnly name="name" value={p.name} />
                    </label>
                  </div>
                ))
              : null}
          </Form>
        </Container>
      </Content>
    </Scroll>
  );
}

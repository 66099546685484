import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import retLanguage from "~/config/language";

import { updateCategory } from "~/store/modules/category/actions";
import { destroyHomebox } from "~/store/modules/homebox/actions";

import { Container } from "./styles";
import { verifyAccess } from "~/scripts";
import {
  FaBars,
  FaHandHoldingUsd,
  FaReceipt,
  FaTasks,
  FaUserPlus,
  FaUsersCog,
  FaRegCreditCard
} from "react-icons/fa";

export default function Menu() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const activeCategory = useSelector(state => state.category);
  const [visible, setVisible] = useState(false);
  const newaccesses = useSelector(state => state.auth.newaccesses);
  const lang = retLanguage(profile.language);
  const Items = [
    {
      icon: <FaBars size={16} />,
      id: null
    },
    {
      icon: <FaHandHoldingUsd size={24} />,
      id: "contas_a_receber",
      title: lang.menu.h2,
      defHomebox: "dizimos_e_ofertas"
    },
    {
      icon: <FaReceipt size={24} />,
      id: "contas_a_pagar",
      title: lang.menu.h3,
      defHomebox: "transferencias"
    },
    {
      icon: <FaUserPlus size={24} />,
      id: "registrations",
      title: lang.menu.h4,
      defHomebox: "agentes"
    },
    {
      icon: <FaUsersCog size={24} />,
      id: "configs",
      title: lang.menu.h5,
      defHomebox: "setores"
    },
    {
      icon: <FaRegCreditCard size={24} />,
      id: "pdv",
      title: lang.menu.h6,
      defHomebox: "operadoras"
    },
    {
      icon: <FaTasks size={24} />,
      id: "balance",
      title: lang.menu.h7,
      defHomebox: "caixa_da_filial"
    }
  ];

  function handleCategory(activeCategory, defHomebox) {
    dispatch(destroyHomebox());
    dispatch(updateCategory(activeCategory, defHomebox));
    setVisible(false);
  }

  function handleMenu() {
    setVisible(!visible);
  }
  return (
    <Container visible={visible}>
      <ul>
        <li onClick={() => handleMenu()}>
          <FaBars size={16} />
        </li>
        {Items.map(
          item =>
            item.id && verifyAccess(newaccesses, item.id) ? (
              // !accesses.find(access =>
              //   allowAccess(access, item.id, "read")
              // ) ? null : item.id ? (
              <li
                key={item.id}
                className={
                  item.id === activeCategory.category ? "active" : null
                }
                id={item.id}
                onClick={() => handleCategory(item.id, item.defHomebox)}
              >
                {item.icon} <span>{item.title}</span>
              </li>
            ) : null
          // ) : (
          //   <li
          //     key={item.id}
          //     className={item.id === activeCategory.category ? "active" : null}
          //     id={item.id}
          //     onClick={() => handleMenu()}
          //   >
          //     {item.icon} <span>{item.title}</span>
          //   </li>
          // )
        )}
      </ul>
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import api from "~/services/api";
import * as yup from "yup";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import { maskPhone, maskZIP, loadCountries, verifyAccess } from "~/scripts/index.js";

import {
  FaCheck,
  FaAngleRight,
  FaTimesCircle,
  FaRegAddressCard,
  FaSearch,
  FaUserAlt,
  FaEnvelope,
  FaPhone,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaRoad,
  FaStreetView,
  FaUniversity,
  FaFlagUsa,
  FaGlobeAmericas,
  FaHome
} from "react-icons/fa";

import { Form, Input, Select } from "@rocketseat/unform";
import { Content, Container, BtnType, Scroll } from "./styles";

import Fornecedor from "./FormFornecedor";
import Funcionario from "./FormFuncionario";
import Pastor from "./FormPastor";
import Missionario from "./FormMissionario";
import Proprietario from "./FormProprietario";
import Administradora from "./FormAdministradora";
import RepLegal from "./FormRepLegal";

import Title from "~/components/Title";

import "react-datepicker/dist/react-datepicker.css";

export default function CreateAgent({
  handleCreateAgent,
  opennedCGC,
  edit,
  setAgents,
  agents
}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const accesses = useSelector(state => state.auth.newaccesses);
  const code = useSelector(state => state.filial.country.code);

  let [agent, setAgent] = useState({
    cgc: "",
    zip: "",
    phone: "",
    number: 0,
    complement: "",
    created_by: profile.id,
    country: "",
    country_id: null
  });


  const [countries, setCountries] = useState([]);

  let [newAgent, setNewAgent] = useState(false);
  let [editAgent, setEditAgent] = useState(false);
  let [finish] = useState(true);
  let [typeActive, setTypeActive] = useState(null);

  /*
   * Liberar botão gravar no dízimo
   */

  const agentSchema = yup.object().shape({
    cgc: yup.string().required("Documento obrigatório."),
    name: yup.string().required("Nome obrigatório."),
    zip: yup.string().required("CEP obrigatório."),
    country: yup.string().required("País obrigatório.")
  });

  async function handleZIP(cep) {
    if (agent.country === "BR") {
      if (cep.length === 8) {
        const response = await api.get("correios/" + cep.toString());
        const { data } = response;
        if (data.logradouro) {
          return setAgent({
            ...agent,
            zip: cep,
            address: data.logradouro,
            complement: data.complemento,
            neighborhood: data.bairro,
            city: data.localidade,
            state: data.uf,
            country: "BR"
          });
        }
      }
      setAgent({ ...agent, zip: cep })
    } else {
      return setAgent({
        ...agent,
        zip: cep.toString(),
        address: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: ""
      });
    }
    setAgent({ ...agent, zip: cep })
  }

  async function findCGC(e) {
    e.preventDefault();
    const valCgc = agent.cgc.replace(/\D/g, "");
    let person = valCgc.length === 11 ? "Física" : "Jurídica";
    let foreign = false;
    if (valCgc === "99999999999999") {
      foreign = true;
      person = "Estrangeiro";
    }
    if (!valCgc) {
      toast.error("Preencha o campo de Documento!", { className: "error" });
      return;
    }
    setAgent({ ...agent, cgc: valCgc, person });
    try {
      const response = await api.get("agents/" + valCgc);
      const { data } = response;
      if (data.length > 0) {
        setAgent(data[0]);
        setNewAgent(false);
        setEditAgent(true);
      } else {
        setNewAgent(true);
        setEditAgent(true);
      }
      return;
    } catch (err) {

    }
    setNewAgent(true);
    setEditAgent(true);
  }

  async function handleTypes(e) {
    const btns = [...document.getElementsByClassName("btnTypes")];
    if (e.target.classList.contains("openned")) {
      e.target.classList.remove("openned");
      await setTypeActive(null);
      return;
    }
    btns.map(btn => {
      return btn.classList.remove("openned");
    });
    e.target.classList.add("openned");
    await setTypeActive(e.target.id);
  }

  async function handleSubmit(e) {
    setAgent({ ...agent, createdBy: profile.id });
    try {
      const response = newAgent
        ? await api.post("agents/", agent)
        : await api.put("agents/" + agent.cgc, agent);
      setAgent({ ...agent, id: response.data.id });
      toast.success(
        `Agente ${newAgent ? "inserido" : "alterado"} com sucesso!`,
        { className: "success" }
      );
      setEditAgent(true);
      setNewAgent(false);
      if (newAgent) {
        setAgents([...agents, agent]);
      } else {
        handleCreateAgent();
      }
      return;
    } catch (err) {
      switch (err.response.data.error) {
        case 'email-already-exists':
          toast.error("E-mail já cadastrado.", { className: "error" });
          break
        default:
          toast.error("Por favor, revise os dados!", { className: "error" });
      }
    }
  }

  function handleCountry(country) {
    let code = null;
    countries.map(c => {
      if (c.id === parseInt(country)) {
        code = c.code;
      }
    });
    setAgent({ ...agent, country: code, country_id: parseInt(country) });
  }

  useEffect(() => {
    async function loadAgent() {
      try {
        const response = await api.get("agents/" + opennedCGC);
        const { data } = response;
        const ag = data[0];
        if (ag.cgc) {
          setAgent({ ...ag, country_id: ag.country === 'BR' ? 1 : ag.country === 'US' ? 2 : 9 });
          setNewAgent(false);
          setEditAgent(true);
          return;
        }
      } catch (err) {

      }
    }

    loadCountries(setCountries, true);
    loadAgent();
  }, [opennedCGC]);

  useEffect(() => {
    if (newAgent) {
      handleZIP(agent.zip);
    }
  }, [agent.country])

  return (
    <Scroll>
      <Content>
        <header>
          <Title back={true} func={handleCreateAgent} title={"Cancelar"} />
        </header>
        {newAgent || editAgent ? (
          <>
            <Container id="existingAgent">
              <h1>{newAgent ? "Novo Agente" : "Editar Agente"}</h1>
              <Form schema={agentSchema} onSubmit={handleSubmit} autoComplete="off">
                <div className="rowInputs">
                  <label htmlFor="cgc">
                    <div>
                      <FaRegAddressCard size={20} />
                      <br />
                      Doc.
                    </div>
                    <Input
                      name="cgc"
                      readOnly="readonly"
                      placeholder="Documento"
                      onChange={e =>
                        setAgent({ ...agent, cgc: e.target.value })
                      }
                      value={agent.cgc}
                    />
                  </label>
                  <label htmlFor="name">
                    <div>
                      <FaUserAlt size={20} />
                      <br />
                      Nome
                    </div>
                    <Input
                      name="name"
                      placeholder="Nome completo"
                      readOnly={!edit ? "readonly" : ""}
                      maxLength="40"
                      onChange={e =>
                        setAgent({ ...agent, name: e.target.value })
                      }
                      value={agent.name}
                    />
                  </label>
                </div>

                <hr />

                <div className="rowInputs">
                  <label htmlFor="country">
                    <div>
                      <FaGlobeAmericas size={20} />
                      <br />
                      País
                    </div>
                    <Select
                      name="country"
                      options={countries}
                      disabled={!edit}
                      maxLength="2"
                      onChange={e => handleCountry(e.target.value)}
                      value={agent.country_id}
                    />
                  </label>
                </div>

                <hr />

                {agent.country ?
                  <>

                    <div className="rowInputs">
                      <label htmlFor="zip">
                        <div>
                          <FaMapMarkedAlt size={20} />
                          <br />
                          CEP
                        </div>
                        <Input
                          name="zip"
                          placeholder="_____-___"
                          readOnly={!edit ? "readonly" : ""}
                          maxLength="9"
                          onChange={e => handleZIP(e.target.value)}
                          value={
                            agent.country === "BR"
                              ? maskZIP(agent.zip)
                              : agent.zip
                          }
                        />
                      </label>
                      {agent.country === 'BR' ?
                        <>
                          <label htmlFor="address">
                            <div>
                              <FaRoad size={20} />
                              <br />
                              Endereço
                            </div>
                            <Input
                              name="address"
                              readOnly={!edit ? "readonly" : null}
                              placeholder="Endereço "
                              onChange={e =>
                                setAgent({ ...agent, address: e.target.value })
                              }
                              maxLength="60"
                              value={agent.address}
                            />
                          </label>
                          <label htmlFor="number">
                            <div>
                              <FaMapMarkerAlt size={20} />
                              <br />
                              Número
                            </div>
                            <Input
                              name="number"
                              placeholder="___"
                              readOnly={!edit ? "readonly" : ""}
                              maxLength="6"
                              onChange={e =>
                                setAgent({
                                  ...agent,
                                  number: e.target.value
                                    .toString()
                                    .replace(/[^\d]+/g, "")
                                })
                              }
                              value={agent.number}
                            />
                          </label>
                        </>
                        :
                        <>
                          <label htmlFor="number">
                            <div>
                              <FaMapMarkerAlt size={20} />
                              <br />
                              Número
                            </div>
                            <Input
                              name="number"
                              placeholder="___"
                              readOnly={!edit ? "readonly" : ""}
                              maxLength="6"
                              onChange={e =>
                                setAgent({
                                  ...agent,
                                  number: e.target.value
                                    .toString()
                                    .replace(/[^\d]+/g, "")
                                })
                              }
                              value={agent.number}
                            />
                          </label>
                          <label htmlFor="address">
                            <div>
                              <FaRoad size={20} />
                              <br />
                              Endereço
                            </div>
                            <Input
                              name="address"
                              readOnly={!edit ? "readonly" : null}
                              placeholder="Endereço "
                              onChange={e =>
                                setAgent({ ...agent, address: e.target.value })
                              }
                              maxLength="60"
                              value={agent.address}
                            />
                          </label>
                        </>
                      }
                      <label htmlFor="complement">
                        <div>
                          <FaHome size={18} />
                          <br />
                          Comple.
                        </div>
                        <Input
                          name="complement"
                          placeholder="Complemento"
                          readOnly={!edit ? "readonly" : ""}
                          onChange={e =>
                            setAgent({ ...agent, complement: e.target.value })
                          }
                          value={agent.complement}
                        />
                      </label>
                    </div>

                    <hr />

                    <div className="rowInputs">
                      <label htmlFor="neighborhood">
                        <div>
                          <FaStreetView size={20} />
                          <br />
                          Bairro
                        </div>
                        <Input
                          name="neighborhood"
                          readOnly={!edit ? "readonly" : null}
                          placeholder="Bairro"
                          onChange={e =>
                            setAgent({ ...agent, neighborhood: e.target.value })
                          }
                          maxLength="60"
                          value={agent.neighborhood}
                        />
                      </label>
                      <label htmlFor="city">
                        <div>
                          <FaUniversity size={20} />
                          <br />
                          Cidade
                        </div>
                        <Input
                          name="city"
                          readOnly={!edit ? "readonly" : null}
                          placeholder="Cidade"
                          onChange={e =>
                            setAgent({ ...agent, city: e.target.value })
                          }
                          value={agent.city}
                        />
                      </label>
                      <label htmlFor="state">
                        <div>
                          <FaFlagUsa size={20} />
                          <br />
                          Estado
                        </div>
                        <Input
                          name="state"
                          readOnly={!edit ? "readonly" : null}
                          placeholder="UF"
                          maxLength="2"
                          onChange={e =>
                            setAgent({ ...agent, state: e.target.value })
                          }
                          value={agent.state}
                        />
                      </label>
                    </div>

                    <hr />

                    <div className="rowInputs">
                      <label htmlFor="phone">
                        <div>
                          <FaPhone size={20} />
                          <br />
                          Telefone
                        </div>
                        <Input
                          name="phone"
                          placeholder={agent.country.length > 1 ?
                            agent.country === 'BR' ? "(__) _____-____" : "(___) ___-____"
                            : '(__) _____-____'}
                          readOnly={!edit ? "readonly" : ""}
                          maxLength="15"
                          onChange={e =>
                            setAgent({ ...agent, phone: e.target.value })
                          }
                          value={maskPhone(
                            agent.phone.toString(),
                            agent.country.length > 1 ? agent.country : "BR"
                          )}
                        />
                      </label>
                      <label htmlFor="email">
                        <div>
                          <FaEnvelope size={20} />
                          <br />
                          E-mail
                        </div>
                        <Input
                          name="email"
                          type="email"
                          placeholder="@email.com"
                          readOnly={!edit ? "readonly" : ""}
                          maxLength="40"
                          onChange={e =>
                            setAgent({ ...agent, email: e.target.value })
                          }
                          value={agent.email}
                        />
                      </label>
                      {edit ? (
                        <button
                          type="submit"
                          className="btnFinish"
                          finish={finish.toString()}
                          disabled={!finish}
                          title={
                            !finish
                              ? "Há campos obrigatórios não preenchidos!"
                              : "Finalizar"
                          }
                        >
                          {finish ? (
                            <>
                              {newAgent ? "Finalizar" : "Atualizar"}{" "}
                              <FaAngleRight size={20} />
                            </>
                          ) : (
                            <>
                              ... <FaTimesCircle size={20} />
                            </>
                          )}
                        </button>
                      ) : null}
                    </div>

                  </>
                  : null}
              </Form>
            </Container>
            {!newAgent ? (
              <BtnType>
                {verifyAccess(accesses, 'cadastro_fornecedor') ? (
                  <button
                    className="btnTypes"
                    id="fornecedor"
                    onClick={e => handleTypes(e)}
                    type="button"
                  >
                    {agent.type && agent.type.includes("1") ? (
                      <FaCheck size={10} className="successColor" />
                    ) : null}
                    {" Fornecedor"}
                  </button>
                ) : null}

                {/* {verifyAccess(accesses,'cadastro_funcionario') ? (
                  <button
                    className="btnTypes"
                    id="funcionario"
                    onClick={e => handleTypes(e)}
                    type="button"
                  >
                    {agent.type && agent.type.includes("2") ? (
                      <FaCheck size={10} className="successColor" />
                    ) : null}
                    {" Funcionário"}
                  </button>
                ) : null} */}

                {verifyAccess(accesses, 'cadastro_pastores') ? (
                  <button
                    className="btnTypes"
                    id="pastor"
                    onClick={e => handleTypes(e)}
                    type="button"
                  >
                    {agent.type && agent.type.includes("3") ? (
                      <FaCheck size={10} className="successColor" />
                    ) : null}
                    {" Pastor"}
                  </button>
                ) : null}

                {/* {(accesses.find(access =>
                  allowAccess(access, "missionaries", "create")
                ) || profile.group_id === 1) && (
                  <button
                    className="btnTypes"
                    id="missionario"
                    onClick={e => handleTypes(e)}
                    type="button"
                  >
                    {agent.type && agent.type.includes("4") ? (
                      <FaCheck size={10} className="successColor" />
                    ) : null}
                    {" Missionário"}
                  </button>
                )}

                {(accesses.find(access =>
                  allowAccess(access, "property_owners", "create")
                ) || profile.group_id === 1) && (
                  <button
                    className="btnTypes"
                    id="proprietario"
                    onClick={e => handleTypes(e)}
                    type="button"
                  >
                    {agent.type && agent.type.includes("5") ? (
                      <FaCheck size={10} className="successColor" />
                    ) : null}
                    {" Proprietário"}
                  </button>
                )}

                {(accesses.find(access =>
                  allowAccess(access, "property_manager", "create")
                ) || profile.group_id === 1) && (
                  <button
                    className="btnTypes"
                    id="administradora"
                    onClick={e => handleTypes(e)}
                    type="button"
                  >
                    {agent.type && agent.type.includes("6") ? (
                      <FaCheck size={10} className="successColor" />
                    ) : null}
                    {" Administradora"}
                  </button>
                )}

                {(accesses.find(access =>
                  allowAccess(access, "legal_representant", "create")
                ) || profile.group_id === 1) && (
                  <button
                    className="btnTypes"
                    id="replegal"
                    onClick={e => handleTypes(e)}
                    type="button"
                  >
                    {agent.type && agent.type.includes("7") ? (
                      <FaCheck size={10} className="successColor" />
                    ) : null}
                    {" Rep. Legal"}
                  </button>
                )} */}
              </BtnType>
            ) : null}
            {typeActive === "fornecedor" ? (
              <Fornecedor
                cgc={agent.cgc}
                agentId={agent.id}
                agent={agent}
                setAgent={setAgent}
                edit={edit}
              />
            ) : typeActive === "funcionario" ? (
              <Funcionario
                cgc={agent.cgc}
                agentId={agent.id}
                agent={agent}
                setAgent={setAgent}
                edit={edit}
              />
            ) : typeActive === "pastor" ? (
              <Pastor
                cgc={agent.cgc}
                agentId={agent.id}
                agent={agent}
                setAgent={setAgent}
                edit={edit}
              />
            ) : typeActive === "missionario" ? (
              <Missionario
                cgc={agent.cgc}
                agentId={agent.id}
                agent={agent}
                setAgent={setAgent}
                edit={edit}
              />
            ) : typeActive === "proprietario" ? (
              <Proprietario
                cgc={agent.cgc}
                agentId={agent.id}
                agent={agent}
                setAgent={setAgent}
                edit={edit}
              />
            ) : typeActive === "administradora" ? (
              <Administradora
                cgc={agent.cgc}
                agentId={agent.id}
                agent={agent}
                setAgent={setAgent}
                edit={edit}
              />
            ) : typeActive === "replegal" ? (
              <RepLegal
                cgc={agent.cgc}
                agentId={agent.id}
                agent={agent}
                setAgent={setAgent}
                edit={edit}
              />
            ) : null}
          </>
        ) : (
          <Container id="newAgent">
            <h1>Verificar Documento</h1>
            <form onSubmit={findCGC}>
              <div className="rowInputs">
                <label htmlFor="cgc">
                  <div>
                    <FaRegAddressCard size={20} />
                    <br />
                    Doc.
                  </div>
                  <Input
                    name="cgc"
                    placeholder="Documento"
                    onChange={cgc =>
                      setAgent({ ...agent, cgc: cgc.target.value })
                    }
                    // maxLength="18"
                    // value={maskCGC(agent.cgc)}
                    value={agent.cgc}
                  />
                </label>
                <button type="submit" className="btnFind">
                  <FaSearch size={16} />
                </button>
              </div>
            </form>
          </Container>
        )}
      </Content>
    </Scroll>
  );
}

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import api from "~/services/api";

import { toast } from "react-toastify";

import Title from "~/components/Title";

import {
  FaAngleRight,
  FaTimesCircle,
  FaUserAlt,
  FaServer,
  FaEthernet,
  FaLock,
  FaExpeditedssl
} from "react-icons/fa";

import { Input, Form, Select } from "@rocketseat/unform";

import { Scroll, Content, Container } from "./styles";

function CreateSMTP({ handlerExitCreateSMTP, handlerCreateEmail, edit, id }) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);

  const [newSMTP, setNewSMTP] = useState({
    host: "",
    port: "",
    user: "",
    pass: "",
    created_by: profile.id,
    filial_id: filial.filial
  });

  const [finish, setFinish] = useState(false);
  const smtpOptions = [{
    id: true, title: 'Sim'
  }, {
    id: false, title: 'Não'
  }]

  const { host, port, user, pass } = newSMTP;
  useEffect(() => {
    setFinish(host && port && user && pass);
  }, [host, port, user, pass]);

  useEffect(() => {
    async function getSMTP() {
      const { data } = await api.get(`email/${id}`);

      setNewSMTP({ ...newSMTP, ...data });
    }

    getSMTP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function handleSubmit() {
    try {
      id
        ? await api.put(`email/${id}`, {
            newSMTP
          })
        : await api.post("email", newSMTP);

      toast.success(
        `Servidor de e-mail ${id ? "Atualizado" : "Cadastrado"} com sucesso!`,
        {
          className: "success"
        }
      );

      handlerExitCreateSMTP(false);
    } catch (err) {
      toast.error(
        `Erro ao tentar ${id ? "Atualizar" : "Cadastrar"} Servidor de e-mail!`,
        {
          className: "error"
        }
      );
    }
  }

  return (
    <Scroll>
      <Content>
        <header>
          <Title back={true} func={handlerCreateEmail} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Novo Servidor SMTP</h1>
          <Form onSubmit={handleSubmit}>
            <div className="rowInputs">
              <label>
                <div>
                  <FaServer size={18} />
                  <br />
                  Host
                </div>
                <Input
                  value={newSMTP.host}
                  placeholder="Host"
                  name="host"
                  onChange={e =>
                    setNewSMTP({ ...newSMTP, host: e.target.value })
                  }
                />
              </label>
              <label>
                <div>
                  <FaEthernet size={18} />
                  <br />
                  Porta
                </div>
                <Input
                  value={newSMTP.port}
                  placeholder="Porta"
                  name="port"
                  onChange={e =>
                    setNewSMTP({ ...newSMTP, port: e.target.value })
                  }
                />
              </label>
              <label>
                <div>
                  <FaExpeditedssl size={18} />
                  <br />
                  SSL
                </div>
                <Select
                  options={smtpOptions}
                  value={newSMTP.secure}
                  name="secure"
                  onChange={e =>
                    setNewSMTP({ ...newSMTP, secure: e.target.value })
                  }
                />
              </label>
            </div>
            <hr />
            <div className="rowInputs">
              <label>
                <div>
                  <FaUserAlt size={18} />
                  <br />
                  Usuário
                </div>
                <Input
                  value={newSMTP.user}
                  placeholder="Usuário"
                  name="user"
                  onChange={e =>
                    setNewSMTP({ ...newSMTP, user: e.target.value })
                  }
                />
              </label>
              <label>
                <div>
                  <FaLock size={18} />
                  <br />
                  Senha
                </div>
                <Input
                  value={newSMTP.pass}
                  placeholder="Senha"
                  name="pass"
                  onChange={e =>
                    setNewSMTP({ ...newSMTP, pass: e.target.value })
                  }
                />
              </label>
              <button type="submit" className="btnFinish" disabled={!finish}>
                {finish ? (
                  <>
                    {id ? (
                      <>
                        Atualizar <FaAngleRight size={20} />
                      </>
                    ) : (
                      <>
                        Cadastrar <FaAngleRight size={20} />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    ... <FaTimesCircle size={20} />
                  </>
                )}
              </button>
            </div>
          </Form>
        </Container>
      </Content>
    </Scroll>
  );
}

export default CreateSMTP;

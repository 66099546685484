import React, { useState } from 'react'
import './style.css'
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';
import { ImCross } from 'react-icons/im';

export default function DateFilterUnique({ date = false, setDate = () => { } }) {
  const profile = useSelector(state => state.user.profile);

  return (
    <>
      <div className="date-filter-unique-container">
        <>
          <div className="date-filter-unique-area">
            <DatePicker
              selected={date}
              onChange={dt => setDate(dt)}
              className="date-filter-unique-picker"
              dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
              locale={profile.language === 'pt-BR' ? pt : en}
            />
          </div>
          <div className="data-filter-unique-icon">
            <FaCalendarAlt size={12} className="data-filter-unique-icon" />
          </div>
        </>
      </div>
      {
        date &&
        <div className="date-filter-unique-cancel-button" onClick={() => { setDate(false) }}><ImCross /></div>
      }
    </>
  )
}
import React from "react";
import { useSelector } from "react-redux";

import { Grade } from "./styles";
import Menu from "~/components/Menu";

import Breadcrumbs from "./Breadcrumbs";
import HomeboxDizimos from "./Grade/contas_a_receber/dizimos_e_ofertas";
import HomeboxEDizimos from "./Grade/contas_a_receber/e_dizimos_e_ofertas";
import HomeboxAgents from "./Grade/cadastros/agents";
import HomeboxFilials from "./Grade/cadastros/filials";
import HomeboxUsers from "./Grade/cadastros/users";
import HomeboxUserGroups from "./Grade/configs/userGroups";
import HomeboxEmail from "./Grade/configs/emails";
import HomeboxTransferencias from "./Grade/contas_a_pagar/transferencias";
import HomeboxPaymentOrder from "./Grade/contas_a_pagar/ordem_de_pagamento";
import HomeboxInvoices from "./Grade/contas_a_pagar/invoices";
import HomeboxBalance from "./Grade/balance/balance";
import HomeboxBalanceBanks from "./Grade/balance/banks";
import HomeboxBanks from "./Grade/cadastros/banks";
import HomeboxChartOfAccounts from "./Grade/cadastros/ChartOfAccounts";
import HomeboxCheckBook from "./Grade/contas_a_receber/CheckBook";
import HomeboxCardBook from "./Grade/contas_a_receber/CardBook";
import HomeBoxSector from "./Grade/configs/Sector";
import HomeBoxOperator from "./Grade/pos/operator";
import HomeboxPDV from "./Grade/pos/pdv";
import HomeboxPDVConciliation from "./Grade/pos/conciliation";
import HomeBoxFixedCoast from "./Grade/contas_a_pagar/fixed_cost";
import HomeboxPurchaseOrder from "./Grade/request_order/purchase_order";

export default function Dashboard() {
  const activeCategory = useSelector(state => state.category);
  const activeHomebox = useSelector(state => state.homebox);
  const { category } = activeCategory;
  const homebox = activeHomebox.homebox
    ? activeHomebox.homebox
    : activeCategory.defHomebox;

  // eslint-disable-next-line
  const contas_a_receber = [
    {
      title: "dizimos_e_ofertas",
      Component: HomeboxDizimos
    },
    {
      title: "e_dizimos",
      Component: HomeboxEDizimos
    },
    {
      title: "carteira_de_cheques",
      Component: HomeboxCheckBook
    },
    {
      title: "carteira_de_cartoes",
      Component: HomeboxCardBook
    }
  ];
  // eslint-disable-next-line
  const contas_a_pagar = [
    {
      title: "transferencias",
      Component: HomeboxTransferencias
    },
    {
      title: "ordem_de_pegamento",
      Component: HomeboxPaymentOrder
    },
    {
      title: "nfs_de_entrada",
      Component: HomeboxInvoices
    },
    {
      title: "custos_fixos",
      Component: HomeBoxFixedCoast
    }
  ];
  // eslint-disable-next-line
  const configs = [
    {
      title: "setores",
      Component: HomeBoxSector
    },
    {
      title: "grupos_de_usuarios",
      Component: HomeboxUserGroups
    },
    {
      title: "usuarios",
      Component: HomeboxUsers
    },
    {
      title: "config_email",
      Component: HomeboxEmail
    },
  ];

  const registrations = [
    {
      title: "agentes",
      Component: HomeboxAgents
    },
    {
      title: "filiais",
      Component: HomeboxFilials
    },
    {
      title: "bancos",
      Component: HomeboxBanks
    },
    {
      title: "plano_de_contas",
      Component: HomeboxChartOfAccounts
    }
  ];
  // eslint-disable-next-line
  const pdv = [
    {
      title: "operadoras",
      Component: HomeBoxOperator
    },
    {
      title: "pdvs",
      Component: HomeboxPDV
    },
    {
      title: "conciliacao_de_cartoes",
      Component: HomeboxPDVConciliation
    }
  ];
  // eslint-disable-next-line
  const balance = [
    {
      title: "caixa_da_filial",
      Component: HomeboxBalance
    },
    {
      title: "extrato_bancario",
      Component: HomeboxBalanceBanks
    },
  ];

  return (
    <>
      <Menu />
      <main>
        <header>
          <Breadcrumbs category={activeCategory.category} />
        </header>
        <Grade>
          {!category
            ? null
            : //eslint-disable-next-line
              eval(category).map((hb, index) => {
                const { Component, title } = hb;
                if (title === homebox) {
                  return <Component key={index} />;
                }
              })}
        </Grade>
      </main>
    </>
  );
}

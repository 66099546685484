import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import { format, parseISO } from "date-fns";

// import allowAccess from "~/scripts/menuController";

// import BgBlack from "~/components/BgBlack";

import FiltroData from "~/components/FiltroData";

import FiltroSelect from "~/components/FiltroSelect";

import {
  FaTimes,
  // FaEdit,
  FaCalendarAlt,
  FaAngleRight,
  FaCheck
} from "react-icons/fa";

import {
  handleEstorno,
  handleDialog,
  formatMoney,
  formatedDate,
  verifyAccess
} from "~/scripts/index.js";

import pt from "date-fns/locale/pt";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import api from "~/services/api";

import Title from "~/components/Title";

import {
  GradeTable,
  GradeHeader,
  GradeBody,
  Dialog,
  Filtros,
  // New,
  ModalDateBaixa,
  Scroll,
  ChequeSelected
} from "./styles";

export default function CheckBook() {
  // const accesses = useSelector(state => state.auth.accesses);
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [checkBook, setCheckBook] = useState([]);
  const [block, setBlock] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [checkBaixar, setCheckBaixar] = useState({});
  const [selected, setSelected] = useState(new Date());
  const accesses = useSelector(state => state.auth.newaccesses);

  const [filtro, setFiltro] = useState({
    date: format(new Date(), "yyyy-MM-dd"),
    option: "all"
  });

  const { date, option } = filtro;

  useEffect(() => {
    async function getCheques() {
      const { data } = await api.get(
        `filtercheques/${option}/${date}/${filial.filial}?page=${page}`
      );
      // console.log(data)

      setCheckBook(data.cheques);
      setPages(data.pages)
      setBlock(false);
    }

    getCheques();
  }, [block, option, date, filial]);

  function baixar(date) {
    setSelected(date);
  }

  function handleOpenDate() {
    setOpenDate(!openDate);
  }

  function handleData({ date }) {
    setFiltro({
      ...filtro,
      date: format(new Date(date), "yyyy-MM-dd")
    });
    setOpenDate(!openDate);
  }

  async function handleBaixar() {
    try {
      await api.delete(
        `/baixa_checks/${checkBaixar.id}/${profile.id}/${selected}`
      );
      setBlock(true);
      setOpenModal(false);
      toast.success(`Baixa realizada com sucesso!`, { className: "success" });
    } catch (err) {
      toast.error(`Falha ao realizar baixa`, { className: "error" });
    }
  }

  function handleShowModal(id, correntista, approved_at, number, value, pre) {
    setCheckBaixar({ id, correntista, approved_at, number, value, pre });

    setOpenModal(true);
  }

  async function handleFiltroSelect(e) {
    if (e === "all") {
      setFiltro({
        date: format(new Date(), "yyyy-MM-dd"),
        option: e
      });
    } else {
      setFiltro({ ...filtro, option: e });
    }
  }

  return (
    <>
      <div className="gradeFiltros">
        <p>Filtros:</p>
        <FiltroData
          openDate={openDate}
          handleOpenDate={handleOpenDate}
          selected={parseISO(date)}
          handleData={handleData}
        />
        <FiltroSelect
          options={[
            { id: "all", title: "Todos" },
            { id: "withLow", title: "Baixados" },
            { id: "withoutDischarge", title: "Não baixados" }
          ]}
          handleFiltroSelect={handleFiltroSelect}
        />
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>Id</td>
            <td>Correntista</td>
            <td>Telefone</td>
            <td>Banco</td>
            <td>Agência</td>
            <td>Conta</td>
            <td>N° Cheque</td>
            <td>Vencimento</td>
            <td>D. Baixa</td>
            <td>Valor</td>
            <td>Pré</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {checkBook && checkBook.length > 0 ? (
            checkBook.map((coc, index) => {
              return (
                <tr
                  key={coc.id}
                  id={coc.id}
                  className={`checkBook ${coc.approved_at ? "approved" : null}`}
                  onClick={() => handleDialog(coc, "checkBook")}
                >
                  <td>
                    {coc.id}

                    {verifyAccess(accesses,'baixar_cheque') ? coc.approved_at ? (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleEstorno(
                              coc.id,
                              coc.approved_at,
                              `Cheque ${coc.number}`,
                              "baixa_checks",
                              setBlock,
                              null,
                              coc.approved_at
                            )
                          }
                        >
                          <FaTimes size={12} /> Estornar
                        </li>
                      </ul>
                    ) : (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleShowModal(
                              coc.id,
                              coc.benef,
                              coc.approved_at,
                              coc.number,
                              coc.value,
                              coc.pre ? `Sim` : `Não`
                            )
                          }
                        >
                          <FaCheck size={12} /> Baixar
                        </li>
                      </ul>
                    ) : null}
                  </td>
                  <td title={coc.benef}>
                    {coc.benef}
                  </td>
                  <td>{coc.phone}</td>
                  <td>{coc.bank}</td>
                  <td>{coc.agency}</td>
                  <td>{coc.account}</td>
                  <td>{coc.number}</td>
                  <td>{formatedDate(coc.date)}</td>
                  <td>
                    {coc.extract_at
                      ? formatedDate(coc.extract_at)
                      : "Não ha baixa"}
                  </td>
                  <td>
                    {formatMoney(coc.value.toFixed(2), filial.country.language)}
                  </td>
                  <td>{coc.pre ? `Sim` : `Não`}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={11}>Não há cheques.</td>
            </tr>
          )}
        </tbody>
      </table>

      {openModal && (
        <Scroll>
          <div className="overlay"></div>
          <ModalDateBaixa>
            <header>
              <Title
                back={true}
                func={() => setOpenModal(false)}
                title={"Cancelar"}
              />
            </header>
            <div className="container">
              <h1>Baixar cheque</h1>
              <div className="form">
                <div className="rowInputs">
                  <label htmlFor="date">
                    <div>
                      <FaCalendarAlt size={20} />
                      <br />
                      Data
                    </div>
                    <DatePicker
                      style={{ border: "none" }}
                      selected={selected}
                      dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                      minDate={new Date(filial.open_date)}
                      onChange={date => baixar(date)}
                      locale={pt}
                    />
                  </label>
                  <button
                    type="submit"
                    className="btnFinish"
                    onClick={() => handleBaixar()}
                  >
                    Finalizar
                    <FaAngleRight size={20} />
                  </button>
                </div>
              </div>
              <ChequeSelected>
                <li>
                  <table>
                    <thead>
                      <tr>
                        <td>Data de Baixa</td>
                        <td>N° Cheque</td>
                        <td>Valor</td>
                        <td>Pré</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{format(new Date(selected), "dd/MM/yyy")}</td>
                        <td>{checkBaixar.number}</td>
                        <td>
                          {formatMoney(
                            checkBaixar.value.toFixed(2),
                            filial.country.language
                          )}
                        </td>
                        <td>{checkBaixar.pre}</td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ChequeSelected>
            </div>
          </ModalDateBaixa>
        </Scroll>
      )}
    </>
  );
}

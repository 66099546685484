import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

// import allowAccess from "~/scripts/menuController";

import { format, parseISO, addDays, startOfMonth, endOfMonth } from 'date-fns';

import { handleDialog, formatMoney, verifyAccess } from "~/scripts";

import api from '~/services/api';

import { file_extension } from "~/assets/";

import Title from "~/components/Title";

import { Form, Input } from "@rocketseat/unform";

import "react-datepicker/dist/react-datepicker.css";

import BuscaOperator from "~/components/BuscaOperator";

import BgBlack from "~/components/BgBlack";

import Despesas from "./Despesas";

import {
  New,
  Loading,
  Scroll, Content, Container,
  Extrato,
  Filters,
  CardLine,
  Card,
  ButtonConciliar,
  Conciliado,
  BoxConciliar,
  Expense,
  ExpenseLine,
} from "./styles";

import {
  FaEye,
  FaFile,
  FaSearch,
  FaTrashAlt,
  FaUniversity,
  FaAngleRight
} from "react-icons/fa";

export default function PDVConciliation() {
  const profile = useSelector(state => state.user.profile);
  const accesses = useSelector(state => state.auth.newaccesses);
  const [loading,setLoading] = useState(false);
  const [opened,setOpened] = useState(false);
  const [newConciliation,setNewConciliation] = useState(false);
  const [editConciliation,setEditConciliation] = useState(false);
  const [files,setFiles] = useState(null);
  const [conciliationInfo,setConciliationInfo] = useState([]);
  const [conciliationList,setConciliationList] = useState([]);
  const [filters,setFilters] = useState({conciliados: true, naoConciliados: true, despesas: true})
  const [totais,setTotais] = useState({conciliados: 0, naoConciliados: 0, despesas: 0})
  const [option,setOption] = useState({});
  const [options,setOptions] = useState([]);
  const [selectedOption,setSelectedOption] = useState(null);
  const [file,setFile] = useState(null);
  const [block,setBlock] = useState(false);
  const [newDespesa,setNewDespesa] = useState(false);
  const [expenses,setExpenses] = useState([]);
  const [uploadFile, setUploadFile] = useState({
    status: false,
    name: "",
    url: ""
  });
  const conciliationDefault = {
    operadora: '',
    operadora_id: '',
    bank_id: '',
    bank: ''
  }
  const [isConcialized,setIsConcialized] = useState(false);
  const [isFinished,setIsFinished] = useState(false);
  const [conciliation,setConciliation] = useState(conciliationDefault);
  const [operadora, setOperadora] = useState(false);

  useEffect(() => {
    async function getConciliations() {
      if(files) {
        const response = await api.get(`card_conciliation/files/${files}`);
        setConciliationInfo(response.data)
        let conciliados = 0;
        let naoConciliados = 0;
        response.data.map(r => {
          if(r.card_parcels_id) {
            conciliados++;
          } else {
            naoConciliados++;
          }
        })
        setTotais({...totais,conciliados, naoConciliados, despesas: expenses.length})
      }
      try {
      const response = await api.get(`/card_conciliation`)
      const { data } = response;
      setConciliationList(data);
      } catch(err) {

      }
    }

    getConciliations();
    setBlock(false)

  },[block])

  useEffect(() => {
    async function loadConciliation() {
      const response = await api.get(`card_conciliation/files/${file}`);
      setConciliationInfo(response.data)
    }
    if(file) {
      loadConciliation();
    }
  },[block])

  async function handleFile(e) {
    const data = new FormData();
    setLoading(true);

    data.append("file", e.target.files[0]);
    const response = await api.post("s3files/" + profile.id, data);
    const { id: s3file_id, path } = response.data;

    let fileResponse = null;

    fileResponse = await api.get(`card_conciliation_csv/${s3file_id}`);
    let conciliation_data = [];
    function transformDate(initial) {
      const info = initial.split(' ');
      const dataInfo = info[0].split('-')
      let month = "01";
      switch(dataInfo[1]) {
        case 'Jan':
          month = "01";
          break;
        case 'Feb':
          month = "02";
          break;
        case 'Mar':
          month = "03";
          break;
        case 'Apr':
          month = "04";
          break;
        case 'May':
          month = "05";
          break;
        case 'Jun':
          month = "06";
          break;
        case 'Jul':
          month = "07";
          break;
        case 'Aug':
          month = "08";
          break;
        case 'Sep':
          month = "09";
          break;
        case 'Oct':
          month = "10";
          break;
        case 'Nov':
          month = "11";
          break;
        case 'Dec':
          month = "12";
          break;
      }
      return dataInfo[2]+"-"+month+"-"+dataInfo[0];
    }
    // console.log('fileResponse.data',fileResponse.data);
    fileResponse.data.map(async (trn,index) => {
      if(index > 0 && trn[0].length > 1) {
        const toAdd = {
        s3file_id:                  s3file_id,
        bank_id:                    conciliation.bank_id,
        operator_id:                conciliation.operadora_id,
        payment_date:               transformDate(trn[0][0]),
        payment_id:                 trn[0][1],
        external_payment_id:        trn[0][2],
        invoice_number:             trn[0][3],
        card_auth_code:             trn[0][4],
        transaction:                trn[0][5],
        note:                       trn[0][6],
        tender:                     trn[0][7],
        card_brand:                 trn[0][8],
        card_number:                trn[0][9],
        card_entry_type:            trn[0][10],
        currency:                   trn[0][11],
        amount:                     trn[0][12],
        tax_amount:                 trn[0][13],
        tip_amount:                 trn[0][14],
        service_charge_amount:      trn[0][15],
        customer_name:              trn[0][16],
        payment_empolyee_id:        trn[0][17],
        payment_empolyee_name:      trn[0][18],
        payment_empolyee_custom_id: trn[0][19],
        order_id:                   trn[0][20],
        order_date:                 transformDate(trn[0][21]),
        order_employee_id:          trn[0][22],
        order_employee_name:        trn[0][23],
        order_employee_custom_id:   trn[0][24],
        result:                     trn[0][25],
        device:                     trn[0][26],
        refunds:                    trn[0][27],
        refund_amount:              trn[0][28],
        created_at:                 new Date(),
        created_by:                 profile.id
        }
        // console.log('toAdd',toAdd)
        const responseCard = await api.post(`card_conciliation/${response.data.id}`, toAdd);
        const { data } = responseCard;
        conciliation_data.push(data)
      }
    })
    setConciliationInfo(conciliation_data)
    setTimeout(() => { setLoading(false); setBlock(true) }, 1500)

    setFile(s3file_id)
  }

  async function handleInput(e) {
    handleFile(e);
    const { name, size } = e.target.files[0];
    setUploadFile({ status: true, name, size, e });
  }

  function setIconFileExtension(file) {
    const ext = file.split(".").pop();
    for (let i = 0; i < file_extension.length; i++) {
      if (ext === file_extension[i].title) {
        return file_extension[i].icon;
      }
    }
  }

  function handleOperadora(info) {
    setOperadora(false)
    setConciliation({ ...conciliation, operadora_id: info.id, operadora: info.name, bank_id: info.bank_id, bank: info.bank });
  }

  function handleNew() {
    setConciliationInfo([]);
    setNewConciliation(true);
    setOpened(true);
  }

  async function handleRemoveConciliacao() {
    setLoading(true)
    try {
      await api.delete(`/card_conciliation/file/${files}`);
      setFile(null)
      setConciliation(conciliationDefault)
      setNewConciliation(false)
      setOpened(false)
    } catch(err) {

    }
    setLoading(false)
    setBlock(true)
  }

  useEffect(() => {
    if(conciliationInfo.length > 0) {
      async function getExpenses() {
        const minDate = format(startOfMonth(parseISO(conciliationInfo[0].order_date)),'yyyy-MM-dd');
        const maxDate = format(endOfMonth(parseISO(conciliationInfo[0].order_date)),'yyyy-MM-dd');
        const bank_id = conciliationInfo[0].bank.id;
        const response = await api.get(`paymentOrders/card_expenses/${bank_id}/${minDate}/${maxDate}`);
        setExpenses(response.data);
      }
      getExpenses();
      let isFinished = true;
      for(let card of conciliationInfo) {
        if(!card.card_parcels_id) {
          isFinished = false;
          break;
        }
      }
      let isConcialized = false;
      for(let card of conciliationInfo) {
        if(card.card_parcels_id) {
          isConcialized = true;
          break;
        }
      }
      setIsFinished(isFinished)
      setIsConcialized(isConcialized)
    }
  },[conciliationInfo])

  async function handleConciliacao(file,action) {
    setFiles(file)
    const response = await api.get(`card_conciliation/files/${file}`);
    setConciliationInfo(response.data)

    setEditConciliation(action === 'edit' ? true : false);
    setOpened(true);
    let conciliados = 0;
    let naoConciliados = 0;
    if(response.data.length > 0) {
      response.data.map(r => {
        if(r.result === 'SUCCESS') {
          if(r.card_parcels_id) {
            conciliados++;
          } else {
            naoConciliados++;
          }
        }
      })
    }
    setTotais({...totais,conciliados, naoConciliados})
  }

  async function handleTypeSelection(index,e,info){
    const inputs = Array.from(document.querySelectorAll('.inputType'));
    inputs.forEach((input,indice) => {
      if(indice !== index) {
        inputs[indice].value = '';
      }
    })
    let response = "";
    if(e === 'CRD'){
      try {
      response = await api.get(`/card_conciliation/search/${info.payment_id}/${format(parseISO(info.payment_date),'yyyy-MM-dd')}/${info.amount}`);
      setOptions(response.data);
      setOption({index,type: e});
      } catch(err) {
        toast.error(
          `Nenhum lançamento encontrado.`,
          { className: "error" }
        );
      }
    }
    if(!response) {
      return;
    }
      if(response.data.length > 0){
        setSelectedOption(response.data[0].id)
      }
  }

  async function handleConciliar(info) {
    let page = '';
    try {
      if(option.type === 'CRD'){
        page = 'card';
      }
      // await api.put(`/${page}/conciliate/${selectedOption}/${info.id}`);
      info.card_parcels_id ?
      await api.put(`/card_conciliation/card_conciliate/${info.id}/${info.card_parcels_id}`)
      :
      await api.put(`/card_conciliation/card_conciliate/${info.id}/${selectedOption}`);
      setBlock(true)
      toast.success(
        `${info.card_parcels_id ? 'Estornado com sucesso!' : 'Conciliado com sucesso!'}`,
        { className: "success" }
      );
    } catch(err) {
      toast.error(
        `${info.card_parcels_id ? 'Movimento conciliado no extrato bancário.' : 'impossível conciliar.'}`,
        { className: "error" }
      );
    }
  }

  function handleDespesa() {
    setNewDespesa(!newDespesa);
  }

  function handle_cancel() {
    setOpened(false);
    setOptions([]);
    setOption([]);
  }

  async function handleRemoveExpense(expense) {
    try {
      await api.delete(`paymentOrders/${expense.id}`)
      setBlock(true)
    } catch(err) {
      toast.error(
        `Movimento conciliado no extrato bancário.`,
        { className: "error" }
      );
    }
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses,'conciliar_cartoes') ?
          <New
            type="button"
            onClick={() => handleNew()}
          >
            Novo +
          </New>
        : null}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>Período</td>
            <td>Operadora</td>
            <td>Nome do Banco</td>
            <td>Conciliado</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          { conciliationList.length > 0 ?
          conciliationList.map((cList,index) => {
            return (<tr key={index}
              id={cList.id}
              onClick={() => handleDialog(cList, "conciliacao")} className={`${cList.conciliado ? 'approved ' : null} conciliacao`}>
            <td>{cList.periodo}
                <ul className="dialog">
                  {cList.conciliado ?
                  <li onClick={() => handleConciliacao(cList.s3file_id,'view')}>
                    <FaEye size={12} /> Visualizar
                  </li>
                  :
                  verifyAccess(accesses,'conciliar_cartoes') ?
                  <li onClick={() => handleConciliacao(cList.s3file_id,'edit')}>
                    Continuar Conciliação
                  </li>
                  : null
                  }
                </ul>
            </td>
            <td>{cList.operadora.name}</td>
            <td>{cList.bank.name}</td>
            <td>{cList.conciliado ? 'Sim' : 'Não'}</td>
            </tr>);
          })
          :
          <tr>
          <td colSpan={4}>Não há conciliações iniciadas.</td>
          </tr>
          }
        </tbody>
      </table>

      { opened ?
        <Scroll>
        <div className="overlay"></div>

        <Content>

          <header>
            <Title
              back={true}
              func={handle_cancel}
              title={"Cancelar"}
            />
          </header>
          <Container>
            {loading ? <Loading>Aguarde, carregando...</Loading>
            :
            <>
            <h1>
              { newConciliation ? 'Nova Conciliação de Cartão de Crédito' : 'Conciliação de Cartão de Crédito'}
              <span>
              { verifyAccess(accesses,'lancar_despesa_conciliacao_cartoes') ? <div className="apagar_conciliacao" onClick={() => handleDespesa()}>+ Despesa</div> : null }
              { !isConcialized && !newConciliation && verifyAccess(accesses,'exclusao_conciliacao_cartoes') ? <div className="apagar_conciliacao" onClick={() => handleRemoveConciliacao()}>Apagar Conciliação</div> : null }
              </span>
            </h1>

            { conciliationInfo.length > 0 ?
            <>
              <Extrato>
                <Filters>
                  <li className={filters.conciliados ? 'active' : null } onClick={() => setFilters({...filters, conciliados: !filters.conciliados})}>Conciliados <small>{totais.conciliados}</small></li>
                  <li className={filters.naoConciliados ? 'active' : null } onClick={() => setFilters({...filters, naoConciliados: !filters.naoConciliados})}>Não conciliados <small>{totais.naoConciliados}</small></li>
                  {/* <li className={filters.despesas ? 'active' : null } onClick={() => setFilters({...filters, despesas: !filters.despesas})}>Despesas <small>{totais.despesas}</small></li> */}
                </Filters>
                { expenses.length > 0 ?
                <ExpenseLine>
                {
                  expenses.map((expense, index) => {
                    return (
                      <Expense className={expense.conciliation_id ? 'conciliado' : ''}>
                        <h2>
                        { verifyAccess(accesses,'lancar_despesa_conciliacao_cartoes') ? <FaTrashAlt style={{cursor: 'pointer',marginRight: '10px'}} onClick={() => handleRemoveExpense(expense)} /> : null } {expense.reason}
                        </h2>
                        <div className="flex-table">
                          <div className="flex-row">
                            <div className="flex-column">
                              <strong>Valor:</strong>
                            </div>
                            <div className="flex-column">
                              <strong>Data:</strong>
                            </div>
                            <div className="flex-column">
                              <strong>Tipo:</strong>
                            </div>
                          </div>
                          <div className="flex-row">
                            <div className="flex-column">
                            {conciliationInfo[0].bank.country.currency}{" "}{formatMoney(expense.total.toFixed(2), conciliationInfo[0].bank.country.language)}
                            </div>
                            <div className="flex-column">
                            {format(parseISO(expense.approved_at.substring(0,10)),profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}

                            </div>
                            <div className="flex-column">
                            {profile.language === 'pt-BR' ? expense.deduct ? 'Deduz do saldo no dia.' : 'Lançamento avulso.' : expense.deduct ? 'Deduct the balance on the day.' : 'Single outbound' }

                            </div>
                          </div>
                        </div>

                      </Expense>);
                  })
                }
                </ExpenseLine>
                : null }
                {conciliationInfo.map((info,indexConciliation) => {

                  return (
                    <div key={indexConciliation}>
                    { info.result === 'SUCCESS' ?

                      filters.conciliados && info.card_parcels_id || filters.naoConciliados && !info.card_parcels_id ?
                      <CardLine>
                        <Card>
                          <h2>
                          ID: {info.payment_id}
                          </h2>
                          <div className="flex-table">
                            <div className="flex-row">
                              <div className="flex-column">
                                <strong>Valor:</strong>
                              </div>
                              <div className="flex-column">
                                <strong>Data:</strong>
                              </div>
                              <div className="flex-column">
                                <strong>Pagamento:</strong>
                              </div>
                            </div>
                            <div className="flex-row">
                              <div className="flex-column">
                              {info.bank ? info.bank.country.currency : ""}{" "}{formatMoney(typeof info.amount === 'number' ? info.amount.toFixed(2) : info.amount, info.bank ? info.bank.country.language : "")}
                              </div>
                              <div className="flex-column">
                              {format(parseISO(info.payment_date.substring(0,10)),profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}

                              </div>
                              <div className="flex-column">
                              {format(addDays(parseISO(info.order_date.substring(0,10)),1),profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}

                              </div>
                            </div>
                          </div>
                        </Card>
                        <ButtonConciliar>
                          <FaAngleRight size={20} />
                        </ButtonConciliar>
                        {info.card_parcels_id ?
                        <Conciliado>
                          {info.bank_conciliation_id ?
                          <div>Conciliado no banco!</div>
                          :
                          <>
                          Conciliado!
                          <br/><div className='small' onClick={() => { handleConciliar(info) }}>Estornar</div>
                          </>
                          }
                        </Conciliado>
                        :
                          <BoxConciliar>
                            <ul>
                              <li className="active"><FaSearch size={16} />
                              &nbsp;&nbsp; Buscar
                              </li>
                              {/* <li>
                                <FaPlusSquare size={16} />&nbsp;&nbsp; Lançar
                              </li> */}
                            </ul>
                            <div>
                              <select className='inputType' onChange={e => handleTypeSelection(indexConciliation,e.target.value,info)}>
                                <option value="">Selecione...</option>
                                <option value="CRD">Lançamento Manual</option>
                              </select>
                              { option.index === indexConciliation && options.length > 0 ?
                              <select onChange={e => setSelectedOption(e.target.value)}>
                                {
                                option.index === indexConciliation ? options.map(opt => {
                                  return <option key={opt.id} value={opt.id}>Data: {format(parseISO(opt.date),info.bank.country.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}
                                  - Valor: {info.bank.country.currency}{" "}{formatMoney(opt.value.toFixed(2), info.bank.country.language)} - ID: {opt.payment_id}</option>
                                }) : null
                                }
                              </select>
                              : null }
                              { option.index === indexConciliation && options.length > 0 ?
                              <button className="buttonConciliar" onClick={() => handleConciliar(info)}>
                                Conciliar
                              </button>
                              : null }
                            </div>
                          </BoxConciliar>
                        }
                      </CardLine>
                      : null
                    : null }
                    </div>
                  );
                })}
              </Extrato>
            </>
            :
            <Form>
              <div className="rowInputs">

                <label htmlFor="operator">
                  <div>
                    <FaFile size={20} />
                    <br />
                    Oper.
                  </div>
                  <Input
                    name="operator"
                    placeholder="Operadora"
                    readOnly={"readonly"}
                    value={conciliation.operadora}
                  />
                </label>
                <button
                  type="button"
                  className="btnFinish"
                  onClick={() => setOperadora(true)}
                >
                  Buscar <FaSearch size={14} />
                </button>

                <label htmlFor="bank">
                  <div>
                    <FaUniversity size={20} />
                    <br />
                    Banco
                  </div>
                  <Input
                  name="bank"
                  readOnly={true}
                  value={conciliation.bank}
                  />
                </label>
                { conciliation.operadora ?
                <>
                <input
                  type="file"
                  id="file_csv"
                  name="files"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={e => handleInput(e)}
                />
                <button
                  type="button"
                  className="inputFile"
                  onClick={() => document.getElementById("file_csv").click()}
                    >
                <img
                  src={setIconFileExtension("csv")}
                  alt=""
                  style={{ height: "53px", width: "53px" }}
                />
                </button>
                </>
                : null }
              </div>
            </Form>
            }
            </> }
          </Container>
        </Content>
        </Scroll>
      : null }


      {
      newDespesa ?
      <>
      <BgBlack zIndex={13}/>
      <Despesas handleDespesa={handleDespesa} conciliationInfo={conciliationInfo} setBlock={setBlock} />
      </>
      : null
      }


      { operadora ?
      <BuscaOperator
        handlerOperator={handleOperadora}
        setOperatorSearch={setOperadora}
        show={operadora}
      />
      : null }
    </>
  );
}

import api from '~/services/api';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

export async function handleBlock(id,canceled_at,field,pathApi,setBlock){
  const options = {
    title: canceled_at !== null ? 'Desbloqueio' : 'Bloqueio',
    message: `Você tem certeza que deseja ${canceled_at !== null ? 'desbloquear' : 'bloquear'} - ${field}?`,
    buttons: [
      {
        label: 'Sim',
        onClick: async () => {
          try {
            await api.delete(`${pathApi}/${id}`);
            toast.success(`${field} ${canceled_at !== null ? 'desbloqueado' : 'bloqueado'} com sucesso!`,{ className: 'success' });
            setBlock(true);
            return;
          } catch(err) {
            toast.error(`Este ${field} não pôde ser ${canceled_at !== null ? 'desbloqueado' : 'bloqueado'}.`,{ className: 'error' })
            console.log(err)
          }
        }
      },
      {
        label: 'Cancelar'
      }
    ],
  }

  confirmAlert(options)
  setBlock(true);
}

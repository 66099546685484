import produce from "immer";

import { format, addDays, parseISO } from "date-fns";

const INITIAL_STATE = {
  filial: null,
  filial_name: "",
  open_date: null,
  country: null,
  initialBalance: null
};

export default function filial(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case "@auth/LOGIN_SUCCESS": {
        draft.filial = action.payload.user.filial.id;
        draft.filial_name = action.payload.user.filial.name;
        draft.open_date = format(
          addDays(
            new Date(action.payload.user.filial.filialBalance.period_end),
            2
          ),
          "yyyy-MM-dd"
        );
        draft.country = action.payload.user.filial.countryFilial;
        draft.initialBalance = action.payload.user.filial.initialBalance;
        break;
      }
      case "@filial/UPDATE": {
        draft.filial = action.payload.filial.filial;
        draft.filial_name = action.payload.filial.filial_name;
        draft.open_date = action.payload.filial.open_date;
        draft.country = action.payload.filial.country;
        draft.initialBalance = action.payload.filial.initialBalance;
        break;
      }
      default:
    }
  });
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import allowAccess from "~/scripts/menuController";
import retLanguage from '~/config/language';
import { verifyAccess } from "~/scripts";

import { updateHomebox } from "~/store/modules/homebox/actions";

import { Container } from "./styles";

import { FaAngleRight } from "react-icons/fa";

export default function Breadcrumbs({ category }) {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const lang = retLanguage(profile.language)
  const activeCategory = useSelector(state => state.category);
  const activeHomebox = useSelector(state => state.homebox);
  const accesses = useSelector(state => state.auth.accesses);
  const newaccesses = useSelector(state => state.auth.newaccesses);

  const homebox = activeHomebox.homebox
    ? activeHomebox.homebox
    : activeCategory.defHomebox;

  // eslint-disable-next-line
  const contas_a_receber = {
    title: lang.menu.h2,
    data: [
      {
        title: lang.menu.breadcrumbs.h2.p1,
        homebox: "dizimos_e_ofertas"
      },
      {
        title: lang.menu.breadcrumbs.h2.p2,
        homebox: "e_dizimos"
      },
      {
        title: lang.menu.breadcrumbs.h2.p3,
        homebox: "carteira_de_cheques"
      },
      {
        title: lang.menu.breadcrumbs.h2.p4,
        homebox: "carteira_de_cartoes"
      }
    ]
  };

  // eslint-disable-next-line
  const contas_a_pagar = {
    title: lang.menu.h3,
    data: [
      {
        title: lang.menu.breadcrumbs.h3.p1,
        homebox: "transferencias"
      },
      {
        title: lang.menu.breadcrumbs.h3.p2,
        homebox: "ordem_de_pegamento"
      },
      {
        title: lang.menu.breadcrumbs.h3.p3,
        homebox: "nfs_de_entrada"
      },
      {
        title: lang.menu.breadcrumbs.h3.p4,
        homebox: "custos_fixos"
      }
    ]
  };

  const request_order = {
    title: "Solicitações",
    data: [
      {
        title: "Solicitação de Compra",
        homebox: "purchase_order"
      }
    ]
  };

  // eslint-disable-next-line
  const balance = {
    title: lang.menu.h7,
    data: [
      {
        title: lang.menu.breadcrumbs.h7.p1,
        homebox: "caixa_da_filial"
      },
      {
        title: lang.menu.breadcrumbs.h7.p2,
        homebox: "extrato_bancario"
      },
    ]
  };

  // eslint-disable-next-line
  const configs = {
    title: lang.menu.h5,
    data: [
      {
        title: lang.menu.breadcrumbs.h5.p3,
        homebox: "setores"
      },
      {
        title: lang.menu.breadcrumbs.h5.p1,
        homebox: "grupos_de_usuarios"
      },
      {
        title: lang.menu.breadcrumbs.h4.p3,
        homebox: "usuarios"
      },
      {
        title: lang.menu.breadcrumbs.h5.p2,
        homebox: "config_email"
      },
    ]
  };

  const registrations = {
    title: lang.menu.h4,
    data: [
      {
        title: lang.menu.breadcrumbs.h4.p1,
        homebox: "agentes"
      },
      {
        title: lang.menu.breadcrumbs.h4.p2,
        homebox: "filiais"
      },
      {
        title: lang.menu.breadcrumbs.h4.p4,
        homebox: "bancos"
      },
      {
        title: lang.menu.breadcrumbs.h4.p5,
        homebox: "plano_de_contas"
      }
    ]
  };
  // eslint-disable-next-line
  const pdv = {
    title: lang.menu.h6,
    data: [
      {
        title: lang.menu.breadcrumbs.h6.p1,
        homebox: "operadoras"
      },
      {
        title: lang.menu.breadcrumbs.h6.p2,
        homebox: "pdvs"
      },
      {
        title: lang.menu.breadcrumbs.h6.p3,
        homebox: "conciliacao_de_cartoes"
      }
    ]
  };

  function handleHomebox(activeHomebox) {
    dispatch(updateHomebox(activeHomebox));
  }

  return category ? (
    <Container>
      <li>
        {
          // eslint-disable-next-line
          eval(category).title
        }{" "}
        <FaAngleRight color="#555" size={20} />
      </li>
      {// eslint-disable-next-line
      eval(category).data.map((menu, index) =>
      verifyAccess(newaccesses,menu.homebox) ? (
          <li
            key={index}
            className={
              menu.homebox === homebox
                ? "active"
                : ""
            }
          >
            <button type="button" onClick={() => handleHomebox(menu.homebox)}>
              {menu.title}
            </button>
          </li>
        ) : null
      )}
    </Container>
  ) : null;
}

Breadcrumbs.propTypes = {
  category: PropTypes.string
};

Breadcrumbs.defaultProps = {
  category: "contas_a_receber"
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import api from "~/services/api";

import { parseISO } from "date-fns";

import pt from "date-fns/locale/pt";

import { toast } from "react-toastify";

import { FaCheck, FaTimes } from "react-icons/fa";

import { Content, Container, Scroll } from "./styles";

import { formatMoney, formatedDate, handleDialog } from "~/scripts/index.js";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Title from "~/components/Title";

export default function InvoiceParcels({
  invoice,
  handleOpenInvoiceParcels,
  setBlock,
  block
}) {
  const filial = useSelector(state => state.filial);
  const profile = useSelector(state => state.user.profile);
  // const filial = useSelector(state => state.filial);

  const [parcels, setParcels] = useState([]);
  useEffect(() => {
    setParcels(invoice.parcels_array);
  }, [invoice.parcels_array]);

  useEffect(() => {
    async function loadParcels() {
      const response = await api.get("/invoice/id/" + invoice.id);
      const { data } = response;
      if (data) {
        setParcels(data.parcels_array);
      }
    }
    loadParcels();
  }, [block, invoice.id]);

  async function approveParcel(parcel, i) {
    try {
      const { data } = await api.put("invoice/parcels/approve/" + parcel.id, {
        approved_at: parcels[i].approved_at
          ? new Date(parcels[i].approved_at)
          : new Date()
      });
      if (data.approved_at) {
        toast.success("Parcela aprovada com sucesso!", {
          className: "success"
        });
      } else {
        toast.success("Parcela estornada com sucesso!", {
          className: "success"
        });
      }
      setBlock(true);
      return;
    } catch (err) {
      toast.error("Esta Parcela não pode ser aprovada no momento.", {
        className: "error"
      });
    }
  }

  function changed(e, i, item) {
    const newparcels = JSON.parse(JSON.stringify(parcels));

    if (item === "document") {
    } else if (item === "approved_at") {
      newparcels[i].approved_at = e;
    }

    setParcels(newparcels);
  }

  return (
    <>
      <Scroll>
        <Content>
          <header>
            <Title
              back={true}
              func={handleOpenInvoiceParcels}
              title={"Cancelar"}
            />
          </header>
          <Container>
            <h1>Parcelas da NF: </h1>
            <table className="gradeTable">
              <thead className="gradeHeader">
                <tr>
                  <td>Status</td>
                  <td>Data de Baixa</td>
                  <td>Parcela</td>
                  <td>Vencimento</td>
                  <td>Valor</td>
                  <td>Desconto</td>
                  <td>Acréscimo</td>
                  <td>Juros</td>
                  <td>Impostos</td>
                  <td>Total</td>
                  <td></td>
                </tr>
              </thead>
              <tbody className="gradeBody">
                {parcels.length > 0
                  ? parcels.map((parcel, index) => (
                      <tr
                        key={parcel.parcel}
                        onClick={() => handleDialog(parcel, "parcels")}
                        className={
                          parcel.approved ? "parcels approved" : "parcels"
                        }
                        id={parcel.id}
                      >
                        <td>{parcel.approved ? "Baixado" : "Pendente..."}</td>
                        <td>
                          {parcel.approved ? (
                            formatedDate(parcel.approved_at)
                          ) : (
                            <DatePicker
                              name="date"
                              selected={
                                parcel.approved_at ? parcel.approved_at : null
                              }
                              minDate={parseISO(filial.open_date)}
                              onChange={date =>
                                changed(date, index, "approved_at")
                              }
                              placeholderText="Escolher data de baixa"
                              dateFormat={
                                profile.language === "pt-BR"
                                  ? "dd/MM/yyyy"
                                  : "MM/dd/yyyy"
                              }
                              locale={pt}
                            />
                          )}
                        </td>
                        <td>{parcel.parcel}</td>
                        <td>{formatedDate(parcel.due_date)}</td>
                        <td>
                          {formatMoney(
                            parcel.value.toFixed(2),
                            filial.country.language
                          )}
                        </td>
                        <td>
                          {formatMoney(
                            parcel.discount.toFixed(2),
                            filial.country.language
                          )}
                        </td>
                        <td>
                          {formatMoney(
                            parcel.addition.toFixed(2),
                            filial.country.language
                          )}
                        </td>
                        <td>
                          {formatMoney(
                            parcel.interest.toFixed(2),
                            filial.country.language
                          )}
                        </td>
                        {/* <td>
                      <Input
                      name={"discount_"+parcel.parcel }
                      value={formatMoney(parcel.discount.toFixed(2),filial.country.language)}
                      onChange={e => parcel.discount = formatNum(e.target.value)}/>
                    </td>
                    <td>
                      <Input
                      name={"addition_"+parcel.parcel }
                      value={formatMoney(parcel.addition.toFixed(2),filial.country.language)}/>
                    </td>
                    <td>
                      <Input
                      name={"interest_"+parcel.parcel }
                      value={formatMoney(parcel.interest.toFixed(2),filial.country.language)}/>
                    </td> */}
                        <td>
                          {formatMoney(
                            parcel.tax_total.toFixed(2),
                            filial.country.language
                          )}
                        </td>
                        <td>
                          {formatMoney(
                            parcel.value.toFixed(2),
                            filial.country.language
                          )}
                        </td>
                        <td>
                          <button
                            className="btn"
                            disabled={
                              !parcel.approved_at || parcel.concialized_at
                            }
                            // readOnly
                            onClick={() => approveParcel(parcel, index)}
                          >
                            {!parcel.approved ? (
                              <>
                                <FaCheck size={12} /> Baixar
                              </>
                            ) : (
                              <>
                                <FaTimes size={12} /> Estornar
                              </>
                            )}
                          </button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </Container>
        </Content>
      </Scroll>
    </>
  );
}

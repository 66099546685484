import { combineReducers } from "redux";

import auth from "./auth/reducer";
import user from "./user/reducer";
import homebox from "./homebox/reducer";
import category from "./category/reducer";
import filial from "./filial/reducer";
import functionalities from "./functionalities/reducer";
import selectedFilials from './selectedFilials/reducer'

export default combineReducers({
  auth,
  user,
  homebox,
  category,
  filial,
  functionalities,
  selectedFilials
});

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import api from "~/services/api";

import {
  FaEdit,
} from "react-icons/fa";

import {
  handleDialog,
  verifyAccess,
} from "~/scripts/index.js";

import {
  New,
} from "./styles";

import BgBlack from "~/components/BgBlack";

import CreateEmail from "./CreateSMTP";

export default function Emails() {
  const profile = useSelector(state => state.user.profile);
  const accesses = useSelector(state => state.auth.newaccesses);
  const [createSmtp, setCreateSmtp] = useState(false);
  const [smtps, setSmtps] = useState([]);
  const [block, setBlock] = useState(false);
  const [id, setID] = useState(null);

  useEffect(() => {
    async function getSmtp() {
      const { data } = await api.get(`email`);
      setSmtps(data);
      setBlock(false);
    }

    getSmtp();
  }, [block]);

  function handlerCreateSmtp(id, edit) {
    setCreateSmtp(edit);
    setID(id);
  }

  function handlerExitCreateSMTP() {
    setCreateSmtp(false);
    setBlock(true);
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses,'cadastro_email') ?
          <New type="button" onClick={() => handlerCreateSmtp(null, true)}>
            Novo +
          </New>
        : null}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>Host</td>
            <td style={{ width: "80px" }}>Porta</td>
            <td>Usuário</td>
            <td>Senha</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {smtps.length > 0 ? (
            smtps.map(smtp => {
              return (
                <tr
                  key={smtp.id}
                  onClick={() => handleDialog(smtp, "smtp")}
                  className="smtp"
                  id={smtp.id}
                >
                  <td>
                    {smtp.host}
                    {verifyAccess(accesses,'cadastro_email') ? <ul className="dialog">
                      <li onClick={() => handlerCreateSmtp(smtp.id, true)}>
                        <FaEdit size={12} /> Alterar
                      </li>
                    </ul> : null }
                  </td>
                  <td style={{ width: "80px" }}>{smtp.port}</td>
                  <td>{smtp.user}</td>
                  <td>{smtp.pass}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={2}>Não há servidores SMTP configurados</td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
      {createSmtp && (
        <>
          <BgBlack />
          <CreateEmail
            id={id}
            handlerCreateEmail={setCreateSmtp}
            handlerExitCreateSMTP={handlerExitCreateSMTP}
          />
        </>
      )}
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "~/store/modules/auth/actions";
import { FaExchangeAlt, FaCheckSquare, FaSquare } from "react-icons/fa";
import { parseISO, format, addDays } from "date-fns";
import api from "~/services/api";
import { maskCGC } from "~/scripts";

import {
  Container,
  Main,
  ChangeFilial,
  FilialList,
  Filial,
  Scroll
} from "./styles";
import { updateFilial } from "~/store/modules/filial/actions";

export default function Filials({ title }) {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [filialDefault, setFilialDefault] = useState([]);
  const [visible, setVisible] = useState(false);
  const [filials, setFilials] = useState([]);
  const [isAuth, setIsAuth] = useState(true);

  async function handleFilial(fil) {
    const responseBalance = await api.get(`/balance/closed/${fil.id}`);

    const { data } = responseBalance;
    dispatch(
      updateFilial({
        filial: fil.id,
        filial_name: fil.name,
        open_date: format(addDays(parseISO(data.period_end), 1), "yyyy-MM-dd"),
        country: fil.countryFilial,
        initialBalance: fil.initialBalance
      })
    );
    setVisible(false);
  }

  useEffect(() => {
    async function loadFilials() {
      try {
        const response = await api.get(`filiais/${filial.filial}`);

        const { data } = response;

        setFilialDefault(data);
        if (!filial.open_date) {
          handleFilial(data[0]);
        }
      } catch (err) {
        setIsAuth(false);
      }
    }
    loadFilials();
  }, [profile.filial_id, visible, filial]);

  useEffect(() => {
    // async function openFilials() {
    //   const { data } = await api.get(`filiais/controller/1/1`);
    //   setFilials(data);
    // }
    if (!isAuth) {
      dispatch(logout());
    }
    if (profile.filiais && profile.group_id !== 1) {
      setFilials(profile.filiais);
    } else if (profile.group_id === 1) {
      setFilials(profile.filiais);
    }
  }, [isAuth]);

  return (
    <>
      {filialDefault.map((filiald, index) => (
        <Container key={index} title={title}>
          {filials.length > 0 ? (
            <ChangeFilial onClick={() => setVisible(!visible)}>
              <FaExchangeAlt size={22} />
            </ChangeFilial>
          ) : null}
          <Main>
            <strong>
              {filial.country ? (
                <img
                  src={`/images/${filial.country.code}.svg`}
                  width="12"
                  title={filial.country.name}
                />
              ) : null}{" "}
              {`${filiald.name} ${filiald.state ? ` | ${filiald.state}` : ""} `}
            </strong>
            {filiald.cgc ? maskCGC(filiald.cgc) : null}
          </Main>
          <FilialList visible={visible}>
            <Scroll>
              {filials.map(fil => {
                if (!fil.filial) {
                  return <></>;
                }
                return (
                  <Filial
                    onClick={() => handleFilial(fil.filial)}
                    key={fil.filial_id}
                  >
                    {fil.filial_id === filial.filial.id ? (
                      <FaCheckSquare color="#CCC" size={20} />
                    ) : (
                      <FaSquare color="#CCC" size={20} />
                    )}
                    <div key={fil.filial_id}>
                      <p>
                        {fil.filial && fil.countryFilial ? (
                          <img
                            src={`/images/${fil.countryFilial.code}.svg`}
                            width="12"
                            title={fil.countryFilial.name}
                          />
                        ) : null}{" "}
                        {fil.filial.name} | {fil.filial.state}
                        <br />
                        {maskCGC(fil.filial.cgc)}
                      </p>
                    </div>
                  </Filial>
                );
              })}
            </Scroll>
          </FilialList>
        </Container>
      ))}
    </>
  );
}

import styled, { css } from 'styled-components';

import { lighten } from 'polished';

import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div`
  position:relative;
`;

export const Badge = styled.button`
  background:none;
  border:0;
  position:relative;

  ${props => props.hasUnread && css `
    &::after {
      position:absolute;
      right:0;
      top:0;
      width:8px;
      height:8px;
      background:#9cd72e;
      content:'';
      border-radius:50%;
    }
  `}

`;

export const NotificationList = styled.div`
  position:absolute;
  z-index:5;
  left:calc(50% - 145px);
  width:290px;
  top:calc(100% + 20px);
  background: #fff;
  border:1px solid #ccc;
  border-radius:4px;
  padding: 15px 5px;
  display: ${props => props.visible ? 'block' : 'none' };

  &::before {
    content:'';
    position:absolute;
    left:calc(50% - 20px);
    top: -20px;
    width:0;
    height:0;
    border-left:20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height:260px;
  padding:5px 15px;
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color:#222;

  & + div {
    margin-top:15px;
    padding-top:15px;
    border-top:1px solid #ddd;
  }

  p {
    font-size:13px;
    line-height:18px;
    margin-bottom:3px;
  }

  time {
    display:block;
    font-size:12px;
    opacity:.6;
    margin-bottom:3px;
  }

  button {
    font-size:12px;
    border:0;
    background: none;
    color: ${lighten(0.2,'#222')};
  }

  /* ${props => props.unread && css ` */
    /* &::after {
      content: '';
      display:inline-block;
      width:8px;
      height:8px;
      background: #9cd72e;
      border-radius:50%;
      margin-left:10px;
    } */
  /* `} */
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  padding: 0 5px;

  button {
    font-size: 10px;
  }
`;

import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import allowAccess from "~/scripts/menuController";

import { FaEye, FaTimes, FaEdit } from "react-icons/fa";

import { handleBlock, handleDialog, verifyAccess } from "~/scripts/index.js";

import api from "~/services/api";

import CreateOperator from "./CreateOperator";

import {
  New
} from "./styles";

export default function Operator() {
  const profile = useSelector(state => state.user.profile);
  const accesses = useSelector(state => state.auth.newaccesses);
  const [operators, setOperators] = useState([]);
  const [block, setBlock] = useState(false);
  const [createOperator, setCreateOperator] = useState(false);
  const [editOperator, setEditOperator] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    async function initOperator() {
      const response = await api.get("operator");
      setOperators(response.data);
      setBlock(false);
    }
    initOperator();
  }, [block]);

  function handleOpenCreateOperator(id, open, edite) {
    setCreateOperator(open);
    setId(id);
    setEditOperator(edite);
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses,'cadastro_operadora') ?
          <New
            type="button"
            onClick={() => handleOpenCreateOperator(null, true, true)}
          >
            Novo +
          </New>
        : null}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>Id</td>
            <td>Nome da Operadora</td>
            <td>Nome do Banco</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {operators && operators.length > 0 ? (
            operators.map((operator, index) => {
              return (
                <tr
                  key={operator.id}
                  id={operator.id}
                  className={`chartOfAccouts ${
                    operator.canceled_at ? "canceled" : null
                  }`}
                  onClick={() => handleDialog(operator, "chartOfAccouts")}
                >
                  <td>
                    {operator.id}
                    {verifyAccess(accesses,'exclusao_operadoras') ? operator.canceled_at ? (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleBlock(
                              operator.id,
                              operator.canceled_at,
                              "Operadora",
                              "operator",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Desbloquear
                        </li>
                      </ul>
                    ) : (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleOpenCreateOperator(operator.id, true, false)
                          }
                        >
                          <FaEye size={12} /> Visualizar
                        </li>
                        { verifyAccess(accesses,'cadastro_operadora') ? <li
                          onClick={() =>
                            handleOpenCreateOperator(operator.id, true, true)
                          }
                        >
                          <FaEdit size={12} /> Alterar
                        </li> : null }
                        { verifyAccess(accesses,'exclusao_operadoras') ? <li
                          className="trash"
                          onClick={() =>
                            handleBlock(
                              operator.id,
                              operator.canceled_at,
                              "Operadora",
                              "operator",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Bloquear
                        </li> : null }
                      </ul>
                    ) : null}
                  </td>
                  <td>{operator.name}</td>
                  <td>{operator.bank.name}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={3}>Não há operadoras cadastradas.</td>
            </tr>
          )}
        </tbody>
      </table>
      {createOperator && (
        <CreateOperator
          setCreateOperator={setCreateOperator}
          edit={editOperator}
          id={id}
          setBlock={setBlock}
        />
      )}
    </>
  );
}

import { takeLatest, put, all } from "redux-saga/effects";
import { updateFilial } from "./actions";

export function update({ payload }) {
  const {
    filial,
    filial_name,
    open_date,
    country,
    initialBalance
  } = payload.filial;

  put(
    updateFilial({ filial, filial_name, open_date, country, initialBalance })
  );
}

export default all([takeLatest("@filial/UPDATE", update)]);

import React, { useState, useEffect } from "react";

import { Content, Container, Scroll, Back } from "./styles";

import { FaRegAddressCard, FaArrowLeft, FaFile } from "react-icons/fa";

import { Form, Input } from "@rocketseat/unform";

import Title from "~/components/Title";

import api from "~/services/api";

export default function BuscaOperator({
  show,
  handlerOperator,
  setOperatorSearch
}) {
  const [operatorDate, setOperatorDate] = useState({ code: "", name: "" });

  const [operators, setProviders] = useState([]);

  useEffect(() => {
    async function getOperadoras() {
      try {
        const response = await api.get(
          "/operatorsearch?code=" +
            operatorDate.code +
            "&name=" +
            operatorDate.name
        );
        const { data } = response;
        setProviders(data);
      } catch (err) {
        setProviders([]);
      }
    }
    getOperadoras();
  }, [show, operatorDate.code, operatorDate.name]);

  return (
    <Scroll style={!show ? { display: "none" } : null}>
      <Content style={{ maxWidth: "1100px", left: "calc(50% - 550px)" }}>
        <header>
          <Title back={true} func={setOperatorSearch} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Buscar Operadora</h1>
          <Form>
            <div className="rowInputs">
              <label htmlFor="code">
                <div>
                  <FaRegAddressCard size={20} />
                  <br />
                  Código
                </div>
                <Input
                  name="code"
                  placeholder="Código da operadora"
                  onChange={e =>
                    setOperatorDate({
                      ...operatorDate,
                      code: e.target.value
                    })
                  }
                  value={operatorDate.code}
                />
              </label>
              <label htmlFor="name">
                <div>
                  <FaFile size={20} />
                  <br />
                  Nome
                </div>
                <Input
                  name="name"
                  placeholder="Nome da operadora"
                  maxLength={30}
                  value={operatorDate.name}
                  onChange={e =>
                    setOperatorDate({
                      ...operatorDate,
                      name: e.target.value
                    })
                  }
                />
              </label>
            </div>

            {operators.length > 0
              ? operators.map((p,index) => (
                  <div className="rowInputs" key={index}>
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() =>
                        handlerOperator({
                          id: p.id,
                          name: p.name,
                          bank_id: p.bank_id,
                          bank: p.bank.name
                        })
                      }
                    >
                      Selecionar
                    </button>
                    <label htmlFor="code">
                      <div>
                        <FaRegAddressCard size={20} />
                        <br />
                        Código
                      </div>
                      <Input readOnly name="code" value={p.id} />
                    </label>
                    <label htmlFor="name">
                      <div>
                        <FaFile size={20} />
                        <br />
                        Nome.
                      </div>
                      <Input readOnly name="name" value={p.name} />
                    </label>
                    <label htmlFor="bank">
                      <div>
                        <FaFile size={20} />
                        <br />
                        Banco
                      </div>
                      <Input readOnly name="bank" value={p.bank.name} />
                    </label>
                  </div>
                ))
              : null}
          </Form>
        </Container>
      </Content>
    </Scroll>
  );
}

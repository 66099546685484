import React, { useState, useEffect } from "react";

import { Content, Container, Scroll, Title, Back } from "./styles";

import api from "~/services/api";

import { FaRegAddressCard, FaArrowLeft } from "react-icons/fa";

import { Form, Input } from "@rocketseat/unform";

function BuscarNatureza({ handleNature, setShowSearch }) {
  const [natureSearch, setNatureSearch] = useState({ id: "", name: "" });

  const [nature, setNature] = useState([]);

  useEffect(() => {
    async function getNature() {
      try {
        const response = await api.get(
          "naturesearch/?id=" + natureSearch.id + "&nome=" + natureSearch.name
        );

        const { data } = response;

        setNature(data);
      } catch (err) {
        setNature([]);
      }
    }
    getNature();
  }, [natureSearch.id, natureSearch.name]);

  return (
    <Scroll>
      <Content>
        <header>
          <Title>
            <Back type="button" onClick={() => setShowSearch(false)}>
              <FaArrowLeft size={14} />
            </Back>
            Cancelar
          </Title>
        </header>
        <Container>
          <h1>Buscar Natureza</h1>
          <Form initialData={{}}>
            <div className="rowInputs">
              <label htmlFor="name">
                <div>
                  <FaRegAddressCard size={20} />
                  <br />
                  Lanç.
                </div>
                <Input
                  name="id"
                  maxLength={30}
                  placeholder="Número de lançamento da natureza"
                  value={natureSearch.id}
                  onChange={e =>
                    setNatureSearch({
                      ...natureSearch,
                      id: e.target.value
                    })
                  }
                />
              </label>
              <label htmlFor="name">
                <div>
                  <FaRegAddressCard size={20} />
                  <br />
                  Nome
                </div>
                <Input
                  name="name"
                  maxLength={30}
                  placeholder="Nome da natureza"
                  value={natureSearch.name}
                  onChange={e =>
                    setNatureSearch({
                      ...natureSearch,
                      name: e.target.value
                    })
                  }
                />
              </label>
            </div>
            <hr />

            {nature.length > 0
              ? nature.map(p => (
                  <div className="rowInputs">
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() =>
                        handleNature({
                          nome: p.nome,
                          tax_nature_id: p.id
                        })
                      }
                    >
                      Selecionar
                    </button>
                    <label htmlFor="id">
                      <div>
                        <FaRegAddressCard size={20} />
                        <br />
                        Lanç.
                      </div>
                      <Input readOnly name="id" value={p.id} />
                    </label>
                    <label htmlFor="nome">
                      <div>
                        <FaRegAddressCard size={20} />
                        <br />
                        Nome
                      </div>
                      <Input readOnly name="nome" value={p.nome} />
                    </label>
                  </div>
                ))
              : null}
          </Form>
        </Container>
      </Content>
    </Scroll>
  );
}

export default BuscarNatureza;

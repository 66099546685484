import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import history from "~/services/history";
import api from "~/services/api";

import { loginSuccess, loginFailure, registerFailure } from "./actions";

export function* login({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, "sessions", {
      email,
      password
    });

    const { token, user } = response.data;

    if (!user.filial_id) {
      toast.error("Usuário sem filial padrão definida.");
      return;
    }

    if (!user.filial.countryFilial.id) {
      toast.error("Filial do usuário sem país padrão definido.");
      return;
    }

    if (!user.group_id) {
      toast.error("Usuário sem grupo definido.");
      return;
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;

    // const respMenus = yield call(api.get, "menu/group/" + user.group_id);
    const respMenus2 = yield call(api.get, "GroupHierarchy/Group/" + user.group_id);
    // console.log(respMenus.data)

    // const { data } = respMenus;

    let accesses = [];

    // data.map(access => {
    //   const acs = { ...access, name: access.menu.name };
    //   return accesses.push({
    //     name: acs.name,
    //     permissions: {
    //       read: acs.read,
    //       create: acs.create,
    //       edit: acs.edit,
    //       delete: acs.delete,
    //       approve: acs.approve
    //     }
    //   });
    // });

    const { data: newaccess } = respMenus2;

    yield put(loginSuccess(token, user, accesses, newaccess));

    history.push("/dashboard");
  } catch (err) {
    toast.error("Falha na autenticação, verifique seus dados.");

    yield put(loginFailure());
  }
}

export function logout() {
  history.push("/");
}

export function* register({ payload }) {
  try {
    const { nome, email, password } = payload;

    yield call(api.post, "users", {
      nome,
      email,
      password,
      provider: true
    });

    history.push("/");
  } catch (err) {
    toast.error("Falha no cadastro, verifique os dados!");

    yield put(registerFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/LOGIN_REQUEST", login),
  takeLatest("@auth/REGISTER_REQUEST", register),
  takeLatest("@auth/LOGOUT", logout)
]);

import React from "react";
import { useSelector } from "react-redux";

import { Container } from "./styles";
// import lang from '~/config/language/en-US';
import retLanguage from '~/config/language';

import { FaSearch } from "react-icons/fa";

import "react-datepicker/dist/react-datepicker.css";

export default function FiltroLancamento({
  handleLancamento,
  placeholder,
  setFind
}) {
  const profile = useSelector(state => state.user.profile);
  const lang = retLanguage(profile.language);
  return (
    <Container onSubmit={handleLancamento}>
      <label>
        <div onClick={handleLancamento}>
          <FaSearch size={12} /> {lang.grade.buttons.b3}
        </div>
        <input
          type="text"
          placeholder={placeholder}
          onChange={e => setFind(e.target.value)}
        />
      </label>
    </Container>
  );
}

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import api from "~/services/api";

import * as Yup from "yup";

import {
  format,
  addMonths,
  addDays,
  addHours,
  isAfter,
  parseISO
} from "date-fns";

import pt from "date-fns/locale/pt";

import { toast } from "react-toastify";

import BuscarFornecedor from "~/components/BuscarFornecedor";

import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

import { file_extension } from "~/assets/";

import {
  FaCalendarAlt,
  FaUser,
  FaSearch,
  FaAngleRight,
  FaFileInvoice,
  FaCommentDots,
  FaUniversity,
  FaMoneyBillAlt,
  FaPercentage,
  FaCoins,
  FaCreditCard,
  FaDollarSign,
  FaChartPie,
  FaTruck,
  FaBullhorn,
  FaQuestion,
  FaAngleDown,
  FaFileInvoiceDollar,
  FaFileUpload,
  FaTrashAlt
} from "react-icons/fa";

import { Form, Input, Select } from "@rocketseat/unform";
import { Content, Container, Scroll, ListParcels, DivFiles } from "./styles";

import { formatMoney, formatNum, getAccountsByPaymentMethod, getPaymentMethods } from "~/scripts/index.js";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Title from "~/components/Title";

export default function CreateInvoice({
  id,
  edit,
  handleCreateInvoice,
  setBlock
}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  // const [closeOnSearch, setCloseOnSearch] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showCoA, setShowCoA] = useState(false);
  const [chartOfAccount, setChartOfAccount] = useState({ code: "", nome: "" });
  const [providerSearch, setProviderSearch] = useState({ cgc: "", name: "" });
  const [provider, setProvider] = useState({ cgc: "", company_name: "" });
  // const [file, setFile] = useState(null);
  const [totalValue, setTotalVaue] = useState(0);
  const [finish, setFinish] = useState(false);
  const [tableOpened, setTableOpened] = useState(false);
  const [taxesOpened, setTaxesOpened] = useState(true);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [filiaisRegional, setFiliaisRegional] = useState([]);
  const [removeFile,setRemoveFile] = useState(null);
  const [nfs, setNfs] = useState({
    bank_id: null,
    provider_id: null,
    plano_de_contas_id: null,
    nota_fiscal: null,
    serie: null,
    tax_nature_id: 0,
    reason: null,
    created_by: profile.id,
    filial_id: filial.filial,
    to_filial_id: filial.filial,
    value: 0,
    discount: 0,
    addition: 0,
    interest: 0,
    total: 0,
    freight: 0,
    parcels: 1,
    s3file_id: null,
    file: {
      nome: null
    },
    payment_method: "Dinheiro",

    base_irrf: 0,
    base_iss: 0,
    base_inss: 0,
    base_pis: 0,
    base_cofins: 0,
    base_csll: 0,

    perc_irrf: 0,
    perc_iss: 0,
    perc_inss: 0,
    perc_pis: 0,
    perc_cofins: 0,
    perc_csll: 0,

    value_irrf: 0,
    value_iss: 0,
    value_inss: 0,
    value_pis: 0,
    value_cofins: 0,
    value_csll: 0,

    date: format(new Date(), 'yyyy-MM-dd'),
    due_date: format(new Date(), 'yyyy-MM-dd'),
    created_at: new Date(),
    parcels_array: []
  });


  async function handleSubmit() {
    try {
      if (removeFile) {
        await api.delete('s3files/'+removeFile);
      }
      const response = id
        ? await api.put("/invoice/edit/" + id, { ...nfs, total: totalValue })
        : await api.post("/invoice", { ...nfs, total: totalValue });
        toast.success(`NFS ${id ? "alterado" : "incluído"} com sucesso!`, {
          className: "success"
        });
        setBlock(true);
        handleCreateInvoice(false);
      return;
    } catch (err) {
      toast.error("Há algum erro, por favor, revise o lançamento.", {
        className: "error"
      });
    }
  }

  function handleSearch(type, value) {
    if (type === "chart_of_account") {
      setShowCoA(value);
    } else {
      setShowSearch(value);
    }
    // setCloseOnSearch(!value);
  }

  function handleProvider(provider) {
    setNfs({ ...nfs, provider_id: provider.id });
    setProvider(provider);
    setShowSearch(false);
    // setCloseOnSearch(true);
  }

  function handleChartOfAccount(coa) {
    setNfs({ ...nfs, plano_de_contas_id: coa.id });
    const { id, nome: title, tax_nature } = coa;
    setChartOfAccount({id, title, tax_nature});
    setShowCoA(false);
  }

  function setValue(bruto) {
    setNfs({
      ...nfs,
      value: bruto
    });
  }

  function handleTable() {
    setTableOpened(!tableOpened);
  }

  function handleTaxes() {
    setTaxesOpened(!taxesOpened);
  }

  useEffect(() => {
    async function loadInvoice() {
      const response = await api.get("/invoice/id/" + id);
      const { data } = response;
      if (data) {
        setNfs(data);

        if (!edit && id) {
          setTimeout(() => {
            setNfs({ ...data, parcels_array: data.parcels_array });
          }, 2000);
        }

        setProvider({
          cgc: data.provider.agente.cgc,
          company_name: data.provider.company_name
        });
        const response2 = await api.get(
          "/chartofaccount/" + data.charts_of_account
        );
        const { data: charts } = response2;
        if(data.charts_of_account) {
          setChartOfAccount({ ...charts, title: profile.language === 'pt-BR' ? data.charts_of_account.pt_name : data.charts_of_account.en_name});
        }
      }
    }

    async function getFiliaisRegional(filial_id) {
      const response = await api.get('/filiais/myregionais/'+filial_id);
      const { data } = response;
      let filiais = [{id: filial_id, title: filial.filial_name}];
      data.map(filial => {
        filiais.push({ id: filial.id, title: filial.name })
      })

      setFiliaisRegional(filiais);
    }
    loadInvoice();
    getFiliaisRegional(filial.filial)
  }, [id]);

  useEffect(() => {
    let {
      value,
      discount,
      addition,
      interest,
      freight,
      value_irrf,
      value_iss,
      value_inss,
      value_pis,
      value_cofins,
      value_csll,
      total
    } = nfs;

    const tributos =
      parseFloat(value_irrf) +
      parseFloat(value_iss) +
      parseFloat(value_inss) +
      parseFloat(value_pis) +
      parseFloat(value_cofins) +
      parseFloat(value_csll);

    total =
      parseFloat(value) -
      parseFloat(discount) +
      parseFloat(addition) +
      parseFloat(interest) +
      parseFloat(freight) -
      parseFloat(tributos);

    setNfs({
      ...nfs,
      tributos
    });

    setTotalVaue(total);
  }, [
    nfs.value,
    nfs.discount,
    nfs.addition,
    nfs.interest,
    nfs.freight,
    nfs.value_irrf,
    nfs.value_iss,
    nfs.value_inss,
    nfs.value_pis,
    nfs.value_cofins,
    nfs.value_css
  ]);

  useEffect(() => {
    let base_irrf = nfs.value;
    let base_iss = nfs.value;
    let base_inss = nfs.value;
    let base_pis = nfs.value;
    let base_cofins = nfs.value;
    let base_csll = nfs.value;

    let perc_irrf = 0;
    let perc_iss = 0;
    let perc_inss = 0;
    let perc_csll = 0;
    let perc_cofins = 0;
    let perc_pis = 0;

    let value_irrf = 0;
    let value_iss = 0;
    let value_inss = 0;
    let value_csll = 0;
    let value_cofins = 0;
    let value_pis = 0;

    if (chartOfAccount && chartOfAccount.tax_nature && nfs.type === "NFS-e") {
      perc_irrf = chartOfAccount.tax_nature.perc_irrf;
      perc_iss = chartOfAccount.tax_nature.perc_iss;
      perc_inss = chartOfAccount.tax_nature.perc_inss;
      perc_csll = chartOfAccount.tax_nature.perc_csll;
      perc_cofins = chartOfAccount.tax_nature.perc_cofins;
      perc_pis = chartOfAccount.tax_nature.perc_pis;

      value_irrf = (
        (nfs.value * chartOfAccount.tax_nature.perc_irrf) /
        100
      ).toFixed(2);
      value_iss = (
        (nfs.value * chartOfAccount.tax_nature.perc_iss) /
        100
      ).toFixed(2);
      value_inss = (
        (nfs.value * chartOfAccount.tax_nature.perc_inss) /
        100
      ).toFixed(2);
      value_csll = (
        (nfs.value * chartOfAccount.tax_nature.perc_csll) /
        100
      ).toFixed(2);
      value_cofins = (
        (nfs.value * chartOfAccount.tax_nature.perc_cofins) /
        100
      ).toFixed(2);
      value_pis = (
        (nfs.value * chartOfAccount.tax_nature.perc_pis) /
        100
      ).toFixed(2);
    }

    setNfs({
      ...nfs,
      base_irrf,
      base_iss,
      base_inss,
      base_pis,
      base_cofins,
      base_csll,

      perc_irrf,
      perc_iss,
      perc_inss,
      perc_csll,
      perc_cofins,
      perc_pis,

      value_irrf,
      value_iss,
      value_inss,
      value_csll,
      value_cofins,
      value_pis
    });
  }, [chartOfAccount, nfs.value, nfs.type]);

  useEffect(() => {
    async function verify() {
      const { s3file_id } = nfs;
      setFinish(!!s3file_id);
    }

    verify();
  }, [nfs.s3file_id]);

  useEffect(() => {
    let parcels_push = [];
    let tributos = parseFloat(
      parseFloat(nfs.value_irrf) +
        parseFloat(nfs.value_inss) +
        parseFloat(nfs.value_iss) +
        parseFloat(nfs.value_pis) +
        parseFloat(nfs.value_cofins) +
        parseFloat(nfs.value_csll)
    );
    let parcel_value =
      parseFloat(nfs.value) -
      parseFloat(nfs.discount) +
      parseFloat(nfs.addition) +
      parseFloat(nfs.interest) +
      parseFloat(nfs.freight);
    let total_parc1 = parcel_value / parseInt(nfs.parcels) - tributos;
    let total_parc = parcel_value / parseInt(nfs.parcels);
    for (let i = 0; i < parseInt(nfs.parcels); i++) {
      parcels_push.push({
        parcel: i + 1,
        date: format(parseISO(nfs.date), 'yyyy-MM-dd'),
        due_date: format(addMonths(parseISO(nfs.due_date), i),'yyyy-MM-dd'),
        total: i === 0 ? total_parc1 : total_parc,
        value: parcel_value / parseInt(nfs.parcels),
        discount: 0,
        tax_total: i === 0 ? tributos : 0,
        addition: 0,
        interest: 0,
        filial_id: filial.filial,
        approved_at: null,
        // total: (parseFloat(nfs.total) / parseFloat(nfs.parcels)).toFixed(2),
        created_at: new Date(),
        created_by: profile.id
      });
    }

    setNfs({ ...nfs, parcels_array: parcels_push });
  }, [nfs.parcels, totalValue, nfs.due_date]);

  useEffect(() => {
    if (chartOfAccount.tax_nature) {
      const value_irrf = ((nfs.base_irrf * nfs.perc_irrf) / 100).toFixed(2);
      const value_iss = ((nfs.base_iss * nfs.perc_iss) / 100).toFixed(2);

      setNfs({
        ...nfs,
        value_irrf,
        value_iss
      });
    }
  }, [nfs.base_irrf, nfs.base_iss, nfs.perc_irrf, nfs.perc_iss]);

  function setParcels(qtd) {
    setNfs({ ...nfs, parcels: qtd });
  }

  async function handlerFiles(e) {
    const datas = new FormData();
    let newNfs = JSON.parse(JSON.stringify(nfs));

    datas.append("file", e.target.files[0]);

    const { data } = await api.post("s3files/" + profile.id, datas);

    newNfs.file = data;
    newNfs.s3file_id = data.id;

    setNfs(newNfs);
  }

  function setIconFileExtension(file) {
    const ext = file.toLowerCase().split(".").pop();
    for (let i = 0; i < file_extension.length; i++) {
      if (ext === file_extension[i].title) {
        return file_extension[i].icon;
      }
    }
  }

  function removerFiles() {
    setRemoveFile(nfs.s3file_id);
    const datas = new FormData();
    let newNfs = JSON.parse(JSON.stringify(nfs));
    newNfs.file = null;
    newNfs.s3file_id = null;

    setNfs(newNfs);
  }

  const payment_methods = getPaymentMethods();

  useEffect(() => {
    getAccountsByPaymentMethod(nfs.payment_method, setNfs, nfs, setPaymentAccounts, filial.filial);
  }, [nfs.payment_method]);

  const type = [
    { id: "NFS-e", title: "NFS-e" },
    { id: "NF-e", title: "NF-e" }
  ];

  const qtdParcels = [];
  for (let parc = 1; parc <= 36; parc++) {
    qtdParcels.push({ id: parc, title: parc.toString() + "x" });
  }

  const schema = Yup.object().shape({
    nota_fiscal: Yup.string().required("Nº do Cupom é obrigatório."),
    serie: Yup.string().required("Série é obrigatório."),
    reason: Yup.string().required("Despesa é obrigatória."),
    // payment_method: Yup.string().required("Tipo é obrigatório."),
    type: Yup.string().required("Tipo nota é obrigatório."),
    provider: Yup.string().required("Fornecedor é obrigatório."),
    charts_of_account: Yup.string().required("Plano de contas é obrigatório."),
    value: Yup.string().test(
      "0,00",
      "Valor é obrigatório",
      value => formatNum(value, false) > 0
    ),
    total: Yup.string().test(
      "0,00",
      "Total é obrigatório.",
      total =>
        formatNum(
          total,
          total.toString().substring(0, 1) === "-" ? true : false
        ) > 0
    )
  });

  return (
    <>
      <Scroll style={showSearch || showCoA ? { display: "none" } : null}>
        <Content>
          <header>
            <Title back={true} func={handleCreateInvoice} title={"Cancelar"} />
          </header>
          <Container>
            <h1>Cadastrar Nota Fiscal</h1>

            <Form initialData={nfs} schema={schema} onSubmit={handleSubmit} autoComplete="off">
              <div className="rowInputs" style={{ paddingTop: "15px" }}>
                <label htmlFor="nome">
                  <img
                    src={
                      profile.avatar
                        ? profile.avatar.url
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrdEvvb_A3fTRZ9FTzpCkDacPDXBYoWgsoxbFbcYnB-jWigqa6w&s"
                    }
                    width="54"
                    height="54"
                    alt=""
                  />
                  <Input
                    name="nome"
                    readOnly="readonly"
                    value={profile.nome}
                    placeholder="Data"
                  />
                </label>

                <label htmlFor="provider" style={{ flexGrow: "2" }}>
                  <div>
                    <FaUser size={20} />
                    <br />
                    Fornec.
                  </div>
                  <Input
                    name="provider"
                    readOnly={"readonly"}
                    value={provider.cgc ? provider.company_name : null}
                  />
                  { edit ?
                  <button
                    type="button"
                    className="btnFinish"
                    onClick={() => handleSearch("provider", true)}
                  >
                    Buscar <FaSearch size={14} />
                  </button>
                  : null }
                </label>
                <label htmlFor="plano_de_contas_id" style={{ flexGrow: "2" }}>
                  <div>
                    <FaFileInvoiceDollar size={20} />
                    <br />
                    Pla.Con
                  </div>
                  <Input
                    name="plano_de_contas_id"
                    readOnly={"readonly"}
                    value={chartOfAccount.title ? `${chartOfAccount.title}` : ""}
                  />
                  { edit ?
                  <button
                    type="button"
                    className="btnFinish"
                    onClick={() => handleSearch("chart_of_account", true)}
                  >
                    Buscar <FaSearch size={14} />
                  </button>
                  : null }
                </label>
              </div>
              <hr />

              <div className="rowInputs">
                <label htmlFor="nota_fiscal">
                  <div>
                    <FaFileInvoice size={20} />
                    <br />
                    NFiscal
                  </div>
                  <Input
                    name="nota_fiscal"
                    maxLength={9}
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setNfs({ ...nfs, nota_fiscal: e.target.value })
                    }
                  />
                </label>
                <label htmlFor="serie">
                  <div>
                    <FaFileInvoice size={20} />
                    <br />
                    Série
                  </div>
                  <Input
                    name="serie"
                    maxLength={9}
                    readOnly={edit ? null : "readonly"}
                    onChange={e => setNfs({ ...nfs, serie: e.target.value })}
                  />
                </label>
                <label htmlFor="date">
                  <div>
                    <FaCalendarAlt size={20} />
                    <br />
                    Emissão
                  </div>
                  <DatePicker
                    name="date"
                    readOnly={edit ? null : "readonly"}
                    selected={parseISO(nfs.date)}
                    minDate={addDays(parseISO(filial.open_date), 1)}
                    maxDate={new Date()}
                    onChange={date => {
                      isAfter(date, parseISO(nfs.due_date))
                        ? setNfs({ ...nfs, date: date, due_date: format(date, 'yyyy-MM-dd') })
                        : setNfs({ ...nfs, date: format(date, 'yyyy-MM-dd') });
                    }}
                    dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    locale={pt}
                  />
                </label>
                <label htmlFor="due_date">
                  <div>
                    <FaCalendarAlt size={20} />
                    <br />
                    Vencto
                  </div>
                  <DatePicker
                    name="due_date"
                    readOnly={edit ? null : "readonly"}
                    selected={parseISO(nfs.due_date)}
                    minDate={parseISO(nfs.date)}
                    onChange={date => setNfs({ ...nfs, due_date: format(date, 'yyyy-MM-dd') })}
                    dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    locale={pt}
                  />
                </label>
              </div>
              <hr />

              <div className="rowInputs">
                <label htmlFor="reason">
                  <div>
                    <FaCommentDots size={20} />
                    <br />
                    Despesa
                  </div>
                  <Input
                    name="reason"
                    readOnly={edit ? null : "readonly"}
                    onChange={e => setNfs({ ...nfs, reason: e.target.value })}
                  />
                </label>
                <label htmlFor="to_filial_id">
                  <div>
                    <FaUniversity size={20} />
                    <br />
                    P/ Filial
                  </div>
                  <Select
                    name="to_filial_id"
                    options={filiaisRegional}
                    selected={nfs.to_filial_id}
                    value={nfs.to_filial_id}
                    disabled={!edit}
                    onChange={e =>
                      setNfs({
                        ...nfs,
                        to_filial_id: e.target.value ? e.target.value : null
                      })
                    }
                  />
                </label>

                <label htmlFor="payment_method">
                  <div>
                    <FaCreditCard size={20} />
                    <br />
                    Pago em
                  </div>
                  <Select
                    name="payment_method"
                    placeholder={
                      nfs.payment_method ? nfs.payment_method : "Dinheiro"
                    }
                    options={payment_methods}
                    selected={nfs.payment_method}
                    value={nfs.payment_method}
                    disabled={edit ? null : "readonly"}
                    onChange={e =>
                      setNfs({ ...nfs, payment_method: e.target.value })
                    }
                  />
                </label>
                <label htmlFor="paymentAccount">
                  <div>
                    <FaUniversity size={20} />
                    <br />
                    Conta
                  </div>
                  <Select
                    name="paymentAccount"
                    placeholder={nfs.bank ? nfs.bank.name : "Caixa Filial"}
                    options={paymentAccounts}
                    value={nfs.bank_id || 0}
                    selected={nfs.bank_id || 0}
                    disabled={!edit}
                    onChange={e =>
                      setNfs({
                        ...nfs,
                        bank_id: e.target.value ? e.target.value : null
                      })
                    }
                  />
                </label>
              </div>
              <hr />

              <div className="rowInputs">
                <label htmlFor="value">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Valor
                  </div>
                  <Input
                    name="value"
                    readOnly={edit ? null : "readonly"}
                    onChange={e => setValue(formatNum(e.target.value))}
                    value={formatMoney(
                      nfs.value.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="discount">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Desc.
                  </div>
                  <Input
                    name="discount"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setNfs({
                        ...nfs,
                        discount: formatNum(e.target.value)
                      })
                    }
                    value={formatMoney(
                      nfs.discount.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="addition">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Acresc.
                  </div>
                  <Input
                    name="addition"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setNfs({
                        ...nfs,
                        addition: formatNum(e.target.value)
                      })
                    }
                    value={formatMoney(
                      nfs.addition.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="interest">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Juros
                  </div>
                  <Input
                    name="interest"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setNfs({
                        ...nfs,
                        interest: formatNum(e.target.value)
                      })
                    }
                    value={formatMoney(
                      nfs.interest.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="total">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Total
                  </div>
                  <Input
                    name="total"
                    readOnly={edit ? null : "readonly"}
                    value={formatMoney(
                      totalValue.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
              </div>
              <hr />

              <div className="rowInputs">
                <label htmlFor="type">
                  <div>
                    <FaFileInvoice size={20} />
                    <br />
                    Tipo
                  </div>
                  <Select
                    name="type"
                    options={type}
                    value={nfs.type}
                    disabled={edit ? null : "readonly"}
                    onChange={e => setNfs({ ...nfs, type: e.target.value })}
                  />
                </label>
                <label htmlFor="solicitation">
                  <div>
                    <FaBullhorn size={20} />
                    <br />
                    O. Serv.
                  </div>
                  <Input
                    name="solicitation"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setNfs({ ...nfs, solicitation: e.target.value })
                    }
                  />
                </label>
                <label htmlFor="freight">
                  <div>
                    <FaTruck size={20} />
                    <br />
                    Frete
                  </div>
                  <Input
                    name="freight"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setNfs({ ...nfs, freight: formatNum(e.target.value) })
                    }
                    value={formatMoney(
                      nfs.freight.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="parcels">
                  <div>
                    <FaChartPie size={20} />
                    <br />
                    Parcelas
                  </div>
                  <Select
                    name="parcels"
                    options={qtdParcels}
                    value={nfs.parcels}
                    disabled={edit ? null : "readonly"}
                    onChange={e => setParcels(e.target.value)}
                  />
                </label>
              </div>
              <hr />

              {nfs.type === "NFS-e" && (
                <>
                  <h1 onClick={handleTaxes} className="h1Open">
                    {taxesOpened ? (
                      <FaAngleDown size={20} />
                    ) : (
                      <FaAngleRight size={20} />
                    )}
                    Impostos NFS-e
                  </h1>
                  {taxesOpened ? (
                    <>
                      <hr />
                      <div className="rowInputs">
                        <label>
                          <div>
                            <FaUniversity size={20} />
                          </div>
                          <input type="text" readOnly={true} value="IRRF" />
                        </label>
                        <label htmlFor="base_irrf">
                          <div>
                            <FaMoneyBillAlt size={20} />
                            <br />
                            Base
                          </div>
                          <Input
                            name="base_irrf"
                            readOnly={edit ? null : "readonly"}
                            onChange={e =>
                              setNfs({
                                ...nfs,
                                base_irrf: formatNum(e.target.value)
                              })
                            }
                            value={formatMoney(
                              nfs.base_irrf.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="perc_irrf">
                          <div>
                            <FaPercentage size={20} />
                            <br />
                            Alíq.
                          </div>
                          <Input
                            name="perc_irrf"
                            onChange={e =>
                              setNfs({
                                ...nfs,
                                perc_irrf:
                                  formatNum(e.target.value) > 100.0 ||
                                  formatNum(e.target.value) < 0.0
                                    ? nfs.perc_irrf
                                    : formatNum(e.target.value)
                              })
                            }
                            value={formatMoney(
                              nfs.perc_irrf.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="value_irrf">
                          <div>
                            <FaCoins size={20} />
                            <br />
                            Valor
                          </div>
                          <Input
                            name="value_irrf"
                            readOnly={true}
                            value={formatMoney(
                              nfs.value_irrf,
                              filial.country.language
                            )}
                          />
                        </label>
                      </div>
                      <hr />

                      <div className="rowInputs">
                        <label>
                          <div>
                            <FaUniversity size={20} />
                          </div>
                          <input type="text" readOnly={true} value="ISS" />
                        </label>
                        <label>
                          <div>
                            <FaMoneyBillAlt size={20} />
                            <br />
                            Base
                          </div>
                          <Input
                            name="base_iss"
                            readOnly={edit ? null : "readonly"}
                            onChange={e =>
                              setNfs({
                                ...nfs,
                                base_iss: formatNum(e.target.value)
                              })
                            }
                            value={formatMoney(
                              nfs.base_iss.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="perc_iss">
                          <div>
                            <FaPercentage size={20} />
                            <br />
                            Alíq.
                          </div>
                          <Input
                            name="perc_iss"
                            onChange={e =>
                              setNfs({
                                ...nfs,
                                perc_iss:
                                  formatNum(e.target.value) > 100.0 ||
                                  formatNum(e.target.value) < 0.0
                                    ? nfs.perc_iss
                                    : formatNum(e.target.value)
                              })
                            }
                            value={formatMoney(
                              nfs.perc_iss.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="value_iss">
                          <div>
                            <FaCoins size={20} />
                            <br />
                            Valor
                          </div>
                          <Input
                            name="value_iss"
                            readOnly={true}
                            value={formatMoney(
                              nfs.value_iss,
                              filial.country.language
                            )}
                          />
                        </label>
                      </div>
                      <hr />

                      <div className="rowInputs">
                        <label>
                          <div>
                            <FaUniversity size={20} />
                          </div>
                          <input type="text" readOnly={true} value="INSS" />
                        </label>
                        <label>
                          <div>
                            <FaMoneyBillAlt size={20} />
                            <br />
                            Base
                          </div>
                          <Input
                            name="base_inss"
                            readOnly={true}
                            value={formatMoney(
                              nfs.base_inss.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="perc_inss">
                          <div>
                            <FaPercentage size={20} />
                            <br />
                            Alíq.
                          </div>
                          <Input
                            name="perc_inss"
                            readOnly={true}
                            value={formatMoney(
                              nfs.perc_inss.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="value_inss">
                          <div>
                            <FaCoins size={20} />
                            <br />
                            Valor
                          </div>
                          <Input
                            name="value_inss"
                            readOnly={true}
                            value={formatMoney(
                              nfs.value_inss,
                              filial.country.language
                            )}
                          />
                        </label>
                      </div>
                      <hr />

                      <div className="rowInputs">
                        <label>
                          <div>
                            <FaUniversity size={20} />
                          </div>
                          <input type="text" readOnly={true} value="PIS" />
                        </label>
                        <label>
                          <div>
                            <FaMoneyBillAlt size={20} />
                            <br />
                            Base
                          </div>
                          <Input
                            name="base_pis"
                            readOnly={true}
                            value={formatMoney(
                              nfs.base_pis.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="perc_pis">
                          <div>
                            <FaPercentage size={20} />
                            <br />
                            Alíq.
                          </div>
                          <Input
                            name="perc_pis"
                            readOnly={true}
                            value={formatMoney(
                              nfs.perc_pis.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="value_pis">
                          <div>
                            <FaCoins size={20} />
                            <br />
                            Valor
                          </div>
                          <Input
                            name="value_pis"
                            readOnly={true}
                            value={formatMoney(
                              nfs.value_pis,
                              filial.country.language
                            )}
                          />
                        </label>
                      </div>
                      <hr />

                      <div className="rowInputs">
                        <label>
                          <div>
                            <FaUniversity size={20} />
                          </div>
                          <input type="text" readOnly={true} value="COFINS" />
                        </label>
                        <label>
                          <div>
                            <FaMoneyBillAlt size={20} />
                            <br />
                            Base
                          </div>
                          <Input
                            name="base_cofins"
                            readOnly={true}
                            value={formatMoney(
                              nfs.base_cofins.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="perc_cofins">
                          <div>
                            <FaPercentage size={20} />
                            <br />
                            Alíq.
                          </div>
                          <Input
                            name="perc_cofins"
                            readOnly={true}
                            value={formatMoney(
                              nfs.perc_cofins.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="value_cofins">
                          <div>
                            <FaCoins size={20} />
                            <br />
                            Valor
                          </div>
                          <Input
                            name="value_cofins"
                            readOnly={true}
                            value={formatMoney(
                              nfs.value_cofins,
                              filial.country.language
                            )}
                          />
                        </label>
                      </div>
                      <hr />

                      <div className="rowInputs">
                        <label>
                          <div>
                            <FaUniversity size={20} />
                          </div>
                          <input type="text" readOnly={true} value="CSLL" />
                        </label>
                        <label>
                          <div>
                            <FaMoneyBillAlt size={20} />
                            <br />
                            Base
                          </div>
                          <Input
                            name="base_csll"
                            readOnly={true}
                            value={formatMoney(
                              nfs.base_csll.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="perc_csll">
                          <div>
                            <FaPercentage size={20} />
                            <br />
                            Alíq.
                          </div>
                          <Input
                            name="perc_csll"
                            readOnly={true}
                            value={formatMoney(
                              nfs.perc_csll.toFixed(2),
                              filial.country.language
                            )}
                          />
                        </label>
                        <label htmlFor="value_csll">
                          <div>
                            <FaCoins size={20} />
                            <br />
                            Valor
                          </div>
                          <Input
                            name="value_csll"
                            readOnly={true}
                            value={formatMoney(
                              nfs.value_csll,
                              filial.country.language
                            )}
                          />
                        </label>
                      </div>
                    </>
                  ) : null}
                  <hr />
                </>
              )}

              <input
                type="file"
                id={`anexo`}
                style={{ display: "none" }}
                accept="image/jpeg,image/gif,image/png,application/pdf"
                onChange={e => handlerFiles(e)}
              />
              <h1 className="title_section_upload_file">
                Anexo
                {edit && (
                  <button type="button" className="upload_file">
                    <FaFileUpload
                      size={18}
                      onClick={() => document.getElementById("anexo").click()}
                    />
                  </button>
                )}
              </h1>
              <DivFiles>
                <li>
                  <table>
                    <thead>
                      <tr>
                        <td>Arquivo</td>
                        <td style={{ width: "100px" }}>Baixar</td>
                        <td style={{ width: "100px" }}>Excluir</td>
                      </tr>
                    </thead>
                    <tbody>
                      {nfs.s3file_id ? (
                        <tr>
                          <td>{nfs.file.name}</td>
                          <td>
                            <a
                              href={nfs.file.url}
                              download
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={setIconFileExtension(nfs.file.key)}
                                alt=""
                                style={{ height: "35px", width: "35px" }}
                              />
                            </a>
                          </td>
                          <td>
                            {edit && (
                              <FaTrashAlt
                                size={20}
                                color="#c47f7f"
                                onClick={() => removerFiles()}
                              />
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>Nenhum arquivo</td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </li>
              </DivFiles>

              <div className="rowInputs" style={{ marginTop: "15px" }}>
                {/* <label htmlFor="anexo" className="anexo">
                  <button
                    type="button"
                    onClick={() =>
                      edit ? document.getElementById(`anexo`).click() : false
                    }
                  >
                    {nfs.file_id ? (
                      <img
                        src={setIconFileExtension(nfs.file.nome)}
                        alt=""
                        style={{ height: "35px", width: "35px" }}
                      />
                    ) : (
                      <FaPaperclip size={18} />
                    )}
                  </button>
                </label> */}
                <label htmlFor="observation">
                  <div>
                    <FaQuestion size={20} />
                    <br />
                    obs
                  </div>
                  <Input
                    name="observation"
                    placeholder="Observação"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setNfs({
                        ...nfs,
                        observation: e.target.value
                      })
                    }
                  />
                </label>

                {edit ? (
                  <>
                    <button
                      type="submit"
                      className="btnFinish"
                      disabled={!finish}
                      onClick={() => handleSubmit()}
                    >
                      {id ? "Alterar" : "Finalizar"} <FaAngleRight size={20} />
                    </button>
                  </>
                ) : null}
              </div>
              <hr />

              <div className="rowInputs nfs_list">
                <h1 onClick={handleTable} className="h1Open">
                  {tableOpened ? (
                    <FaAngleDown size={20} />
                  ) : (
                    <FaAngleRight size={20} />
                  )}
                  Tabela de Parcelas
                </h1>
                {tableOpened ? (
                  <>
                    <ListParcels>
                      <li>
                        <table>
                          <thead>
                            <tr>
                              <td>Baixado em</td>
                              <td>Parcela</td>
                              <td>Emissão</td>
                              <td>Vencimento</td>
                              <td>Valor</td>
                              <td>Desconto</td>
                              <td>Impostos</td>
                              <td>Adicional</td>
                              <td>juros</td>
                              <td>Total</td>
                            </tr>
                          </thead>

                          <tbody>
                            {nfs.parcels_array.map((parcels_item, index) => (
                              <tr key={index}>
                                <td>
                                  {parcels_item.approved_at
                                    ? format(
                                        parseISO(parcels_item.approved_at),
                                        profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                      )
                                    : null}
                                </td>
                                <td>{parcels_item.parcel}</td>
                                {console.log(parcels_item)}
                                <td>
                                  {parcels_item.date ? format(
                                    parseISO(parcels_item.date),
                                    profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                  ) : null}
                                </td>
                                <td>
                                  {parcels_item.due_date ? format(
                                    parseISO(parcels_item.due_date),
                                    profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                  ) : null}
                                </td>
                                <td>
                                  {formatMoney(
                                    parcels_item.value.toFixed(2),
                                    filial.country.language
                                  )}
                                </td>
                                <td>
                                  {formatMoney(
                                    parcels_item.discount.toFixed(2),
                                    filial.country.language
                                  )}
                                </td>
                                <td>
                                  {formatMoney(
                                    parcels_item.tax_total.toFixed(2),
                                    filial.country.language
                                  )}
                                </td>
                                <td>
                                  {formatMoney(
                                    parcels_item.addition.toFixed(2),
                                    filial.country.language
                                  )}
                                </td>
                                <td>
                                  {formatMoney(
                                    parcels_item.interest.toFixed(2),
                                    filial.country.language
                                  )}
                                </td>
                                <td>
                                  {formatMoney(
                                    parcels_item.total.toFixed(2),
                                    filial.country.language
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </li>
                    </ListParcels>
                  </>
                ) : null}
              </div>
            </Form>
          </Container>
        </Content>
      </Scroll>

      <BuscarFornecedor
        show={showSearch}
        setShowSearch={setShowSearch}
        initialData={nfs}
        handleProvider={handleProvider}
      />

      <BuscarPlanoDeContas
        show={showCoA}
        setShowCoA={setShowCoA}
        initialData={nfs}
        handleChartOfAccount={handleChartOfAccount}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import { Scroll, Content, Container } from "./styles";

import BuscarNaturezas from "~/components/BuscarNatureza";

import Title from "~/components/Title";

import { FaAngleRight, FaTimesCircle, FaFile, FaSearch } from "react-icons/fa";

import { Input, Form, Select } from "@rocketseat/unform";

import api from "~/services/api";

export default function CreateChartOfAccouts({
  handleCreateChartOfAccouts,
  opennedID,
  edit,
  setChartOfAccouts,
  chartOfAccouts,
  setBlock,
  setCreateChartOfAccouts
}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [parents,setParents] = useState([]);
  const [showSearch, setShowSearch] = useState(false);

  let [finish, setFinish] = useState(false);
  const [complement, setCompelement] = useState({
    nature: {
      nome: ""
    }
  });

  let [chartOfAccout, setChartOfAccout] = useState({
    id: null,
    nome: "",
    tax_nature_id: null,
    created_by: profile.id,
    created_at: new Date()
  });

  function handleSearch(type, value) {
    setShowSearch(value);
  }

  useEffect(() => {
    async function getParents() {
      const response = await api.get(`planodecontas/parents/${profile.language}`);
      setParents(response.data);
    }
    async function getChartOfAccount() {
      if (opennedID) {
        const response = await api.get(`planodecontas/id/${opennedID}`);

        setChartOfAccout(response.data);
      }
    }
    getParents();
    getChartOfAccount();
  }, [opennedID]);

  useEffect(() => {
    async function confirmchartOfAccout() {
      let { parent_id, pt_name, en_name, es_name } = chartOfAccout;
      parent_id && en_name && pt_name
        ? setFinish(true)
        : setFinish(false);
    }
    confirmchartOfAccout();
  }, [chartOfAccout]);

  async function handleNature(nature) {
    setCompelement({ ...complement, nature });
    setChartOfAccout({ ...chartOfAccout, tax_nature_id: nature.tax_nature_id });
    setShowSearch(false);
  }

  async function handleSubmit() {
    try {
      const response = opennedID
        ? await api.put(`planodecontas/${opennedID}`, chartOfAccout)
        : await api.post("planodecontas", chartOfAccout);
      setCreateChartOfAccouts(false);
      setBlock(true);
      toast.success(
        opennedID
          ? `Plano de Contas atualizado com sucesso!`
          : `Plano de Contas inserido com sucesso!`,
        {
          className: "success"
        }
      );
    } catch (err) {
      toast.error(
        opennedID
          ? "Impossível atualizar plano de Contas."
          : "Impossível inserir plano de Contas.",
        {
          className: "error"
        }
      );
    }
  }

  return (
    <>
      {!showSearch && (
        <Scroll>
          <Content>
            <header>
              <Title
                back={true}
                func={handleCreateChartOfAccouts}
                title={"Cancelar"}
              />
            </header>
            <Container>
              <h1>Cadastro de Plano de Contas</h1>
              <Form onSubmit={handleSubmit}>
                <div className="rowInputs">

                <label htmlFor="parent_id">
                  <div>
                    <FaFile size={20} />
                    <br />
                    Grupo
                  </div>
                  <Select options={parents} name="parent_id" selected={chartOfAccout.parent_id} value={chartOfAccout.parent_id} onChange={(e) => setChartOfAccout({...chartOfAccout,parent_id: e.target.value})} />
                </label>
                </div>

                <hr />

                <div className="rowInputs">
                  <label htmlFor="nome">
                    <div>
                    <img
                      src={`/images/BR.svg`}
                      width="26"
                    />
                    </div>
                    <Input
                      name="pt_name"
                      placeholder="Nome"
                      readOnly={!edit ? "readonly" : ""}
                      onChange={e =>
                        setChartOfAccout({
                          ...chartOfAccout,
                          pt_name: e.target.value
                        })
                      }
                      value={chartOfAccout.pt_name}
                    />
                  </label>
                </div>
                <hr />

                <div className="rowInputs">
                  <label htmlFor="nome">
                    <div>
                    <img
                      src={`/images/US.svg`}
                      width="26"
                    />
                    </div>
                    <Input
                      name="en_name"
                      placeholder="Name"
                      readOnly={!edit ? "readonly" : ""}
                      onChange={e =>
                        setChartOfAccout({
                          ...chartOfAccout,
                          en_name: e.target.value
                        })
                      }
                      value={chartOfAccout.en_name}
                    />
                  </label>
                </div>
                <hr />

                <div className="rowInputs">
                  <label htmlFor="nome">
                    <div>
                    <img
                      src={`/images/sp.svg`}
                      width="26"
                    />
                    </div>
                    <Input
                      name="es_name"
                      placeholder="Nombre"
                      readOnly={!edit ? "readonly" : ""}
                      onChange={e =>
                        setChartOfAccout({
                          ...chartOfAccout,
                          es_name: e.target.value
                        })
                      }
                      value={chartOfAccout.es_name}
                    />
                  </label>
                </div>
                <hr />

                <div className="rowInputs">
                  <label htmlFor="tax_nature_id">
                    <div>
                      <FaFile size={20} />
                      <br />
                      Nat.
                    </div>
                    <Input
                      name="tax_nature_id"
                      placeholder="Natureza"
                      readOnly={true}
                      value={complement.nature.nome}
                    />
                    {edit && (
                      <button
                        type="button"
                        className="btnFinish"
                        onClick={() => handleSearch("provider", true)}
                      >
                        Buscar <FaSearch size={14} />
                      </button>
                    )}
                  </label>
                  {edit ? (
                    <button
                      type="submit"
                      className="btnFinish"
                      finish={finish.toString()}
                      disabled={!finish}
                      title={
                        !finish
                          ? "Há campos obrigatórios não preenchidos!"
                          : "Finalizar"
                      }
                    >
                      {finish ? (
                        <>
                          {chartOfAccout ? "Finalizar" : "Atualizar"}{" "}
                          <FaAngleRight size={20} />
                        </>
                      ) : (
                        <>
                          ... <FaTimesCircle size={20} />
                        </>
                      )}
                    </button>
                  ) : null}
                </div>
              </Form>
            </Container>
          </Content>
        </Scroll>
      )}
      {showSearch && (
        <BuscarNaturezas
          setShowSearch={setShowSearch}
          handleNature={handleNature}
        />
      )}
    </>
  );
}

import styled from "styled-components";

export const Container = styled.ul`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 160px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  .filterCase {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${props => (props.openDate ? "rgb(155,213,49)" : "transparent")};
    color: ${props => (props.openDate ? "#fff" : "#fff")};
    border: none;
    transition: all 0.2s;
    svg {
      margin: 0 5px;
    }

    > option {
      background: #fff;
      color: #333;
    }
  }
`;

import React from 'react'
import './style.css'

export default function FilterComponent({ title, children, firstComponent = false}) {

  return (
    <div className="filter-component-container">
      <div className={`filter-component-title ${firstComponent ? 'first-filter-component' : ''}`}>
        <span>{title}</span>
      </div>
      <div className="filter-component-element">
        {children}
      </div>
    </div>
  )
}
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaAngleDown, FaAngleRight, FaTrash, FaEdit } from "react-icons/fa";
import { format, addHours } from "date-fns";

import { formatMoney, formatedDate } from "~/scripts/index.js";
import { Container, ListCards } from "./styles";
import {
  amex,
  aura,
  banescard,
  cooper,
  credsystem,
  diners,
  discover,
  elo,
  greencard,
  hipercard,
  jcb,
  mastercard,
  sodexo,
  sorocred,
  ticket,
  visa
} from "~/assets/";

export default function TabelaCartoes({ cards, removeCards, edit, setEditCard }) {
  const filial = useSelector(state => state.filial);
  const [tableOpened, setTableOpened] = useState(true);

  function handleTable() {
    setTableOpened(!tableOpened);
  }

  const flags = [
    { id: "Amex", title: "Amex", img: amex },
    { id: "Aura", title: "Aura", img: aura },
    { id: "Cooper", title: "Cooper", img: cooper },
    { id: "Cred System", title: "Cred System", img: credsystem },
    { id: "Banescard", title: "Banescard", img: banescard },
    { id: "Diners", title: "Diners", img: diners },
    { id: "Discover", title: "Discover", img: discover },
    { id: "Elo", title: "Elo", img: elo },
    { id: "Green Card", title: "Green Card", img: greencard },
    { id: "Hipercard", title: "Hipercard", img: hipercard },
    { id: "Jcb", title: "Jcb", img: jcb },
    { id: "Mastercard", title: "Mastercard", img: mastercard },
    { id: "Sodexo", title: "Sodexo", img: sodexo },
    { id: "Sorocred", title: "Sorocred", img: sorocred },
    { id: "Ticket", title: "Ticket", img: ticket },
    { id: "Visa", title: "Visa", img: visa }
  ];

  return (
    <Container>
      <h1 onClick={handleTable}>
        {tableOpened ? <FaAngleDown size={20} /> : <FaAngleRight size={20} />}
        Transações em Cartões
      </h1>

      {tableOpened ? (
        <ListCards>
          <li>
            <table>
              <thead>
                <tr>
                  <td>Bandeira</td>
                  <td>Data</td>
                  <td>Valor</td>
                  <td>Operação</td>
                  <td>Parcelas</td>
                  <td>Payment ID</td>
                  <td>Ações</td>
                </tr>
              </thead>
              <tbody>
                {cards.length > 0 ? (
                  cards.map((card, index) =>
                    !card.remove ? (
                      <tr key={index}>
                        <td>
                          {flags.map(flag => {
                            if (card.flag === flag.title) {
                              return (
                                <img
                                  key={flag.title}
                                  src={flag.img}
                                  className="icone_flags"
                                  alt="icone_flags"
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        </td>
                        {/* <td>{format(new Date(card.date), "yyyy-MM-dd")}</td> */}
                        <td>
                          {formatedDate(
                            format(
                              addHours(new Date(card.date), 3),
                              "yyyy-MM-dd"
                            )
                          )}
                        </td>
                        <td>
                          {formatMoney(
                            card.value.toFixed(2),
                            filial.country.language
                          )}
                        </td>
                        <td>{card.operation}</td>
                        <td>{card.parcels}</td>
                        <td>{card.payment_id}</td>
                        {/* <td>{card.document}</td>
                        <td>{card.authorization}</td> */}
                        <td>
                          {edit && !card.qtd_parcels[0].conciliation_id && (
                            <div style={{ justifyContent: 'space-evenly', display: 'flex' }}>
                            <FaEdit
                              size={14}
                              onClick={() => {
                                setEditCard(index);
                              }}
                              style={{ cursor: "pointer" }}
                            />

                            <FaTrash
                              size={14}
                              onClick={() => {
                                removeCards(index);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            </div>
                          )}
                        </td>
                      </tr>
                    ) : null
                  )
                ) : (
                  <tr>
                    <td colSpan={9} style={{ textAlign: "inherit" }}>
                      Não há cartões
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </li>
        </ListCards>
      ) : null}
    </Container>
  );
}

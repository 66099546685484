import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFilial } from "~/store/modules/filial/actions";
import api from "~/services/api";
import { toast } from "react-toastify";

import Title from "~/components/Title";
import {
  maskPhone,
  maskZIP,
  maskCGC,
  formatMoney,
  formatNum,
  loadCountries
} from "~/scripts/index.js";
// import { valida_cpf_cnpj } from "~/scripts/valida_cgc.js";
import { confirmAlert } from "react-confirm-alert";
import * as Yup from "yup";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";

import { parseISO, format, startOfMonth, endOfMonth, addDays } from "date-fns";

import {
  FaPlus,
  FaMinus,
  FaKey,
  FaCalendarAlt,
  FaMoneyBillWave,
  FaTimes,
  FaUndo,
  FaAngleRight,
  FaTimesCircle,
  FaRegAddressCard,
  FaSearch,
  FaUserAlt,
  FaEnvelope,
  FaPhone,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaRoad,
  FaStreetView,
  FaUniversity,
  FaFlagUsa,
  FaGlobeAmericas,
  FaHome
} from "react-icons/fa";

import { Form, Input, Select, Choice } from "@rocketseat/unform";
import { Content, Container, Scroll } from "./styles";

export default function CreateFilials({
  handleCreateFilial,
  opennedCGC,
  edit,
  setFilials,
  filials,
  setBlock
}) {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const filialDefault = useSelector(state => state.filial);
  const [newFilial, setNewFilial] = useState(false);
  const [editFilial, setEditFilial] = useState(false);
  const [add, setAdd] = useState(false);
  const [remove, setRemove] = useState(false);
  const [finish] = useState(true);
  const [responsable, setResponsable] = useState("");
  // const [controllers, setControllers] = useState([]);
  const [filial, setFilial] = useState({
    cgc: "",
    zip: "",
    complement: "",
    phone: "",
    type: 1,
    regional_id: 9999,
    is_regional: false,
    country_id: 0,
    created_by: profile.id
  });
  const [regionais, setRegionais] = useState([]);
  const [editBalance, setEditBalance] = useState(true);

  const [countries, setCountries] = useState([]);

  const [pastoresAvaiable, setPastoresAvaiable] = useState([]);
  const [pastores, setPastores] = useState([]);

  const [tesoureiros, setTesoureiros] = useState([]);
  const [tesoureiro, setTesoureiro] = useState({});

  const [diaconos, setDiaconos] = useState([]);
  const [diacono, setDiacono] = useState({});

  const [newPastor, setNewPastor] = useState(null);

  const [verifyBalance, setVerifyBalance] = useState({});

  const [balance, setBalance] = useState({
    created_by: profile.id,
    created_at: false,
    filial_id: filial.id,
    date: new Date(),
    approved_at: new Date(),
    approved_by: profile.id,
    accounting_date: new Date(),
    accounting_by: profile.id,
    period_start: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    period_end: format(endOfMonth(new Date()), "yyyy-MM-dd"),
    balance: 0,
    tipo: 0,
    initial_balance: true
  });

  const balanceTipos = [
    { id: 1, title: "Positivo" },
    { id: 2, title: "Negativo" }
  ];

  useEffect(() => {
    async function loadFilial() {
      try {
        let promises = [];
        const response = await api.get("filiais/cgc/" + opennedCGC);
        const { data } = response;
        promises.push(
          new Promise((resolve, reject) => {
            resolve(loadRegionais(data[0] ? data[0].id : 0));
          })
        );
        if (data[0].cgc) {
          promises.push(
            new Promise((resolve, reject) => {
              resolve(
                setFilial({
                  ...data[0],
                  code: data[0].countryFilial.code,
                  country: data[0].countryFilial.code
                })
              );
            })
          );
          promises.push(
            new Promise((resolve, reject) => {
              resolve(setNewFilial(false));
            })
          );
          promises.push(
            new Promise((resolve, reject) => {
              resolve(setEditFilial(true));
            })
          );
          promises.push(
            new Promise((resolve, reject) => {
              resolve(
                setBalance({
                  ...balance,
                  filial_id: data[0].id
                })
              );
            })
          );
          Promise.all(promises).then(async results => {
            try {
              const response2 = await api.get("pastor/filial/" + data[0].id);
              const retPastores = response2.data;
              let arrayPastores = [];
              retPastores.map(p => {
                arrayPastores.push({
                  cgc: p.agente.cgc,
                  name: p.full_name,
                  id: p.id,
                  email: p.user.email,
                  agent_id: p.agent_id,
                  user_id: p.user.id
                });
                return null;
              });
              setPastores(arrayPastores);
            } catch (err) {}

            try {
              const response3 = await api.get("users/filial/" + data[0].id);
              const retUsers = response3.data;
              let arrayDiaconos = [];
              let arrayTesoureiros = [];
              retUsers.map(u => {
                if (u.group_id === 2) {
                  arrayDiaconos.push({
                    id: u.id,
                    name: u.nome,
                    email: u.email,
                    canceled_at: u.canceled_at
                  });
                }
                if (u.group_id === 3) {
                  arrayTesoureiros.push({
                    id: u.id,
                    name: u.nome,
                    email: u.email,
                    canceled_at: u.canceled_at
                  });
                }
                return null;
              });
              setDiaconos(arrayDiaconos);
              setTesoureiros(arrayTesoureiros);
            } catch (err) {}

            try {
              const response4 = await api.get("/balanceInitial/" + data[0].id);

              const {
                id,
                filial_id,
                period_start,
                period_end,
                balance,
                initial_balance,
                approved_at,
                approved_by,
                accounting_date,
                accounting_by,
                created_at
              } = response4.data.balance;

              setBalance({
                id,
                filial_id,
                period_start,
                period_end,
                balance,
                initial_balance,
                approved_at,
                approved_by,
                accounting_date,
                accounting_by,
                created_at,
                date: parseISO(period_start)
              });

              setVerifyBalance({
                id,
                filial_id,
                period_start,
                period_end,
                balance,
                initial_balance,
                approved_at,
                approved_by,
                accounting_date,
                accounting_by,
                created_at,
                date: parseISO(period_start)
              });

              setEditBalance(!!!response4.data.verify.approved_at);
            } catch (err) {}
            return null;
          });
        }
      } catch (err) {}
    }

    async function loadPastoresAvaiable() {
      try {
        const response = await api.get("pastor/avaiable/");
        const { data } = response;
        setPastoresAvaiable([]);
        let arrayPastoresAvaiable = [];
        data.map(p => {
          arrayPastoresAvaiable.push({
            id: p.agente.id,
            title: maskCGC(p.agente.cgc) + " | " + p.full_name
          });
          return null;
        });
        setPastoresAvaiable(arrayPastoresAvaiable);
      } catch (err) {}
    }
    async function loadRegionais(filial_id) {
      try {
        const response_regionais = await api.get(
          "/filiais/regionais/" + filial_id
        );
        const { data } = response_regionais;
        let filRegionais = [
          { id: 9999, title: "Esta filial não está abaixo de outra." }
        ];
        data.map(filial => {
          return filRegionais.push({ id: filial.id, title: filial.name });
        });
        setRegionais(filRegionais);
      } catch (err) {}
    }

    loadCountries(setCountries, false);

    loadFilial();

    loadPastoresAvaiable();

    setRemove(false);

    setAdd(false);
  }, [opennedCGC, remove, add]);

  async function handleZIP(cep) {
    if (filial.country === "BR") {
      if (cep.length === 8) {
        const response = await api.get("correios/" + cep.toString());
        const { data } = response;
        if (data.logradouro) {
          setFilial({
            ...filial,
            zip: cep,
            address: data.logradouro,
            complement: data.complemento,
            neighborhood: data.bairro,
            city: data.localidade,
            state: data.uf
          });
          return;
        }
      }
    }
    setFilial({
      ...filial,
      zip: cep.toString(),
      address: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: ""
    });
  }

  async function findCGC(e) {
    e.preventDefault();
    const valCgc = filial.cgc.replace(/\D/g, "");
    if (!valCgc) {
      toast.error("Preencha o campo de Documento!", { className: "error" });
      return;
    }
    // if (!valida_cpf_cnpj(valCgc) && valCgc !== "99999999999999") {
    //   toast.error("Documento inválido", { className: "error" });
    //   return;
    // }
    try {
      const response = await api.get("filiais/cgc/" + valCgc);
      const { data } = response;
      if (data.length > 0) {
        setNewFilial(false);
        setEditFilial(true);
      } else {
        setNewFilial(true);
        setEditFilial(false);
      }
      return;
    } catch (err) {
      setFilial({ ...filial, cgc: valCgc });
      setNewFilial(true);
      setEditFilial(false);
    }
    setFilial({ ...filial, cgc: valCgc });
    setNewFilial(true);
    setEditFilial(false);
  }

  async function handleSubmit(e) {
    setFilial({ ...filial, createdBy: profile.id });

    // if (!(balance.balance > 0)) {
    //   return toast.error("Preencha o saldo inicial", {
    //     className: "error"
    //   });
    // }

    try {
      const response =
        newFilial && !filial.id
          ? await api.post("filiais/", {
              ...filial,
              regional_id:
                filial.regional_id === "9999" ? null : filial.regional_id
            })
          : await api.put("filiais/", {
              ...filial,
              regional_id:
                filial.regional_id === "9999" ? null : filial.regional_id
            });

      setBalance({ ...balance, filial_id: response.data.id });

      toast.success(
        `Filial ${newFilial ? "inserida" : "alterada"} com sucesso!`,
        { className: "success" }
      );

      setFilial({ ...filial, id: response.data.id });

      if (newFilial) {
        setFilials([...filials, filial]);
      }

      if (
        verifyBalance.balance !== balance.balance ||
        verifyBalance.date + "" !== balance.date + ""
      ) {
        if (profile.group_id === 1 || profile.group_id === 5) {
          if (!balance.id || editBalance) {
            handleAddBalance(response.data.id, newFilial);
          }
        }
      }

      if (response.data.id == filial.id) {
        dispatch(
          updateFilial({
            filial: response.data.id,
            filial_name: response.data.name,
            open_date: format(
              addDays(parseISO(response.data.filialBalance.period_end), 1),
              "yyyy-MM-dd"
            ),
            country: response.data.countryFilial,
            initialBalance: filialDefault.initialBalance
          })
        );
      }
      handleCreateFilial();

      setBlock(true);
      return;
    } catch (err) {
      toast.error("Por favor, revise os dados!", { className: "error" });
    }
  }

  async function handleAddPastor() {
    if (newPastor) {
      try {
        const response = await api.get("pastor?agentId=" + newPastor);
        const { data } = response;

        let user = null;
        if (!data.agente.email) {
          toast.error(
            "Por favor, preencha o e-mail deste pastor para ser criado seu usuário de acesso.",
            { className: "error" }
          );
        }
        if (data.user) {
          try {
            const response2 = await api.put("users/associate_filial", {
              email: data.agente.email,
              filial_id: filial.id,
              updated_by: profile.id,
              id: data.agente.id,
              group_id: 4
            });
            user = response2.data;
            toast.success(`Pastor associado à filial com sucesso!`, {
              className: "success"
            });
          } catch (err) {
            toast.error(`Não foi possível associar o pastor à filial.`, {
              className: "error"
            });
          }
        } else {
          try {
            const response2 = await api.post("users", {
              created_by: profile.id,
              filial_id: filial.id,
              nome: data.full_name,
              group_id: 4,
              password: "12345678",
              language: profile.language,
              email: data.agente.email
            });
            user = response2.data;

            await api.put("users/associate_filial", {
              email: user.email,
              filial_id: user.filial_id,
              updated_by: profile.id,
              id: user.id,
              group_id: 4
            });

            toast.success(`Usuário do pastor incluído com sucesso!`, {
              className: "success"
            });
          } catch (err) {
            toast.error(`Erro ao criar o usuário do Pastor.`, {
              className: "error"
            });
          }
        }
        await api.put("pastor", {
          agent_id: newPastor,
          filial_id: filial.id,
          user_id: user.id,
          updated_by: profile.id
        });
        if (pastores.length === 0) {
          await api.put("filiais", {
            cgc: filial.cgc,
            responsible_id: user.id
          });
        }
      } catch (err) {
        toast.error(`Não foi possível associar o pastor à filial.`, {
          className: "error"
        });
      }
      setAdd(true);
      setNewPastor(null);
    }
  }

  async function handleRemovePastor(p) {
    const options = {
      title: "Remover pastor",
      message: `Tem certeza que deseja remover este pastor desta filial?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await api.put("pastor", {
                agent_id: p.agent_id,
                filial_id: null
              });

              await api.put("users/disassociate_filial", {
                user_id: p.user_id,
                profile_id: profile.id
              });

              toast.success(`Pastor removido da filial com sucesso!`, {
                className: "success"
              });
              setRemove(true);
              setNewPastor(null);
              return;
            } catch (err) {
              toast.error(`Não foi possível removê-lo da filial.`, {
                className: "error"
              });
              return;
            }
          }
        },
        {
          label: "Cancelar"
        }
      ]
    };
    if (filial.responsible_id === p.user_id) {
      toast.error(
        "Este pastor é responsável pela filial. Impossível removê-lo.",
        { className: "error" }
      );
      return;
    }
    confirmAlert(options);
  }

  async function handleRemoveUser(u) {
    const options = {
      title: `${u.canceled_at ? "Desbloquear" : "Bloquear"} usuário`,
      message: `Tem certeza que deseja ${
        u.canceled_at ? "desbloquear" : "bloquear"
      } este usuário?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const changes = {
              user_id: u.id,
              email: u.email,
              profile_id: profile.id
            };
            try {
              await api.put("users/disassociate_filial", changes);
              toast.success(
                `Usuário ${
                  u.canceled_at ? "desbloqueado" : "bloqueado"
                } com sucesso!`,
                { className: "success" }
              );
              setRemove(true);
              return;
            } catch (err) {
              toast.error(
                `Não foi possível ${
                  u.canceled_at ? "desbloqueá-lo" : "bloqueá-lo"
                }.`,
                { className: "error" }
              );
            }
          }
        },
        {
          label: "Cancelar"
        }
      ]
    };
    confirmAlert(options);
  }

  async function setAssociateFilial(data) {
    const response = await api.put("users/associate_filial", {
      email: data.email,
      filial_id: data.filial_id,
      updated_by: profile.id,
      group_id: data.group_id,
      new_user: data.new_user ? data.new_user : false
    });
    return response.data;
  }

  async function handleAddUser(type) {
    if (type === "diacono") {
      if (!diacono.nome || !diacono.email) {
        toast.error("Há campos não preenchidos!", { className: "error" });
        return;
      }
      try {
        /* await api.get(`agents/verify/${diacono.email}`) */

        const { data } = await api.post("users", {
          ...diacono,
          group_id: 2,
          language: profile.language,
          filial_id: filial.id,
          created_by: profile.id,
          password: "12345678"
        });

        setAssociateFilial({ ...data, new_user: true });

        toast.success("Diácono criado com sucesso!", { className: "success" });
        setDiacono({ nome: "", email: "" });
        setAdd(true);
        return;
      } catch (err) {
        if (
          err.response.data.error.name === "SequelizeUniqueConstraintError" ||
          err.response.data.error === "user-already-exists"
        ) {
          try {
            await setAssociateFilial({
              email: diacono.email,
              filial_id: filial.id,
              updated_by: profile.id,
              group_id: 2
            });
          } catch (err) {
            toast.error("e-mail já foi associado.", { className: "error" });
            return;
          }
          setDiacono({ nome: "", email: "" });
          setAdd(true);
          toast.success("Diácono adicionado com sucesso!", {
            className: "success"
          });
        } else {
          toast.error("Ocorreu algum erro ao processar sua solicitação.", {
            className: "error"
          });
        }
      }
    }
    if (type === "tesoureiro") {
      if (!tesoureiro.nome || !tesoureiro.email) {
        toast.error("Há campos não preenchidos!", { className: "error" });
        return;
      }
      try {
        const { data } = await api.post("users", {
          ...tesoureiro,
          group_id: 3,
          filial_id: filial.id,
          language: profile.language,
          created_by: profile.id,
          password: "12345678"
        });

        setAssociateFilial({ ...data, new_user: true });

        toast.success("Tesoureiro criado com sucesso!", {
          className: "success"
        });
        setTesoureiro({ nome: "", email: "" });
        setAdd(true);
        return;
      } catch (err) {
        if (
          err.response.data.error.name === "SequelizeUniqueConstraintError" ||
          err.response.data.error === "user-already-exists"
        ) {
          try {
            await setAssociateFilial({
              email: tesoureiro.email,
              filial_id: filial.id,
              updated_by: profile.id,
              group_id: 3
            });
          } catch (err) {
            toast.error("e-mail já foi associado.", { className: "error" });
            return;
          }
          setTesoureiro({ nome: "", email: "" });
          setAdd(true);
          toast.success("Tesoureiro adicionado com sucesso!", {
            className: "success"
          });
        } else {
          toast.error("Ocorreu algum erro ao processar sua solicitação.", {
            className: "error"
          });
        }
      }
    }
  }

  async function handleResetPw(user) {
    try {
      console.log(user);
      /* await api.get(`agents/verify/${diacono.email}`) */

      await api.put("reset_password", {
        id: user.id,
        email: user.email
      });
      toast.success("Senha do usuário resetada com sucesso!", {
        className: "success"
      });
    } catch (err) {
      toast.error("Não foi possível resetar a senha do usuário.", {
        className: "error"
      });
    }
  }

  async function handleResponsible(p) {
    if (p.user_id !== filial.responsible_id) {
      try {
        const response = await api.put("filiais", {
          cgc: filial.cgc,
          responsible_id: p.user_id,
          updated_by: profile.id
        });
        setFilial(response.data);
        toast.success("Pastor definido como responsável com sucesso!", {
          className: "success"
        });
      } catch (err) {
        toast.error(
          "Não foi possível tornar este pastor responsável pela filial.",
          { className: "error" }
        );
        return;
      }
    }
  }

  async function handleAddBalance(id, newFilial) {
    try {
      const response = await api.post("/balance", {
        ...balance,
        filial_id: id
      });

      await api.post(`/filial_closings/initial/${id}`, {
        year: response.data.period_start.substring(0, 4),
        month: response.data.period_start.substring(5, 7),
        balance: response.data.balance
      });

      setBalance({
        ...balance,
        created_at: response.data.created_at,
        created_by: response.data.created_by
      });

      if (!newFilial) {
        try {
          const response4 = await api.get(
            "/balanceInitial/" + balance.filial_id
          );
          const {
            id,
            filial_id,
            period_start,
            period_end,
            balance,
            initial_balance,
            approved_at,
            approved_by,
            accounting_date,
            accounting_by,
            created_at
          } = response4.data.balance;

          setVerifyBalance({
            id,
            filial_id,
            period_start,
            period_end,
            balance,
            initial_balance,
            approved_at,
            approved_by,
            accounting_date,
            accounting_by,
            created_at,
            date: parseISO(period_start)
          });
        } catch (err) {}
      }

      if (response.data.filial_id == filial.id) {
        dispatch(
          updateFilial({
            ...filialDefault,
            open_date: format(
              addDays(parseISO(response.data.period_end), 1),
              "yyyy-MM-dd"
            ),
            initialBalance: {
              ...filial.initialBalance,
              period_end: response.data.period_end
            }
          })
        );
      }

      toast.success("Caixa criado com sucesso!", { className: "success" });
    } catch (err) {
      toast.error("Há campos não preenchidos!", { className: "error" });
    }
  }

  function handleCountry(country) {
    let code = null;
    let id = null;
    countries.map(c => {
      if (c.id === parseInt(country)) {
        code = c.code;
        id = c.id;
      }
    });
    setFilial({ ...filial, country: code, country_id: id });
  }

  const schemaFilial = Yup.object().shape({
    name: Yup.string().required("Nome da filial obrigatório."),
    zip: Yup.string().required("CEP obrigatório."),
    // address: Yup.string().required("Endereço obrigatório."),
    // number: Yup.string("Digite um número válido").required(
    //   "Número obrigatório."
    // ),
    // neighborhood: Yup.string().required("Bairro obrigatório."),
    // city: Yup.string().required("Cidade obrigatório."),
    // state: Yup.string().required("Estado obrigatório."),
    country_id: Yup.string().required("País obrigatório.")
  });

  const schemaTesoureiros = Yup.object().shape({
    name_tesoureiro: Yup.string().required("Nome obrigatório."),
    email_tesoureiro: Yup.string()
      .email("Insira um e-mail válido!")
      .required("E-mail obrigatório.")
  });

  const schemaDiacono = Yup.object().shape({
    name_diacono: Yup.string().required("Nome obrigatório."),
    email_diacono: Yup.string()
      .email("Insira um e-mail válido!")
      .required("E-mail obrigatório.")
  });

  return (
    <Scroll>
      <Content>
        <header>
          <Title back={true} func={handleCreateFilial} title={"Cancelar"} />
        </header>
        {newFilial || editFilial ? (
          <Container>
            <>
              {/* {selectPlaceholder(4)} */}
              <h1>{newFilial ? "Nova Filial" : "Editar Filial"}</h1>
              <Form
                className="pricipal_form"
                autoComplete="off"
                schema={schemaFilial}
                onSubmit={handleSubmit}
              >
                <div className="rowInputs">
                  <label htmlFor="cgc">
                    <div>
                      <FaRegAddressCard size={20} />
                      <br />
                      Doc.
                    </div>
                    <Input
                      name="cgc"
                      readOnly="readonly"
                      placeholder="Documento"
                      onChange={e =>
                        setFilial({ ...filial, cgc: e.target.value })
                      }
                      // maxLength="18"
                      // value={maskCGC(filial.cgc)}
                      value={filial.cgc}
                    />
                  </label>
                  <label htmlFor="name">
                    <div>
                      <FaUserAlt size={20} />
                      <br />
                      Nome
                    </div>
                    <Input
                      name="name"
                      placeholder="Nome da filial"
                      readOnly={!edit ? "readonly" : ""}
                      maxLength="25"
                      onChange={e =>
                        setFilial({ ...filial, name: e.target.value })
                      }
                      value={filial.name}
                    />
                  </label>
                  <label htmlFor="responsible_id">
                    <div>
                      <FaUserAlt size={20} />
                      <br />
                      Tipo
                    </div>
                    <Select
                      options={[
                        { id: true, title: "Regional" },
                        { id: false, title: "Filial" }
                      ]}
                      disabled={!edit ? "disabled" : ""}
                      name="is_regional"
                      value={filial.is_regional}
                      selected={filial.is_regional}
                      onChange={e =>
                        setFilial({ ...filial, is_regional: e.target.value })
                      }
                    />
                  </label>
                </div>
                <hr />

                <div className="rowInputs">
                  <label htmlFor="country_id">
                    <div>
                      {filial.countryFilial || filial.country ? (
                        filial.countryFilial && !filial.country ? (
                          <img
                            src={`/images/${filial.countryFilial.code}.svg`}
                            width="30"
                          />
                        ) : (
                          <img
                            src={`/images/${filial.country}.svg`}
                            width="30"
                          />
                        )
                      ) : (
                        <FaGlobeAmericas
                          size={20}
                          style={{ marginTop: "4px" }}
                        />
                      )}
                    </div>
                    <Select
                      options={countries}
                      disabled={!edit ? "disabled" : ""}
                      placeholder="Selecione um país"
                      name="country_id"
                      onChange={e => handleCountry(e.target.value)}
                      value={filial.country_id ? filial.country_id : ""}
                    />
                  </label>
                </div>
                <hr />

                {filial.country || filial.countryFilial ? (
                  <>
                    <div className="rowInputs">
                      <label htmlFor="zip">
                        <div>
                          <FaMapMarkedAlt size={20} />
                          <br />
                          CEP
                        </div>
                        <Input
                          name="zip"
                          placeholder="00000-000"
                          readOnly={!edit ? "readonly" : ""}
                          maxLength="9"
                          onChange={e => handleZIP(e.target.value)}
                          value={
                            filial.country === "BR"
                              ? maskZIP(filial.zip)
                              : filial.zip
                          }
                        />
                      </label>
                      {filial.country === "BR" ? (
                        <>
                          <label htmlFor="address">
                            <div>
                              <FaRoad size={20} />
                              <br />
                              End.
                            </div>
                            <Input
                              name="address"
                              readOnly={!edit ? "readonly" : null}
                              placeholder="Endereço"
                              onChange={e =>
                                setFilial({
                                  ...filial,
                                  address: e.target.value
                                })
                              }
                              maxLength="60"
                              value={filial.address}
                            />
                          </label>
                          <label htmlFor="number">
                            <div>
                              <FaMapMarkerAlt size={20} />
                              <br />
                              Número
                            </div>
                            <Input
                              name="number"
                              placeholder="--"
                              readOnly={!edit ? "readonly" : ""}
                              maxLength="8"
                              onChange={e =>
                                setFilial({
                                  ...filial,
                                  number: e.target.value
                                    .toString()
                                    .replace(/[^\d]+/g, "")
                                })
                              }
                              value={filial.number === 0 ? null : filial.number}
                            />
                          </label>
                        </>
                      ) : (
                        <>
                          <label htmlFor="number">
                            <div>
                              <FaMapMarkerAlt size={20} />
                              <br />
                              Número
                            </div>
                            <Input
                              name="number"
                              placeholder="--"
                              readOnly={!edit ? "readonly" : ""}
                              maxLength="8"
                              onChange={e =>
                                setFilial({
                                  ...filial,
                                  number: e.target.value
                                    .toString()
                                    .replace(/[^\d]+/g, "")
                                })
                              }
                              value={filial.number === 0 ? null : filial.number}
                            />
                          </label>
                          <label htmlFor="address">
                            <div>
                              <FaRoad size={20} />
                              <br />
                              End.
                            </div>
                            <Input
                              name="address"
                              readOnly={!edit ? "readonly" : null}
                              placeholder="Endereço"
                              onChange={e =>
                                setFilial({
                                  ...filial,
                                  address: e.target.value
                                })
                              }
                              maxLength="60"
                              value={filial.address}
                            />
                          </label>
                        </>
                      )}
                      <label htmlFor="complement">
                        <div>
                          <FaHome size={18} />
                          <br />
                          Comple.
                        </div>
                        <Input
                          name="complement"
                          onChange={e =>
                            setFilial({ ...filial, complement: e.target.value })
                          }
                          value={filial.complement}
                          placeholder="Complemento"
                        />
                      </label>
                    </div>
                    <hr />

                    <div className="rowInputs">
                      <label htmlFor="neighborhood">
                        <div>
                          <FaStreetView size={20} />
                          <br />
                          Bairro
                        </div>
                        <Input
                          name="neighborhood"
                          readOnly={!edit ? "readonly" : null}
                          placeholder="Bairro"
                          onChange={e =>
                            setFilial({
                              ...filial,
                              neighborhood: e.target.value
                            })
                          }
                          maxLength="60"
                          value={filial.neighborhood}
                        />
                      </label>
                      <label htmlFor="city">
                        <div>
                          <FaUniversity size={20} />
                          <br />
                          Cidade
                        </div>
                        <Input
                          name="city"
                          readOnly={!edit ? "readonly" : null}
                          placeholder="Cidade"
                          onChange={e =>
                            setFilial({ ...filial, city: e.target.value })
                          }
                          value={filial.city}
                        />
                      </label>
                      <label htmlFor="state">
                        <div>
                          <FaFlagUsa size={20} />
                          <br />
                          Estado
                        </div>
                        <Input
                          name="state"
                          readOnly={!edit ? "readonly" : null}
                          placeholder="Estado"
                          maxLength="2"
                          onChange={e =>
                            setFilial({ ...filial, state: e.target.value })
                          }
                          value={filial.state}
                        />
                      </label>
                    </div>
                    <hr />

                    <div className="rowInputs">
                      <label htmlFor="phone">
                        <div>
                          <FaPhone size={20} />
                          <br />
                          Telefone
                        </div>
                        <Input
                          name="phone"
                          placeholder={
                            filial.country
                              ? filial.country === "BR"
                                ? "(99) 99999-9999"
                                : "(999) 999-9999"
                              : "(99) 99999-9999"
                          }
                          readOnly={!edit ? "readonly" : ""}
                          maxLength="15"
                          onChange={e =>
                            setFilial({ ...filial, phone: e.target.value })
                          }
                          value={maskPhone(
                            filial.phone.toString(),
                            filial.country ? filial.country : "BR"
                          )}
                        />
                      </label>
                      <label htmlFor="email">
                        <div>
                          <FaEnvelope size={20} />
                          <br />
                          E-mail
                        </div>
                        <Input
                          name="email"
                          type="email"
                          placeholder="@email.com"
                          readOnly={!edit ? "readonly" : ""}
                          maxLength="40"
                          onChange={e =>
                            setFilial({ ...filial, email: e.target.value })
                          }
                          value={filial.email}
                        />
                      </label>
                      <label htmlFor="id237">
                        <div>
                          <FaEnvelope size={20} />
                          <br />
                          Banco
                        </div>
                        <Input
                          name="id237"
                          type="id237"
                          placeholder="Cód Depósito"
                          readOnly={!edit ? "readonly" : ""}
                          maxLength="8"
                          onChange={e =>
                            setFilial({ ...filial, id237: e.target.value })
                          }
                          value={filial.id237 ? filial.id237 : ""}
                        />
                      </label>
                    </div>
                    <hr />

                    <div className="rowInputs">
                      {/* <label htmlFor="controller_id">
                    <div>
                      <FaUserAlt size={20} />
                      <br />
                      Control.
                    </div>
                    <Select
                      options={controllers}
                      disabled={!edit ? "disabled" : ""}
                      name="controller_id"
                      onChange={e =>
                        setFilial({ ...filial, controller_id: e.target.value })
                      }
                      value={filial.controller_id}
                      placeholder={responsable}
                    />
                  </label> */}
                      <label htmlFor="regional_id">
                        <div>
                          <FaUserAlt size={20} />
                          <br />
                          Regional
                        </div>
                        <Select
                          options={regionais}
                          disabled={!edit ? "disabled" : ""}
                          name="regional_id"
                          value={
                            filial.regional_id !== null
                              ? filial.regional_id
                              : 9999
                          }
                          selected={
                            filial.regional_id !== null
                              ? filial.regional_id
                              : 9999
                          }
                          onChange={e =>
                            setFilial({
                              ...filial,
                              regional_id: e.target.value
                            })
                          }
                        />
                      </label>
                    </div>
                    <h1>Caixa Inicial</h1>

                    <div
                      className="rowInputs"
                      style={{ marginTop: 15, marginBottom: 15 }}
                    >
                      <label htmlFor="date">
                        <div>
                          <FaCalendarAlt size={20} />
                          <br />
                          Data
                        </div>
                        <DatePicker
                          readOnly={
                            balance.created_at && !editBalance ? "readonly" : ""
                          }
                          name="date"
                          showMonthYearPicker
                          selected={balance.date}
                          onChange={date =>
                            setBalance({
                              ...balance,
                              date: parseISO(format(date, "yyyy-MM-dd")),
                              period_start: format(
                                startOfMonth(date),
                                "yyyy-MM-dd"
                              ),
                              period_end: format(endOfMonth(date), "yyyy-MM-dd")
                            })
                          }
                          dateFormat="MM/yyyy"
                          locale={pt}
                        />
                        {edit && (
                          <span
                            style={{
                              bottom: "-46px",
                              fontSize: "11px",
                              padding: "6px 6px",
                              display: "block",
                              textAlign: "start",
                              color: "#222"
                            }}
                          >
                            Somente serão possíveis realizar lançamentos a
                            partir do mês seguinte da data prevista acima.
                          </span>
                        )}
                      </label>
                      <label htmlFor="tipo">
                        <div>
                          {balance.tipo == 1 || !balance.tipo ? (
                            <FaPlus size={20} />
                          ) : (
                            <FaMinus size={20} />
                          )}
                          <br />
                          Saldo
                        </div>
                        <Select
                          options={balanceTipos}
                          name="tipo"
                          value={
                            balance.tipo > 0
                              ? balance.tipo
                              : balance.balance > 0
                              ? 1
                              : 2
                          }
                          selected={
                            balance.tipo > 0
                              ? balance.tipo
                              : balance.balance > 0
                              ? 1
                              : 2
                          }
                          onChange={e =>
                            setBalance({
                              ...balance,
                              tipo: e.target.value,
                              balance: formatNum(
                                balance.balance.toFixed(2),
                                e.target.value == 2
                              )
                            })
                          }
                        />
                      </label>
                      <label htmlFor="balance">
                        <div>
                          <FaMoneyBillWave size={20} />
                          <br />
                          Valor
                        </div>
                        <Input
                          name="balance"
                          readOnly={
                            balance.created_at && !editBalance ? "readonly" : ""
                          }
                          onChange={e =>
                            setBalance({
                              ...balance,
                              balance: formatNum(
                                e.target.value,
                                balance.tipo == 2
                              )
                            })
                          }
                          value={formatMoney(
                            balance.balance.toFixed(2),
                            filial.country ? filial.country.language : "pt-BR"
                          )}
                        />
                      </label>

                      {edit ? (
                        <button
                          type="submit"
                          className="btnFinish"
                          finish={finish.toString()}
                          disabled={!finish}
                          title={
                            !finish
                              ? "Há campos obrigatórios não preenchidos!"
                              : "Finalizar"
                          }
                        >
                          {finish ? (
                            <>
                              {newFilial && !filial.id ? "Gravar" : "Atualizar"}
                              <FaAngleRight size={20} />
                            </>
                          ) : (
                            <>
                              ... <FaTimesCircle size={20} />
                            </>
                          )}
                        </button>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </Form>
              {filial.id && (
                <>
                  <h1>Pastores</h1>
                  <div className="rowInputs">
                    {pastores.map((p, index) => {
                      if (p.email) {
                        return (
                          <Form key={index} initialData={p}>
                            <div className="rowInputs">
                              <label className="radioInput">
                                <Choice
                                  name="responsible"
                                  onClick={e => handleResponsible(p)}
                                  options={[{ id: "S" }]}
                                />
                                <span className="radioCheck">
                                  {filial.responsible_id === p.user_id ? (
                                    <FaKey size={16} />
                                  ) : null}
                                </span>
                              </label>
                              <label htmlFor={`pastor_name_${index}`}>
                                <div>
                                  <FaUserAlt size={20} />
                                  <br />
                                  Nome
                                </div>
                                <Input
                                  name={`pastor_name_${index}`}
                                  readOnly="readonly"
                                  placeholder="Nome do Pastor"
                                  value={p.name}
                                  maxLength="60"
                                />
                              </label>
                              <label htmlFor={`pastor_email_${index}`}>
                                <div>
                                  <FaEnvelope size={20} />
                                  <br />
                                  E-mail
                                </div>
                                <Input
                                  name={`pastor_email_${index}`}
                                  readOnly="readonly"
                                  type="email"
                                  placeholder="E-mail do Pastor"
                                  value={p.email}
                                  maxLength="60"
                                />
                              </label>
                              <label htmlFor={`pastor_cpf_${index}`}>
                                <div>
                                  <FaRegAddressCard size={20} />
                                  <br />
                                  CPF
                                </div>
                                <Input
                                  name={`pastor_cpf_${index}`}
                                  readOnly="readonly"
                                  placeholder="Documento do Pastor"
                                  // maxLength="18"
                                  // value={maskCGC(p.cgc)}
                                  value={p.cgc}
                                />
                              </label>
                              {edit ? (
                                <>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleResetPw({
                                        id: p.user_id,
                                        email: p.email
                                      })
                                    }
                                    className="btn reset"
                                  >
                                    Resetar Senha
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => handleRemovePastor(p)}
                                    className="btn remove"
                                  >
                                    <FaTimes size={12} />
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </Form>
                        );
                      }
                    })}
                    {edit ? (
                      <>
                        <hr style={{ margin: "0 15px" }} />
                        <Form>
                          <div className="rowInputs">
                            <label htmlFor="cpf">
                              <div>
                                <FaRegAddressCard size={20} />
                                <br />
                                Pastor
                              </div>
                              <Select
                                name="avaiablePastor"
                                placeholder="Adicionar pastor..."
                                options={pastoresAvaiable}
                                onChange={e => setNewPastor(e.target.value)}
                                value={newPastor || null}
                              />
                            </label>
                            {newPastor ? (
                              <button
                                type="button"
                                onClick={() => handleAddPastor()}
                                className="btn"
                              >
                                <FaPlus size={12} />
                              </button>
                            ) : null}
                          </div>
                        </Form>
                      </>
                    ) : null}
                  </div>

                  <h1>Tesoureiros</h1>
                  {tesoureiros.map((t, index) => {
                    return (
                      <Form
                        key={index}
                        className={`${t.canceled_at ? "canceled" : ""}`}
                      >
                        <div className="rowInputs">
                          <label htmlFor={`tesoureiro_name_${index}`}>
                            <div>
                              <FaUserAlt size={20} />
                              <br />
                              Nome
                            </div>
                            <Input
                              name={`tesoureiro_name_${index}`}
                              readOnly="readonly"
                              placeholder="Nome do Tesoureiro"
                              value={t.name}
                              maxLength="60"
                            />
                          </label>
                          <label htmlFor={`tesoureiro_email_${index}`}>
                            <div>
                              <FaEnvelope size={20} />
                              <br />
                              E-mail
                            </div>
                            <Input
                              name={`tesoureiro_email_${index}`}
                              readOnly="readonly"
                              type="email"
                              placeholder="E-mail do Tesoureiro"
                              value={t.email}
                              maxLength="60"
                            />
                          </label>
                          {edit ? (
                            <>
                              <button
                                type="button"
                                onClick={() => handleResetPw(t)}
                                className="btn reset"
                              >
                                Resetar Senha
                              </button>
                              <button
                                type="button"
                                onClick={() => handleRemoveUser(t)}
                                className="btn remove"
                              >
                                {t.canceled_at ? (
                                  <FaUndo size={12} />
                                ) : (
                                  <FaTimes size={12} />
                                )}
                              </button>
                            </>
                          ) : null}
                        </div>
                      </Form>
                    );
                  })}
                  {edit ? (
                    <>
                      <hr style={{ margin: "0 15px" }} />
                      <Form
                        schema={schemaTesoureiros}
                        onSubmit={() => handleAddUser("tesoureiro")}
                      >
                        <div className="rowInputs">
                          <label htmlFor="name_tesoureiro">
                            <div>
                              <FaUserAlt size={20} />
                              <br />
                              Nome
                            </div>
                            <Input
                              name="name_tesoureiro"
                              placeholder="Nome do Tesoureiro"
                              maxLength="20"
                              onChange={e =>
                                setTesoureiro({
                                  ...tesoureiro,
                                  nome: e.target.value
                                })
                              }
                              value={tesoureiro.nome}
                            />
                          </label>
                          <label htmlFor="email_tesoureiro">
                            <div>
                              <FaEnvelope size={20} />
                              <br />
                              E-mail
                            </div>
                            <Input
                              name="email_tesoureiro"
                              type="email"
                              placeholder="E-mail do Tesoureiro"
                              onChange={e =>
                                setTesoureiro({
                                  ...tesoureiro,
                                  email: e.target.value
                                })
                              }
                              value={tesoureiro.email}
                              maxLength="60"
                            />
                          </label>
                          <button type="submit" className="btn">
                            <FaPlus size={12} />
                          </button>
                        </div>
                      </Form>
                    </>
                  ) : null}

                  <h1>Diáconos</h1>
                  {diaconos.map((d, index) => {
                    return (
                      <Form
                        key={index}
                        className={`${d.canceled_at ? "canceled" : ""}`}
                      >
                        <div className="rowInputs">
                          <label htmlFor={`diacono_name_${index}`}>
                            <div>
                              <FaUserAlt size={20} />
                              <br />
                              Nome
                            </div>
                            <Input
                              name={`diacono_name_${index}`}
                              readOnly="readonly"
                              placeholder="Nome do Diácono"
                              value={d.name}
                              maxLength="60"
                            />
                          </label>
                          <label htmlFor={`diacono_email_${index}`}>
                            <div>
                              <FaEnvelope size={20} />
                              <br />
                              E-mail
                            </div>
                            <Input
                              name={`diacono_email_${index}`}
                              readOnly="readonly"
                              type="email"
                              placeholder="E-mail do Diácono"
                              value={d.email}
                              maxLength="60"
                            />
                          </label>
                          {edit ? (
                            <>
                              <button
                                type="button"
                                onClick={() => handleResetPw(d)}
                                className="btn reset"
                              >
                                Resetar Senha
                              </button>
                              <button
                                type="button"
                                onClick={() => handleRemoveUser(d)}
                                className="btn remove"
                              >
                                {d.canceled_at ? (
                                  <FaUndo size={12} />
                                ) : (
                                  <FaTimes size={12} />
                                )}
                              </button>
                            </>
                          ) : null}
                        </div>
                      </Form>
                    );
                  })}
                  {edit ? (
                    <>
                      <hr style={{ margin: "0 15px" }} />
                      <Form
                        schema={schemaDiacono}
                        onSubmit={() => handleAddUser("diacono")}
                      >
                        <div className="rowInputs">
                          <label htmlFor="name_diacono">
                            <div>
                              <FaUserAlt size={20} />
                              <br />
                              Nome
                            </div>
                            <Input
                              name="name_diacono"
                              placeholder="Nome do Diácono"
                              maxLength="20"
                              onChange={e =>
                                setDiacono({ ...diacono, nome: e.target.value })
                              }
                              value={diacono.nome}
                            />
                          </label>
                          <label htmlFor="email_diacono">
                            <div>
                              <FaEnvelope size={20} />
                              <br />
                              E-mail
                            </div>
                            <Input
                              name="email_diacono"
                              type="email"
                              placeholder="E-mail do Diácono"
                              onChange={e =>
                                setDiacono({
                                  ...diacono,
                                  email: e.target.value
                                })
                              }
                              value={diacono.email}
                              maxLength="60"
                            />
                          </label>
                          <button type="submit" className="btn">
                            <FaPlus size={12} />
                          </button>
                        </div>
                      </Form>
                    </>
                  ) : null}
                </>
              )}
            </>
          </Container>
        ) : (
          <Container id="newAgent">
            <h1>Verificar Documento</h1>
            <form onSubmit={findCGC}>
              <div className="rowInputs">
                <label htmlFor="cgc">
                  <div>
                    <FaRegAddressCard size={20} />
                    <br />
                    Doc.
                  </div>
                  <Input
                    name="cgc"
                    placeholder="Documento"
                    onChange={cgc =>
                      setFilial({ ...filial, cgc: cgc.target.value })
                    }
                    // maxLength="18"
                    // value={maskCGC(filial.cgc)}
                    value={filial.cgc}
                  />
                </label>
                <button type="submit" className="btnFind">
                  <FaSearch size={16} />
                </button>
              </div>
            </form>
          </Container>
        )}
      </Content>
    </Scroll>
  );
}

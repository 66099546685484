import styled from "styled-components";
import perfectScrollbar from "react-perfect-scrollbar";

import autoscroll from "autoscroll-react";

export const Container = styled.div`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 12;
  height: 100%;

  .finish {
    margin: 5px 5px 0;
    height: 44px;
    background: transparent;
    padding: 0 15px;
    color: rgb(155,213,49);
    border: 0;
    border-radius: 4px;
    transition: background 0.2s;
    border: 1px solid rgb(155,213,49);
    display: flex;
    align-items: center;

    &:hover {
      background: rgb(155,213,49);
      color: #fff;
    }
  }

  .show_content_consult {
    right: -416px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 480px);
  height: 80%;

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    display: none;
  }

  header {
    width: 100%;
    padding: 15px 0;
    background: #555555;
  }
`;

export const ContainerChat = styled.div`
  background: #efefef;
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #333;
    text-align: left;
    background: #ccc;
    padding: 15px;
    border-bottom: 1px solid #ddd;

    svg {
      margin-right: 5px;
      border-radius: 4px;
      transition: background 0.2s;
    }

    &:hover svg {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  form {
    ${"" /* display: flex;
    flex-direction: column;
    padding: 15px;
    height: 100%; */}
    height: calc(100% - 57px);
    position: relative;
    overflow: hidden;

    > .body_content {
      height: 85%;
      background: #fff;
    }

    > .send_content {
      height: 15%;
      display: flex;

      > .content_text {
        flex-grow: 1;
        padding: 8px;

        > textarea {
          width: 100%;
          height: 100%;
          border: none;
          resize: none;
          border-radius: 12px;
          padding: 12px;
        }
      }

      > .content_button_send {
        width: 100px;
        display: flex;
        align-items: center;

        > button {
          margin: 5px 5px 0;
          height: 44px;
          background: transparent;
          padding: 0 15px;
          color: rgb(155,213,49);
          border: 0;
          border-radius: 4px;
          transition: background 0.2s;
          border: 1px solid rgb(155,213,49);
          display: flex;
          align-items: center;

          &:hover {
            background: rgb(155,213,49);
            color: #fff;
          }
        }
      }
    }
  }
`;

export const BodyContent = styled(autoscroll(perfectScrollbar))`
  height: 85%;
  background: #fff;
  padding: 0px 12px;
  padding-right: 24px;

  > h1 {
    background: transparent;
    border: none;
    color: #ccc;
    text-align: center;
    justify-content: center;
    font-weight: 300;
  }

  > .msg {
    display: flex;
    flex-direction: column;

    p.user {
      font-size: 15px;
      font-weight: 600;
      padding: 0px 0px 8px 0px;
      color: #878787;
    }

    > p.text {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;
      text-align: left;
      max-width: 80%;
      background: #ccc;
      padding: 12px;
      margin-top: 12px;
      border-radius: 12px;
      flex-direction: column;
      width: max-content;
    }

    > p.hour {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;
      text-align: left;
      padding: 6px 12px;
      font-size: 11px;
      color: #8e8e8e;
    }
  }

  > .my {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    p.user {
      font-size: 15px;
      font-weight: 600;
      padding: 0px 0px 8px 0px;
      color: #ffffffa3;
    }

    > p.text {
      display: flex;
      align-items: flex-end;
      align-content: flex-end;
      justify-content: flex-end;
      text-align: right;
      max-width: 80%;
      background: #4e6b17a8;
      color: #fff;
      padding: 12px;
      margin-top: 12px;
      border-radius: 12px;
      width: max-content;
    }
  }

  > .add {
    padding: 20px 0px;
    color: #747474;
  }
`;

export const ContentConsult = styled.div`
  width: 400px;
  height: 100%;
  position: absolute;
  background: #fff;
  right: 0;
  z-index: 9;
  ${"" /* box-shadow: 0px 7px 8px 0px #595959; */}
  transition: 0.4s;
  background: #ebebeb;
`;

export const Scroll = styled(perfectScrollbar)`
  .content {
    height: 100%;

    > li.empty_list {
      padding: 38px;
      color: #303030;
    }

    > li.tecnicos_list {
      text-align: justify;
      padding: 18px 24px;
      transition: background 0.2s;
      color: #535353;

      &:hover {
        background: #ededed;
        cursor: pointer;
      }
    }

    > li + li {
      border-top: 1px solid #ededed;
    }
  }
`;

import React, { useState, useEffect } from "react";
import api from "~/services/api";
import { useSelector } from "react-redux";
import allowAccess from "~/scripts/menuController";

import {
  New
} from "./styles";

import { FaEye, FaEdit, FaTimes } from "react-icons/fa";

import NavigationButtons from "~/components/NavigationButtons";

import { handleBlock, handleDialog, verifyAccess } from "~/scripts/index.js";

import FiltroLancamento from "~/components/FiltroLancamento";
import CreateFilials from "./CreateFilials";
import BgBlack from "~/components/BgBlack";

export default function HomeboxFilials() {
  const profile = useSelector(state => state.user.profile);
  const [filials, setFilials] = useState([]);
  const [openFilial, setOpenFilial] = useState(false);
  const [createFilial, setCreateFilial] = useState(false);
  const [opennedCGC, setOpennedCGC] = useState(null);
  const [find, setFind] = useState(null);
  const [edit, setEdit] = useState(false);
  const [block, setBlock] = useState(false);
  const [lancamento, setLancamento] = useState(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const accesses = useSelector(state => state.auth.newaccesses);

  function handleCreateFilial() {
    setEdit(!createFilial);
    setCreateFilial(!createFilial);
  }

  function handleOpenFilial(cgc, edit) {
    setOpennedCGC(cgc);
    setEdit(edit);
    setOpenFilial(!openFilial);
  }

  function handleLancamento(e) {
    e.preventDefault();
    setLancamento(find);
  }

  function handlerOptions(value) {
    setCreateFilial(!createFilial);
    handleOpenFilial(value, true);
  }

  useEffect(() => {
    async function loadFilials() {
      const response = lancamento
        ? await api.get("filiais/cgc/" + lancamento)
        : await api.get(`filiais/?page=${page}`);

      const { data } = response;

      setPages(data.pages);

      setFilials(lancamento ? data : data.filial);
    }

    loadFilials();

    setBlock(false);
  }, [setFilials, block, lancamento, page]);

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses,'cadastro_filiais') ?
          <New type="button" onClick={() => handleCreateFilial()}>
            Novo +
          </New>
        : null}
        <p>Filtros:</p>
        <FiltroLancamento
          handleLancamento={handleLancamento}
          setFind={setFind}
          placeholder="Documento"
        />
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>Código</td>
            <td>Nome</td>
            <td>Documento</td>
            <td>Cód. Depósito</td>
            <td>Telefone</td>
            <td>Endereço</td>
            <td>Pastor Responsável</td>
            <td>Regional</td>
            <td>País</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {filials.map((fil, index) => {
            return (
              <tr
                key={fil.id}
                id={fil.id}
                className={`filials ${fil.canceled_at ? "canceled" : null}`}
                onClick={() => handleDialog(fil, "filials")}
              >
                <td style={{ width: "80px" }}>
                  {fil.id}
                  {verifyAccess(accesses,'cadastro_filiais') ? fil.canceled_at ? (
                    <ul className="dialog">
                      <li
                        onClick={() =>
                          handleBlock(
                            fil.id,
                            fil.canceled_at,
                            "filial",
                            "filiais",
                            setBlock
                          )
                        }
                      >
                        <FaTimes size={12} /> Desbloquear
                      </li>
                    </ul>
                  ) : (
                    <ul className="dialog">
                        <li onClick={() => handleOpenFilial(fil.cgc, false)}>
                          <FaEye size={12} /> Visualizar
                        </li>
                        <li onClick={() => handleOpenFilial(fil.cgc, true)}>
                          <FaEdit size={12} /> Alterar
                        </li>
                        <li
                          className="trash"
                          onClick={() =>
                            handleBlock(
                              fil.id,
                              fil.canceled_at,
                              "filial",
                              "filiais",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Bloquear
                        </li>
                    </ul>
                  ) : null}
                </td>
                <td>{fil.name}</td>
                <td>{fil.cgc}</td>
                <td>{fil.id237}</td>
                <td>{fil.phone}</td>
                <td>
                  {fil.countryFilial
                    ? fil.countryFilial.code === "BR"
                      ? fil.address +
                        ", " +
                        fil.number +
                        " - " +
                        fil.neighborhood +
                        " - " +
                        fil.city +
                        ", " +
                        fil.state
                      : fil.number +
                        " " +
                        fil.address +
                        ", " +
                        fil.city +
                        ", " +
                        fil.state
                    : null}
                </td>
                <td>{fil.responsible ? fil.responsible.nome : null}</td>
                <td>{fil.regional ? fil.regional.name : null}</td>
                <td>
                  {fil.countryFilial ? (
                    <img
                      alt='country'
                      src={`/images/${fil.countryFilial.code}.svg`}
                      width="26"
                    />
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {openFilial ? (
        <>
          <BgBlack />
          <CreateFilials
            handleCreateFilial={handleOpenFilial}
            opennedCGC={opennedCGC}
            handlerOptions={handlerOptions}
            edit={edit}
            setFilials={setFilials}
            filials={filials}
            setBlock={setBlock}
          />
        </>
      ) : null}
      {createFilial ? (
        <>
          <BgBlack />
          <CreateFilials
            handleCreateFilial={handleCreateFilial}
            edit={edit}
            handlerOptions={handlerOptions}
            setFilials={setFilials}
            filials={filials}
            setBlock={setBlock}
          />
        </>
      ) : null}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import { parseISO, format } from "date-fns";
import { toast } from "react-toastify";
import api from "~/services/api";
import {
  FaCalendarAlt,
  FaSun,
  FaMoon,
  FaMoneyBillWave,
  FaMoneyBill,
  FaMoneyCheckAlt,
  FaCreditCard,
  FaIndustry,
  FaAngleRight,
  FaTimesCircle,
  FaCoins,
  FaUserCheck
} from "react-icons/fa";
import { Form, Input, Select } from "@rocketseat/unform";

import { formatMoney, verifyAccess, formatNum } from "~/scripts/index.js";
import { Content, Container, Scroll } from "./styles";
import Title from "~/components/Title";
import TabelaCheques from "./TabelaCheques";
import TabelaCartoes from "./TabelaCartoes";
import TabelaMoedaEstrangeira from "./TabelaMoedaEstrangeira";
import TabelaConferencias from "./TabelaConferencias";
import NewCard from "./NewCard";
import NewCheck from "./NewCheck";
import NewMoeda from "./NewMoeda";
import NewConferencia from "./NewConferencia";

export default function CreateDizimo({
  id,
  edit,
  handleCreateDizimo,
  setBlock
}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const accesses = useSelector(state => state.auth.newaccesses);
  const [newCheck, setNewCheck] = useState(false);
  const [newCard, setNewCard] = useState(false);
  const [newMoeda, setNewMoeda] = useState(false);
  const [newConferencia, setNewConferencia] = useState(false);
  const [load, setLoad] = useState(false);
  const [cards, setCards] = useState([]);
  const [checks, setChecks] = useState([]);
  const [moedas, setMoedas] = useState([]);
  const [conferencias, setConferencias] = useState([]);
  const [setores, setSetores] = useState([]);
  const [finish, setFinish] = useState(false);
  const [editCard, setEditCard] = useState(null);
  const [editCheck, setEditCheck] = useState(null);
  const newDate = new Date();
  const [dizimo, setDizimo] = useState({
    created_by: profile.id,
    filial_id: filial.filial,
    plano_de_contas_id: 48,
    date: `${newDate.getFullYear() + "-"}${(newDate.getMonth() + 1)
      .toString()
      .padStart(2, "0") + "-"}${newDate
      .getDate()
      .toString()
      .padStart(2, "0")}`,
    period: "",
    reason: "",
    money: 0,
    check: 0,
    debit: 0,
    credit: 0,
    total: 0,
    checks: [],
    cards: [],
    conf: [],
    moedas: [],
    approved_at: verifyAccess(
      accesses,
      "aprovacao_automatica_dizimos_e_ofertas"
    )
      ? new Date()
      : null
  });

  function setDate(date) {
    setDizimo({ ...dizimo, date: format(date, "yyyy-MM-dd") });
  }

  function handlerChecks(newcheck, indexCheck) {
    if (indexCheck !== null) {
      checks[indexCheck] = newcheck;
    } else {
      setChecks([...checks, newcheck]);
    }
    setNewCheck(false);
    setEditCheck(null);
  }

  function handlerMoeda(moeda) {
    setMoedas([...moedas, moeda]);
    setNewMoeda(false);
  }

  function handlerConferencia(conf) {
    setConferencias([...conferencias, conf]);
    setNewConferencia(false);
  }

  function handleOpenConferencia() {
    if (conferencias.length < 4) {
      setNewConferencia(true);
    } else {
      toast.error("Somente é possível registrar quatro responsáveis.", {
        className: "error"
      });
    }
  }

  function handlerCard(newCard, editCard) {
    if (editCard !== null) {
      cards[editCard] = newCard;
    } else {
      setCards([...cards, newCard]);
    }
    setNewCard(false);
    setEditCard(null);
  }

  function removeCards(index) {
    const newCardsTotal = [];

    for (let i = 0; i < cards.length; i++) {
      if (index !== i) {
        newCardsTotal.push(cards[i]);
      }
    }

    setCards(newCardsTotal);
  }

  function removeChecks(index) {
    const newChecksTotal = [];

    for (let i = 0; i < checks.length; i++) {
      if (index !== i) {
        newChecksTotal.push(checks[i]);
      }
    }

    setChecks(newChecksTotal);
  }

  async function handleSubmit() {
    // setLoad(true);
    try {
      id
        ? await api.put(`dizimos/${id}`, dizimo)
        : await api.post("dizimos/", dizimo);
      toast.success("Relatório inserido com sucesso!", {
        className: "success"
      });
      handleCreateDizimo(false);
      setBlock(true);

      return;
    } catch (err) {
      toast.error("Há algum erro, por favor, revise o lançamento.", {
        className: "error"
      });
    }
  }

  useEffect(() => {
    async function getDizimoAndOfrtas() {
      const { data } = await api.get(`dizimos/id/${id}`);
      setDizimo(data[0]);
      setCards(data[0].cards);
      setChecks(data[0].checks);
      setMoedas(data[0].moedas);
      const newConfs = [];
      if (data[0].conf_1) {
        newConfs.push({ responsavel: data[0].conf_1, sign: data[0].sign_1 });
      }
      if (data[0].conf_2) {
        newConfs.push({ responsavel: data[0].conf_2, sign: data[0].sign_2 });
      }
      if (data[0].conf_3) {
        newConfs.push({ responsavel: data[0].conf_3, sign: data[0].sign_3 });
      }
      if (data[0].conf_4) {
        newConfs.push({ responsavel: data[0].conf_4, sign: data[0].sign_4 });
      }
      setConferencias(newConfs);
      // console.log(newConfs)
      setLoad(false);
    }

    async function getSetores() {
      const { data } = await api.get("sector/");
      setSetores(data);
    }

    if (id) {
      setLoad(true);
      getDizimoAndOfrtas();
    }
    getSetores();
  }, [id]);

  useEffect(() => {
    let credit = 0;
    let debit = 0;

    for (let i = 0; i < cards.length; i++) {
      if (cards[i].operation === "Crédito") {
        credit = parseFloat(credit + cards[i].value);
      } else {
        debit = parseFloat(debit + cards[i].value);
      }
    }

    setDizimo({ ...dizimo, credit, debit, cards });
  }, [cards, editCard]);

  useEffect(() => {
    let check = 0;

    checks.map(item =>
      !item.remove ? (check += parseFloat(item.value.toFixed(2))) : null
    );

    setDizimo({ ...dizimo, check, checks });
  }, [checks]);

  useEffect(() => {
    setDizimo({ ...dizimo, conf: conferencias });
  }, [conferencias]);

  useEffect(() => {
    setDizimo({ ...dizimo, moedas });
  }, [moedas]);

  useEffect(() => {
    setDizimo({
      ...dizimo,
      total: parseFloat(
        parseFloat(dizimo.money) +
          parseFloat(dizimo.check) +
          parseFloat(dizimo.debit) +
          parseFloat(dizimo.credit)
      )
    });
  }, [dizimo.money, dizimo.check, dizimo.debit, dizimo.credit]);

  useEffect(() => {
    let { date, total, period, reason } = dizimo;

    setFinish(
      date &&
        formatNum(total) > 0 &&
        period &&
        period !== "Selecione..." &&
        reason &&
        reason !== "Selecione..."
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dizimo.date, dizimo.total, dizimo.period, dizimo.reason]);

  const options = [
    { id: null, title: "Selecione..." },
    { id: "Manhã", title: "Manhã" },
    { id: "Tarde", title: "Tarde" },
    { id: "Noite", title: "Noite" }
  ];

  return (
    <Scroll>
      <Content NewCheck={newCheck}>
        {load && (
          <div className="content-load">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <header>
          <Title back={true} func={handleCreateDizimo} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Novo lançamento de dízimos e ofertas</h1>
          <Form initialData={dizimo} onSubmit={handleSubmit} autoComplete="off">
            <div className="rowInputs">
              <label htmlFor="nome">
                {id && dizimo.user ? (
                  <>
                    <img
                      src={
                        dizimo.user.avatar
                          ? dizimo.user.avatar.url
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrdEvvb_A3fTRZ9FTzpCkDacPDXBYoWgsoxbFbcYnB-jWigqa6w&s"
                      }
                      width="54"
                      height="54"
                      alt=""
                    />
                    <Input
                      name="nome"
                      readOnly="readonly"
                      value={dizimo.user.nome}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={
                        profile.avatar
                          ? profile.avatar.url
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrdEvvb_A3fTRZ9FTzpCkDacPDXBYoWgsoxbFbcYnB-jWigqa6w&s"
                      }
                      width="54"
                      height="54"
                      alt=""
                    />
                    <Input
                      name="nome"
                      readOnly="readonly"
                      value={profile.nome}
                    />
                  </>
                )}
              </label>
              <label htmlFor="date">
                <div>
                  <FaCalendarAlt size={20} />
                  <br />
                  Data
                </div>
                <DatePicker
                  name="date"
                  disabled={edit ? null : "disabled"}
                  maxDate={new Date()}
                  minDate={parseISO(
                    filial.open_date.substring(0, 4),
                    parseInt(filial.open_date.substring(5, 7)) - 1,
                    filial.open_date.substring(8, 10)
                  )}
                  selected={parseISO(dizimo.date)}
                  onChange={date => setDate(date)}
                  dateFormat={
                    profile.language === "pt-BR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                  }
                  locale={pt}
                />
              </label>
              <label htmlFor="period">
                <div>
                  <FaSun size={14} /> / <FaMoon size={14} />
                  <br />
                  Período
                </div>
                <Select
                  options={options}
                  disabled={edit ? false : true}
                  name="period"
                  value={dizimo.period ? dizimo.period : ""}
                  onChange={e =>
                    setDizimo({ ...dizimo, period: e.target.value })
                  }
                  placeholder="Selecione..."
                />
              </label>
              <label htmlFor="reason">
                <div>
                  <FaIndustry size={20} />
                  <br />
                  Setor
                </div>
                <Select
                  options={setores}
                  disabled={edit ? null : "disabled"}
                  name="reason"
                  value={dizimo.reason ? dizimo.reason : ""}
                  onChange={e =>
                    setDizimo({ ...dizimo, reason: e.target.value })
                  }
                  placeholder="Selecione..."
                />
              </label>
            </div>
            <hr />

            <div className="rowInputs">
              <label htmlFor="money">
                <div>
                  <FaMoneyBillWave size={20} />
                  <br />
                  Dinheiro
                </div>
                <Input
                  name="money"
                  readOnly={edit ? null : "readonly"}
                  onChange={e =>
                    setDizimo({ ...dizimo, money: formatNum(e.target.value) })
                  }
                  value={formatMoney(
                    dizimo.money.toFixed(2),
                    filial.country.language
                  )}
                />
              </label>
              <label htmlFor="check">
                <div>
                  <FaMoneyCheckAlt size={20} />
                  <br />
                  Cheque
                </div>
                <Input
                  name="check"
                  readOnly="readonly"
                  value={formatMoney(
                    dizimo.check.toFixed(2),
                    filial.country.language
                  )}
                />
              </label>
              <label htmlFor="debit">
                <div>
                  <FaCreditCard size={20} />
                  <br />
                  Débito
                </div>
                <Input
                  name="debit"
                  readOnly="readonly"
                  value={formatMoney(
                    dizimo.debit.toFixed(2),
                    filial.country.language
                  )}
                />
              </label>
              <label htmlFor="credit">
                <div>
                  <FaCreditCard size={20} />
                  <br />
                  Crédito
                </div>
                <Input
                  name="credit"
                  readOnly="readonly"
                  value={formatMoney(
                    dizimo.credit.toFixed(2),
                    filial.country.language
                  )}
                />
              </label>
            </div>
            <hr />

            <div className="rowInputs">
              <label htmlFor="total">
                <div>
                  <FaMoneyBill size={20} />
                  <br />
                  Total
                </div>
                <Input
                  name="total"
                  readOnly="readonly"
                  value={formatMoney(
                    dizimo.total.toFixed(2),
                    filial.country.language
                  )}
                />
              </label>
              {edit ? (
                <>
                  <button
                    type="button"
                    className="btnAdd"
                    onClick={() => handleOpenConferencia()}
                    title="Conferência"
                  >
                    <FaUserCheck size={20} /> Conferência
                  </button>
                  <button
                    type="button"
                    className="btnAdd"
                    onClick={() => setNewMoeda(true)}
                    title="Moedas Estrangeiras"
                  >
                    <FaMoneyBillWave size={20} /> + Moeda Estrang.
                  </button>
                  <button
                    type="button"
                    className="btnAdd"
                    onClick={() => setNewCheck(true)}
                    title="Novo Cheque"
                  >
                    <FaMoneyCheckAlt size={20} /> + Cheque
                  </button>
                  <button
                    type="button"
                    className="btnAdd"
                    onClick={() => setNewCard(true)}
                    title="Novo Cartão"
                  >
                    <FaCreditCard size={20} title="Novo Cartão" /> + Cartão
                  </button>
                  <button
                    type="submit"
                    className="btnFinish"
                    disabled={!finish}
                    title={
                      !finish
                        ? "Há campos obrigatórios não preenchidos!"
                        : "Finalizar"
                    }
                  >
                    {finish ? (
                      <>
                        {id ? "Alterar" : "Finalizar"}{" "}
                        <FaAngleRight size={20} />
                      </>
                    ) : (
                      <>
                        {id ? "Alterar" : "Finalizar"}{" "}
                        <FaTimesCircle size={20} />
                      </>
                    )}
                  </button>
                </>
              ) : null}
            </div>
          </Form>
          <TabelaCheques
            checks={checks}
            removeChecks={removeChecks}
            edit={edit}
            setEditCheck={setEditCheck}
            editCheck={editCheck}
          />
          <TabelaCartoes
            cards={cards}
            removeCards={removeCards}
            setEditCard={setEditCard}
            edit={edit}
          />
          <TabelaMoedaEstrangeira setMoedas={setMoedas} moedas={moedas} />
          <TabelaConferencias
            conferencias={conferencias}
            setConferencias={setConferencias}
          />
        </Container>
      </Content>

      {editCard !== null && (
        <NewCard
          handlerCard={handlerCard}
          setNewCard={setNewCard}
          newCard={false}
          setEditCard={setEditCard}
          editCard={editCard}
          cards={cards}
          date={dizimo.date}
        />
      )}

      {newCard && (
        <NewCard
          handlerCard={handlerCard}
          setNewCard={setNewCard}
          editCard={null}
          setEditCard={setEditCard}
          cards={cards}
          newCard={newCard}
          date={dizimo.date}
        />
      )}

      {editCheck !== null && (
        <NewCheck
          newCheck={newCheck}
          setNewCheck={setNewCheck}
          handlerChecks={handlerChecks}
          setEditCheck={setEditCheck}
          editCheck={editCheck}
          checks={checks}
        />
      )}

      {newCheck && (
        <NewCheck
          newCheck={newCheck}
          setNewCheck={setNewCheck}
          handlerChecks={handlerChecks}
          setEditCheck={setEditCheck}
          editCheck={editCheck}
          checks={checks}
        />
      )}

      {newMoeda && (
        <NewMoeda
          setNewMoeda={setNewMoeda}
          newMoeda={newMoeda}
          moedas={moedas}
          handlerMoeda={handlerMoeda}
        />
      )}

      {newConferencia && (
        <NewConferencia
          setNewConferencia={setNewConferencia}
          conferencias={conferencias}
          handlerConferencia={handlerConferencia}
        />
      )}
    </Scroll>
  );
}

import styled from "styled-components";

import PerfectScrollbar from "react-perfect-scrollbar";

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 12;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #00000036;
    left: 0;
    top: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 480px);

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    display: none;
  }

  header {
    width: 100%;
    padding: 15px 0;
    background: #555555;
  }
`;

export const Container = styled.div`
  background: #efefef;
  width: 100%;
  text-align: center;

  h1 {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #333;
    text-align: left;
    background: #ccc;
    padding: 15px;
    border-bottom: 1px solid #ddd;

    svg {
      margin-right: 5px;
      border-radius: 4px;
      transition: background 0.2s;
    }

    &:hover svg {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .rowInputs {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .open_chat {
        width: 100%;
        height: 100%;
        background: rgb(155,213,49);
        border: none;
        color: #fff;
        border-radius: 3px;
      }
    }

    .add {
      display: flex;
      justify-content: start;
      padding: 0px 3px;
    }

    .button_upload_budget {
      background: #fff;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      max-width: 167px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0px 7px;
      width: 54px;
      height: 54px;

      > svg {
        margin-right: 5px;
      }
    }

    label {
      display: flex;
      text-align: left;
      align-items: center;
      margin: 0 3px 10px;
      flex: 1;

      span {
        position: absolute;
        bottom: -33px;
        background: rgb(155,213,49);
        padding: 2px 0px;
        color: #fff;
        width: calc(100% - 6px);
        text-align: center;
        font-style: normal;
        font-size: 11px;
      }

      div.react-datepicker-wrapper {
        padding: 0 10px !important;
        border-radius: 0px !important;
        align-items: center !important;
        justify-content: center !important;
        display: flex !important;
      }

      div.react-datepicker-wrapper {
        width: 100% !important;
        background: #fff;
        height: 54px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        padding: 15px;

        input {
          border: none;
        }
      }

      img {
        border-radius: 4px;
        overflow: hidden;
      }
    }

    label > div:first-child {
      display: inline-block;
      width: 54px;
      height: 54px;
      text-align: center;
      font-size: 10px;
      color: #555;
      background: #fff;
      padding: 13px 8px 0;
      border-radius: 4px;
    }

    button.btnAdd svg {
      margin-right: 7px;
    }

    button.btnFinish svg {
      margin-left: 7px;
    }
  }
`;

export const DivTable = styled.div`
  li {
    width: 100%;
    background: #efefef;
    display: flex;
    height: auto;
    align-items: center;

    table {
      width: 100%;

      thead {
        font-weight: bold;

        tr {
          background: #d7d2de;

          td {
            padding: 5px 10px;
            vertical-align: middle;
          }
        }
      }

      tbody tr {
        &:nth-child(odd) {
          background: #fff;
        }

        td {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          padding: 5px 10px;
          vertical-align: middle;

          > svg:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
`;

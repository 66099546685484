import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import api from "~/services/api";
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt";
import allowAccess from "~/scripts/menuController";

import {
  New
} from "./styles";

import { formatMoney, verifyAccess } from "~/scripts/index.js";

import { FaEye, FaTimes, FaEdit } from "react-icons/fa";
import { handleBlock, handleDialog } from "~/scripts/index.js";

import CreateBank from "./CreateBank/";

import BgBlack from "~/components/BgBlack";

export default function HomeboxBanks() {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [banks, setBanks] = useState([]);
  const [createBank, setCreateBank] = useState(false);
  const [opennedID, setOpennedID] = useState(null);
  const [edit, setEdit] = useState(false);
  const [block, setBlock] = useState(false);
  const [lancamento] = useState(null);
  const [page] = useState(1);
  const accesses = useSelector(state => state.auth.newaccesses);

  useEffect(() => {
    async function loadBanks() {
      try {
        const response = await api.get("/banks/user/"+profile.id);
        setBanks(response.data);
        setBlock(false);
      } catch(err) {

      }
    }
    loadBanks();
  }, [setBanks, block, lancamento, page, createBank, profile.id]);

  function handleCreateBank(id) {
    setOpennedID(id);
    setEdit(!createBank);
    setCreateBank(!createBank);
  }
  function handleOpenBanks(id, edit) {
    setOpennedID(id);
    setEdit(edit);
    setCreateBank(!createBank);
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses,'cadastro_bancos') ?
          <New type="button" onClick={() => handleCreateBank(null, true)}>
            Novo +
          </New>
        : null}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>Apelido</td>
            <td>Banco</td>
            <td>Filial</td>
            <td>Agência</td>
            <td>Conta</td>
            <td>Saldo inicial</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {banks.length > 0 ? (
            banks.map((bnk, index) => {
              return (
                <tr
                  key={bnk.id}
                  id={bnk.id}
                  className={`banks ${bnk.canceled_at ? "canceled" : null}`}
                  onClick={() => handleDialog(bnk, "banks")}
                >
                  <td>{bnk.name}{verifyAccess(accesses,'cadastro_bancos') ? bnk.canceled_at ? (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleBlock(
                              bnk.id,
                              bnk.canceled_at,
                              "banco",
                              "banks",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Desbloquear
                        </li>
                      </ul>
                    ) : (
                      <ul className="dialog">
                          <li onClick={() => handleOpenBanks(bnk.id, false)}>
                            <FaEye size={12} /> Visualizar
                          </li>
                          <li onClick={() => handleOpenBanks(bnk.id, true)}>
                            <FaEdit size={12} /> Alterar
                          </li>
                          <li
                            className="trash"
                            onClick={() =>
                              handleBlock(
                                bnk.id,
                                bnk.canceled_at,
                                "banco",
                                "banks",
                                setBlock
                              )
                            }
                          >
                            <FaTimes size={12} /> Bloquear
                          </li>
                      </ul>
                    ) : null}
                  </td>
                  {/* <td>{getBankName(bnk.country.code,bnk.bank)}</td> */}
                  <td>{bnk.bank_name}</td>
                  <td>{bnk.filial ? bnk.filial.name : ""}</td>
                  <td>{`${bnk.agency}-${bnk.agency_code}`}</td>
                  <td>{`${bnk.account}-${bnk.account_code}`}</td>
                  <td>
                    {filial.country.currency}{" "}
                    {formatMoney(
                      bnk.balance.toFixed(2),
                      filial.country.language
                    )}{" "}
                    em{" "}
                    {format(parseISO(bnk.period_end), "MMMM/yyyy", {
                      locale: pt
                    })}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6}>Não há bancos cadastrados.</td>
            </tr>
          )}
        </tbody>
      </table>
      {createBank ? (
        <>
          <BgBlack />
          <CreateBank
            handleCreateBank={handleCreateBank}
            opennedID={opennedID}
            edit={edit}
            setBanks={setBanks}
            banks={banks}
          />
        </>
      ) : null}
    </>
  );
}

import styled from "styled-components";

import { lighten } from "polished";

export const GradeTable = styled.table`
  width: 100%;
  background: #eee;
`;

export const GradeHeader = styled.thead`
  background: ${lighten(0.4, "rgb(155,213,49)")};
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);

  td {
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  td.iconSize {
    width: 25px;
  }
`;

export const Dialog = styled.ul`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 100%;
  width: 200px;
  background: #fff;
  border-radius: 4px;
  display: none;
  cursor: auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.1s;

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  &::before {
    content: "";
    position: absolute;
    left: 5px;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
  }

  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: #777;
    }

    &.trash:hover {
      cursor: pointer;
      color: #f00;
    }
  }
`;

export const GradeBody = styled.tbody`
  tr {
    width: 100%;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in;

    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.05);
    }

    &.approved {
      background: rgba(0, 255, 0, 0.2);
    }

    &:hover {
      background: ${lighten(0.2, "rgb(155,213,49)")};
    }

    &.active ul {
      display: block;
    }

    td {
      padding: 10px;
      font-size: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;
    }
  }
`;

export const Filtros = styled.div`
  background: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 39px;

  p {
    padding: 0 20px;
  }
`;

export const New = styled.button`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 39px;
  background: rgb(155,213,49);
  color: #fff;
  font-weight: bold;
`;

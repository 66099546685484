import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import allowAccess from "~/scripts/menuController";

import { FaEye, FaTimes, FaEdit } from "react-icons/fa";

import { handleBlock, handleDialog, verifyAccess } from "~/scripts/index.js";

import api from "~/services/api";

import CreateSector from "./CreateSector";

import {
  New
} from "./styles";

function Sector() {
  const profile = useSelector(state => state.user.profile);
  const accesses = useSelector(state => state.auth.newaccesses);
  const [sectors, setSectors] = useState([]);
  const [block, setBlock] = useState(false);
  const [createSector, setCreateSector] = useState(false);
  const [editSector, setEditeSector] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    async function initSector() {
      const response = await api.get(`sector`);
      setSectors(response.data);
      setBlock(false);
    }
    initSector();
  }, [block]);

  function handleOpenCreateSector(id, open, edite) {
    setCreateSector(open);
    setId(id);
    setEditeSector(edite);
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses,'cadastro_setores') ?
          <New
            type="button"
            onClick={() => handleOpenCreateSector(null, true, true)}
          >
            Novo +
          </New>
        : null}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td style={{ width: "10px" }}>Código</td>
            <td>Nome</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {sectors && sectors.length > 0 ? (
            sectors.map((sector, index) => {
              return (
                <tr
                  key={sector.id}
                  id={sector.id}
                  className={`setor  ${sector.canceled_at ? "canceled" : null}`}
                  onClick={() => handleDialog(sector, "setor")}
                >
                  <td>
                    {sector.id}
                    {verifyAccess(accesses,'cadastro_setores') ? sector.canceled_at ? (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleBlock(
                              sector.id,
                              sector.canceled_at,
                              "Setor",
                              "sector",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Desbloquear
                        </li>
                      </ul>
                    ) : (
                      <ul className="dialog">
                        <li
                          onClick={() =>
                            handleOpenCreateSector(sector.id, true, false)
                          }
                        >
                          <FaEye size={12} /> Visualizar
                        </li>
                        <li
                          onClick={() =>
                            handleOpenCreateSector(sector.id, true, true)
                          }
                        >
                          <FaEdit size={12} /> Alterar
                        </li>
                        <li
                          className="trash"
                          onClick={() =>
                            handleBlock(
                              sector.id,
                              sector.canceled_at,
                              "Setor",
                              "sector",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Bloquear
                        </li>
                      </ul>
                    ) : null}
                  </td>
                  <td>{sector.title}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={2}>Não há setores cadastrados.</td>
            </tr>
          )}
        </tbody>
      </table>
      {createSector && (
        <CreateSector
          setCreateSector={setCreateSector}
          edit={editSector}
          id={id}
          setBlock={setBlock}
        />
      )}
    </>
  );
}

export default Sector;

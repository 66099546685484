import { takeLatest, put, all } from 'redux-saga/effects';
import { updateCategory } from './actions';

export function update({ payload }) {

    const { activeCategory } = payload.category;
    put(updateCategory(activeCategory));
}

export default all([
  takeLatest('@category/UPDATE', update),
]);

import React from 'react'
import './style.css'
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';

export default function DateFilter({ from, to, setFrom, setTo }) {

  const profile = useSelector(state => state.user.profile);

  return (
    <div className="date-filter-container">
      <div className="date-filter-element">
        <DatePicker
          selected={from}
          onChange={dt => setFrom(dt)}
          className="date-filter-picker"
          dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy' }
          locale={profile.language === 'pt-BR' ? pt : en}
        />
        <FaCalendarAlt size={14.5} className="data-filter-icon" />
      </div>
      <span>-</span>
      <div className="date-filter-element">
        <DatePicker
          selected={to}
          onChange={dt => setTo(dt)}
          className="date-filter-picker"
          dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy' }
          locale={profile.language === 'pt-BR' ? pt : en}
        />
        <FaCalendarAlt size={14.5} className="data-filter-icon" />
      </div>
    </div>
  )
}
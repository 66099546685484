import React from "react";
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import logo from '~/assets/images/weTrust_white.png';

import { registerRequest } from '~/store/modules/auth/actions';

const schema = Yup.object().shape({
  nome: Yup.string()
  .required('O nome é obrigatório'),
  email: Yup.string()
  .email('Insira um e-mail válido!')
  .required('O e-mail é obrigatório.'),
  password: Yup.string()
  .required('A senha é obrigatória.')
});

export default function Register() {
  const dispatch = useDispatch();


  function handleSubmit({nome, email, password}) {
    dispatch(registerRequest(nome,email,password));
  }
  return (
    <>
      <img src={logo} alt="WeTrust" />
      <Form schema={schema} onSubmit={handleSubmit}>
        <Input name="nome" type="nome" placeholder="Seu nome" />
        <Input name="email" type="email" placeholder="Seu e-mail" />
        <Input name="password" type="password" placeholder="Sua senha" />

        <button type="submit">Cadastrar</button>
        <Link to="/">Voltar</Link>
      </Form>
    </>
  );
}

import styled from 'styled-components';

import { lighten } from 'polished';

import PerfectScrollbar from 'react-perfect-scrollbar';

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 300px);
  padding-bottom:200px;
  transition: all .4s ease-in;

  &.uploadedContent {
    max-width:1220px;
    left: calc(50% - 610px);
  }

  &:before {
    content:'';
    background: rgba(0,0,0,.4);
    width:100%;
    height:100%;
    position:fixed;
    z-index:1;
    left:0;
    top:0;
    display: ${props => props.NewCheck ? 'block' : 'none' };
  }

  header {
    width:100%;
    padding:15px 0;
    background: #555555;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index:12;
`;

export const BtnType = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;

  button {
    border:1px solid rgb(155,213,49);
    padding:8px 16px;
    border-radius:4px;
    background:#222;
    color:#FFF;
    font-weight:bold;
    font-size:14px;
    margin:16px 8px;
    transition:all .2s;
    position:relative;

    &:hover {
      background: #efefef;
      color:#222;
    }

    &.active {
      border-color: #339e2b;
    }

    &.openned {
      background: rgb(155,213,49);
    }

    &.openned:before {
      content:'';
      position:absolute;
      left:10px;
      bottom: -9px;
      width:0;
      height:0;
      border-left:9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid rgb(155,213,49);
      overflow:hidden;
    }
  }
`;

export const Container = styled.div`
  background: #efefef;
  width:100%;
  text-align:center;

  h1 {
    display:flex;
    align-items: center;
    font-size:21px;
    color:#333;
    text-align:left;
    background:#ccc;
    padding:15px;
    border-bottom:1px solid #ddd;

    .apagar_conciliacao {
      position: absolute;
      right: 15px;
      font-size: 13px;
      border: 1px solid #555;
      border-radius: 4px;
      padding: 5px 10px;
      color: #555;
      transition: all .2s ease-in;
      cursor: pointer;

      &:hover {
        background: #555;
        color: #FFF;
      }
    }

    svg {
      margin-right:5px;
      border-radius:4px;
      transition:background .2s;
    }

    &:hover svg {
      background:rgba(255,255,255,.5);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding:15px;

    .rowInputs {
      display:flex;
      flex-direction:row;
      justify-content: space-around;

    }

    label {
      display:flex;
      text-align:left;
      align-items: center;
      margin:0 3px 10px;
      flex:1;
      position:relative;

      div {
        display: inline-block;
        width: 54px;
        height: 54px;
        text-align: center;
        font-size: 10px;
        color: #555;
        background: #FFF;
        padding: 13px 8px 0;
        border-radius: 4px;
      }

      span {
        position: absolute;
        bottom: -27px;
        background: rgb(155,213,49);
        padding: 2px 0px;
        color: #FFF;
        width: calc(100% - 6px);
        text-align: center;
        font-style: normal;
        font-size: 11px;


      div.react-datepicker-wrapper {
        width: 100%;
        background: #FFF;
        height: 54px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        padding: 15px;

        input {
          border:none;
        }
      }

      img {
        border-radius:4px;
        overflow:hidden;
      }
    }

    label > div:first-child {
      display:inline-block;
      width:54px;
      height:54px;
      text-align:center;
      font-size:10px;
      color:#555;
      background:#FFF;
      padding:13px 8px 0;
      border-radius:4px;
    }

    .btnFind {
      border:none;
      background: rgb(155,213,49);
      color:#FFF;
      width:54px;
      height:54px;
      display:flex;
      align-items:center;
      border-radius:4px;
      justify-content: center;
      cursor:pointer;

      &:hover {

      }
    }

    label > input, label > select, label > .input {
      width:calc(100% - 60px);
      border: 0;
      background:#FFF;
      height:54px;
      padding: 0 15px;
      color:#333;
      margin:0 5px;
      display:Flex;
      align-items:center;
    }

    span {
      color: #ccc;
      align-self: flex-start;
      margin:0 0 10px;
      font-style: italic;
      font-size:12px;
    }

    hr {
      border:0;
      height:1px;
      background: rgba(0,0,0,.1);
      margin:10px 0 20px;
    }

    button.btnAdd, button.btnFinish {
      margin: 5px 5px 0;
      height:44px;
      background: transparent;
      padding:0 15px;
      color:rgb(155,213,49);
      border:0;
      border-radius:4px;
      transition: background 0.2s;
      border:1px solid rgb(155,213,49);
      display:flex;
      align-items:center;
    }

    button.btnAdd:hover {
        background: rgb(155,213,49);
        color:#fff;
      }
    button.btnFinish {
      background: transparent;
      color: rgb(155,213,49);
      border:1px solid rgb(155,213,49);
      cursor:pointer;

      &:hover {
        background: rgb(155,213,49);
        color: #fff;
      }

      &:disabled {
        background: #ccc;
        color: #777;
        cursor:auto;

        &:hover {
          background: #ccc;
          color:#777;
        }
      }
    }

    button.btnAdd svg {
      margin-right:7px;
    }

    button.btnFinish svg {
      margin-left:7px;
    }
  }

  }
`;

export const Section = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;
  transition: all .3s ease-in;

  button {
    width:50%;
    border-radius:8px;
    border:2px solid #ccc;
    margin:5px;
    padding:5px;
    transition: all .1s ease-in;

    &:hover {
      border:2px solid rgb(155,213,49);
    }
  }

  form {
    width: 100%;
  }
`;

export const DivFiles = styled.div`
  li {
    width: 100%;
    background: #efefef;
    display: flex;
    height: auto;
    align-items: center;

    table {
      width: 100%;

      thead {
        font-weight: bold;

        tr {
          background: #d7d2de;

          td {
            padding: 5px 10px;
            vertical-align: middle;
          }
        }
      }

      tbody tr {
        &:nth-child(odd) {
          background: #fff;
        }

        td {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          padding: 5px 10px;
          vertical-align: middle;
        }
      }
    }
  }
`;

export const GradeTable = styled(PerfectScrollbar)`
  width:100%;
  position:absolute;
  left:0;
  background: #eee;
`;

export const GradeHeader = styled.thead`
  background: ${lighten(0.4,'rgb(155,213,49)')};
  border-bottom:2px solid rgba(0,0,0,.05);

  td {
    padding:10px;
    font-weight: bold;
    font-size:14px;
  }

  td.iconSize {
    width:25px;
  }
`;

export const Dialog = styled.ul`
  position:absolute;
  z-index:2;
  left:0;
  top:100%;
  width:200px;
  background:rgb(155,213,49);
  border-radius:4px;
  display:none;
  cursor:auto;

  &::before {
    content:'';
    position:absolute;
    left:7px;
    top: -7px;
    width:0;
    height:0;
    border-left:7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgb(155,213,49);
    overflow:hidden;
  }

  li {
    border-bottom:1px solid rgba(0,0,0,.05);
    width:100%;
    padding:10px;
    cursor:pointer;
    transition:all .2s;
    color:#FFF;

    &:hover {
      color:#ccc;
    }

    &.trash:hover {
      cursor:pointer;
      background: rgba(215,47,47,1);
    }
  }
`;

export const GradeBody = styled.tbody`

  tr {
    width:100%;
    cursor:pointer;
    position:relative;

    &:nth-child(odd) {
      background: rgba(0,0,0,.05);
    }

    &:hover {
      background: ${lighten(0.5,'rgb(155,213,49)')};
    }

    &.active ul {
      display:block;
    }

    &.canceled {
      background: #888;
    }

    td {
      padding:10px;
      font-size:14px;
      border-bottom:1px solid rgba(0,0,0,.05);

      &:first-child {
        position:relative;
      }
    }
  }


`;

export const Filters = styled.ul`
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  max-width:700px;
  height:30px;
  margin:20px 10px 10px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border:1px solid #aaa;
    color: rgb(155,213,49);
    border-color: rgb(155,213,49);
    border-radius:4px;
    padding:0 8px;
    transition: all .2s ease-in;
    cursor: pointer;
    transition: all .2s ease-in;

    @keyframes newNumber {
      from{ transform: scale(2) }
      to{ transform: scale(1) }
    }

    :nth-child(3) small {
      animation: newNumber;
      animation-duration: .4s;
    }

    small {
      background-color:#ffffff;
      color:rgb(155,213,49);
      font-weight:bold;
      padding: 2px 4px;
      border-radius:4px;
      margin-left:10px;
    }

    &.active, &:hover {
      color: #ffffff;
      background-color: rgb(155,213,49);
    }
  }

  .search_box {
    height:30px;
    border:1px solid rgb(155,213,49);
    border-radius: 4px;
    color: rgb(155,213,49);
    padding:0 5px;
  }
`;

export const Extrato = styled.div`
  width: 100%;
  position:relative;
`;

export const CardLine = styled.div`
  width: 100%;
  margin:20px 0;
  height:120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Card = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
  align-items: center;
  margin:10px;
  border:1px solid rgba(0,0,0,.15);
  background-color: rgba(0,0,0,.05);
  border-radius:4px;

  h2 {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    background: rgba(0,0,0,.05);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      font-size:14px;
      margin:0;
      color: rgba(170, 0, 0,1);

      &.CREDIT {
        color: rgba(0, 170, 0,1);
      }
    }
  }

  p {
    padding: 10px;
    text-align:left;
    width:100%;
    flex-grow: 1;
  }
`;

export const ButtonConciliar = styled.button`
  width: auto !important;
  color: #555;
  display: flex;
  cursor: auto;
`;

export const BoxConciliar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin:10px;
  border:1px solid rgba(0,0,0,.15);
  background-color: rgba(0,0,0,.05);
  border-radius: 4px;
  height:100%;
  overflow: hidden;

  ul {
      width: 150px;
      height:100%;

    li {
      height:50%;
      width: 100%;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition:all .2s ease-in;
      border-right:1px solid rgba(0,0,0,.1);
      border-bottom:1px solid rgba(0,0,0,.1);
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: rgba(0,0,0,.1);
      }

      &.active {
        border-right: 1px solid transparent;
      }
    }

  }

  div {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    select {
      width: 90%;
      padding:5px;
      margin: 5px 0;
    }

    @keyframes newNumber {
      from{ transform: scale(1.5) }
      to{ transform: scale(1) }
    }

    button {
      color: rgb(155,213,49);
      animation: newNumber;
      animation-duration: .4s;
    }
  }
`;

export const Conciliado = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin:10px;
  border:1px solid rgba(0,0,0,.15);
  background-color: rgba(0,0,0,.05);
  border-radius: 4px;
  height:100%;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  color: rgb(155,213,49);

  .small {
    cursor: pointer;
    margin-top:10px;
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 4px 8px;
    background: #ccc;
    color: #555;
    font-size: 12px;
    transition: all .2s ease-in;

    &:hover {
      background: #ddd;
      color: #444;
    }
  }
  `;

export const Loading = styled.div`
  padding:7px 15px;
  margin:15px  auto;
  color: #555;
  border:1px solid #aaa;
  border-radius:4px;
  width:170px;
`;

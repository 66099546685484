import { takeLatest, put, all } from 'redux-saga/effects';
import { updateHomebox, destroyHomebox } from './actions';

export function update({ payload }) {

    const { activeHomebox } = payload.homebox;
    put(updateHomebox(activeHomebox));
}

export function destroy() {
  put(destroyHomebox());
}

export default all([
  takeLatest('@homebox/UPDATE', update),
  takeLatest('@homebox/DESTROY', destroy),
]);

import produce from "immer";

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  accesses: null,
  newaccesses: null
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case "@auth/LOGIN_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@auth/LOGIN_SUCCESS": {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        draft.accesses = action.payload.accesses;
        draft.newaccesses = action.payload.newaccesses;
        break;
      }
      case "@auth/LOGIN_FAILURE": {
        draft.loading = false;
        break;
      }
      case "@auth/LOGOUT": {
        draft.token = null;
        draft.signed = false;
        draft.loading = false;
        draft.accesses = null;
        draft.newaccesses = null;
        break;
      }
      default:
    }
  });
}

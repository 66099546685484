export function loginRequest(email, password) {
  return {
    type: "@auth/LOGIN_REQUEST",
    payload: { email, password }
  };
}

export function loginSuccess(token, user, accesses, newaccesses) {
  return {
    type: "@auth/LOGIN_SUCCESS",
    payload: { token, user, accesses, newaccesses }
  };
}

export function loginFailure() {
  return {
    type: "@auth/LOGIN_FAILURE"
  };
}

export function registerRequest(nome, email, password) {
  return {
    type: "@auth/REGISTER_REQUEST",
    payload: { nome, email, password }
  };
}

export function registerFailure(nome, email, password) {
  return {
    type: "@auth/REGISTER_REQUEST",
    payload: { nome, email, password }
  };
}

export function logout() {
  return {
    type: "@auth/LOGOUT"
  };
}

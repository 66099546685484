import styled from 'styled-components';

import { lighten } from 'polished';

import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div`
  position:relative;
  min-width:100px;
  display: flex;
  margin-left:20px;
  padding-left:20px;
  border-left:1px solid ${lighten(0.08,'#555')};
  align-items: center;
`;

export const Main = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  color:#fff;
  margin-left:10px;
`;

export const ChangeFilial = styled.div`
  display:flex;
  flex-direction: column;
  color:#fff;
  border-radius:4px;
  background:transparent;
  padding:5px;
  transition:all .2s;
  cursor:pointer;

  &:hover {
  background:rgba(0,0,0,.1);
  color:#fff;
  }
`;


export const FilialList = styled.div`
  position:absolute;
  z-index:5;
  left:0px;
  width:260px;
  top:calc(100% + 20px);
  background: #eee;
  border-radius:4px;
  padding: 15px 5px;
  display: ${props => props.visible ? 'block' : 'none' };

  &::before {
    content:'';
    position:absolute;
    left:15px;
    top: -20px;
    width:0;
    height:0;
    border-left:20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #eee;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height:260px;
  padding:5px 15px;
`;

export const Filial = styled.div`
  color:#222;
  display:flex;
  flex-direction:row;
  cursor:pointer;

  div {
    margin-left:10px;
  }

  & + div {
    margin-top:15px;
    padding-top:15px;
    border-top:1px solid #ddd;
  }

  p {
    font-size:13px;
    line-height:18px;
    margin-bottom:3px;
  }

  time {
    display:block;
    font-size:12px;
    opacity:.6;
    margin-bottom:3px;
  }

  button {
    font-size:12px;
    border:0;
    background: none;
    color: ${lighten(0.2,'#222')};
  }
`;

import styled from 'styled-components';

export const Container = styled.ul`
  display:flex;
  justify-content: start;
  align-items: center;
  margin-left:15px;

  li {
    display:flex;
    flex-direction: row;
    align-items: center;
    color:#555;
    font-weight:bold;
    font-size:20px;
    border-radius: 4px;

    button {
      background:transparent;
      border:none;
      color:#FFF;
      padding: 8px 16px;
    }

    svg {
      margin:0 16px;
      color: #555;
    }

    & + li {
      margin-right:16px;
      background-color:#888;
      border-bottom:2px solid transparent;
      color:#FFF;
      font-weight:bold;
      font-size:12px;
      transition:all .2s;
    }

    & + li.active, & + li:hover {
      border-bottom:2px solid #FFF;
      background:rgb(155,213,49);
    }
  }
`;

import React from 'react'
import './style.css'

export default function CheckBox({ input = false, title = 'teste', setInput = () => { } }) {
  return (
    <div className="checkbox-container">
      <div className={`checkbox-input ${input ? 'checkbox-active' : 'checkbox-inactive'}`} onClick={setInput}></div>
      <div className="checkbox-title">{title}</div>
    </div>
  )
}

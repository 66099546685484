import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import allowAccess from "~/scripts/menuController";

import { format, parseISO, addMonths, addDays } from "date-fns";
import {
  handleDialog,
  handleBlock,
  formatMoney,
  formatedDate,
  verifyAccess
} from "~/scripts/index.js";

import { toast } from "react-toastify";

// import CreateOrder from './CreateOrder';
import BgBlack from "~/components/BgBlack";

import "react-confirm-alert/src/react-confirm-alert.css";

import NavigationButtons from "~/components/NavigationButtons";

import api from "~/services/api";
import {
  FaEye,
  FaEdit,
  FaRedo,
  FaTimes,
  FaCalendarAlt,
  FaAngleRight
} from "react-icons/fa";
import {
  New,
  ModalDateBaixa,
  Scroll
} from "./styles";
import FiltroData from "~/components/FiltroData";
import FiltroLancamento from "~/components/FiltroLancamento";

import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

import CreateOrder from "./CreateOrder/index";

import Title from "~/components/Title";
import pt from "date-fns/locale/pt";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import MainFilter, { MainFilterButton } from "~/components/MainFilter";
import FilterComponent from "~/components/FilterComponent";
import CheckBox from "~/components/CheckBox";
import DateFilterUnique from "~/components/DateFilterUnique";
import TextInputFilter from "~/components/TextInputFilter";
import BuscarFiliais from "~/components/BuscarFiliais";
import GreenSearchButton from "~/components/GreenSearchButton";
import BuscaFornecedor from "~/components/BuscarFornecedor";
import BuscarBanks from "~/components/BuscarBanks";
import DateFilter from "~/components/DateFilter";

// import { Container } from './styles';

export default function HomeboxOrders() {
  const accesses = useSelector(state => state.auth.newaccesses);
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [filtro, setFiltro] = useState({
    date: format(new Date(), "yyyy-MM-dd 23:59:59")
  });
  const [Orders, setOrders] = useState([]);

  const { date } = filtro;
  const [openDate, setOpenDate] = useState(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [lancamento, setLancamento] = useState(null);
  const [openOrder, setOpenOrder] = useState(false);
  const [opennedId, setOpennedId] = useState(null);
  const [block, setBlock] = useState(false);
  const [edit, setEdit] = useState(false);
  const [createOrder] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [checkBaixar, setCheckBaixar] = useState({});
  const [selected, setSelected] = useState(new Date());
  const [showCoA, setShowCoA] = useState(false);
  const [reclassificar, setReclassificar] = useState(null);
  const [showFilter, setShowFilter] = useState(false)
  const [showFilterFilials, setShowFilterFilials] = useState(false)
  const [showFilterProvider, setShowFilterProvider] = useState(false)
  const [showFilterCoa, setShowFilterCoa] = useState(false)
  const [showFromBankFilter, setShowFromBankFilter] = useState(false)
  const [filtered, setFiltered] = useState(false)
  const [filterSettings, setFilterSettings] = useState({
    status: {
      not_approved: true,
      approved: true,
      conciliate: true
    },
    cupom: '',
    filial: {
      id: filial.filial,
      name: ''
    },
    expense: '',
    provider: {
      cgc: '',
      company_name: '',
      id: 0
    },
    coa: {
      id: 0,
      nome: '',
      tax_nature: ''
    },
    bank_from: {
      id: false,
      name: ''
    },
    from_emission: false,
    to_emission: false,
    from_due: false,
    to_due: false,
  })


  async function handleChartOfAccount(coa) {
    try {
      await api.put(`/reclassificar/payment_order/${reclassificar}/${coa.id}`)
      toast.success("Reclassificado com sucesso!", {
        className: "success"
      });
      setShowCoA(false);
    } catch (err) {
      toast.error("Impossível reclassificar.", {
        className: "error"
      });
    }
  }

  function handleReclass(movement) {
    setShowCoA(true);
    setReclassificar(movement);
  }

  function handleLancamento() { }

  function handleOpenDate() {
    setOpenDate(!openDate);
  }

  function handleCreateOrder() {
    setOpennedId(null);
    setEdit(!createOrder);
    setOpenOrder(!openOrder);
  }

  function handleopenOrder(id, edit) {
    setOpennedId(id);
    setEdit(edit);
    setOpenOrder(!openOrder);
  }

  function handleData({ date }) {
    setFiltro({
      date: format(date, "yyyy-MM-dd 23:59:59")
    });
    setOpenDate(!openDate);
  }

  function handleShowModal(id, dueDate, approved_at) {
    setCheckBaixar({ id, dueDate, approved_at });
    // setSelected(parseISO(dueDate));
    setOpenModal(true);
  }

  function baixar(date) {
    setSelected(date);
  }

  async function handleBaixar(orderId) {
    setOpenModal(false);
    try {
      const response = await api.put(
        `paymentOrders/approve/${orderId || checkBaixar.id}`,
        {
          approved_at: selected ? new Date(selected) : new Date()
        }
      );
      if (response.data.approved_at) {
        toast.success("Ordem de Pagamento aprovada com sucesso!", {
          className: "success"
        });
      } else {
        toast.success("Ordem de Pagamento estornada com sucesso!", {
          className: "success"
        });
      }
      setBlock(true);
      return;
    } catch (err) {
      toast.error(
        "Esta Ordem de Pagamento não pode ser aprovada. Verifique se o período está aberto.",
        {
          className: "error",
          autoClose: 7000
        }
      );
    }
  }

  useEffect(() => {
    async function loadOrders() {
      try {

        if (filtered) {
          const { data } = await api.get('paymentOrders/filter/', {
            params: {
              page: page,
              ...filtered
            }
          })

          setPages(data.pages);
          setOrders(data.paymentOrder);

        } else {

          const response = lancamento
            ? await api.get(
              "paymentOrders/filial/" +
              filial.filial +
              "?lancamento=" +
              lancamento
            )
            : await api.get(
              "paymentOrders/filial/" +
              filial.filial +
              "?page=" +
              page +
              "&dateOrder=" +
              date
            );

          const { data } = response;
          setPages(data.pages);
          setOrders(data.paymentOrder);
        }

      } catch (err) {
        setOrders([]);
      }
      setBlock(false);
    }
    loadOrders();
  }, [filial, block, lancamento, date, page]);


  async function applyFilter() {

    try {

      if (filterSettings.from_emission && filterSettings.to_emission && filterSettings.from_emission > filterSettings.to_emission) {
        return toast.error("A data de emissão inicial não pode ser maior do que a final.", {
          className: "error"
        });
      }

      if (filterSettings.from_due && filterSettings.to_due && filterSettings.from_due > filterSettings.to_due) {
        return toast.error("A data de vencimento inicial não pode ser maior do que a final.", {
          className: "error"
        });
      }

      let from_emission = filterSettings.from_emission ? new Date(filterSettings.from_emission) : new Date(1900, 0, 1)
      from_emission = from_emission.toISOString().substring(0, 10)

      let to_emission = filterSettings.to_emission ? new Date(filterSettings.to_emission) : new Date(3000, 0, 1)
      to_emission = to_emission.toISOString().substring(0, 10)

      let from_due = filterSettings.from_due ? new Date(filterSettings.from_due) : new Date(1900, 0, 1)
      from_due.setDate(from_due.getDate() + 1)
      from_due = from_due.toISOString().substring(0, 10)

      let to_due = filterSettings.to_due ? new Date(filterSettings.to_due) : new Date(3000, 0, 1)
      to_due.setDate(to_due.getDate() + 1)
      to_due = to_due.toISOString().substring(0, 10)

      const params = {
        not_approved: filterSettings.status.not_approved,
        approved: filterSettings.status.approved,
        conciliate: filterSettings.status.conciliate,
        from_emission,
        to_emission,
        from_due,
        to_due,
        cupom_fiscal: filterSettings.cupom,
        filial_id: filterSettings.filial.id,
        expense: filterSettings.expense,
        provider: filterSettings.provider.id,
        plano_de_contas: filterSettings.coa.id,
        bank: filterSettings.bank_from.id
      }

      const { data } = await api.get('paymentOrders/filter/', {
        params: {
          page: filtered ? page : 1,
          ...params
        }
      })

      setPage(1)

      setFiltered({
        ...params
      })

      setPages(data.pages);
      setOrders(data.paymentOrder);

      setShowFilter(false)

    } catch (err) {

    }

  }

  async function resetFilter() {

    setFilterSettings({
      status: {
        not_approved: true,
        approved: true,
        conciliate: true
      },
      from_emission: false,
      to_emission: false,
      from_due: false,
      to_due: false,
      cupom: '',
      filial: {
        id: filial.filial,
        name: ''
      },
      expense: '',
      provider: {
        cgc: '',
        company_name: '',
        id: 0
      },
      coa: {
        id: 0,
        nome: '',
        tax_nature: ''
      },
      bank_from: {
        id: false,
        name: ''
      },
    })

    setFiltered(false)
    setPage(1)

    try {
      const response = await api.get(
        "paymentOrders/filial/" +
        filial.filial +
        "?page=" +
        page +
        "&dateOrder=" +
        date
      );
      const { data } = response;
      setPages(data.pages);
      setOrders(data.paymentOrder);
    } catch (err) {

    }
    setShowFilter(false)

  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses, 'lancamento_ordem_pagamento') ?
          <New type="button" onClick={() => {
            handleCreateOrder()
            setShowFilter(false)
          }}>
            Novo +
          </New>
          : null}
        <MainFilterButton click={() => setShowFilter(!showFilter)} />
        {/* <FiltroData
          openDate={openDate}
          handleOpenDate={handleOpenDate}
          selected={parseISO(date)}
          handleData={handleData}
        />
        <FiltroLancamento
          handleLancamento={handleLancamento}
          setFind={setLancamento}
          placeholder="Cupom Fiscal"
        /> */}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          {showFilter &&
            <MainFilter applyFilter={applyFilter} resetFilter={resetFilter} >
              <FilterComponent title="Status">
                <CheckBox title="Não aprovado"
                  input={filterSettings.status.not_approved}
                  setInput={() => setFilterSettings({ ...filterSettings, status: { ...filterSettings.status, not_approved: !filterSettings.status.not_approved } })}
                />
                <CheckBox title="Aprovado"
                  input={filterSettings.status.approved}
                  setInput={() => setFilterSettings({ ...filterSettings, status: { ...filterSettings.status, approved: !filterSettings.status.approved } })}
                />
                <CheckBox title="Conciliado"
                  input={filterSettings.status.conciliate}
                  setInput={() => setFilterSettings({ ...filterSettings, status: { ...filterSettings.status, conciliate: !filterSettings.status.conciliate } })}
                />
              </FilterComponent>

              <FilterComponent title="Data de Emissão">
                <DateFilter to={filterSettings.to_emission} setTo={dt => setFilterSettings({ ...filterSettings, to_emission: dt })} from={filterSettings.from_emission} setFrom={dt => setFilterSettings({ ...filterSettings, from_emission: dt })} />
              </FilterComponent>

              <FilterComponent title="Data de Vencimento">
                <DateFilter to={filterSettings.to_due} setTo={dt => setFilterSettings({ ...filterSettings, to_due: dt })} from={filterSettings.from_due} setFrom={dt => setFilterSettings({ ...filterSettings, from_due: dt })} />
              </FilterComponent>

              <FilterComponent title="Cupom">
                <TextInputFilter input={filterSettings.cupom} placeholder="Digite apenas números." setInput={number => {
                  if (isNaN(number))
                    return
                  setFilterSettings({ ...filterSettings, cupom: number })
                }} />
              </FilterComponent>

              <FilterComponent title="Filial">
                <GreenSearchButton
                  input={filterSettings.filial.name}
                  searchFunction={() => { setShowFilterFilials(true) }}
                  resetFunction={() => setFilterSettings({ ...filterSettings, filial: { id: filial.filial, name: '' } })}
                />
                <BuscarFiliais
                  showFilials={showFilterFilials}
                  setShowFilials={setShowFilterFilials}
                  handleSearchFilials={
                    fil => {
                      setFilterSettings({ ...filterSettings, filial: { ...fil } })
                      setShowFilterFilials(false)
                    }
                  }
                />
              </FilterComponent>

              <FilterComponent title="Despesa">
                <TextInputFilter input={filterSettings.expense} setInput={txt => { setFilterSettings({ ...filterSettings, expense: txt }) }} />
              </FilterComponent>

              <FilterComponent title="Fornecedor">
                <GreenSearchButton
                  input={filterSettings.provider.company_name}
                  searchFunction={() => { setShowFilterProvider(true) }}
                  resetFunction={() => setFilterSettings({ ...filterSettings, provider: { cgc: '', company_name: '', id: 0 } })}
                />
                <BuscaFornecedor
                  show={showFilterProvider}
                  setShowSearch={setShowFilterProvider}
                  handleProvider={provider => {
                    setFilterSettings({ ...filterSettings, provider: { ...provider } })
                    setShowFilterProvider(false)
                  }}
                />
              </FilterComponent>

              <FilterComponent title="Plano de Contas">
                <GreenSearchButton
                  input={filterSettings.coa.nome}
                  searchFunction={() => { setShowFilterCoa(true) }}
                  resetFunction={() => setFilterSettings({ ...filterSettings, coa: { id: 0, nome: '', tax_nature: '' } })}
                />
                <BuscarPlanoDeContas
                  show={showFilterCoa}
                  setShowCoA={setShowFilterCoa}
                  handleChartOfAccount={coa => {
                    setFilterSettings({ ...filterSettings, coa: { ...coa } })
                    setShowFilterCoa(false)
                  }}
                />
              </FilterComponent>

              <FilterComponent title="Conta Débito">
                <GreenSearchButton
                  input={filterSettings.bank_from.name}
                  searchFunction={() => { setShowFromBankFilter(true) }}
                  resetFunction={() => { setFilterSettings({ ...filterSettings, bank_from: { id: false, name: '' } }) }}
                />
                <BuscarBanks
                  showBanks={showFromBankFilter}
                  setShowBanks={setShowFromBankFilter}
                  handleSearchBanks={bank => {
                    setFilterSettings({ ...filterSettings, bank_from: { ...bank } })
                    setShowFromBankFilter(false)
                  }}
                />
              </FilterComponent>

            </MainFilter>
          }
          <tr>
            <td>Lançamento</td>
            <td>Cupom Fiscal</td>
            <td>Vencimento</td>
            <td>Baixado Em</td>
            <td>Conta</td>
            <td>Despesa</td>
            <td>Fornecedor</td>
            <td>Valor</td>
            <td>Desconto</td>
            <td>Acréscimo</td>
            <td>Juros</td>
            <td>Total</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {Orders && Orders.length > 0 ? (
            Orders.map(Order => (
              <tr
                key={Order.id}
                onClick={() => handleDialog(Order, "paymentOrder")}
                className={
                  Order.conciliation_id ? "paymentOrder conciliated" : Order.approved_at ? "paymentOrder approved" : "paymentOrder"
                }
                id={Order.id}
              >
                <td>
                  {Order.id}
                  {Order.canceled_at ? (
                    <ul className="dialog">
                      {verifyAccess(accesses, 'exclusao_ordem_pagamento')
                        && Order.filial_id === filial.filial
                        && !Order.conciliation_id
                        && !Order.approved_at ?
                        <li
                          className="trash"
                          onClick={() =>
                            handleBlock(
                              Order.id,
                              Order.canceled_at,
                              `Ordem de Pagamento ${Order.id}`,
                              "paymentOrders",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Restaurar
                        </li>
                        : null}
                    </ul>
                  ) : (
                    <ul className="dialog">
                      {verifyAccess(accesses, 'baixar_ordem_pagamento')
                        && Order.filial_id === filial.filial
                        && Order.due_date >= filial.open_date
                        && !Order.conciliation_id ? (
                        <>
                          {!Order.approved_at ? (
                            <li
                              onClick={() =>
                                handleShowModal(Order.id, Order.extract_at)
                              }
                            >
                              <FaEye size={12} /> Baixar
                            </li>
                          ) : (
                            <li
                              onClick={() => {
                                handleBaixar(Order.id);
                              }}
                            >
                              <FaTimes size={12} /> Estornar
                            </li>
                          )}
                        </>
                      ) : null}
                      <li onClick={() => handleopenOrder(Order.id, false)}>
                        <FaEye size={12} /> Visualizar
                      </li>
                      {verifyAccess(accesses, 'lancamento_ordem_pagamento')
                        && Order.filial_id === filial.filial
                        && !Order.approved_at ? (
                        <li onClick={() => handleopenOrder(Order.id, true)}>
                          <FaEdit size={12} /> Alterar
                        </li>
                      ) : null}
                      {verifyAccess(accesses, 'exclusao_ordem_pagamento') && Order.filial_id === filial.filial
                        && !Order.approved_at ?
                        <li
                          className="trash"
                          onClick={() =>
                            handleBlock(
                              Order.id,
                              Order.canceled_at,
                              `Ordem de Pagamento ${Order.id}`,
                              "paymentOrders",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Excluir
                        </li>
                        : null}
                      {verifyAccess(accesses, 'reclassificar_ordem_pagamento') ?
                        <li
                          className="reclassificar"
                          onClick={() =>
                            handleReclass(
                              Order.id
                            )
                          }
                        >
                          <FaRedo size={12} /> Reclassificar
                        </li>
                        : null}
                    </ul>
                  )}
                </td>
                <td>{Order.cupom_fiscal}</td>
                <td>{formatedDate(Order.due_date)}</td>
                <td>
                  {Order.approved_at ? formatedDate(Order.approved_at) : null}
                </td>
                <td>{Order.bank_id ? Order.bank.name : "Caixa Filial"}</td>
                <td>{Order.reason}</td>
                <td>{Order.provider.fantasy_name}</td>
                <td>
                  {formatMoney(Order.value.toFixed(2), filial.country.language)}
                </td>
                <td>
                  {formatMoney(
                    Order.discount.toFixed(2),
                    filial.country.language
                  )}
                </td>
                <td>
                  {formatMoney(
                    Order.addition.toFixed(2),
                    filial.country.language
                  )}
                </td>
                <td>
                  {formatMoney(
                    Order.interest.toFixed(2),
                    filial.country.language
                  )}
                </td>
                <td>
                  {formatMoney(Order.total.toFixed(2), filial.country.language)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan='13'>Não há lançamentos.</td>
            </tr>
          )}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {openOrder || createOrder ? (
        <>
          <BgBlack />
          <CreateOrder
            handleCreateOrder={handleCreateOrder}
            id={opennedId}
            edit={edit}
            setBlock={setBlock}
          />
        </>
      ) : null}

      {openModal && (
        <Scroll>
          <div className="overlay"></div>
          <ModalDateBaixa>
            <header>
              <Title
                back={true}
                func={() => setOpenModal(false)}
                title={"Cancelar"}
              />
            </header>
            <div className="container">
              <h1>Baixar Ordem de Pagamento</h1>
              <div className="form">
                <div className="rowInputs">
                  <label htmlFor="date">
                    <div>
                      <FaCalendarAlt size={20} />
                      <br />
                      Data
                    </div>
                    <DatePicker
                      style={{ border: "none" }}
                      selected={selected}
                      dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                      onChange={date => baixar(date)}
                      locale={pt}
                      minDate={addDays(new Date(filial.open_date), 1)}
                    />
                  </label>
                  <button
                    type="submit"
                    className="btnFinish"
                    onClick={() => handleBaixar()}
                  >
                    Finalizar
                    <FaAngleRight size={20} />
                  </button>
                </div>
              </div>
            </div>
          </ModalDateBaixa>
        </Scroll>
      )}
      {showCoA ? (
        <>
          <BgBlack />
          <BuscarPlanoDeContas
            show={showCoA}
            setShowCoA={setShowCoA}
            handleChartOfAccount={handleChartOfAccount}
          />
        </>
      ) : null}
    </>
  );
}

export function updateHomebox(homebox) {
  return {
    type: '@homebox/UPDATE',
    payload:  { homebox },
  };
}

export function destroyHomebox() {
  return {
    type: '@homebox/DESTROY',
    payload:  { },
  };
}

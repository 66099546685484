import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "~/services/api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { format, parseISO, addDays, startOfMonth } from 'date-fns';

import Title from "~/components/Title";

import BuscaFilial from "~/components/BuscaFilial";

import {
  FaPlus,
  FaMinus,
  FaAngleRight,
  FaBuilding,
  FaSearch,
  FaGlobeAmericas,
  FaUniversity,
  FaPager,
  FaMoneyCheckAlt,
  FaDollarSign,
  FaCalendarAlt,
  FaTimes
} from "react-icons/fa";

import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt";

import { formatMoney, formatNum, getBanks } from "~/scripts/index.js";

import { Form, Input, Select } from "@rocketseat/unform";
import { Content, Container, Scroll } from "./styles";

export default function CreateBank({
  handleCreateBank,
  opennedID,
  edit,
  setBanks,
  banks
}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [countries,setCountries] = useState([]);
  const [bankList,setBankList] = useState(getBanks("BR"));
  const [filialSearch, setFilialSearch] = useState(false);
  // let [finish, setFinish] = useState(false);
  let [bank, setBank] = useState({
    name: "",
    period_end: format(new Date(), 'yyyy-MM-15'),
    bank: "",
    bank_name: "",
    agency_code: 0,
    agency: "",
    account: "",
    account_code: 0,
    balance: 0,
    tipo: 0,
    created_by: profile.id,
    created_at: new Date()
  });

  const schemaBank = Yup.object().shape({
    name: Yup.string().required("Apelido da conta obrigatória."),
    bank: Yup.string().required("Banco obrigatório."),
    agency: Yup.string().required("Agência obrigatória."),
    account: Yup.string().required("Conta obrigatória."),
    country_id: Yup.string().required("País obrigatório.")
  });

  async function handleSubmit(e) {
    // e.preventDefault();
    try {
      const response = !opennedID
        ? await api.post("banks", bank)
        : await api.put(`banks/${opennedID}`, bank);

      toast.success(`Banco ${!opennedID ? "inserido" : "alterado"} com sucesso!`, {
        className: "success"
      });
      if (bank) {
        setBanks([...banks, response.data]);
      }
      handleCreateBank();
      return;
    } catch (err) {
      toast.error("Revise as informações preenchidas.", { className: "error", });
    }
  }

  function handleSearch(type, status) {
    if (type === "filial") {
      toast.info(`Apenas selecione uma filial se esta conta for específica a ela.`, {
        className: "info",autoClose: 10000
      });
      setFilialSearch(status);
    }
  }

  async function loadCountries() {
    try {
      const response = await api.get("countries/actives/");
      const { data } = response;
      let arrayCountries = [];
        data.map(p => {
          arrayCountries.push({ id: p.id, title: p.name, code: p.code });
          return null;
        });
        setCountries(arrayCountries);
    } catch(err) {

    }
  }

  function handleBank(bankRet){
    setBank({ ...bank, bank: bankRet.substr(0,3), bank_name: bankRet.substr(6,40) })
  }

  function handleCountry(country) {
    let code = null;
    countries.map(c => {
      if (c.id === parseInt(country)) {
        code = c;
      }
      return code;
    });
    setBank({ ...bank, country: code, bank: "", bank_name: "" , country_id: parseInt(country) });
  }

  function handleFilial(date) {
    setBank({ ...bank, filial_id: date.id, filial_name: date.name });
    setFilialSearch(false);
  }

  useEffect(() => {
    async function loadBank() {
      try {
        const response = await api.get("/banks/" + opennedID);
        const { account, account_code, agency, agency_code, balance, bank: banco, bank_name, canceled_at, country, country_id, created_at, created_by, filial, filial_id, id, name, period_end, updated_at } = response.data;
        setBank({ ...bank, account, account_code, agency, agency_code, balance, bank: banco, bank_name, canceled_at, country, country_id, created_at, created_by, filial, filial_id, id, name, period_end, updated_at, });
      } catch(err) {

      }
    }
    loadCountries();
    if(opennedID) {
      loadBank();
    }
  }, [opennedID]);

  useEffect(() => {
    if(bank.country) {
      setBankList(getBanks(bank.country.code))
    }
  }, [bank.country])

  function clearFilial() {
    setBank({...bank, filial: null, filial_name: "", filial_id: null })
  }

  async function handleUpdateSaldo() {
    try {
    const { filial_id, balance} = bank;
    const created_by = profile.id;
    const date = bank.period_end;
    await api.post(`/bank_balance_initial_update/${bank.id}`, {filial_id, balance, created_by, date});
    toast.success(`Saldo inicial alterado com sucesso!`, {
      className: "success"
    });
    handleCreateBank();
    } catch(err) {
      toast.error("Não foi possível alterar o saldo inicial. Verifique se todos os períodos estão abertos.", { className: "error", });
    }
  }

  const balanceTipos = [
    {id: 1, title: 'Positivo'},{id: 2, title: 'Negativo'}
  ]

  // useEffect(() => {
  //   if(bank.bank) {
  //     setBank({...bank,bank_name: getBankName(bank.country.code,bank.bank)})
  //   }
  // }, [bank.bank])


  return (
    <>
      <Scroll>
        <Content>
          <header>
            <Title back={true} func={handleCreateBank} title={"Cancelar"} />
          </header>
          <Container>
            <h1>Cadastro de Banco</h1>
            <Form schema={schemaBank} onSubmit={handleSubmit} autoComplete="off">
            <div className="rowInputs">
              <label htmlFor="country_id">
                <div>
                  {bank.countryFilial || bank.country ? (
                    bank.countryFilial && !bank.country ? (
                      <img
                        alt="country"
                        src={`/images/${bank.countryFilial.code}.svg`}
                        width="30"
                      />
                    ) : (
                      <img
                      alt="country"
                        src={`/images/${bank.country.code}.svg`}
                        width="30"
                      />
                    )
                  ) : (
                    <FaGlobeAmericas
                      size={20}
                      style={{ marginTop: "4px" }}
                    />
                  )}
                </div>
                <Select
                  key={countries.id}
                  options={countries}
                  disabled={!edit ? "disabled" : ""}
                  placeholder="Selecione um país"
                  name="country_id"
                  onChange={e => handleCountry(e.target.value)}
                  value={bank.country_id ? bank.country_id : ""}
                />
              </label>
            </div>
            { bank.country ?
            <>
            <hr />
              <div className="rowInputs">
                <label htmlFor="name">
                  <div>
                    <FaUniversity size={20} />
                    <br />
                    Apelido
                  </div>
                  <Input
                    name="name"
                    placeholder="Apelido da Conta"
                    readOnly={!edit ? "readonly" : ""}
                    maxLength="40"
                    onChange={e => setBank({ ...bank, name: e.target.value })}
                    value={bank.name}
                  />
                </label>

                <label htmlFor="filial">
                    <div>
                      <FaBuilding size={20} />
                      <br />
                      Filial
                    </div>
                    <Input
                      name="filial"
                      placeholder="Selecione..."
                      readOnly={"readonly"}
                      value={!bank.filial_name && bank.filial ? bank.filial.name : bank.filial_name}
                    />
                  </label>
                  {edit && (
                    bank.filial_name || bank.filial
                    ?
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() => clearFilial()}
                    >
                      Limpar <FaTimes size={14} />
                    </button>
                    :
                    <button
                      type="button"
                      className="btnFinish"
                      onClick={() => handleSearch("filial", true)}
                    >
                      Buscar <FaSearch size={14} />
                    </button>
                  )}
                <label htmlFor="bank">
                  <div>
                    <FaUniversity size={20} />
                    <br />
                    Banco
                  </div>
                  <Select
                    key={bankList.id}
                    options={bankList}
                    disabled={edit ? null : "disabled"}
                    name="bank"
                    placeholder={bank.bank_name ? bank.bank_name : 'Banco'}
                    onChange={e => handleBank(e.target.value)}
                    defaultValue={bank.bank}
                  />
                </label>
              </div>
              <hr />
              <div className="rowInputs">
                <label htmlFor="agency">
                  <div>
                    <FaPager size={20} />
                    <br />
                    { bank.country.code === "BR" ? "Agência" : "R.N." }
                  </div>
                  <Input
                    name="agency"
                    placeholder="Agência"
                    readOnly={!edit ? "readonly" : ""}
                    maxLength="12"
                    onChange={e =>
                      setBank({
                        ...bank,
                        agency: e.target.value.toString().replace(/[^\d]+/g, "")
                      })
                    }
                    value={bank.agency}
                  />
                </label>
                { bank.country.code === "BR" ?
                <label htmlFor="agency_code">
                  <Input
                    name="agency_code"
                    placeholder="Dígito"
                    readOnly={!edit ? "readonly" : ""}
                    maxLength="1"
                    onChange={e =>
                      setBank({
                        ...bank,
                        agency_code: e.target.value
                          .toString()
                          .replace(/[^\d]+/g, "")
                      })
                    }
                    value={bank.agency_code}
                  />
                </label>
                : null }

                <label htmlFor="account">
                  <div>
                    <FaMoneyCheckAlt size={20} />
                    <br />
                    { bank.country.code === "BR" ? "Conta" : "Account" }
                  </div>
                  <Input
                    name="account"
                    placeholder="Conta"
                    readOnly={!edit ? "readonly" : ""}
                    maxLength="12"
                    onChange={e =>
                      setBank({
                        ...bank,
                        account: e.target.value.toString().replace(/[^\d]+/g, "")
                      })
                    }
                    value={bank.account}
                  />
                </label>
                { bank.country.code === "BR" ?
                <label htmlFor="account_code">
                  <Input
                    name="account_code"
                    placeholder="Dígito"
                    readOnly={!edit ? "readonly" : ""}
                    maxLength="1"
                    onChange={e =>
                      setBank({
                        ...bank,
                        account_code: e.target.value
                          .toString()
                          .replace(/[^\d]+/g, "")
                      })
                    }
                    value={bank.account_code}
                  />
                </label>
                : null }

                {edit ? (
                  <button
                    type="submit"
                    className="btnFinish"
                  >
                    {!opennedID ? "Finalizar" : "Atualizar"}{" "}
                        <FaAngleRight size={20} />

                  </button>
                ) : null}
              </div>
              <h1>Saldo Inicial</h1>
              <hr />
              <div className="rowInputs">
                <label htmlFor="tipo">
                  <div>
                    { bank.tipo < 2 ? <FaPlus size={20} /> : <FaMinus size={20} /> }
                    <br />
                    Saldo
                  </div>
                  <Select
                    options={balanceTipos}
                    name="tipo"
                    value={bank.tipo > 0 ? bank.tipo : bank.balance > 0 ? 1 : 2}
                    selected={bank.tipo > 0 ? bank.tipo : bank.balance > 0 ? 1 : 2}
                    onChange={e =>
                      setBank({
                        ...bank,
                        tipo: e.target.value,
                        balance: formatNum(bank.balance.toFixed(2), e.target.value == 2)
                      })
                    }
                  />
                </label>
                <label htmlFor="balance">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Saldo
                  </div>
                  <Input
                    name="balance"
                    placeholder="Saldo"
                    // readOnly={opennedID ? "disabled" : null}
                    onChange={e =>
                      setBank({ ...bank, balance: formatNum(e.target.value, bank.tipo == 2) })
                    }
                    value={formatMoney(
                      bank.balance.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="inital_balance">
                  <div>
                    <FaCalendarAlt size={20} />
                    <br />
                    Mês
                  </div>
                  {console.log(bank.period_end)}
                  <DatePicker
                    name="period_end"
                    // readOnly={opennedID ? "disabled" : null}
                    placeholder="Data"
                    selected={parseISO(bank.period_end)}
                    onChange={date => setBank({ ...bank, period_end: format(date,'yyyy-MM-15') })}
                    dateFormat="MM/yyyy"
                    locale={pt}
                    showMonthYearPicker
                  />
                </label>
                {edit ? (
                  <button
                    type="button"
                    onClick={() => handleUpdateSaldo()}
                    className="btnFinish"
                  >
                    Alterar Saldo Inicial
                        <FaAngleRight size={20} />

                  </button>
                ) : null}
              </div>
                  </>
                  : null }
            </Form>
          </Container>
        </Content>
      </Scroll>

      <BuscaFilial
        handleFilial={handleFilial}
        setFilialSearch={setFilialSearch}
        show={filialSearch}
      />
    </>
  );
}

import styled from "styled-components";

import { lighten } from "polished";

import PerfectScrollbar from "react-perfect-scrollbar";

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 550px);

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    display: ${props => (props.NewCheck ? "block" : "none")};
  }

  header {
    width: 100%;
    padding: 15px 0;
    background: #555555;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 12;
`;

export const Container = styled.div`
  background: #efefef;
  width: 100%;
  text-align: center;

  h1 {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #333;
    text-align: left;
    background: #ccc;
    padding: 15px;
    border-bottom: 1px solid #ddd;

    svg {
      margin-right: 5px;
      border-radius: 4px;
      transition: background 0.2s;
    }

    &:hover svg {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .rowInputs {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .nfs_list {
      position: relative;
      display: flex;
      flex-direction: column;

      h1 {
        cursor: pointer;
        width: 100%;
      }
    }

    label {
      display: flex;
      text-align: left;
      align-items: center;
      margin: 0 3px 10px;
      flex: 1;
      position: relative;

      span {
        position: absolute;
        bottom: -27px;
        background: rgb(155,213,49);
        padding: 2px 0px;
        color: #fff;
        width: calc(100% - 6px);
        text-align: center;
        font-style: normal;
        font-size: 11px;
      }

      div.react-datepicker-wrapper {
        width: 100%;
        background: #fff;
        height: 54px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        padding: 15px;

        input {
          border: none;
        }
      }

      img {
        border-radius: 4px;
        overflow: hidden;
      }
    }

    label > div:first-child {
      display: inline-block;
      width: 54px;
      height: 54px;
      text-align: center;
      font-size: 10px;
      color: #555;
      background: #fff;
      padding: 13px 8px 0;
      border-radius: 4px;

      &.fileInput {
        display: flex;
        width: 100%;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 12px;

        svg {
          margin-left: 15px;
        }
      }
    }

    button.btnAdd svg {
      margin-right: 7px;
    }

    button.btnFinish svg {
      margin-left: 7px;
    }
  }
`;

export const ListParcels = styled.ul`
  li {
    width: 100%;
    background: #efefef;
    display: flex;
    height: auto;
    align-items: center;

    table {
      width: 100%;

      thead {
        font-weight: bold;

        tr {
          background: #d7d2de;

          td {
            padding: 5px 10px;
            text-align: center;
            vertical-align: middle;
          }
        }
      }

      tbody tr {
        &:nth-child(odd) {
          background: #fff;
        }

        td {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          padding: 5px 10px;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }
`;

export const GradeTable = styled.table`
  width: 100%;
  background: #eee;
`;

export const GradeHeader = styled.thead`
  background: ${lighten(0.4, "rgb(155,213,49)")};
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);

  td {
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  td.iconSize {
    width: 25px;
  }
`;

export const GradeBody = styled.tbody`
  tr {
    width: 100%;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in;

    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.05);
    }

    &.approved {
      background: rgba(0, 255, 0, 0.2);
    }

    &:hover {
      background: ${lighten(0.2, "rgb(155,213,49)")};
    }

    &.active ul {
      display: block;
    }

    td {
      padding: 0px 10px;
      font-size: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;

      input {
        height: 33px;
        border: none;
        padding-left: 12px;
        margin: 6px 0px;
      }

      > button {
        padding: 3px 6px;
        background: transparent;
        background: rgb(155,213,49);
        color: #fff;
        ${"" /* color: rgb(155,213,49); */}
        border: 0;
        border-radius: 4px;
        transition: background 0.2s;
        border: 1px solid rgb(155,213,49);
        display: flex;
        align-items: center;

        > svg {
          margin-right: 7px;
        }
      }

      > button:disabled {
        background: #ccc;
        color: #777;
      }
    }
  }
`;

export const Dialog = styled.ul`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 100%;
  width: 200px;
  background: #fff;
  border-radius: 4px;
  display: none;
  cursor: auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.1s;

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  &::before {
    content: "";
    position: absolute;
    left: 5px;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
  }

  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: #777;
    }

    &.trash:hover {
      cursor: pointer;
      color: #f00;
    }
  }
`;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import retLanguage from "~/config/language";
import allowAccess from "~/scripts/menuController";

import printJS from "print-js";

import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt";
import en from "date-fns/locale/en-US";
// import pt from 'date-fns/locale/pt';
// import ReactPDF from '@react-pdf/renderer';

import { toast } from "react-toastify";

import "react-confirm-alert/src/react-confirm-alert.css";

import NavigationButtons from "~/components/NavigationButtons";

import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

import api from "~/services/api";
import {
  FaEye,
  FaEdit,
  FaCheck,
  FaTimes,
  FaRedo
  // FaPrint
} from "react-icons/fa";

import {
  GradeTable,
  GradeHeader,
  GradeBody,
  Dialog,
  Filtros,
  New
} from "./styles";
import {
  handleDialog,
  handleBlock,
  formatMoney,
  verifyAccess,
  getStatus
} from "~/scripts/index.js";

import FiltroData from "~/components/FiltroData";
import FiltroLancamento from "~/components/FiltroLancamento";

// import Dizimos from '~/Printers/dizimos';

import CreateDizimo from "./CreateDizimo";
import BgBlack from "~/components/BgBlack";

import "react-datepicker/dist/react-datepicker.css";

import MainFilter, { MainFilterButton } from "~/components/MainFilter";
import FilterComponent from "~/components/FilterComponent";
import BuscarFiliais from "~/components/BuscarFiliais";
import GreenSearchButton from "~/components/GreenSearchButton";
import DateFilterUnique from "~/components/DateFilterUnique";
import BuscarBanks from "~/components/BuscarBanks";
import TextInputFilter from "~/components/TextInputFilter";
import { Select } from "@rocketseat/unform";
import DateFilter from "~/components/DateFilter";

export default function HomeboxEDizimos() {
  const [dizimos, setDizimos] = useState([]);
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [openDate, setOpenDate] = useState(false);
  const [block, setBlock] = useState(false);
  const [lancamento, setLancamento] = useState(null);
  const [openDizimo, setOpenDizimo] = useState(false);
  const [createDizimo, setCreateDizimo] = useState(false);
  const [opennedId, setOpennedId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [showCoA, setShowCoA] = useState(false);
  const [reclassificar, setReclassificar] = useState(null);
  const accesses = useSelector(state => state.auth.newaccesses);
  const lang = retLanguage(profile.language);
  const [setores, setSetores] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showFilterFilials, setShowFilterFilials] = useState(false);
  const [showToBankFilter, setShowToBankFilter] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [filterSettings, setFilterSettings] = useState({
    from: false,
    to: false,
    filial: {
      id: filial.filial,
      name: ""
    },
    setor: false,
    bank_to: {
      id: false,
      name: ""
    },
    obs: ""
  });

  const [filtro, setFiltro] = useState({
    date: format(new Date(), "yyyy-MM-dd 23:59:59")
  });

  const { date } = filtro;

  useEffect(() => {
    async function loadDizimos() {
      try {
        if (filtered) {
          const { data } = await api.get("e-dizimos/e_dizimos_filter/", {
            params: {
              page: page,
              ...filtered
            }
          });

          setPages(data.pages);
          setDizimos(data.dizimos);
        } else {
          const response = lancamento
            ? await api.get("e-dizimos/id/" + lancamento)
            : await api.get(
                "e-dizimos/filial/" +
                  filial.filial +
                  "?page=" +
                  page +
                  "&dateDizimo=" +
                  date
              );

          const { data } = response;
          setPages(data.pages);
          if (lancamento) {
            setDizimos(data);
          } else {
            setDizimos(data.dizimos);
          }
        }
        // setDizimos(
        //   lancamento
        //     ? data.dizimos
        //     : [data.dizimos]
        // );
      } catch (err) {}
    }
    loadDizimos();
    setBlock(false);
  }, [filial, date, page, block, lancamento, createDizimo]);

  function handleData({ date }) {
    setFiltro({
      date: format(date, "yyyy-MM-dd 23:59:59")
    });
    setOpenDate(!openDate);
  }

  function handleOpenDate() {
    setOpenDate(!openDate);
  }

  function handleLancamento(e) {
    e.preventDefault();
    setBlock(true);
  }

  async function approveDizimo(id) {
    try {
      const { data } = await api.put("e-dizimos/approve/" + id);
      toast.success(
        `e-Dízimo ${data.approved_at ? "aprovado" : "estornado"} com sucesso!`,
        {
          className: "success"
        }
      );
      setBlock(true);
      if (data.conciliation_id) {
        toast.success("e-Dízimo encontrado na conciliação!", {
          className: "success"
        });
      }
      return;
    } catch (err) {
      toast.error("Erro ao aprovar e-Dízimo, tente novamente.", {
        className: "error"
      });
    }
  }

  function handleOpenDizimo(id, edit) {
    setOpennedId(id);
    setEdit(edit);
    setOpenDizimo(!openDizimo);
  }

  function handleCreateDizimo() {
    setEdit(!createDizimo);
    setCreateDizimo(!createDizimo);
  }

  async function handleChartOfAccount(coa) {
    try {
      await api.put(`/reclassificar/dizimos/${reclassificar}/${coa.id}`);
      toast.success("Reclassificado com sucesso!", {
        className: "success"
      });
      setShowCoA(false);
    } catch (err) {
      toast.error("Impossível reclassificar.", {
        className: "error"
      });
    }
  }

  function handleReclass(movement) {
    setShowCoA(true);
    setReclassificar(movement);
  }

  useEffect(() => {
    async function getSetores() {
      const { data } = await api.get("sector/");
      setSetores(data);
    }
    getSetores();
  }, []);

  async function applyFilter() {
    try {
      if (
        filterSettings.from &&
        filterSettings.to &&
        filterSettings.from > filterSettings.to
      ) {
        return toast.error(
          "A data de emissão inicial não pode ser maior do que a final.",
          {
            className: "error"
          }
        );
      }

      let from = filterSettings.from
        ? new Date(filterSettings.from)
        : new Date(1900, 0, 1);
      from = from.toISOString().substring(0, 10);

      let to = filterSettings.to
        ? new Date(filterSettings.to)
        : new Date(3000, 0, 1);
      to = to.toISOString().substring(0, 10);

      const params = {
        from_date: from,
        to_date: to,
        filial_id: filterSettings.filial.id,
        reason: filterSettings.setor,
        to: filterSettings.bank_to.id,
        obs: filterSettings.obs
      };

      const { data } = await api.get("e-dizimos/e_dizimos_filter/", {
        params: {
          page: filtered ? page : 1,
          ...params
        }
      });

      if (!filtered) {
        setPage(1);
      }

      setPages(data.pages);
      setDizimos(data.dizimos);

      setFiltered({
        ...params
      });

      setShowFilter(false);

      return data;
    } catch (err) {}
  }

  async function resetFilter() {
    try {
      setFilterSettings({
        from: false,
        to: false,
        filial: {
          id: filial.filial,
          name: ""
        },
        setor: false,
        bank_to: {
          id: false,
          name: ""
        },
        obs: ""
      });

      setFiltered(false);
      setPage(1);

      const response = await api.get(
        "e-dizimos/filial/" +
          filial.filial +
          "?page=" +
          1 +
          "&dateDizimo=" +
          date
      );

      const { data } = response;
      setPages(data.pages);
      setDizimos(data.dizimos);
      setShowFilter(false);
    } catch (err) {}
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses, "lancamento_e-dizimos") ? (
          <New
            type="button"
            onClick={() => {
              handleCreateDizimo();
              setShowFilter(false);
            }}
          >
            {lang.grade.buttons.b1} +
          </New>
        ) : null}
        <MainFilterButton click={() => setShowFilter(!showFilter)} />
        {/* <FiltroData
          openDate={openDate}
          handleOpenDate={handleOpenDate}
          selected={parseISO(date)}
          handleData={handleData}
        />
        <FiltroLancamento
          handleLancamento={handleLancamento}
          placeholder={lang.grade.buttons.b5}
          setFind={setLancamento}
        /> */}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          {showFilter && (
            <MainFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <FilterComponent title="Data de Emissão">
                <DateFilter
                  to={filterSettings.to}
                  setTo={dt => setFilterSettings({ ...filterSettings, to: dt })}
                  from={filterSettings.from}
                  setFrom={dt =>
                    setFilterSettings({ ...filterSettings, from: dt })
                  }
                />
              </FilterComponent>

              <FilterComponent title="Filial">
                <GreenSearchButton
                  input={filterSettings.filial.name}
                  searchFunction={() => {
                    setShowFilterFilials(true);
                  }}
                  resetFunction={() =>
                    setFilterSettings({
                      ...filterSettings,
                      filial: { id: filial.filial, name: "" }
                    })
                  }
                />
                <BuscarFiliais
                  showFilials={showFilterFilials}
                  setShowFilials={setShowFilterFilials}
                  handleSearchFilials={fil => {
                    setFilterSettings({
                      ...filterSettings,
                      filial: { ...fil }
                    });
                    setShowFilterFilials(false);
                  }}
                />
              </FilterComponent>

              <FilterComponent title="Setor">
                <Select
                  options={setores}
                  name="setor"
                  value={filterSettings.setor ? filterSettings.setor : ""}
                  onChange={e => {
                    setFilterSettings({
                      ...filterSettings,
                      setor: e.target.value
                    });
                  }}
                  placeholder="Selecione..."
                  style={{
                    width: 150,
                    height: 27,
                    border: "none",
                    borderRadius: 4
                  }}
                />
              </FilterComponent>

              <FilterComponent title="Conta Crédito">
                <GreenSearchButton
                  input={filterSettings.bank_to.name}
                  searchFunction={() => {
                    setShowToBankFilter(true);
                  }}
                  resetFunction={() => {
                    setFilterSettings({
                      ...filterSettings,
                      bank_to: { id: false, name: "" }
                    });
                  }}
                />
                <BuscarBanks
                  caixa_filial={false}
                  showBanks={showToBankFilter}
                  setShowBanks={setShowToBankFilter}
                  handleSearchBanks={bank => {
                    setFilterSettings({
                      ...filterSettings,
                      bank_to: { ...bank }
                    });
                    setShowToBankFilter(false);
                  }}
                />
              </FilterComponent>

              <FilterComponent title="Observação">
                <TextInputFilter
                  input={filterSettings.obs}
                  setInput={txt => {
                    setFilterSettings({ ...filterSettings, obs: txt });
                  }}
                />
              </FilterComponent>
            </MainFilter>
          )}
          <tr>
            <td>{lang.grade.pages.p2.header.h1}</td>
            <td>{lang.grade.pages.p2.header.h2}</td>
            <td>{lang.grade.pages.p2.header.h3}</td>
            <td>{lang.grade.pages.p2.header.h4}</td>
            <td>{lang.grade.pages.p2.header.h5}</td>
            <td>{lang.grade.pages.p2.header.h6}</td>
            <td>{lang.grade.pages.p2.header.h7}</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {dizimos && dizimos.length > 0 ? (
            dizimos.map(dizimo => (
              <tr
                key={dizimo.id}
                onClick={() => handleDialog(dizimo, "dizimos")}
                className={
                  dizimo.conciliation_id
                    ? "dizimos conciliated"
                    : dizimo.approved_at
                    ? "dizimos approved"
                    : "dizimos"
                }
                id={dizimo.id}
              >
                <td>
                  {dizimo.id}
                  {dizimo.canceled_at ? (
                    <ul className="dialog">
                      {verifyAccess(accesses, "exclusao_e-dizimos") &&
                        !dizimo.approved_at &&
                        !dizimo.conciliation_id && (
                          <li
                            className="trash"
                            onClick={() =>
                              handleBlock(
                                dizimo.id,
                                dizimo.canceled_at,
                                `lançamento de dízimo ${dizimo.id}`,
                                "dizimos",
                                setBlock
                              )
                            }
                          >
                            <FaTimes size={12} /> Excluir
                          </li>
                        )}
                    </ul>
                  ) : (
                    <ul className="dialog">
                      {verifyAccess(accesses, "aprovacao_e-dizimos") &&
                      !dizimo.conciliation_id ? (
                        <>
                          <li onClick={() => approveDizimo(dizimo.id)}>
                            {!dizimo.approved_at ? (
                              <>
                                <FaCheck size={12} /> Aprovar
                              </>
                            ) : (
                              <>
                                <FaTimes size={12} /> Estornar Aprovação
                              </>
                            )}
                          </li>
                        </>
                      ) : null}
                      <li onClick={() => handleOpenDizimo(dizimo.id, false)}>
                        <FaEye size={12} /> Visualizar
                      </li>
                      {verifyAccess(accesses, "lancamento_e-dizimos") &&
                      !dizimo.approved_at &&
                      !dizimo.conciliation_id ? (
                        <li onClick={() => handleOpenDizimo(dizimo.id, true)}>
                          <FaEdit size={12} /> Alterar
                        </li>
                      ) : null}
                      {verifyAccess(accesses, "exclusao_e-dizimos") &&
                      !dizimo.approved_at &&
                      !dizimo.conciliation_id ? (
                        <li
                          className="trash"
                          onClick={() =>
                            handleBlock(
                              dizimo.id,
                              dizimo.canceled_at,
                              `lançamento de dízimo ${dizimo.id}`,
                              "dizimos",
                              setBlock
                            )
                          }
                        >
                          <FaTimes size={12} /> Excluir
                        </li>
                      ) : null}

                      {verifyAccess(accesses, "reclassificar_e-dizimos") ? (
                        <li
                          className="reclassificar"
                          onClick={() => handleReclass(dizimo.id)}
                        >
                          <FaRedo size={12} /> Reclassificar
                        </li>
                      ) : null}
                    </ul>
                  )}
                </td>
                {profile.language === "pt-BR" ? (
                  <td>
                    {format(parseISO(dizimo.date), "dd MMMM, yyyy", {
                      locale: pt
                    })}
                  </td>
                ) : (
                  <td>
                    {format(parseISO(dizimo.date), `MMMM do, yyyy`, {
                      locale: en
                    })}
                  </td>
                )}
                <td>
                  {formatMoney(dizimo.total.toFixed(2), profile.language)}
                </td>
                <td>{dizimo.observation ? dizimo.observation : null}</td>
                <td>{dizimo.setor ? dizimo.setor.name : null}</td>
                <td>{dizimo.bank ? dizimo.bank.name : null}</td>
                <td
                  className={
                    dizimo.conciliation_id
                      ? "conciliated"
                      : dizimo.approved_at
                      ? "approved"
                      : ""
                  }
                >
                  {getStatus(
                    dizimo.conciliation_id
                      ? "Conciliado"
                      : dizimo.approved_at
                      ? "Aprovado"
                      : "Pendente"
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">Não há lançamentos.</td>
            </tr>
          )}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {openDizimo ? (
        <>
          <BgBlack />
          <CreateDizimo
            handleCreateDizimo={handleOpenDizimo}
            id={opennedId}
            edit={edit}
            setBlock={setBlock}
          />
        </>
      ) : null}
      {createDizimo ? (
        <>
          <BgBlack />
          <CreateDizimo
            id={opennedId}
            handleCreateDizimo={handleCreateDizimo}
            edit={edit}
            setBlock={setBlock}
          />
        </>
      ) : null}
      {showCoA ? (
        <>
          <BgBlack />
          <BuscarPlanoDeContas
            show={showCoA}
            setShowCoA={setShowCoA}
            handleChartOfAccount={handleChartOfAccount}
          />
        </>
      ) : null}
    </>
  );
}

import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import * as yup from "yup";

import api from "~/services/api";

import { handleBlock } from "~/scripts/handleBlock.js";

import { toast } from "react-toastify";

import { Container } from "./styles";

import { getAccountTypes, getBanks } from "~/scripts";

import { Form, Input, Select } from "@rocketseat/unform";

import {
  FaTimes,
  FaAngleRight,
  FaUserAlt,
  FaLandmark,
  FaInfo,
  FaPager,
  FaMoneyCheckAlt
} from "react-icons/fa";

export default function FormProprietario({
  cgc,
  agentId,
  agent,
  setAgent,
  edit
}) {
  const profile = useSelector(state => state.user.profile);
  const [block, setBlock] = useState(false);
  const [proprietario, setProprietario] = useState({
    agent_id: agentId,
    created_by: profile.id,
    cgc,
    new: true
  });
  const account_types = getAccountTypes(agent.country)

  const banks = getBanks(agent.country)

  useEffect(() => {
    async function loadProprietario() {
      try {
      const response = await api.get("property-owner/?agentId=" + agentId, {
        agentId
      });
      const { data } = response;
      setProprietario({ ...data, new: false });
    } catch(err) {

    }
    }
    loadProprietario();
    setBlock(false);
  }, [agentId, setBlock, block]);

  async function handleSubmit() {
    try {
      const response = proprietario.new
        ? await api.post("property-owner/", proprietario)
        : await api.put("property-owner/", proprietario);

      toast.success(`Dados de Proprietário atualizado com sucesso!`, {
        className: "success"
      });
      setAgent({
        ...agent,
        type: agent.type + ",5",
        new: false,
        agent_id: response.data.id
      });
      return;
    } catch (err) {
      toast.error("Por favor, revise os dados!", { className: "error" });
    }
  }

  const agentSchema = yup.object().shape({
    full_name: yup.string().required("Nome obrigatório"),
    short_name: yup.string().required("Apelido obrigatório"),
    bank: yup.string().required("Banco obrigatório"),
    account_type: yup.string().required("Tipo de conta obrigatório"),
    agency: yup.string().required(agent.country === "BR" ? "Agência obrigatória" : "R.N. Obrigatório" ),
    account: yup.string().required(agent.country === "BR" ? "Conta obrigatória" : "Account Number Obrigatório" )
  });

  return (
    <Container>
      <h1>Dados de Proprietário</h1>
      <Form
        initialData={proprietario}
        onSubmit={handleSubmit}
        schema={agentSchema}
      >
        <div className="rowInputs">
          <label htmlFor="full_name">
            <div>
              <FaUserAlt size={20} />
              <br />
              Nome
            </div>
            <Input
              name="full_name"
              readOnly={!edit ? "readonly" : ""}
              onChange={e =>
                setProprietario({ ...proprietario, full_name: e.target.value })
              }
              // placeholder={agent.name}
              maxLength="60"
            />
          </label>
          <label htmlFor="short_name">
            <div>
              <FaUserAlt size={20} />
              <br />
              Apelido
            </div>
            <Input
              name="short_name"
              readOnly={!edit ? "readonly" : ""}
              onChange={e =>
                setProprietario({ ...proprietario, short_name: e.target.value })
              }
              maxLength="40"
            />
          </label>
        </div>

        <hr />

        <div className="rowInputs">
          {/* <label htmlFor="accounting_code">
            <div>
              <FaBalanceScale size={20} />
              <br />
              C.Contáb.
            </div>
            <Input
              name="accounting_code"
              readOnly={!edit ? "readonly" : ""}
              onChange={e =>
                setProprietario({
                  ...proprietario,
                  accounting_code: e.target.value
                })
              }
              maxLength="18"
            />
          </label>
          <label htmlFor="tax_nature">
            <div>
              <FaCube size={20} />
              <br />
              Natureza
            </div>
            <Input
              name="tax_nature"
              readOnly={!edit ? "readonly" : ""}
              onChange={e =>
                setProprietario({ ...proprietario, tax_nature: e.target.value })
              }
            />
          </label> */}
          <label htmlFor="bank">
            <div>
              <FaLandmark size={20} />
              <br />
              Banco
            </div>
            <Select
              options={banks}
              name="bank"
              value={proprietario.bank}
              disabled={!edit ? "disabled" : ""}
              onChange={e =>
                setProprietario({ ...proprietario, bank: e.target.value })
              }
            />
          </label>
          <label htmlFor="account_type">
            <div>
              <FaInfo size={20} />
              <br />
              Tipo
            </div>
            <Select
              options={account_types}
              name="account_type"
              disabled={!edit ? "disabled" : ""}
              onChange={e =>
                setProprietario({
                  ...proprietario,
                  account_type: e.target.value
                })
              }
              value={proprietario.account_type}
            />
          </label>
        </div>

        <hr />

        <div className="rowInputs">
          <label htmlFor="agency">
            <div>
              <FaPager size={20} />
              <br />
              { agent.country === "BR" ? "Agencia" : "R.N" }
            </div>
            <Input
              name="agency"
              readOnly={!edit ? "readonly" : ""}
              placeholder=""
              value={proprietario.agency}
              onChange={e =>
                setProprietario({
                  ...proprietario,
                  agency: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
              maxLength="40"
            />
          </label>
          {agent.country === "BR" ?
          <label htmlFor="agency_code" className="mini_input">
            <Input
              name="agency_code"
              readOnly={!edit ? "readonly" : ""}
              placeholder="Dígito"
              maxLength="1"
              onChange={e =>
                setProprietario({
                  ...proprietario,
                  agency_code: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
              value={proprietario.agency_code}
            />
          </label>
          : null }
          <label htmlFor="account">
            <div>
              <FaMoneyCheckAlt size={20} />
              <br />
              { agent.country === "BR" ? "Conta" : "Acc." }
            </div>
            <Input
              name="account"
              readOnly={!edit ? "readonly" : ""}
              placeholder=""
              value={proprietario.account}
              maxLength="40"
              onChange={e =>
                setProprietario({
                  ...proprietario,
                  account: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
            />
          </label>
          { agent.country === "BR" ?
          <label htmlFor="account_code" className="mini_input">
            <Input
              name="account_code"
              readOnly={!edit ? "readonly" : ""}
              placeholder="Dígito"
              maxLength="1"
              value={proprietario.account_code}
              onChange={e =>
                setProprietario({
                  ...proprietario,
                  account_code: e.target.value.toString().replace(/[^\d]+/g, "")
                })
              }
            />
          </label>
          : null }
        </div>

        <hr />

        <div
          className="rowInputs"
          style={{ "justify-content": "space-between" }}
        >
          {edit ? (
            <>
              <button
                type="button"
                onClick={() =>
                  handleBlock(
                    proprietario.id,
                    proprietario.canceled_at,
                    "proprietário",
                    "property-owner",
                    setBlock
                  )
                }
                class="btnFinish trash"
                id="btnBlock"
              >
                {proprietario.canceled_at ? (
                  <>
                    <FaAngleRight size={16} />
                    &nbsp; Desbloquear proprietário
                  </>
                ) : (
                  <>
                    <FaTimes size={16} />
                    &nbsp; Bloquear proprietário
                  </>
                )}
              </button>
              <button type="submit" class="btnFinish" id="btnFinish">
                <>
                  {proprietario.new ? "Gravar" : "Atualizar"}{" "}
                  <FaAngleRight size={20} />
                </>
              </button>
            </>
          ) : null}
        </div>
      </Form>
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "~/services/api";
import { toast } from "react-toastify";
import retLanguage from '~/config/language';
import { updateSelectedFilialsRequest } from '~/store/modules/selectedFilials/actions';

import Title from "~/components/Title";

import {
  FaUsers,
  FaAngleRight,
  FaTimesCircle,
  FaUserAlt,
  FaEnvelope
} from "react-icons/fa";

import { Input, Select } from "@rocketseat/unform";
import { Content, Container, Scroll } from "./styles";
import GroupAccess from './GroupAccess'

export default function CreateUser({
  handleCreateUser,
  opennedID,
  edit,
  setUsers,
  users
}) {
  const profile = useSelector(state => state.user.profile);
  const selectedFilials = useSelector(state => state.selectedFilials.filials);
  const [newUser] = useState(opennedID ? false : true);
  const [grupos, setGrupos] = useState([]);
  const [filiais, setFiliais] = useState([]);
  const [isAlterado, setIsAlterado] = useState(false);
  const [filialDefault, setFilialDefault] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()
  let [finish, setFinish] = useState(false);
  let [user, setUser] = useState({
    created_by: profile.id,
    filial_id: 1,
    language: profile.language,
    password: "12345678",
  });
  const lang = retLanguage(profile.language);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!filialDefault) {
      return toast.error(`Por favor escolha a filial principal.`, { className: "error" });
    }
    const allowed = [];
    async function gravar() {
      try {
        const response = newUser
          ? await api.post("users", user)
          : await api.put("users/access", { ...user, allowed: selectedFilials.selected, filialDefault: selectedFilials.main });

        setUser({ ...user, id: response.data.id });

        toast.success(
          `${newUser ? lang.success.s1 : lang.success.s2}`,
          { className: "success" }
        );
        if (newUser) {
          setUsers([...users, response.data]);
        }
        handleCreateUser();
        dispatch(updateSelectedFilialsRequest({ selected: selectedFilials.selected, main: selectedFilials.main, changed: false }));
        return;
      } catch (err) {
        switch (err.response.data.error) {
          case 'email-already-used':
            toast.error(lang.errors.e2, { className: "error" });
            break
          case 'user-does-not-exist':
            toast.error(lang.errors.e3, { className: "error" });
            break
          default:
            toast.error(lang.errors.e1, { className: "error" });
        }
      }
    }
    filiais.map(filial => {
      if (filial.allow) {
        allowed.push(filial.id);
      }
      filial.filhas.map(filha1 => {
        if (filha1.allow) {
          allowed.push(filha1.id);
        }
        filha1.filhas.map(filha2 => {
          if (filha2.allow) {
            allowed.push(filha2.id);
          }
        })
      })
    })
    setTimeout(() => {
      gravar();
    }, 1000)

  }

  useEffect(() => {
    async function loadFilials() {
      const response = await api.get("filiais/hierarquia");
      setFiliais(response.data);
    }
    async function loadGroups() {
      const response = await api.get("user_group/");
      const findGrupos = [];
      response.data && response.data.map(grupo => {
        if (grupo.id >= 5) {
          findGrupos.push({ id: grupo.id, title: grupo.nome })
        }
      })
      setGrupos(findGrupos);
    }
    async function loadUser() {
      try {
        const response = await api.get(`users/id/${opennedID}`);

        setUser({ ...response.data });
        return;
      } catch (err) {

      }
      handleCreateUser();
    }
    if (opennedID) {
      loadUser();
    }
    loadGroups();
    loadFilials();
  }, [opennedID, handleCreateUser]);

  useEffect(() => {
    if (selectedFilials.changed) {
      setIsAlterado(true)
    }
  }, [selectedFilials])

  useEffect(() => {
    const { group_id, nome, email } = user;
    setFinish(false);
    if (group_id && nome && email) {
      setFinish(true);
    }
   
    if (user.filial_id) {
      setFilialDefault(user.filial_id)
    }

  }, [user]);

  return (
    <Scroll>
      <Content>
        <header>
          <Title
            back={true}
            func={() => { handleCreateUser(); setIsAlterado(false); dispatch(updateSelectedFilialsRequest({ selected: selectedFilials.selected, main: selectedFilials.main, changed: false })) }}
            title={"Cancelar"} />
        </header>
        <Container>
          <h1>Usuário Matriz</h1>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="rowInputs">
              <label htmlFor="group_id">
                <div>
                  <FaUsers size={20} />
                  <br />
                  Grupo
                </div>
                <Select
                  name="group_id"
                  disabled={opennedID ? "disabled" : ""}
                  options={grupos}
                  onChange={e => { setUser({ ...user, group_id: e.target.value }); setIsAlterado(true); }}
                  value={user.group_id}
                />
              </label>
              <label htmlFor="nome">
                <div>
                  <FaUserAlt size={20} />
                  <br />
                  Nome
                </div>
                <Input
                  name="nome"
                  placeholder="Nome do Usuário"
                  readOnly={!edit ? "readonly" : ""}
                  maxLength="40"
                  onChange={e => { setUser({ ...user, nome: e.target.value }); setIsAlterado(true); }}
                  value={user.nome}
                />
              </label>
            </div>

            <hr />

            <div className="rowInputs">
              <label htmlFor="email">
                <div>
                  <FaEnvelope size={20} />
                  <br />
                  E-mail
                </div>
                <Input
                  name="email"
                  type="email"
                  placeholder="@email.com"
                  readOnly={!edit ? "readonly" : ""}
                  maxLength="40"
                  onChange={e => { setUser({ ...user, email: e.target.value }); setIsAlterado(true); }}
                  value={user.email}
                />
              </label>

              <button
                type="submit"
                className="btnFinish"
                disabled={!isAlterado}
              >
                {isAlterado ? (<>Gravar Alterações <FaAngleRight size={20} /></>) : 'Usuário Atualizado'}
              </button>
            </div>
            <hr />
            <GroupAccess user_info={user} />
          </form>
        </Container>
      </Content>
    </Scroll>
  );
}

import React, { useState, useEffect } from "react";
import { parseISO } from "date-fns";
import { useSelector } from "react-redux";
import { FaAngleDown, FaAngleRight, FaTrash, FaEdit } from "react-icons/fa";
import { format } from "date-fns";

import { Container, ListCheques } from "./styles";
import { formatMoney, getBanks } from "~/scripts/index.js";
// import Itau from "~/assets/images/banks/itau.png";
// import Bradesco from "~/assets/images/banks/bradesco.png";

export default function TabelaCheques({
  checks,
  edit,
  removeChecks,
  setEditCheck
}) {
  const filial = useSelector(state => state.filial);
  // const profile = useSelector(state => state.user.profile);
  const [tableOpened, setTableOpened] = useState(true);
  const banks = getBanks(filial.country.code);

  function handleTable() {
    setTableOpened(!tableOpened);
  }

  useEffect(() => {
    checks.map(check => {
      banks.map(bank => {
        const bankCode = parseInt(bank.id.substring(0, 3));

        if (bankCode === check.bank) {
          check.bank_name = bank.title;
        }
      });
    });
  }, [checks]);

  return (
    <Container>
      <h1 onClick={handleTable}>
        {tableOpened ? <FaAngleDown size={20} /> : <FaAngleRight size={20} />}
        Tabela de Cheques
      </h1>
      {tableOpened ? (
        <ListCheques>
          <li>
            <table>
              <thead>
                <tr>
                  <td>Banco</td>
                  <td>Data</td>
                  <td>Valor</td>
                  <td>Responsável</td>
                  <td>Agência</td>
                  <td>Conta</td>
                  <td>Telefone</td>
                  <td>Remover</td>
                </tr>
              </thead>
              <tbody>
                {checks.length > 0 ? (
                  checks.map((check, index) => {
                    return (
                      <tr key={index}>
                        <td>{check.bank_name}</td>
                        <td>
                          {format(
                            parseISO(check.date),
                            filial.country.language === "pt-BR"
                              ? "dd/MM/yyyy"
                              : "MM/dd/yyyy"
                          )}
                        </td>
                        <td>
                          {formatMoney(
                            check.value.toFixed(2),
                            filial.country.language
                          )}
                        </td>
                        <td>{check.benef}</td>
                        <td>{check.agency + "/" + check.agency_code}</td>
                        <td>{check.account + "/" + check.account_code}</td>
                        <td>{check.phone}</td>
                        <td>
                          {edit && (
                            <div
                              style={{
                                justifyContent: "space-evenly",
                                display: "flex"
                              }}
                            >
                              <FaEdit
                                size={14}
                                onClick={() => {
                                  setEditCheck(index);
                                }}
                                style={{ cursor: "pointer" }}
                              />

                              <FaTrash
                                size={14}
                                onClick={() => removeChecks(index)}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9} style={{ textAlign: "inherit" }}>
                      Não há cheques
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </li>
        </ListCheques>
      ) : null}
    </Container>
  );
}

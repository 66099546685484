import styled from 'styled-components';

export const Container = styled.div`
  background:rgba(0,0,0,.5);
  width:100%;
  height:100%;
  position: fixed;
  z-index: ${props => (props.zIndex ? props.zIndex : 11)};
  left: 0;
  top: 0;

`;

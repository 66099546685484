import React, { useState, useEffect } from "react";

import {
  FaUniversity,
  FaRegAddressCard
} from "react-icons/fa";

import Title from "~/components/Title";

import { maskCGC } from "~/scripts/index.js";

import { Input, Form } from "@rocketseat/unform";

import { Content, Container, Scroll } from "./styles";

import api from "~/services/api";

function BuscarFilial({ handleFilial, show, setFilialSearch }) {
  const [filial, setFilial] = useState({ code: "", name: "" });
  const [listFilial, setListFilial] = useState([]);

  useEffect(() => {
    async function getFIlial() {
      const response = await api.get(
        `searchfiliais?code=${filial.code}&name=${filial.name}`
      );

      setListFilial(response.data);
    }
    getFIlial();
  }, [show, filial]);

  async function handleSubmit() {
    const response = await api.get(
      `searchfiliais?code=${filial.code}&name=${filial.name}`
    );

    setListFilial(response.data);
  }

  return (
    <Scroll style={!show ? { display: "none" } : null}>
      <Content style={{ maxWidth: "1100px", left: "calc(50% - 550px)" }}>
        <header>
          <Title back={true} func={setFilialSearch} title={"Cancelar"} />
        </header>
        <Container>
          <h1>Buscar Filial</h1>
          <Form
            onSubmit={handleSubmit}
            style={!show ? { display: "none" } : null}
          >
            <div className="rowInputs">
              <label htmlFor="search">
                <div>
                  <FaRegAddressCard size={20} />
                  <br />
                  Doc.
                </div>
                <Input
                  name="search"
                  placeholder="Documento da Filial"
                  onChange={e => setFilial({ ...filial, code: e.target.value })}
                />
              </label>
              <label htmlFor="search">
                <div>
                  <FaUniversity size={20} />
                  <br />
                  Nome
                </div>
                <Input
                  name="search"
                  placeholder="Nome da Filial"
                  onChange={e => setFilial({ ...filial, name: e.target.value })}
                />
              </label>
            </div>
          </Form>
          <Container>
            {listFilial.map((filial,index) => {
              return (
                <div className="rowInputs" key={index}>
                  <button
                    type="button"
                    className="btnFinish"
                    onClick={() => handleFilial(filial)}
                  >
                    Selecionar
                  </button>
                  <label htmlFor="cgc">
                    <div>
                      <FaRegAddressCard size={20} />
                      <br />
                      Doc.
                    </div>
                    <Input readOnly name="cgc" value={maskCGC(filial.cgc)} />
                  </label>
                  <label htmlFor="name">
                    <div>
                      <FaUniversity size={20} />
                      <br />
                      Nome
                    </div>
                    <Input readOnly name="name" value={filial.name} />
                  </label>
                </div>
              );
            })}
          </Container>
        </Container>
      </Content>
    </Scroll>
  );
}

export default BuscarFilial;

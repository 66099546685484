import gif from "./file_extension/gif.png";
import jpeg from "./file_extension/jpeg.png";
import jpg from "./file_extension/jpg.png";
import png from "./file_extension/png.png";
import rar from "./file_extension/rar.png";
import rtf from "./file_extension/rtf.png";
import xls from "./file_extension/xls.png";
import csv from "./file_extension/csv.png";
import ofx from "./file_extension/ofx.png";
import zip from "./file_extension/zip.png";
import pdf from "./file_extension/pdf.png";

export { default as amex } from "./cartoes/amex.png";
export { default as aura } from "./cartoes/aura.png";
export { default as banescard } from "./cartoes/banescard.png";
export { default as diners } from "./cartoes/diners.png";
export { default as discover } from "./cartoes/discover.jpg";
export { default as elo } from "./cartoes/elo.png";
export { default as hipercard } from "./cartoes/hipercard.png";
export { default as jcb } from "./cartoes/jcb.png";
export { default as mastercard } from "./cartoes/mastercard.png";
export { default as sorocred } from "./cartoes/sorocred.jpg";
export { default as visa } from "./cartoes/visa.png";

export { default as cooper } from "./cartoes/cooper.jpg";
export { default as credsystem } from "./cartoes/credsystem.png";
export { default as greencard } from "./cartoes/greencard.jpg";
export { default as sodexo } from "./cartoes/sodexo.png";
export { default as ticket } from "./cartoes/ticket.jpg";

export const file_extension = [
  { title: "gif", icon: gif },
  { title: "jpeg", icon: jpeg },
  { title: "jpg", icon: jpg },
  { title: "png", icon: png },
  { title: "rar", icon: rar },
  { title: "rtf", icon: rtf },
  { title: "xls", icon: xls },
  { title: "csv", icon: csv },
  { title: "ofx", icon: ofx },
  { title: "zip", icon: zip },
  { title: "pdf", icon: pdf }
];

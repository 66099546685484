import api from "~/services/api";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt";
import extenso from "extenso";

export function verifyAccess(accesses, alias) {
  const hierarchy = accesses.groupHierarchy.hierarchies;
  if (accesses.groupHierarchy.id === 1) {
    return true;
  }
  let ret = false;
  hierarchy.map(h => {
    if (h.alias === alias && h.allow) {
      ret = true;
    }
    h.subGroup &&
      h.subGroup.map(sg => {
        if (sg.alias === alias && sg.allow) {
          ret = true;
        }
        sg.subGroup &&
          sg.subGroup.map(sgg => {
            if (sgg.alias === alias && sgg.allow) {
              ret = true;
            }
            sgg.subGroup &&
              sgg.subGroup.map(sggg => {
                if (sggg.alias === alias && sggg.allow) {
                  ret = true;
                }
              });
          });
      });
  });
  return ret;
}

export function formatNum(receive, negative = false) {
  receive = receive || 0;
  const justNumbers = receive.toString().replace(/[^\d]+/g, "");
  const decimal =
    justNumbers.substr(-2, 2).length > 1
      ? justNumbers.substr(-2, 2)
      : `0${justNumbers.substr(-2, 2)}`;
  let result = `${justNumbers.substr(0, justNumbers.length - 2)}.${decimal}`;
  result = negative ? result * -1 : result;
  return parseFloat(result);
}

export function formatMoney(moneyIn, language = "pt-BR") {
  moneyIn = moneyIn || "0";
  if (!language) {
    language = "pt-BR";
  }
  let money = moneyIn.toString().replace(/[^\d]+/g, "");
  let total = (parseFloat(moneyIn < 0 ? -money : money) / 100).toLocaleString(
    language,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  );
  return total;
}

export function formatedDate(date) {
  let mes = format(parseISO(date), "MMMM", { locale: pt });
  mes = mes.substring(0, 1).toUpperCase() + mes.substring(1);
  return format(parseISO(date), "dd 'de " + mes + " de' yyyy", { locale: pt });
}

export function maskPhone(v, code) {
  var r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");

  if (code === "BR") {
    if (r.length > 10) {
      // 11+ digits. Format as 5+4.
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      // 6..10 digits. Format as 4+4
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      // 3..5 digits. Add (..)
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    }
  } else {
    if (r.length > 10) {
      // 11+ digits. Format as 5+4.
      r = r.replace(/^(\d\d\d)(\d{3})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 4) {
      // 6..10 digits. Format as 4+4
      r = r.replace(/^(\d\d\d)(\d{0,3})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 3) {
      // 3..5 digits. Add (..)
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "($1) $2");
    }
  }

  return r;
}

export function getPeriod(period, country = "pt-BR") {
  if (!country) {
    country = "pt-BR";
  }
  if (country === "pt-BR") {
    return period;
  }
  if (period === "Manhã") {
    return "Morning";
  } else if (period === "Tarde") {
    return "Afternoon";
  } else {
    return "Night";
  }
}

export function getStatus(status, country = "pt-BR") {
  if (!country) {
    country = "pt-BR";
  }
  if (country === "pt-BR") {
    return status;
  }
  if (status === "Pendente") {
    return "Pending";
  } else if (status === "Aprovado") {
    return "Approved";
  } else if (status === "Conciliado") {
    return "Concialized";
  } else {
    return "Reproved";
  }
}

export function maskZIP(v) {
  v = v.toString().replace(/\D/g, "");
  if (v.length === 8) {
    v = v.replace(/^(\d{5})(\d{3}).*/, "$1-$2");
  }
  return v;
}

export function maskCGC(v) {
  //Remove tudo o que não é dígito
  v = v.toString().replace(/\D/g, "");

  if (v.length <= 11) {
    //CPF

    //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2");

    //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d)/, "$1.$2");

    //Coloca um hífen entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    //CNPJ

    //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");

    //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");

    //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");

    //Coloca um hífen depois do bloco de quatro dígitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
}

export function handleDialog(field, className) {
  let fields = Array.from(document.getElementsByClassName(`${className}`));
  fields.map(fil => {
    if (parseInt(fil.id) === field.id) {
      if (fil.classList.contains("active")) {
        fil.classList.remove("active");
      } else {
        fil.classList.add("active");
      }
    } else {
      fil.classList.remove("active");
    }
    return null;
  });
}

export async function handleBlock(id, canceled_at, field, pathApi, setBlock) {
  const options = {
    title: canceled_at !== null ? "Restaurar" : "Cancelar",
    message: `Você tem certeza que deseja ${
      canceled_at !== null ? "restaurar" : "cancelar"
    } - ${field}?`,
    buttons: [
      {
        label: "Sim",
        onClick: async () => {
          try {
            await api.delete(`${pathApi}/${id}`);
            setBlock(true);
            toast.success(
              `${field} ${
                canceled_at !== null ? "restaurado" : "cancelado"
              } com sucesso!`,
              { className: "success" }
            );
            return;
          } catch (err) {
            toast.error(
              `Este ${field} não pôde ser ${
                canceled_at !== null ? "restaurado" : "cancelado"
              }.`,
              { className: "error" }
            );
          }
        }
      },
      {
        label: "Cancelar"
      }
    ]
  };

  confirmAlert(options);
  setBlock(true);
}

export async function handleEstorno(id, canceled_at, field, pathApi, setBlock) {
  const options = {
    title: canceled_at !== null ? "Estornar" : "Baixar",
    message: `Você tem certeza que deseja ${
      canceled_at !== null ? "Estornar" : "Baixar"
    } - ${field}?`,
    buttons: [
      {
        label: "Sim",
        onClick: async () => {
          try {
            await api.delete(`${pathApi}/${id}/${0}/${canceled_at}}`);
            setBlock(true);
            toast.success(
              `${field} ${
                canceled_at !== null ? "Estornar" : "Baixar"
              } com sucesso!`,
              { className: "success" }
            );
            return;
          } catch (err) {
            toast.error(
              `Este ${field} não pôde ser ${
                canceled_at !== null ? "Estornar" : "Baixar"
              }.`,
              { className: "error" }
            );
          }
        }
      },
      {
        label: "Cancelar"
      }
    ]
  };

  confirmAlert(options);
  setBlock(true);
}

export function getAccountTypes(country = "BR") {
  return country === "BR"
    ? [
        { id: "CC", title: "Conta Corrente" },
        { id: "CP", title: "Conta Poupança" },
        { id: "CS", title: "Conta Salário" }
      ]
    : [{ id: "CC", title: "Conta Corrente" }];
}

export function getBanks(country = "BR") {
  return country === "BR"
    ? [
        { id: "318 - Banco Bmg", title: "Banco Bmg" },
        { id: "237 - Banco Bradesco / Next", title: "Banco Bradesco / Next" },
        { id: "745 - Bano Citibank", title: "Bano Citibank" },
        { id: "069 - Banco Crefisa", title: "Banco Crefisa" },
        { id: "001 - Banco do Brasil", title: "Banco do Brasil" },
        { id: "077 - Banco Inter", title: "Banco Inter" },
        { id: "399 - HSBC Bank Brasil", title: "HSBC Bank Brasil" },
        { id: "652 - Itaú Unibanco", title: "Itaú Unibanco" },
        {
          id: "389 - Banco Mercantil do Brasil",
          title: "Banco Mercantil do Brasil"
        },
        { id: "356 - Banco Real", title: "Banco Real" },
        { id: "633 - Banco Rendimento", title: "Banco Rendimento" },
        { id: "453 - Banco Rural", title: "Banco Rural" },
        { id: "422 - Banco Safra", title: "Banco Safra" },
        { id: "044 - Banco Santander", title: "Banco Santander" },
        {
          id: "756 - Banco Sicoob",
          title: "Banco Cooperativo do Brasil - Sicoob"
        },
        { id: "341 - Banco Itaú", title: "Banco Itaú" },
        { id: "348 - Banco Xp", title: "Banco Xp" },
        {
          id: "104 - Caixa Econômica Federal",
          title: "Caixa Econômica Federal"
        },
        { id: "260 - Nu Pagamentos (Nubank)", title: "Nu Pagamentos (Nubank)" },
        { id: "102 - Xp Investimentos", title: "Xp Investimentos" }
      ]
    : [
        { id: "001 - Chase Bank", title: "Chase Bank" },
        { id: "002 - Bank of America", title: "Bank of America" },
        { id: "003 - Wells Fargo", title: "Wells Fargo" },
        { id: "004 - U.S. Bank", title: "U.S. Bank" },
        { id: "005 - BBVA Compass", title: "BBVA Compass" },
        { id: "006 - Capital One Bank", title: "Capital One Bank" },
        { id: "007 - Bank of the west", title: "Bank of the west" },
        {
          id: "008 - Santander Consumer Bank",
          title: "Santander Consumer Bank"
        },
        { id: "009 - Citi Bank", title: "Citi Bank" },
        { id: "010 - Huntington Bank", title: "Huntington Bank" },
        { id: "011 - M&T Bank", title: "M&T Bank" },
        {
          id: "012 - Woodforest National Bank",
          title: "Woodforest National Bank"
        },
        { id: "013 - Citizens Bank", title: "Citizens Bank" },
        { id: "014 - Fifth Third Bank", title: "Fifth Third Bank" },
        { id: "015 - Key Bank", title: "Key Bank" },
        { id: "016 - TD Bank", title: "TD Bank" },
        { id: "017 - Sun Trust Bank", title: "Sun Trust Bank" },
        { id: "018 - Regions Bank", title: "Regions Bank" },
        { id: "019 - PNC Bank", title: "PNC Bank" },
        { id: "020 - BB&T Bank", title: "BB&T Bank" },
        { id: "021 - First National Bank", title: "First National Bank" },
        { id: "022 - BMO Harris Bank", title: "BMO Harris Bank" },
        { id: "023 - First Citizens Bank", title: "First Citizens Bank" },
        { id: "024 - Comerica Bank", title: "Comerica Bank" },
        { id: "025 - People's United Bank", title: "People's United Bank" },
        { id: "026 - Umpqua Bank", title: "Umpqua Bank" },
        { id: "027 - Bank of the Ozarks", title: "Bank of the Ozarks" },
        { id: "028 - HSBC", title: "HSBC" },
        { id: "029 - MUFG Union Bank", title: "MUFG Union Bank" },
        { id: "030 - Arvest Bank", title: "Arvest Bank" },
        { id: "031 - Chemical Bank", title: "Chemical Bank" },
        { id: "032 - TCF Bank", title: "TCF Bank" },
        { id: "033 - Synovus Bank", title: "Synovus Bank" },
        { id: "034 - Bancorp South Bank", title: "Bancorp South Bank" },
        { id: "035 - Washington Federal", title: "Washington Federal" },
        { id: "036 - Assiciated Bank", title: "Assiciated Bank" },
        { id: "037 - Iberiabank", title: "Iberiabank" },
        { id: "038 - Valley National Bank", title: "Valley National Bank" },
        { id: "039 - Whitney Bank", title: "Whitney Bank" },
        {
          id: "040 - Trust Mark National Bank",
          title: "Trust Mark National Bank"
        },
        { id: "041 - Great Western Bank", title: "Great Western Bank" },
        { id: "042 - Columbia State Bank", title: "Columbia State Bank" },
        { id: "043 - Centennial Bank", title: "Centennial Bank" },
        { id: "044 - Old National Bank", title: "Old National Bank" },
        { id: "045 - South State Bank", title: "South State Bank" },
        { id: "046 - First Tennessee Bank", title: "First Tennessee Bank" },
        { id: "047 - NBT Bank", title: "NBT Bank" },
        { id: "048 - Renasant Bank", title: "Renasant Bank" },
        { id: "049 - Banner Bank", title: "Banner Bank" },
        { id: "050 - Webster Bank", title: "Webster Bank" },
        { id: "051 - Simmons Bank", title: "Simmons Bank" },
        { id: "052 - United Bank", title: "United Bank" },
        { id: "053 - Frost Bank", title: "Frost Bank" },
        { id: "054 - WesBanco Bank", title: "WesBanco Bank" },
        { id: "055 - Commerce Bank", title: "Commerce Bank" },
        { id: "056 - Investors Bank", title: "Investors Bank" },
        { id: "057 - TrustCo Bank", title: "TrustCo Bank" },
        {
          id: "058 - First Commonwealth Bank",
          title: "First Commonwealth Bank"
        },
        { id: "059 - Sterling National Bank", title: "Sterling National Bank" },
        { id: "060 - Carter Bank And Trust", title: "Carter Bank And Trust" },
        { id: "061 - First Midwest Bank", title: "First Midwest Bank" },
        { id: "062 - First Bank", title: "First Bank" },
        { id: "063 - Park National Bank", title: "Park National Bank" },
        { id: "064 - Pinnacle Bank", title: "Pinnacle Bank" },
        { id: "065 - Glacier Bank", title: "Glacier Bank" },
        { id: "066 - Fulton Bank", title: "Fulton Bank" },
        { id: "067 - Rabobank", title: "Rabobank" },
        { id: "068 - Zions Bank", title: "Zions Bank" },
        { id: "069 - First Merchants Bank", title: "First Merchants Bank" },
        { id: "070 - East West Bank", title: "East West Bank" },
        { id: "071 - First Interstate Bank", title: "First Interstate Bank" },
        { id: "072 - Union Bank and Trust", title: "Union Bank and Trust" },
        { id: "073 - Great Southern Bank", title: "Great Southern Bank" },
        { id: "074 - Flagster Bank", title: "Flagster Bank" }
      ];
}

export async function getBankName(country, id) {
  let promises = [];
  let bankList = [];

  promises.push(
    new Promise((resolve, reject) => {
      bankList = resolve(getBanks(country, id));
    })
  );

  Promise.all(promises).then(async results => {
    return "";
  });

  return new Promise(resolve => {
    setTimeout(() => {
      bankList.map(bank => {
        return parseInt(bankList.id) === parseInt(id) ? bankList.title : null;
      });
    }, 2000);
  });
}

export function n_extenso(country, value) {
  let text = extenso(formatMoney(value.toFixed(2)), {
    mode: "currency",
    currency: { type: "BRL" }
  });

  if (country !== "BR") {
    text = text.replace("reais", "dólares");
    text = text.replace("real", "dólar");
    text = text.replace("centavo", "cent");
  }

  return text;
}

export async function getAccountsByPaymentMethod(
  forma_pagamento,
  setInfo,
  info,
  setPaymentAccounts,
  filial
) {
  let newPaymentAccounts = [{ id: -1, title: "Selecione..." }];
  setInfo({ ...info, bank: null, bank_id: -1 });
  if (
    forma_pagamento === "Dinheiro" ||
    forma_pagamento === "Depósito" ||
    forma_pagamento === "Pago por Membro" ||
    !forma_pagamento
  ) {
    newPaymentAccounts.push({ id: 0, title: "Caixa Filial" });
    setPaymentAccounts(newPaymentAccounts);
  } else if (forma_pagamento === "Boleto") {
    const responseBol = await api.get("/banks/filial/" + filial);
    const { data } = responseBol;
    newPaymentAccounts.push({ id: 0, title: "Caixa Filial" });
    let promises = [];
    data.map((d, index) => {
      return promises.push(
        new Promise((resolve, reject) => {
          resolve(newPaymentAccounts.push({ id: d.id, title: d.name }));
        })
      );
    });
    Promise.all(promises).then(async results => {
      setPaymentAccounts(newPaymentAccounts);
    });
  } else {
    const responseDef = await api.get("/banks/filial/" + filial);
    const { data } = responseDef;
    let promises = [];
    data.map((d, index) => {
      return promises.push(
        new Promise((resolve, reject) => {
          resolve(newPaymentAccounts.push({ id: d.id, title: d.name }));
        })
      );
    });
    Promise.all(promises).then(async results => {
      setPaymentAccounts(newPaymentAccounts);
    });
  }
  if (newPaymentAccounts.length > 1) {
    if (newPaymentAccounts[1].id) {
      const newBank = {
        id: newPaymentAccounts[0].id,
        name: newPaymentAccounts[0].title
      };
      // setInfo({...info, bank: newBank, bank_id: newPaymentAccounts[0].id, payment_condition: forma_pagamento})
      setInfo({
        ...info,
        payment_condition: forma_pagamento,
        payment_method: forma_pagamento
      });
    } else {
      // setInfo({...info, bank: null, bank_id: null, payment_condition: forma_pagamento })
      setInfo({
        ...info,
        payment_condition: forma_pagamento,
        payment_method: forma_pagamento
      });
    }
  }
}

export function getPaymentMethods() {
  return [
    { id: "Dinheiro", title: "Dinheiro" },
    { id: "Cheque", title: "Cheque" },
    { id: "Transferência", title: "Transferência" },
    { id: "Boleto", title: "Boleto" },
    { id: "Depósito", title: "Depósito" },
    { id: "Cartão de crédito", title: "Cartão de crédito" },
    { id: "Cartão de débito", title: "Cartão de débito" },
    { id: "Débito automático", title: "Débito automático" },
    { id: "Pago por Membro", title: "Pago por Membro" }
  ];
}

export async function loadCountries(setCountries, notDefined) {
  try {
    const response = await api.get("countries/actives/");
    const { data } = response;
    let arrayCountries = [];
    data.map(p => {
      arrayCountries.push({ id: p.id, title: p.name, code: p.code });
      return null;
    });
    if (notDefined) {
      arrayCountries.push({ id: 9, title: "Não Definido", code: "XX" });
    }
    setCountries(arrayCountries);
  } catch (err) {
    setCountries([]);
  }
}

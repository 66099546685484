export function updateFilial(
  filial,
  filial_name,
  open_date,
  country,
  initialBalance
) {
  return {
    type: "@filial/UPDATE",
    payload: { filial, filial_name, open_date, country, initialBalance }
  };
}

export function destroyFilial() {
  return {
    type: "@filial/DESTROY",
    payload: {}
  };
}

export function updateSelectedFilialsRequest(filials) {
  return {
    type: '@selectedFilials/UPDATE_SELECTED_FILIALS_REQUEST',
    payload: { filials },
  };
}

export function updateSelectedFilialsSuccess(filials) {
  return {
    type: '@selectedFilials/UPDATE_SELECTED_FILIALS_SUCCESS',
    payload: { filials },
  };
}
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { updateFilial } from "~/store/modules/filial/actions";

import { saveAs } from 'file-saver';

import "react-confirm-alert/src/react-confirm-alert.css";

import {
  format,
  parseISO,
  subMonths,
  addMonths,
  addDays,
  startOfMonth,
  endOfMonth
} from "date-fns";

import pt from "date-fns/locale/pt";

import DatePicker from "react-datepicker";

import api from "~/services/api";

import "react-datepicker/dist/react-datepicker.css";

import {
  GradeTotals,
  New,
  FiltroMes
} from "./styles";
import { formatMoney, formatedDate, verifyAccess } from "~/scripts/index.js";

import {
  FaEquals,
  FaMinus,
  FaPlus,
  FaHistory,
  FaLock,
  FaLockOpen,
  FaCalendarAlt,
  FaPrint
} from "react-icons/fa";


export default function HomeboxBalance() {
  const dispatch = useDispatch();
  const accesses = useSelector(state => state.auth.newaccesses);
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [openDate, setOpenDate] = useState(false);
  const [filtro, setFiltro] = useState({
    date: format(new Date(), "yyyy-MM")
  });
  const [block, setBlock] = useState(false);
  const [active,setActive] = useState({});
  const [balance, setBalance] = useState({});
  const [caixaAberto,setCaixaAberto] = useState(false);
  const [caixaAtivo,setCaixaAtivo] = useState(false);
  const [before, setBefore] = useState(
    format(subMonths(new Date(), 1), "yyyy-MM")
  );
  const [waiting,setWaiting] = useState(null);
  const [after, setAfter] = useState(format(new Date(), "yyyy-MM"));
  const [anualClosing,setAnualClosing] = useState(false);
  const [totals, setTotals] = useState({
    inicial: 0,
    debitos: 0,
    creditos: 0,
    transferencias: 0,
    final: 0
  });

  const { date } = filtro;

  async function getAnualClosing() {
    const response = await api.get(`/filial_closings/filial/yearly/${filial.filial}/${filtro.date.substring(0,4)}`)
    const { data } = response;
    setAnualClosing(data.length === 12 ? true : false);
  }

  useEffect(() => {
    async function changeDate(newDate) {
      const period = format(
        subMonths(
          parseISO(newDate+"-01"),
          1
        ),
        "yyyy-MM"
      );
      setAfter(newDate);
      setBefore(period);
    }
    async function getBalance() {
      const response = await api.get(`/balance/${filial.filial}/${date}`)
      const { data } = response;
      setActive(data);
    }
    changeDate(date);
    getBalance();
    getAnualClosing()
  }, [filtro.date, date, filial.filial, block]);

  function handleData(date) {
    const split = format(date, "yyyy-MM").split("-");
    setFiltro({
      date: format(
        subMonths(
          new Date(parseInt(split[0]), parseInt(split[1]), 1, 15, 0, 0),
          1
        ),
        "yyyy-MM"
      )
    });
    setOpenDate(!openDate);
  }

  useEffect(() => {
    async function loadBalance() {
      const response = await api.get(`/filial_balance/all?filial_id=${filial.filial}&startDate=${format(startOfMonth(parseISO(after)), "yyyy-MM-dd")}&endDate=${format(endOfMonth(parseISO(after)), "yyyy-MM-dd")}`)
      const responseBef = await api.get(`/balance/${filial.filial}/${before}`);
      const responseThis = await api.get(`/balance/${filial.filial}/${after}`);
      const responseAft = await api.get(`/balance/${filial.filial}/${format(addMonths(parseISO(after+"-01"),1),"yyyy-MM")}`);

      if(!responseThis.data.approved_at) {
        setCaixaAberto(true)
      } else {
        setCaixaAberto(false)
      }
      if(responseBef.data.approved_at && !responseAft.data.approved_at) {
        setCaixaAtivo(true)
      } else {
        setCaixaAtivo(false)
      }


      setBalance(response.data)


      let creditos = 0;
      let debitos = 0;
      let transferencias = 0;

      response.data.forEach(res => {
        // console.log(res);
        if (res.origin === "Transferência") {
          // console.log('Débito',res.bankTo)
          if (res.bankTo) {
            debitos += parseFloat(res.valor);
            transferencias += parseFloat(res.valor);
          } else {
            // console.log('Crédito',res.bankTo)
            creditos += parseFloat(res.valor);
          }
        } else {
          if (res.transaction === "Débito") {
            debitos += parseFloat(res.valor);
          } else {
            creditos += parseFloat(res.valor);
          }
        }
      })

      setTotals({
        id: responseBef.data.id,
        inicial: responseBef.data.balance,
        debitos,
        creditos,
        transferencias,
        final: responseBef.data.balance + creditos - debitos
      });
    }
    if (before && after) {
      loadBalance(before, after);
    }
    setBlock(false);
  }, [before, after, block, filial.filial]);

  async function MakeReporting() {
    setWaiting('PDF Report')
    const id = active.id;
    const response = await api.post(`/pdf/filial_balance/${id}`, { balance, totals });
    const response2 = await api.get(`/pdf/filial_balance/${id}`, { responseType: 'blob'});
    // console.log('pdf',response2.data)
    const pdfBlob = new Blob([response2.data], { type: 'application/pdf' });
    saveAs(pdfBlob, response.data.name);
    await api.delete(`/pdf/filial_balance/${id}`);
    setWaiting(null)
  }

  async function handleBalance(newBalance) {
    if (totals.final < 0) {
      return toast.error(
        "Atenção!, O saldo final do período deve ser positivo.",
        {
          className: "error"
        }
      );
    }

    try {
      await api.put(
        "/balance/" + filial.filial + "/" + active.period_start,
        {
          approved_at: new Date(),
          approved_by: profile.id,
          balance: newBalance
        }
      );
      toast.success(`Caixa alterado com sucesso!`, { className: "success" });
      const responseBalance = await api.get("/balance/closed/" + filial.filial);
      const { data } = responseBalance;
      dispatch(
        updateFilial({
          filial: filial.filial,
          filial_name: "",
          open_date: format(
            addDays(parseISO(data.period_end), 1),
            "yyyy-MM-dd"
          ),
          initialBalance: filial.initialBalance,
          country: filial.country
        })
      );
      setBlock(true);
    } catch (err) {
      toast.error("Impossível alterar este caixa.", { className: "error" });
    }
  }

  async function GeraExcel(year) {
    setWaiting('Monthly Financial Report')
    const response = await api.post(`excel/${filial.filial}/${year}`);
    const response2 = await api.get(`excel/${response.data.name}`, { responseType: 'blob' });
    const xlsxBlob = new Blob([response2.data], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(xlsxBlob, `Monthly Financial Report ${year}.xlsx`);
    setWaiting(null)
  }

  async function handleAnualClosing(close,year) {
    if(close) {
      try {
      await api.post(`filial_closings/closing/${filial.filial}/${year}/${profile.id}`);
      setBlock(true);
      } catch(err) {
        return toast.error(
          "Impossível encerrar o período. Verifique se há caixas e períodos bancários em aberto.",
          {
            className: "error"
          }
        );
      }
    } else {
      try {
      await api.post(`filial_closings/reopening/${filial.filial}/${year}/${profile.id}`);
      setBlock(true);
      } catch(err) {
        return toast.error(
          "Impossível reabrir o período. Verifique se o ano posterior foi estornado.",
          {
            className: "error"
          }
        );
      }
    }
  }

  return (
    <>
      <div className="gradeFiltros">
        <p>Filtros:</p>
        <FiltroMes>
          <FaCalendarAlt size={12} />
          <DatePicker
            name="date"
            showMonthYearPicker
            selected={new Date(filtro.date + "-02")}
            dateFormat="MMMM/yyyy"
            onChange={date => handleData(date)}
            maxDate={new Date()}
            minDate={addDays(new Date(filial.initialBalance.period_end), 1)}
            locale={pt}
          />
        </FiltroMes>
        { verifyAccess(accesses,'relatorio_mensal') ?
        <New
          type="button"
          onClick={() =>
            waiting === 'PDF Report' ? null : MakeReporting()
          }
        >
          <FaPrint size={14} style={{ marginRight: "15px" }} /> {waiting === 'PDF Report' ? 'Aguarde...' : 'Imprimir Período' }
        </New> : null }
        { verifyAccess(accesses,'relatorio_financeiro') ? <New
          type="button"
          onClick={() =>
            waiting === 'Monthly Financial Report' ? null : GeraExcel(filtro.date.substring(0,4))
          }
        >
          <FaPrint size={14} style={{ marginRight: "15px" }} /> {waiting === 'Monthly Financial Report' ? 'Aguarde...' : `Monthly Financial Report ${filtro.date.substring(0,4)}` }
        </New> : null }
        {verifyAccess(accesses,'fechamento_mensal') && caixaAtivo && caixaAberto ? (
              <>
              <New
                type="button"
                onClick={() =>
                  handleBalance(totals.final)
                }
              >
                <FaLockOpen size={14} style={{ marginRight: "15px" }} /> Fechar
                Caixa
              </New>
                {/* <New onClick={() => MakeReporting()}>Gerar Relatorio</New> */}
              </>
            ) : null }
            { verifyAccess(accesses,'fechamento_mensal') && caixaAtivo && !caixaAberto && !anualClosing ? (
              <New type="button" onClick={() => handleBalance(0)}>
                <FaLock size={14} style={{ marginRight: "15px" }} /> Reabrir
                Caixa
              </New>
            ) : null}
            {verifyAccess(accesses,'fechamento_anual') && filtro.date.substring(5,7) === '12' && !anualClosing && !caixaAberto ?
            <New
              type="button"
              onClick={() =>
                handleAnualClosing(true,filtro.date.substring(0,4))
              }
            >
              <FaLockOpen size={14} style={{ marginRight: "15px" }} /> Encerrar Ano {filtro.date.substring(0,4)}
            </New>
            : null }
            {verifyAccess(accesses,'fechamento_anual') && filtro.date.substring(5,7) === '12' && anualClosing && !caixaAberto ?
            <New
              type="button"
              style={{background: '#ddd', color: 'rgb(155,213,49)', fontWeight: 'normal'}}
              onClick={() =>
                handleAnualClosing(false,filtro.date.substring(0,4))
              }
            >
              <FaLock size={14} style={{ marginRight: "15px" }} /> Reabrir Ano {filtro.date.substring(0,4)}
            </New>
            : null }
      </div>
      <GradeTotals>
        <div>
          <span>Saldo Inicial</span>
          <FaHistory size={20} color="#555" /> {filial.country.currency}{" "}
          {totals.inicial
            ? formatMoney(totals.inicial.toFixed(2), filial.country.language)
            : formatMoney(0, filial.country.language)}
        </div>
        <div className="sum">
          <span>Créditos</span>
          <FaPlus size={20} color="#555" /> {filial.country.currency}{" "}
          {formatMoney(totals.creditos.toFixed(2), filial.country.language)}
        </div>
        <div className="sub">
          <span>Débitos</span>
          <FaMinus size={20} color="#555" /> {filial.country.currency}{" "}
          {formatMoney((totals.debitos - totals.transferencias).toFixed(2), filial.country.language)}
        </div>
        <div className="">
          <span>Saídas em Transf.</span>
          {/* <FaMinus size={20} color="#555" />  */}
          {filial.country.currency}{" "}
          {formatMoney(
            totals.transferencias.toFixed(2),
            filial.country.language
          )}
        </div>
        <div>
          <span>Saldo Final</span>
          <FaEquals size={20} color="#555" /> {filial.country.currency}{" "}
          {totals.final
            ? formatMoney(totals.final.toFixed(2), filial.country.language)
            : formatMoney(0, filial.country.language)}
        </div>
      </GradeTotals>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>Lançamento</td>
            <td>Descrição</td>
            <td>Valor</td>
            <td>Parcela</td>
            <td>Data de Baixa</td>
            <td>Débito/Crédito</td>
            <td>Origem</td>
            <td>Fornecedor</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {balance
            ? balance.length > 0
                ? balance.map((d, index) => (
                    <tr key={index}>
                      {/* {console.log(d)} */}
                      <td>{d.invoice ? d.invoice.lancamento : d.lancamento}</td>
                      <td>{d.descricao ? d.descricao : d.invoice && d.invoice.descricao ? d.invoice.descricao : d.fixedCost && d.fixedCost.descricao ? d.fixedCost.descricao : d.origin}</td>
                      <td>
                        {filial.country.currency +" "+formatMoney(
                          d.valor.toFixed(2),
                          filial.country.language
                        )}
                      </td>
                      <td>{d.fixedCost ? d.parcel+"/"+d.fixedCost.parcels : d.invoice ? d.parcel+"/"+d.invoice.parcels : null}</td>
                      <td>{formatedDate(d.date)}</td>
                      <td>
                        { d.origin === 'Transferência' && d.bankTo ? <div className="balance_debit">Débito</div> : d.origin === 'Transferência' && !d.bankTo ? <div className="balance_credit">Crédito</div> : d.transaction === 'Débito' ? <div className="balance_debit">{d.transaction}</div> : <div className="balance_credit">{d.transaction}</div> }
                      </td>
                      <td>{d.origin}</td>
                      <td>{d.provider ? d.provider.fantasy_name : d.invoice ? d.invoice.provider.fantasy_name : d.fixedCost ? d.fixedCost.provider.fantasy_name : null }</td>
                    </tr>
                  ))
                : null
            : null
          }
          {/* {balance
            ? balance.dizimos
              ? balance.dizimos.length > 0
                ? balance.dizimos.map((d, index) => (
                    <tr key={index}>
                      <td>{d.id}</td>
                      <td>Lançamento de Dízimos e Ofertas</td>
                      <td>
                        {formatMoney(
                          d.money.toFixed(2),
                          filial.country.language
                        )}
                      </td>
                      <td>{formatedDate(d.date)}</td>
                      <td>
                        <div className="balance_credit">CRÉDITO</div>
                      </td>
                      <td>Dízimos e Ofertas</td>
                      <td>-</td>
                    </tr>
                  ))
                : null
              : null
            : null}
          {balance
            ? balance.orders
              ? balance.orders.length > 0
                ? balance.orders.map((o, index) => (
                    <tr key={index}>
                      <td>{o.cupom_fiscal + "/" + o.coo ? o.coo : ''}</td>
                      <td>{o.reason}</td>
                      <td>
                        {formatMoney(
                          o.total.toFixed(2),
                          filial.country.language
                        )}
                      </td>
                      <td>{formatedDate(o.approved_at)}</td>
                      <td>
                        <div className="balance_debit">DÉBITO</div>
                      </td>
                      <td>Ordem de Pagamento</td>
                      <td>{o.provider.fantasy_name}</td>
                    </tr>
                  ))
                : null
              : null
            : null}
          {balance
            ? balance.invoices
              ? balance.invoices.length > 0
                ? balance.invoices.map((i, index) => (
                    <tr key={index}>
                      <td>{i.invoice.nota_fiscal + "/" + i.invoice.serie}</td>
                      <td>
                        {i.invoice.reason}{" "}
                        {i.invoice.parcels > 0
                          ? " | Parcela " + i.parcel + " / " + i.invoice.parcels
                          : null}
                      </td>
                      <td>
                        {formatMoney(
                          i.total.toFixed(2),
                          filial.country.language
                        )}
                      </td>
                      <td>{formatedDate(i.due_date)}</td>
                      <td>
                        <div className="balance_debit">DÉBITO</div>
                      </td>
                      <td>{i.invoice.type}</td>
                      <td>{i.invoice.provider.fantasy_name}</td>
                    </tr>
                  ))
                : null
              : null
            : null}
          {balance
            ? balance.fixedCost
              ? balance.fixedCost.length > 0
                ? balance.fixedCost.map((i, index) => (
                    <tr key={index}>
                      <td>{i.fixedCost.id}</td>
                      <td>
                        {i.fixedCost.description}{" "}
                        {i.fixedCost.validity_quantity > 0
                          ? " | Parcela " +
                            i.parcel +
                            " / " +
                            i.fixedCost.validity_quantity
                          : null}
                      </td>
                      <td>
                        {formatMoney(
                          i.value.toFixed(2),
                          filial.country.language
                        )}
                      </td>
                      <td>{formatedDate(i.due_date)}</td>
                      <td>
                        <div className="balance_debit">DÉBITO</div>
                      </td>
                      <td>{i.fixedCost.origin}</td>
                      <td>{i.fixedCost.provider.fantasy_name}</td>
                    </tr>
                  ))
                : null
              : null
            : null}
          {balance.transfers
            ? balance.transfers.length > 0
              ? balance.transfers.map((t, index) => (
                  <tr key={index}>
                    <td>{t.id}</td>
                    <td>{`${
                      t.bankFrom
                        ? t.bankFrom.bank_name + `: `
                        : t.bankTo.bank_name + `: `
                    } ${t.observation}`}</td>
                    <td>
                      {formatMoney(t.value.toFixed(2), filial.country.language)}
                    </td>
                    <td>{formatedDate(t.date)}</td>
                    <td>
                      {t.transaction === "Débito" ? (
                        <div className="balance_debit">DÉBITO</div>
                      ) : (
                        <div className="balance_credit">CRÉDITO</div>
                      )}
                    </td>
                    <td>Transferência</td>
                    <td>-</td>
                  </tr>
                ))
              : null
            : null} */}
        </tbody>
      </table>
    </>
  );
}

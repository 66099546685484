import React, { useState, useEffect } from "react";
import './style.css'

import { useSelector } from "react-redux";

import allowAccess from "~/scripts/menuController";

import { format, parseISO } from "date-fns";

import { handleDialog, handleBlock, formatedDate, verifyAccess } from "~/scripts/index.js";

import NavigationButtons from "~/components/NavigationButtons";

import { toast } from "react-toastify";

import BgBlack from "~/components/BgBlack";

import "react-confirm-alert/src/react-confirm-alert.css";

import api from "~/services/api";

import { FaEye, FaEdit, FaCheck, FaTimes, FaRedo, FaSlidersH } from "react-icons/fa";

import {
  New
} from "./styles";

import CreateFIxedCost from "./create_FixedCost";

import FiltroData from "~/components/FiltroData";

import FiltroLancamento from "~/components/FiltroLancamento";

import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

import FixedCostParcels from "./FixedCostParcels";
import MainFilter, { MainFilterButton } from "~/components/MainFilter";
import FilterComponent from "~/components/FilterComponent";
import DateFilter from "~/components/DateFilter";
import TextInputFilter from "~/components/TextInputFilter";
import GreenSearchButton from "~/components/GreenSearchButton";
import BuscaFornecedor from "~/components/BuscarFornecedor";
import BuscarFiliais from "~/components/BuscarFiliais";


function Fixed_coast() {
  const filial = useSelector(state => state.filial);
  const profile = useSelector(state => state.user.profile);
  const accesses = useSelector(state => state.auth.newaccesses);
  const [block, setBlock] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [fixedCosts, setFixedCosts] = useState([]);
  const [lancamento, setLancamento] = useState("");
  const [id, setId] = useState(null);
  const [openCreateFixedCost, setOpenCreateFixedCost] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openFixedCostParcels, setOpenFixedCostParcels] = useState(false);
  const [fixedCost, setFixedCost] = useState(null);
  const [openDate, setOpenDate] = useState(null);
  const [filtro, setFiltro] = useState({
    date: format(new Date(), "yyyy-MM-dd 23:59:59")
  });
  const { date } = filtro;
  const [showCoA, setShowCoA] = useState(false);
  const [reclassificar, setReclassificar] = useState(null);

  const [showFilter, setShowFilter] = useState(false)
  const [showFilterCoa, setShowFilterCoa] = useState(false)
  const [showFilterProvider, setShowFilterProvider] = useState(false)
  const [showFilterFilials, setShowFilterFilials] = useState(false)
  const [filtered, setFiltered] = useState(false)
  const [filterSettings, setFilterSettings] = useState({
    status_open: true,
    status_approved: true,
    from: false,
    to: false,
    coa: {
      id: 0,
      nome: '',
      tax_nature: ''
    },
    description: '',
    provider: {
      cgc: '',
      company_name: '',
      id: 0
    },
    filial: {
      id: filial.filial,
      name: ''
    }
  })


  async function handleChartOfAccount(coa) {
    try {
      await api.put(`/reclassificar/fixed_cost/${reclassificar}/${coa.id}`)
      toast.success("Reclassificado com sucesso!", {
        className: "success"
      });
      setShowCoA(false);
    } catch (err) {
      toast.error("Impossível reclassificar.", {
        className: "error"
      });
    }
  }

  function handleReclass(movement) {
    setShowCoA(true);
    setReclassificar(movement);
  }

  useEffect(() => {

    async function loadFixedCost() {

      if (filtered) {
        const { data } = await api.get(
          `/searchfixedcosts`, {
          params: {
            page: page,
            ...filtered
          }
        });

        setFixedCosts(data.fixedCosts);
        setPages(data.pages);

      } else {
        const { data } = await api.get(
          `/searchfixedcost?page=${page}&data=${lancamento}&date=${date}&filial_id=${filial.filial}`
        );
        setFixedCosts(data.fixedCosts);
        setPages(data.pages);
        setBlock(false);
      }

    }
    loadFixedCost();
  }, [date, lancamento, block, filial.filial, page]);

  function handleLancamento(value) {
    lancamento(value);
  }

  function handleOpenDate() {
    setOpenDate(!openDate);
  }

  function handleData({ date }) {
    setFiltro({
      date: format(date, "yyyy-MM-dd 23:59:59")
    });
    setOpenDate(!openDate);
  }

  function handleCreateFixedCost(id, status, editStatus) {
    setId(id);
    setOpenCreateFixedCost(status);
    setEdit(editStatus);
  }

  function handlerOpenFixedCostParcels(itemfixedCost) {
    setFixedCost(itemfixedCost);
    setOpenFixedCostParcels(!openFixedCostParcels);
  }

  async function approveFixedCost(id) {
    try {
      const response = await api.put("fixedcost/approve/" + id);
      if (response.data.approved_at) {
        toast.success("Custo fixo aprovado com sucesso!", {
          className: "success"
        });
      } else {
        toast.success("Custo fixo estornado com sucesso!", {
          className: "success"
        });
      }
      setBlock(true);
      return;
    } catch (err) {
      toast.error("Há parcelas baixadas neste custo fixo.", {
        className: "error"
      });
      return;
    }
  }

  function getChartOfAccount(coa) {
    setFilterSettings({ ...filterSettings, coa: { ...coa } })
    setShowFilterCoa(false)
  }

  function resetChartOfAccount() {
    setFilterSettings({ ...filterSettings, coa: { id: 0, nome: '', tax_nature: '' } })
  }

  function getProvider(provider) {
    setFilterSettings({ ...filterSettings, provider: { ...provider } })
    setShowFilterProvider(false)
  }

  function resetProvider() {
    setFilterSettings({ ...filterSettings, provider: { id: 0, company_name: '', cgc: '' } })
  }

  function getFilials(filial) {
    setFilterSettings({ ...filterSettings, filial: { ...filial } })
    setShowFilterFilials(false)
  }

  function resetFilials() {
    setFilterSettings({ ...filterSettings, filial: { id: filial.filial, name: '' } })
  }


  async function applyFilter() {
    try {

      if (filterSettings.from && filterSettings.to && filterSettings.from > filterSettings.to) {
        return toast.error("A data de emissão inicial não pode ser maior do que a final.", {
          className: "error"
        });
      }

      let from = filterSettings.from ? new Date(filterSettings.from) : new Date(1900, 0, 1)
      from = from.toISOString().substring(0, 10)

      let to = filterSettings.to ? new Date(filterSettings.to) : new Date(3000, 0, 1)
      to = to.toISOString().substring(0, 10)

      const params = {
        is_open: filterSettings.status_open,
        is_approved: filterSettings.status_approved,
        date: from,
        final_date: to,
        provider: filterSettings.provider.id,
        data: filterSettings.description,
        chart_of_accounts: filterSettings.coa.id,
        filial_id: filterSettings.filial.id
      }

      const { data } = await api.get(
        `/searchfixedcosts`, {
        params: {
          page: filtered ? page : 1,
          ...params
        }
      });

      setPage(1)

      setFiltered({
        ...params
      })

      setShowFilter(false)
      setFixedCosts(data.fixedCosts);
      setPages(data.pages);
      setBlock(false);

    } catch (err) {
      console.log(err)
    }
  }

  async function resetFilters() {
    setFilterSettings({
      status_open: true,
      status_approved: true,
      from: false,
      to: false,
      coa: {
        id: 0,
        nome: '',
        tax_nature: ''
      },
      description: '',
      provider: {
        cgc: '',
        company_name: '',
        id: 0
      },
      filial: {
        id: filial.filial,
        name: ''
      }
    })

    const { data } = await api.get(
      `/searchfixedcosts`, {
      params: {
        page,
        is_open: true,
        is_approved: true,
        date: new Date(2000, 0, 1).toISOString(),
        final_date: new Date().toISOString(),
        provider: 0,
        data: '',
        chart_of_accounts: 0,
        filial_id: filial.filial
      }
    });

    setFiltered(false)
    setPage(1)

    setFixedCosts(data.fixedCosts);
    setPages(data.pages);
    setBlock(false);

  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses, 'lancamento_custos_fixos') ?
          <New
            type="button"
            onClick={() => { handleCreateFixedCost(null, true, true); setShowFilter(false) }}
          >
            Novo +
          </New>
          : null}
        <MainFilterButton click={() => { setShowFilter(!showFilter) }} />
        {/* <FiltroData
          openDate={openDate}
          handleOpenDate={handleOpenDate}
          selected={parseISO(date)}
          handleData={handleData}
        />
        <FiltroLancamento
          handleLancamento={handleLancamento}
          setFind={setLancamento}
          placeholder="Custos fixo"
        /> */}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          {
            showFilter && !showCoA ?
              <MainFilter applyFilter={applyFilter} resetFilter={resetFilters}>
                <FilterComponent title="Status" firstComponent={true}>
                  <div className="filter-status-checkbox-area">
                    <div className="filter-status-checkbox">
                      <div
                        className={filterSettings.status_open ? 'filter-status-checkbox-on' : 'filter-status-checkbox-off'}
                        onClick={() => { if (filterSettings.status_open !== true || filterSettings.status_approved !== false) setFilterSettings({ ...filterSettings, status_open: !filterSettings.status_open }) }}
                      />
                      <span>Não aprovado</span>
                    </div>
                    <div className="filter-status-checkbox">
                      <div
                        className={filterSettings.status_approved ? 'filter-status-checkbox-on' : 'filter-status-checkbox-off'}
                        onClick={() => { if (filterSettings.status_approved !== true || filterSettings.status_open !== false) setFilterSettings({ ...filterSettings, status_approved: !filterSettings.status_approved }) }}
                      />
                      <span>Aprovado</span>
                    </div>
                  </div>
                </FilterComponent>

                <FilterComponent title="Data de Emissão">
                  <DateFilter to={filterSettings.to} setTo={dt => setFilterSettings({ ...filterSettings, to: dt })} from={filterSettings.from} setFrom={dt => setFilterSettings({ ...filterSettings, from: dt })} />
                </FilterComponent>

                <FilterComponent showFieldButton={true} title="Fornecedor">
                  <GreenSearchButton
                    input={filterSettings.provider.company_name}
                    searchFunction={() => { setShowFilterProvider(true) }}
                    resetFunction={resetProvider}
                  />
                  <BuscaFornecedor
                    show={showFilterProvider}
                    setShowSearch={setShowFilterProvider}
                    handleProvider={getProvider}
                  />
                </FilterComponent>
                <FilterComponent title="Descrição">
                  <TextInputFilter
                    input={filterSettings.description}
                    setInput={(txt) => { setFilterSettings({ ...filterSettings, description: txt }) }}
                  />
                </FilterComponent>
                <FilterComponent
                  title="Plano de Contas"
                >
                  <GreenSearchButton
                    input={filterSettings.coa.nome}
                    searchFunction={() => { setShowFilterCoa(true) }}
                    resetFunction={resetChartOfAccount}
                  />
                  <BuscarPlanoDeContas
                    show={showFilterCoa}
                    setShowCoA={setShowFilterCoa}
                    handleChartOfAccount={getChartOfAccount}
                  />
                </FilterComponent>
                <FilterComponent showFieldButton={true} title="Filial">
                  <GreenSearchButton
                    input={filterSettings.filial.name}
                    searchFunction={() => { setShowFilterFilials(true) }}
                    resetFunction={resetFilials}
                  />
                  <BuscarFiliais
                    showFilials={showFilterFilials}
                    setShowFilials={setShowFilterFilials}
                    handleSearchFilials={getFilials}
                  />
                </FilterComponent>
              </MainFilter>
              : null
          }
          <tr>
            <td>Lançamento</td>
            <td>Descrição</td>
            <td>Fornecedor</td>
            <td>Emissão</td>
            <td>Cond. Pagamento</td>
            <td>Data Inicial</td>
            <td>Data Final</td>
            <td>Parcelas</td>
            {/* <td>P/ Filial</td> */}
          </tr>
        </thead>
        <tbody className="gradeBody">
          {fixedCosts && fixedCosts.length > 0 ? (
            fixedCosts.map(fixedCost => (
              <tr
                key={fixedCost.id}
                onClick={() => handleDialog(fixedCost, "fixed_cost")}
                className={
                  fixedCost.approved_at ? "fixed_cost approved" : "fixed_cost"
                }
                id={fixedCost.id}
              >
                <td>
                  {fixedCost.id}
                  <ul className="dialog">
                    {verifyAccess(accesses, 'aprovacao_custos_fixos')
                      && fixedCost.filial_id === filial.filial ?
                      <li onClick={() => approveFixedCost(fixedCost.id)}>
                        {!fixedCost.approved_at ? (
                          <>
                            <FaCheck size={12} /> Aprovar
                          </>
                        ) : (
                          <>
                            <FaTimes size={12} /> Estornar Aprovação
                          </>
                        )}
                      </li>
                      : null}
                    {verifyAccess(accesses, 'baixar_custos_fixos')
                      && fixedCost.filial_id === filial.filial
                      && fixedCost.approved_at ? (
                      <li
                        onClick={() => handlerOpenFixedCostParcels(fixedCost)}
                      >
                        <FaEdit size={12} /> Baixar Parcelas
                      </li>
                    ) : null}
                    <li
                      onClick={() =>
                        handleCreateFixedCost(fixedCost.id, true, false)
                      }
                    >
                      <FaEye size={12} /> Visualizar
                    </li>
                    {verifyAccess(accesses, 'lancamento_custos_fixos')
                      && fixedCost.filial_id === filial.filial
                      && !fixedCost.approved_at ?
                      <li
                        onClick={() =>
                          handleCreateFixedCost(fixedCost.id, true, true)
                        }
                      >
                        <FaEdit size={12} /> Alterar
                      </li>
                      : null}
                    {verifyAccess(accesses, 'exclusao_custos_fixos')
                      && fixedCost.filial_id === filial.filial
                      && !fixedCost.approved_at ?
                      <li
                        className="trash"
                        onClick={() =>
                          handleBlock(
                            fixedCost.id,
                            fixedCost.canceled_at,
                            `Custo fixo`,
                            "fixedcost",
                            setBlock
                          )
                        }
                      >
                        <FaTimes size={12} /> Excluir
                      </li>
                      : null}
                    {verifyAccess(accesses, 'reclassificar_custos_fixos') ?
                      <li
                        className="reclassificar"
                        onClick={() =>
                          handleReclass(
                            fixedCost.id
                          )
                        }
                      >
                        <FaRedo size={12} /> Reclassificar
                      </li>
                      : null}
                  </ul>
                </td>
                <td title={fixedCost.description}>
                  <span
                    style={{
                      color: "#333",
                      fontStyle: "inherit",
                      fontSize: "14px",
                      display: "block",
                      margin: "0px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: "156px"
                    }}
                  >
                    {fixedCost.description}
                  </span>
                </td>
                <td>{fixedCost.provider.company_name}</td>
                <td>{formatedDate(fixedCost.emission)}</td>
                <td>{fixedCost.payment_condition}</td>
                <td>{formatedDate(fixedCost.validity_start_date)}</td>
                <td>{formatedDate(fixedCost.validity_end_date)}</td>
                <td>{fixedCost.validity_quantity}</td>
                {/* <td>{fixedCost.to_filial ? fixedCost.to_filial.name : null}</td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9}>Não há lançamentos.</td>
            </tr>
          )}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {openCreateFixedCost && (
        <>
          <BgBlack />
          <CreateFIxedCost
            edit={edit}
            id={id}
            handleCreateFixedCost={setOpenCreateFixedCost}
            setBlock={setBlock}
          />
        </>
      )}

      {openFixedCostParcels ? (
        <>
          <BgBlack />
          <FixedCostParcels
            handlerOpenFixedCostParcels={handlerOpenFixedCostParcels}
            fixedCost={fixedCost}
            setBlock={setBlock}
            block={block}
          />
        </>
      ) : null}
      {showCoA ? (
        <>
          <BgBlack />
          <BuscarPlanoDeContas
            show={showCoA}
            setShowCoA={setShowCoA}
            handleChartOfAccount={handleChartOfAccount}
          />
        </>
      ) : null}
    </>
  );
}

export default Fixed_coast;

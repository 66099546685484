import React, { useState, useEffect } from "react";
import api from "~/services/api";
import { useSelector } from "react-redux";

import { New } from "./styles";

import { FaEye, FaEdit, FaTimes } from "react-icons/fa";
import { handleBlock, handleDialog, verifyAccess } from "~/scripts/index.js";

import NavigationButtons from "~/components/NavigationButtons";
import CreateUserGroups from "./CreateUserGroups";
import EditAccesses from "./EditAccesses";

import BgBlack from "~/components/BgBlack";

export default function UserGroups() {
  const profile = useSelector(state => state.user.profile);
  const [userGroups, setUserGroups] = useState([]);
  const [createUserGroups, setCreateUserGroups] = useState(false);
  const [opennedID, setOpennedID] = useState(null);
  const [edit, setEdit] = useState(false);
  const [block, setBlock] = useState(false);
  const [lancamento] = useState(null);
  const [page, setPage] = useState(1);
  const [pages] = useState(1);
  const [editAccesses, setEditAccesses] = useState(null);
  const accesses = useSelector(state => state.auth.newaccesses);

  useEffect(() => {
    async function loadUserGroups() {
      try {
        const response = await api.get("user_group/");
        setUserGroups(response.data);
        setBlock(false);
      } catch (err) {}
    }
    setTimeout(() => {
      loadUserGroups();
    }, 250);
  }, [editAccesses, block]);

  async function handleCreateUserGroups() {
    setEdit(!createUserGroups);
    setCreateUserGroups(!createUserGroups);
  }
  async function handleOpenUserGroups(id, edit) {
    setOpennedID(id);
    setEdit(edit);
    setCreateUserGroups(!createUserGroups);
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses, "cadastro_grupo_usuarios") ? (
          <New
            type="button"
            onClick={() => setEditAccesses(userGroups.length + 1)}
          >
            Novo +
          </New>
        ) : null}
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td style={{ width: "80px" }}>Código</td>
            <td>Nome</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {userGroups.map((usr, index) => {
            return profile.group_id === 1 ||
              (profile.group_id !== 1 && usr.id > 1) ? (
              <tr
                key={usr.id}
                id={usr.id}
                className={`userGroups ${usr.canceled_at ? "canceled" : null}`}
                onClick={() => handleDialog(usr, "userGroups")}
              >
                <td>
                  {usr.id}
                  {verifyAccess(accesses, "cadastro_grupo_usuarios") ? (
                    <ul className="dialog">
                      <li onClick={() => setEditAccesses(usr.id)}>
                        <FaEdit size={12} /> Acessos
                      </li>
                    </ul>
                  ) : null}
                </td>
                <td>{usr.nome}</td>
              </tr>
            ) : null;
          })}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {editAccesses ? (
        <>
          <BgBlack onClick={() => setEditAccesses(null)} />
          <EditAccesses
            groupId={editAccesses}
            setEditAccesses={setEditAccesses}
            setBlock={setBlock}
          />
        </>
      ) : null}
    </>
  );
}

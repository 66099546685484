import styled from 'styled-components';

export const Container = styled.aside`
  padding-top:15px;
  padding:0 15px;
  display:flex;
  height:100%;
  transition:width .2s ease-in;
  width: ${props => props.visible ? '320px' : '84px' };
  background: ${props => props.visible ? '#888' : 'transparent' };

  ul {
      overflow:hidden;
      width:100%;

    li {
      display:flex;
      align-items:center;
      color: ${props => props.visible ? '#fff' : '#888' };
      border-bottom:1px solid rgba(255,255,255,.05);
      font-size:12px;
      font-weight: bold;
      margin:20px 0;
      /* padding:0 15px; */
      text-align:center;
      height:44px;
      justify-content: center;
      cursor:pointer;
      border-bottom: 1px solid ${props => props.visible ? '#fff' : '#888' };
      border-radius:4px;
      position:relative;
      width:44px;
      transition:all .2s ease-in;

      &.active {
        background:rgb(155,213,49);
        color:#fff;

        span {
          color:#FFF;
        }
      }

      &:hover {
        background:rgb(155,213,49);
        color:#fff;
      }

      & + li {
        background: transparent;
        border-bottom:0;
      }

      span {
        position: absolute;
        top:14px;
        left:70px;
        width: 200px;
        text-align: left;
        font-size:14px;
      }
    }
  }
`;

import React from "react";

import { Container } from "./styles";

import { Select } from "@rocketseat/unform";

import "react-datepicker/dist/react-datepicker.css";

export default function FiltroSelect({ handleFiltroSelect, options }) {
  return (
    <Container>
      <Select
        onChange={e => handleFiltroSelect(e.target.value)}
        className="filterCase"
        placeholder="Todos"
        type="button"
        options={options}
        name="filtro"
      ></Select>
    </Container>
  );
}

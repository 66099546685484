import React from 'react'
import { FaSlidersH } from 'react-icons/fa'
import './style.css'

export default function MainFilter({ children, resetFilter = () => { }, applyFilter = () => { } }) {
  return (
    <div className="main-filter-container">
      <div className="main-filter-margin-area">
        <div></div>
        <div></div>
      </div>
      <div className="main-filter-filter-components">
        {/* Passe <FilterComponents /> como componente filho para esse componente */}
        {children}
      </div>
      <div className="main-filter-buttons-area">
        <div className="main-filter-buttons-void-area"></div>
        <div className="main-filter-buttons">
          <button className="main-filter-clear-button" onClick={resetFilter}>Limpar Filtros</button>
          <button className="main-filter-apply-button" onClick={applyFilter}>Aplicar Filtros</button>
        </div>
      </div>
      <div className="main-filter-info-area">Deixe em branco o que não deve ser filtrado.</div>
    </div>
  )
}

export function MainFilterButton({ click = () => { } }) {
  return (
    <div className="main-filter-button" onClick={click}>
      Filtros <FaSlidersH className="main-filter-icon" />
    </div>
  )
}
import styled from "styled-components";

import PerfectScrollbar from "react-perfect-scrollbar";

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 15px auto;
  position: absolute;
  top: 56px;
  left: calc(50% - 550px);

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    display: ${props => (props.NewCheck ? "block" : "none")};
  }

  header {
    width: 100%;
    padding: 15px 0;
    background: #555555;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 12;
  background: #00000036;
`;

export const Container = styled.div`
  background: #efefef;
  width: 100%;
  text-align: center;

  h1 {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #333;
    text-align: left;
    background: #ccc;
    padding: 15px;
    border-bottom: 1px solid #ddd;

    svg {
      margin-right: 5px;
      border-radius: 4px;
      transition: background 0.2s;
    }

    &:hover svg {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .rowInputs {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    label {
      display: flex;
      text-align: left;
      align-items: center;
      margin: 0 3px 10px;
      flex: 1;
      position: relative;

      span {
        position: absolute;
        bottom: -27px;
        background: rgb(155,213,49);
        padding: 2px 0px;
        color: #fff;
        width: calc(100% - 6px);
        text-align: center;
        font-style: normal;
        font-size: 11px;
      }

      div.react-datepicker-wrapper {
        width: 100%;
        background: #fff;
        height: 54px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        padding: 15px;

        input {
          border: none;
        }
      }

      img {
        border-radius: 4px;
        overflow: hidden;
      }
    }

    label > div:first-child {
      display: inline-block;
      width: 54px;
      height: 54px;
      text-align: center;
      font-size: 10px;
      color: #555;
      background: #fff;
      padding: 13px 8px 0;
      border-radius: 4px;

      &.fileInput {
        display: flex;
        width: 100%;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 12px;

        svg {
          margin-left: 15px;
        }
      }
    }

    button.btnAdd svg {
      margin-right: 7px;
    }

    button.btnFinish svg {
      margin-left: 7px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
`;

export const Back = styled.button`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  color: rgb(155,213,49);
  margin: 0 15px;
  padding: 7px 8px;
  border: none;
  border-radius: 3px;
`;

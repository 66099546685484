import styled from 'styled-components';

export const Container = styled.ul`
  border-radius:4px;
  display:flex;
  flex-direction: column;
  position:relative;
  width:160px;
  border-left:1px solid rgba(0,0,0,.1);
  color: #FFF;

  .filterCase {
    width:100%;
    padding:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    background:${props => props.openDate? 'rgb(155,213,49)' : 'transparent'};
    color:${props => props.openDate? '#fff' : '#fff'};
    border:none;
    transition:all .2s;

    &:hover {
      background:rgb(155,213,49);
      color:#FFF;
    }

    svg {
      margin:0 5px;
    }
  }


  ul {
    position:absolute;
    z-index:2;
    top:100%;
    width:100%;
    padding:10px;
    background: #FFF;
    display:${props => props.openDate ? 'block' : 'none'};

    li {
      display:flex;
      flex-direction: row;
      align-items:center;
      width:100%;
      line-height: 37px;
      text-align:center;

      &:first-child {
        border:1px solid rgb(155,213,49);
        border-radius:4px;
        padding:0 5px;
        background:#efefef;
      }

      .react-datepicker-wrapper {
        width:100%;
      }

      button:first-child {
        margin:5px 0;
        border:none;
        border-bottom:1px solid rgb(155,213,49);
        background:transparent;
        width:100%;
        line-height: 37px;
        transition:all .2s;
        border-radius:4px;


        &:hover {
          background:rgb(155,213,49);
          color:#FFF;
        }
      }

      input {
        height:37px;
        width:100%;
        border:none;
        text-align:center;
        background:transparent;
      }
    }
  }
`;

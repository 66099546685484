import styled from "styled-components";

export const NewConfContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 15px auto;
  position: absolute;
  top: 117px;
  max-height: 480px;
  left: calc(50% - 500px);
  background: #efefef;
  padding: 15px;
  z-index: 1;

  & > .cardTitle {
    position: absolute;
    top: -53px;
    padding: 13px 0;
    background: #333;
    width: 100%;
    left: 0;
  }

  > main {
    > form.form {
      .rowInputs {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }

      label {
        display: flex;
        text-align: left;
        align-items: center;
        margin: 0 3px 10px;
        flex: 1;
        position: relative;

        > span {
          position: absolute;
          bottom: -27px;
          background: rgb(155,213,49);
          padding: 2px 0px;
          color: #fff;
          width: calc(100% - 6px);
          text-align: center;
          font-style: normal;
          font-size: 11px;
        }

        > .icon {
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          padding: 0 10px !important;

          > img.icone_flags {
            width: 100% !important;
          }
        }

        div.react-datepicker-wrapper {
          width: 100%;
          background: #fff;
          height: 54px;
          display: flex;
          align-items: center;
          margin: 0 5px;
          padding: 15px;

          input {
            border: none;
          }
        }

        img {
          border-radius: 4px;
          overflow: hidden;
        }
      }

      label > div:first-child {
        display: inline-block;
        width: 54px;
        height: 54px;
        text-align: center;
        font-size: 10px;
        color: #555;
        background: #fff;
        padding: 13px 8px 0;
        border-radius: 4px;
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  position:relative;

  h1 {
    cursor:pointer;
  }
`;

export const ListCheques = styled.ul`
  li {
    width:100%;
    background: #efefef;
    display:flex;
    height:auto;
    align-items:center;

    table {
      width:100%;

      thead {
        font-weight:bold;

        tr {
          background:#d7d2de;

          td {
            padding:5px 10px;
            text-align:center;
            vertical-align: middle;
          }
        }
      }

      tbody tr {

        &:nth-child(odd) {
          background: #fff;
        }

        td {
          border-top:1px solid rgba(0,0,0,.1);
          padding:5px 10px;
          text-align:center;
          vertical-align: middle;
        }
      }
    }
  }
`;

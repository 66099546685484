import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import api from "~/services/api";

import { Form, Input } from "@rocketseat/unform";

import {
  formatMoney,
  formatNum,
  handleDialog,
  formatedDate
} from "~/scripts/index.js";

import { format, addMonths, subMonths, parseISO, addHours } from "date-fns";

import pt from "date-fns/locale/pt";

import { toast } from "react-toastify";

import { FaCheck, FaTimes, FaFile, FaTimesCircle } from "react-icons/fa";

import {
  Content,
  Container,
  Scroll,
  GradeTable,
  GradeHeader,
  GradeBody
  // Dialog
} from "./styles";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Title from "~/components/Title";

import { file_extension } from "~/assets/";

export default function FixedCostParcels({
  fixedCost,
  handlerOpenFixedCostParcels,
  setBlock,
  block
}) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [parcels, setParcels] = useState([]);
  const [parcelsChanged, setParcelsChanged] = useState([]);
  const [documentInsert, setDocumentInsert] = useState(false);
  const [id, setId] = useState(null);
  const [i, setIndex] = useState(null);
  const [numberDocument, setNumberDocument] = useState("");
  const [finish, setFinish] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    numberDocument ? setFinish(true) : setFinish(false);
  }, [numberDocument]);

  useEffect(() => {
    async function loadParcels() {
      const { data } = await api.get("/fixedcost/" + fixedCost.id);
      const { parcel } = data;
      if (data) {
        setParcels(parcel);
        setParcelsChanged(parcel);
      }
      setRefresh(false);
    }
    loadParcels();
  }, [fixedCost, refresh]);

  function setIconFileExtension(file) {
    const ext = file.split(".").pop();
    for (let i = 0; i < file_extension.length; i++) {
      if (ext == file_extension[i].title) {
        return file_extension[i].icon;
        break;
      }
    }
  }

  async function approveParcel(parcel, index) {
    const newparcels = JSON.parse(JSON.stringify(parcels));
    // console.log(parcel)

    try {
      const response = await api.put(
        "fixedCost/parcels/approve/" + parcel.id,
        parcel
      );

      if (response.data.approved_at) {
        toast.success("Parcela aprovada com sucesso!", {
          className: "success"
        });
      } else {
        toast.success("Parcela estornada com sucesso!", {
          className: "success"
        });
      }
      // newparcels[index].approved_at = response.data.approved_at;
      newparcels[index].approved = response.data.approved;
      setParcels(newparcels);
      // setBlock(true);
      // setRefresh(true);
      setId(null);
      return;
    } catch (err) {
      toast.error("Esta Parcela não pode ser aprovada no momento.", {
        className: "error"
      });
    }
  }

  function funcDocumentInsert(id, defaultValue) {
    setDocumentInsert(true);
    setNumberDocument(defaultValue);
    setId(id);
  }

  async function handlerDocument() {
    try {
      await api.put(`fixedcost/document/${id}`, { numberDocument });
      setBlock(true);
      setRefresh(true);
      setDocumentInsert(false);
      toast.success("Documento lançado com sucesso!");
    } catch (err) {
      toast.error("Erro ao lancar documento!");
    }
  }

  async function handleFile(id, i) {
    setId(id);
    setIndex(i);

    document.getElementById("files").click();
  }

  async function handleUpload(e) {
    const newparcels = JSON.parse(JSON.stringify(parcels));
    try {
      const datas = new FormData();

      datas.append("file", e.target.files[0]);

      let { data: dataFile } = await api.post("s3files/" + profile.id, datas);

      let { data } = await api.post(
        `parcel_fixed_cost/${id}/${dataFile.id}/${profile.id}`,
        datas
      );
      newparcels[i].nf = data.nf;
      newparcels[i].s3file_id = dataFile.id;
      setParcels(newparcels);
      setId(null);
      document.getElementById("files").value = "";
      toast.success("Arquivo laçando com sucesso!");
    } catch (err) {
      toast.error("Erro ao lancar arquivo!");
    }
  }

  function changed(e, i, item) {
    const newparcels = JSON.parse(JSON.stringify(parcels));

    if (item === "document") {
      newparcels[i].document = e;
    } else if (item === "value") {
      newparcels[i].value = parseFloat(formatNum(e));
    } else if (item === "approved_at") {
      newparcels[i].approved_at = format(e,'yyyy-MM-dd');
    }

    setParcels(newparcels);
  }

  return (
    <>
      <input
        type="file"
        id="files"
        accept="image/jpeg,image/gif,image/png,application/pdf"
        style={{ display: "none" }}
        onChange={e => handleUpload(e)}
      />
      <Scroll>
        <Content>
          <header>
            <Title
              back={true}
              func={handlerOpenFixedCostParcels}
              title={"Cancelar"}
            />
          </header>
          <Container>
            <h1>Parcelas de Custo Fixo: </h1>
            <table className="gradeTable">
              <thead className="gradeHeader">
                <tr>
                  <td>Status</td>
                  <td>Parcela</td>
                  <td>Documento</td>
                  <td>Vencimento</td>
                  <td>Valor Previsto</td>
                  <td style={{ width: "130px" }}>Valor Final</td>
                  <td>Data de Baixa</td>
                  <td style={{ justifyContent: "center" }}>NF</td>
                  <td></td>
                </tr>
              </thead>
              <tbody className="gradeBody">
                {parcels.length > 0
                  ? parcels.map((parcel, index) => (
                      <tr
                        key={parcel.parcel}
                        onClick={() => handleDialog(parcel, "parcels")}
                        className={
                          parcel.approved ? "parcels approved" : "parcels"
                        }
                        id={parcel.id}
                      >
                        <td>
                          {parcel.approved ? "Baixado" : "Pendente..."}
                          {/* <ul className="dialog">
                        <li onClick={() => approveParcel(parcel)}>
                        {!parcel.approved_at ? (
                          <>
                            <FaCheck size={12} /> Aprovar
                          </>
                        ) : (
                          <>
                            <FaTimes size={12} /> Estornar Aprovação
                          </>
                        )}
                        </li>
                    </ul> */}
                        </td>
                        <td>{parcel.parcel}</td>
                        <td>
                          {parcel.approved ? (
                            parcel.document
                          ) : (
                            <input
                              readOnly={parcel.approved ? "readOnly" : false}
                              value={parcel.document}
                              // placeholder=""
                              onChange={e =>
                                changed(e.target.value, index, "document")
                              }
                            />
                          )}
                        </td>
                        <td>{format(parseISO(parcel.due_date),profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}</td>
                        <td>
                          {formatMoney(
                            parcel.gross_value.toFixed(2),
                            filial.country.language
                          )}
                        </td>
                        <td>
                          {parcel.approved ? (
                            formatMoney(
                              parcel.value.toFixed(2),
                              filial.country.language
                            )
                          ) : (
                            <input
                              readOnly={parcel.approved ? "readOnly" : false}
                              value={formatMoney(
                                parcel.value.toFixed(2),
                                filial.country.language
                              )}
                              style={{ width: "100%" }}
                              onChange={e =>
                                changed(e.target.value, index, "value")
                              }
                            />
                          )}
                        </td>
                        <td>
                          {parcel.approved ? (
                            formatedDate(parcel.approved_at)
                          ) : (
                            <DatePicker
                              name="due_date"
                              selected={
                                parcel.approved_at
                                  ? parseISO(parcel.approved_at)
                                  : null
                              }
                              onChange={date =>
                                changed(date, index, "approved_at")
                              }
                              minDate={parseISO(filial.open_date)}
                              locale={pt}
                              placeholderText="Escolher data de baixa"
                              dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                              readOnly={parcel.approved ? "readOnly" : false}
                              disabled={parcel.approved ? true : false}
                            />
                          )}
                        </td>
                        <td>
                          <div
                            className="file"
                            onClick={() =>
                              parcel.approved
                                ? false
                                : handleFile(parcel.id, index)
                            }
                          >
                            {parcel.nf && parcel.approved ? (
                              <a href={parcel.nf.url} target="_blank">
                              <img
                                src={setIconFileExtension(parcel.nf.name)}
                                alt=""
                                style={{ height: "35px", width: "35px" }}
                              /></a>
                            ) : (
                              <FaFile size={20} />
                            )}
                          </div>
                        </td>
                        <td>
                          {!parcel.concialized_at ? parcel.approved ? (
                            <button
                              type="button"
                              className="btnFinish"
                              style={{
                                height: "auto",
                                padding: "3px 6px 3px 0px"
                              }}
                              onClick={() => approveParcel(parcel, index)}
                            >
                              <FaTimes size={12} /> Estornar
                            </button>
                          ) : (
                            <button
                              type="button"
                              style={{
                                height: "auto",
                                padding: "3px 6px 3px 0px"
                              }}
                              className="btnFinish"
                              disabled={
                                !(
                                  parcel.document &&
                                  parcel.approved_at &&
                                  parcel.nf &&
                                  parcel.value !== 0
                                )
                              }
                              onClick={() => approveParcel(parcel, index)}
                            >
                              <FaCheck size={12} /> Baixar
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </Container>
        </Content>
      </Scroll>
    </>
  );
}

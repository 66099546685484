import styled from "styled-components";

import { lighten } from "polished";

export const GradeTable = styled.table`
  width: 100%;
  background: #fff;
`;

export const New = styled.button`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 39px;
  background: rgb(155, 213, 49);
  color: #fff;
  font-weight: bold;

  &.print {
    background: #555;
  }
`;

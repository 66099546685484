import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Input } from "@rocketseat/unform";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { Container, Forgot, MessageSolicitaion } from "./styles";

import { loginRequest } from "~/store/modules/auth/actions";

import logo from "~/assets/images/logo_siaf.png";

import api from "~/services/api";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Insira um e-mail válido!")
    .required("O e-mail é obrigatório."),
  password: Yup.string().required("A senha é obrigatória.")
});
const schema_forgot = Yup.object().shape({
  email: Yup.string()
    .email("Insira um e-mail válido!")
    .required("O e-mail é obrigatório.")
});

export default function Login() {
  const dispatch = useDispatch();
  const [forgot, setForget] = useState(false);
  const [forgotSuccess, setForgotSuccess] = useState(false);
  const [loadingForgot, setLoadingForgot] = useState(false);
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(loginRequest(email, password));
  }

  async function handleSubmitForgot({ email }) {
    setLoadingForgot(true);
    try {
      const { data } = await api.post("forgot_password", { email });

      toast.success(
        "Solicitação realizada com sucesso! Verifique seu e-email",
        { className: "success" }
      );
      setLoadingForgot(false);
      setForgotSuccess(true);
    } catch (err) {
      toast.error("Não foi possivel completar a solicitação!", {
        className: "error"
      });
    }
  }

  function handleForgotPass() {
    setForget(!forgot);
    setLoadingForgot(false);
    setForgotSuccess(false);
  }

  return (
    <Container>
      <img src={logo} width="315" alt="SIAF Smart" />
      {!forgot ? (
        <Form schema={schema} onSubmit={handleSubmit}>
          <Input
            name="email"
            autoComplete="email"
            type="email"
            placeholder="Seu e-mail"
          />
          <Input
            name="password"
            autoComplete="current-password"
            type="password"
            placeholder="Sua senha"
          />

          <button type="submit" style={{ background: "rgb(155,213,49)" }}>
            {loading ? "Carregando..." : "Acessar"}
          </button>
          <Forgot type="button" to="/forgot" style={{color:'#555'}} onClick={() => setForget(!forgot)}>
            {!forgot ? "Esqueci minha senha" : "Fazer login"}
          </Forgot>
          {/* <Link to="/register">Cadastrar Usuário</Link>  */}
        </Form>
      ) : (
        <Form schema={schema_forgot} onSubmit={handleSubmitForgot}>
          {!forgotSuccess ? (
            <>
              <Input
                name="email"
                autoComplete="email"
                type="email"
                readOnly={loadingForgot}
                placeholder="Seu e-mail"
              />
              <button
                type="submit"
                disabled={loadingForgot}
                readOnly={loadingForgot}
                style={{ background: "rgb(155,213,49)" }}
              >
                {loadingForgot ? "Carregando..." : "Enviar link de recuperação"}
              </button>
            </>
          ) : (
            <MessageSolicitaion>
              <h2 style={{fontSize: '18px', marginBottom: '15px'}}>Solicitação realizada com sucesso!</h2>
              <h4>
                Você recebera um link no e-mail com os proximos passos para
                resetar sua senha!
              </h4>
            </MessageSolicitaion>
          )}
          <Forgot
            type="button"
            disabled={loadingForgot}
            to="/forgot"
            style={{color:'#555'}}
            onClick={() => handleForgotPass()}
          >
            {!forgot ? "Esqueci minha senha" : "Fazer login"}
          </Forgot>
          {/* <Link to="/register">Cadastrar Usuário</Link>  */}
        </Form>
      )}
    </Container>
  );
}

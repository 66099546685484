import React, { useEffect, useState } from "react";
import api from "~/services/api";
import GroupAccessSelectBox from "~/components/GroupAccessSelectBox";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedFilialsRequest } from "~/store/modules/selectedFilials/actions";

export default function GroupAccess({ user_info }) {
  const dispatch = useDispatch();
  const [filials, setFilials] = useState([]);
  const [mounted, setMounted] = useState(false);
  const selectedFilials = useSelector(state => state.selectedFilials.filials);

  useEffect(() => {
    if (user_info.id && selectedFilials) {
      async function getFilialsData() {
        try {
          const associatedFilials = await api.get(
            `users/show_associated_filials/${user_info.id}`
          );

          dispatch(
            updateSelectedFilialsRequest({
              selected: [...associatedFilials.data.selectedFilials],
              main: associatedFilials.data.filial_id,
              changed: false
            })
          );

          const { data } = await api.get("filiais/hierarchy");
          let components = [];

          data.forEach(val => {
            if (val.regional_id === null) {
              components.push(
                <GroupAccessSelectBox
                  id={val.id}
                  key={val.id}
                  regional_id={val.regional_id}
                  children={[]}
                  name={val.name}
                  filials_info={[...data]}
                  selectedFilials={selectedFilials}
                />
              );
            }
          });

          function handleFilials(filial) {
            data.forEach(val => {
              if (filial.props.id === val.regional_id) {
                filial.props.children.push(
                  <GroupAccessSelectBox
                    id={val.id}
                    key={val.id}
                    regional_id={val.regional_id}
                    children={[]}
                    name={val.name}
                    filials_info={[...data]}
                    selectedFilials={selectedFilials}
                  />
                );
              }
            });
            if (filial.props.children.length > 0) {
              filial.props.children.forEach(val => {
                handleFilials(val);
              });
            }
          }

          components.forEach(val => {
            handleFilials(val);
          });

          setFilials(components);
        } catch (err) {
          console.log(err);
        }
      }
      getFilialsData();
    }
  }, [mounted]);

  useEffect(() => {
    if (user_info.id && !mounted) {
      setMounted(true);
    }
  }, [user_info]);

  return <div className="group-access-container">{filials}</div>;
}

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import api from "~/services/api";

import { addDays, isAfter, addHours, format, parseISO } from "date-fns";

import pt from "date-fns/locale/pt";

import { toast } from "react-toastify";

import { file_extension } from "~/assets/";

import * as Yup from "yup";

import {
  FaCalendarAlt,
  FaUser,
  FaSearch,
  FaAngleRight,
  FaFileUpload,
  FaUniversity,
  FaFileInvoice,
  FaCommentDots,
  FaCreditCard,
  FaDollarSign,
  FaTrashAlt
} from "react-icons/fa";

import { Form, Input, Select } from "@rocketseat/unform";
import { Content, Container, Scroll, DivFiles } from "./styles";

import { formatMoney, formatNum, getAccountsByPaymentMethod, getPaymentMethods } from "~/scripts/index.js";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Title from "~/components/Title";

import BuscarFornecedor from "~/components/BuscarFornecedor";

import BuscarPlanoDeContas from "~/components/BuscarPlanoDeContas";

export default function CreateOrder({ id, edit, handleCreateOrder, setBlock }) {
  const profile = useSelector(state => state.user.profile);
  const filial = useSelector(state => state.filial);
  const [showSearch, setShowSearch] = useState(false);
  const [provider, setProvider] = useState({ cgc: "", name: "" });
  // const [file, setFile] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [preview, setPreview] = useState(null);
  const [showCoA, setShowCoA] = useState(false);
  const [finish, setFinish] = useState(false);
  const [chartOfAccount, setChartOfAccount] = useState({ code: "", nome: "" });
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [filiaisRegional, setFiliaisRegional] = useState([]);
  const [removeFile,setRemoveFile] = useState(null);
  const [order, setOrder] = useState({
    bank_id: null,
    created_by: profile.id,
    filial_id: filial.filial,
    to_filial_id: filial.filial,
    charts_of_account_id: null,
    s3file_id: null,
    cupom_fiscal: null,
    provider_id: null,
    coo: null,
    value: 0,
    discount: 0,
    addition: 0,
    interest: 0,
    total: 0,
    payment_method: null,
    date: format(new Date(),'yyyy-MM-dd'),
    due_date: format(new Date(),'yyyy-MM-dd'),
    created_at: new Date()
  });
  const [uploadFile, setUploadFile] = useState({
    status: false,
    name: "",
    url: ""
  });

  useEffect(() => {
    async function loadOrder() {
      const response = await api.get("paymentOrders/id/" + id);
      const { data } = response;

      setUploadFile(
        data.file
          ? { status: true, name: data.file.key, url: data.file.url }
          : { status: false, name: "" }
      );
      setOrder(data);
      setProvider({
        cgc: data.provider.agente.cgc,
        company_name: data.provider.fantasy_name
      });
      if(data.file) {
        setPreview(data.file.url);
      }
      if(data.charts_of_account) {
        setChartOfAccount({...data.charts_of_account, title: profile.language === 'pt-BR' ? data.charts_of_account.pt_name : data.charts_of_account.en_name });
      }
    }

    async function getFiliaisRegional(filial_id) {
      const response = await api.get('/filiais/myregionais/'+filial_id);
      const { data } = response;
      let filiais = [{id: filial_id, title: filial.filial_name}];
      data.map(filial => {
        return filiais.push({ id: filial.id, title: filial.name })
      })

      setFiliaisRegional(filiais);
    }

    if(id) {
      loadOrder();
    }
    getFiliaisRegional(filial.filial)
  }, [id, filial.filial, filial.filial_name, profile.language]);

  async function handleSubmit() {
    try {
      if (removeFile) {
        await api.delete('s3files/'+removeFile);
      }
      id ? await api.put("/paymentOrders/edit/" + id, order) : await api.post("/paymentOrders", order);
      toast.success(
        `Ordem de Pagamento ${id ? "alterado" : "incluído"} com sucesso!`,
        { className: "success" }
      );
      setBlock(true);
      handleCreateOrder(false);
      return;
    } catch(err) {
      toast.error("Há algum erro, por favor, revise o lançamento.", {
        className: "error"
      });
    }
  }

  function handleSearch(type, value) {
    if (type === "chart_of_account") {
      setShowCoA(value);
    } else {
      setShowSearch(value);
    }
  }

  function handleChartOfAccount(coa) {
    setOrder({ ...order, plano_de_contas_id: coa.id });
    const { id, nome: title, tax_nature } = coa;
    setChartOfAccount({id, title, tax_nature});
    setShowCoA(false);
  }

  function handleProvider(provider) {
    setOrder({ ...order, provider_id: provider.id });
    setProvider(provider);
    setShowSearch(false);
  }

  async function handleFile(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post('s3files/'+profile.id, data);

    const { id: s3file_id, url } = response.data;
    setOrder({ ...order, s3file_id });
    setPreview(url);
  }

  useEffect(() => {
    async function check() {
      const { s3file_id } = order;

      // setFinish(provider_id && charts_of_account_id && cupom_fiscal && coo &&)
      setFinish(!!s3file_id);
    }

    check();
  }, [order]);

  useEffect(() => {
    getAccountsByPaymentMethod(order.payment_method, setOrder, order, setPaymentAccounts, filial.filial);

  }, [order.payment_method, filial.filial]);

  useEffect(() => {
    let { value, discount, addition, interest } = order;
    setOrder({
      ...order,
      total:
        parseFloat(value) -
        parseFloat(discount) +
        parseFloat(addition) +
        parseFloat(interest)
    });
  }, [order.value, order.discount, order.addition, order.interest]);

  async function handleInput(e) {
    handleFile(e);
    const { name, size } = e.target.files[0];

    setUploadFile({ status: true, name, size, e });
  }

  function setIconFileExtension(file) {
    const ext = file.split(".").pop();
    for (let i = 0; i < file_extension.length; i++) {
      if (ext === file_extension[i].title) {
        return file_extension[i].icon;
      }
    }
  }

  function removerFiles() {
    setRemoveFile(order.s3file_id);
    setOrder({ ...order, s3file_id: null });
    setUploadFile({ status: false, name: "" });
    setPreview(null);
  }

  const payment_methods = getPaymentMethods();

  const schema = Yup.object().shape({
    cupom_fiscal: Yup.string().required("Nº do Cupom é obrigatório."),
    coo: Yup.string().required("COO do Cupom é obrigatório."),
    reason: Yup.string().required("Despesa é obrigatória."),
    payment_method: Yup.string().required("Tipo é obrigatório."),
    provider: Yup.string().required("Fornecedor é obrigatório."),
    charts_of_account: Yup.string().required("Plano de contas é obrigatório."),
    bank_id: Yup.number().positive(),
    value: Yup.string().test(
      "0,00",
      "Valor é obrigatório",
      value => formatNum(value, false) > 0
    ),
    total: Yup.string().test(
      "0,00",
      "Total é obrigatório.",
      total =>
        formatNum(
          total,
          total.toString().substring(0, 1) === "-" ? true : false
        ) > 0
    )
  });

  return (
    <>
      <Scroll style={showSearch || showCoA ? { display: "none" } : null}>
        <Content>
          <header>
            <Title back={true} func={handleCreateOrder} title={"Cancelar"} />
          </header>
          <Container>
            <h1>Nova Ordem de Pagamento</h1>
            <Form initialData={order} schema={schema} onSubmit={handleSubmit} autoComplete="off">
              <div className="rowInputs" style={{ paddingTop: "15px" }}>
                <label htmlFor="nome">
                  <img
                    src={
                      profile.avatar
                        ? profile.avatar.url
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrdEvvb_A3fTRZ9FTzpCkDacPDXBYoWgsoxbFbcYnB-jWigqa6w&s"
                    }
                    width="54"
                    height="54"
                    alt=""
                  />
                  <Input
                    name="nome"
                    readOnly="readonly"
                    value={profile.nome}
                    placeholder="Data"
                  />
                </label>
                <label htmlFor="provider" style={{ flexGrow: "2" }}>
                  <div>
                    <FaUser size={20} />
                    <br />
                    Fornec.
                  </div>
                  <Input
                    name="provider"
                    // placeholder="Fornecedor"
                    readOnly="readonly"
                    value={provider.cgc ? provider.company_name : null}
                  />
                  { edit ?
                  <button
                    type="button"
                    className="btnFinish"
                    onClick={() => handleSearch("provider", true)}
                  >
                    Buscar <FaSearch size={14} />
                  </button>
                  : null }
                </label>
                <label htmlFor="plano_de_contas_id" style={{ flexGrow: "2" }}>
                  <div>
                    <FaUser size={20} />
                    <br />
                    Pla.Con
                  </div>
                  <Input
                    name="plano_de_contas_id"
                    // placeholder="Plano de contas"
                    readOnly={"readonly"}
                    value={chartOfAccount.title ? `${chartOfAccount.title}` : ""}
                  />
                  { edit ?
                  <button
                    type="button"
                    className="btnFinish"
                    onClick={() => handleSearch("chart_of_account", true)}
                  >
                    Buscar <FaSearch size={14} />
                  </button>
                  : null }
                </label>
              </div>
              <hr />

              <div className="rowInputs">
                <label htmlFor="cupom_fiscal">
                  <div>
                    <FaFileInvoice size={20} />
                    <br />
                    Cupom
                  </div>
                  <Input
                    name="cupom_fiscal"
                    maxLength={9}
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setOrder({ ...order, cupom_fiscal: e.target.value })
                    }
                  />
                </label>
                <label htmlFor="coo">
                  <div>
                    <FaFileInvoice size={20} />
                    <br />
                    coo
                  </div>
                  <Input
                    name="coo"
                    maxLength={5}
                    readOnly={edit ? null : "readonly"}
                    onChange={e => setOrder({ ...order, coo: e.target.value })}
                  />
                </label>
                <label htmlFor="date">
                  <div>
                    <FaCalendarAlt size={20} />
                    <br />
                    Emissão
                  </div>
                  <DatePicker
                    name="date"
                    readOnly={edit ? null : "readonly"}
                    selected={parseISO(order.date)}
                    minDate={addDays(new Date(filial.open_date), 1)}
                    maxDate={new Date()}
                    onChange={date => {
                      isAfter(date, order.due_date)
                        ? setOrder({ ...order, date: format(date,'yyyy-MM-dd'), due_date: format(date,'yyyy-MM-dd') })
                        : setOrder({ ...order, date: format(date,'yyyy-MM-dd') });
                    }}
                    dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    locale={pt}
                  />
                </label>
                <label htmlFor="due_date">
                  <div>
                    <FaCalendarAlt size={20} />
                    <br />
                    Vencto
                  </div>
                  <DatePicker
                    name="due_date"
                    readOnly={edit ? null : "readonly"}
                    selected={addHours(new Date(order.due_date), 3)}
                    minDate={new Date(order.date)}
                    onChange={date => setOrder({ ...order, due_date: date })}
                    dateFormat={profile.language === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    locale={pt}
                  />
                </label>
              </div>
              <hr />

              <div className="rowInputs">
                <label htmlFor="reason">
                  <div>
                    <FaCommentDots size={20} />
                    <br />
                    Despesa
                  </div>
                  <Input
                    name="reason"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setOrder({ ...order, reason: e.target.value })
                    }
                  />
                </label>
                <label htmlFor="to_filial_id">
                  <div>
                    <FaUniversity size={20} />
                    <br />
                    P/ Filial
                  </div>
                  <Select
                    name="to_filial_id"
                    options={filiaisRegional}
                    selected={order.to_filial_id}
                    value={order.to_filial_id}
                    disabled={!edit}
                    onChange={e =>
                      setOrder({
                        ...order,
                        to_filial_id: e.target.value ? e.target.value : null
                      })
                    }
                  />
                </label>
                <label htmlFor="payment_method">
                  <div>
                    <FaCreditCard size={20} />
                    <br />
                    Pago em
                  </div>
                  <Select
                    name="payment_method"
                    placeholder={
                      order.payment_method ? order.payment_method : "Dinheiro"
                    }
                    options={payment_methods}
                    value={order.payment_method}
                    disabled={!edit}
                    onChange={e =>
                      setOrder({ ...order, payment_method: e.target.value })
                    }
                  />
                </label>
                <label htmlFor="paymentAccount">
                  <div>
                    <FaUniversity size={20} />
                    <br />
                    Conta
                  </div>
                  <Select
                    name="paymentAccount"
                    placeholder={order.bank ? order.bank.name : "Caixa Filial"}
                    options={paymentAccounts}
                    value={order.bank_id || 0}
                    selected={order.bank_id || 0}
                    disabled={!edit}
                    onChange={e =>
                      setOrder({
                        ...order,
                        bank_id: e.target.value ? e.target.value : null
                      })
                    }
                  />
                </label>
              </div>
              <hr />

              <div className="rowInputs">
                <label htmlFor="value">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Valor
                  </div>
                  <Input
                    name="value"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setOrder({ ...order, value: formatNum(e.target.value) })
                    }
                    value={formatMoney(
                      order.value.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="discount">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Desc.
                  </div>
                  <Input
                    name="discount"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setOrder({
                        ...order,
                        discount: formatNum(e.target.value)
                      })
                    }
                    value={formatMoney(
                      order.discount.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="addition">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Acresc.
                  </div>
                  <Input
                    name="addition"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setOrder({
                        ...order,
                        addition: formatNum(e.target.value)
                      })
                    }
                    value={formatMoney(
                      order.addition.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="interest">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Juros
                  </div>
                  <Input
                    name="interest"
                    readOnly={edit ? null : "readonly"}
                    onChange={e =>
                      setOrder({
                        ...order,
                        interest: formatNum(e.target.value)
                      })
                    }
                    value={formatMoney(
                      order.interest.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
                <label htmlFor="total">
                  <div>
                    <FaDollarSign size={20} />
                    <br />
                    Total
                  </div>
                  <Input
                    name="total"
                    readOnly={edit ? null : "readonly"}
                    readOnly
                    value={formatMoney(
                      order.total.toFixed(2),
                      filial.country.language
                    )}
                  />
                </label>
              </div>
              <input
                type="file"
                id="files"
                name="files"
                accept="image/jpeg,image/gif,image/png,application/pdf"
                style={{ display: "none" }}
                onChange={e => handleInput(e)}
              />
              <h1 className="title_section_upload_file">
                Anexo
                {edit && (
                  <button type="button" className="upload_file">
                    <FaFileUpload
                      size={18}
                      onClick={() => document.getElementById("files").click()}
                    />
                  </button>
                )}
              </h1>
              <DivFiles>
                <li>
                  <table>
                    <thead>
                      <tr>
                        <td>Arquivo</td>
                        <td style={{ width: "100px" }}>Baixar</td>
                        <td style={{ width: "100px" }}>Excluir</td>
                      </tr>
                    </thead>
                    <tbody>
                      {uploadFile.status ? (
                        <tr>
                          <td>{uploadFile.name}</td>
                          <td>
                            <a
                              href={`${preview}`}
                              download
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={setIconFileExtension(uploadFile.name)}
                                alt=""
                                style={{ height: "35px", width: "35px" }}
                              />
                            </a>
                          </td>
                          <td>
                            {edit && (
                              <FaTrashAlt
                                size={20}
                                color="#c47f7f"
                                onClick={() => removerFiles()}
                              />
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>Nenhum arquivo</td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </li>
              </DivFiles>
              { edit ?
              <div className="rowInputs btn_finish">
                <button type="submit" className="btnFinish" onClick={() => handleSubmit()} disabled={!finish}>
                  {id ? "Alterar" : "Finalizar"} <FaAngleRight size={20} />
                </button>
              </div>
              : null }
            </Form>
          </Container>
        </Content>
      </Scroll>

      <BuscarFornecedor
        show={showSearch}
        setShowSearch={setShowSearch}
        initialData={order}
        handleProvider={handleProvider}
      />

      <BuscarPlanoDeContas
        show={showCoA}
        setShowCoA={setShowCoA}
        initialData={order}
        handleChartOfAccount={handleChartOfAccount}
      />
    </>
  );
}

import produce from 'immer';

const INITIAL_STATE = {
  category: 'contas_a_receber',
  defHomebox: 'dizimos'
}

export default function category(state = INITIAL_STATE, action) {
  return produce(state, draft => {
  switch (action.type) {
    case '@auth/LOGIN_SUCCESS': {
      draft.category = action.payload.user.group.default_category;
      draft.defHomebox = action.payload.user.group.default_homebox;
      break;
    }
    case '@category/UPDATE': {
      draft.category = action.payload.category;
      draft.defHomebox = action.payload.defHomebox;
      break;
    }
    case '@auth/LOGOUT': {
      draft.category = null;
      draft.defHomebox = null;
      break;
    }
    default:
  }
});
}

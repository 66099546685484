import React from "react";

import history from "~/services/history";

import { FaArrowLeft } from "react-icons/fa";

import { Container, Back } from "./styles";

function showBackButton(back, func) {
  if (back) {
    return (
      <Back type="button" onClick={() => func ? func(null) : history.goBack() }>
        <FaArrowLeft size={14} />
      </Back>
    );
  } else {
    return null;
  }
}

export default function Title({ title, back, func }) {
  return (
    <Container>
      {showBackButton(back, func)}
      <div onClick={() => func ? func(null) : history.goBack() } style={{cursor: "pointer"}}>{title}</div>
    </Container>
  );
}

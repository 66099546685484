import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import retLanguage from '~/config/language';

import allowAccess from "~/scripts/menuController";

import BgBlack from "~/components/BgBlack";

import CreateChartOfAccouts from "./CreateChartOfAccouts";

import NavigationButtons from "~/components/NavigationButtons";

import { FaEye, FaTimes, FaEdit } from "react-icons/fa";

import { handleBlock, handleDialog, verifyAccess } from "~/scripts/index.js";

import api from "~/services/api";

import {
  New
} from "./styles";

import FiltroLancamento from "~/components/FiltroLancamento";

export default function ChartOfAccounts() {
  const accesses = useSelector(state => state.auth.newaccesses);
  const [chartOfAccouts, setChartOfAccouts] = useState([]);
  const [createChartOfAccouts, setCreateChartOfAccouts] = useState(false);
  const [opennedID, setOpennedID] = useState(null);
  const [find, setFind] = useState(null);
  const [lancamento, setLancamento] = useState(null);
  const [edit, setEdit] = useState(false);
  const [block, setBlock] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const profile = useSelector(state => state.user.profile);
  const lang = retLanguage(profile.language)

  useEffect(() => {
    async function getChartOfAccouts() {
      const { data } = lancamento
        ? await api.get(`planodecontas/filter/${profile.language}/${lancamento}`)
        : await api.get(`planodecontas/${profile.language}/?page=${page}`);
      setChartOfAccouts(data.planosdecontas);
      setPages(data.pages);

      setBlock(false);
    }

    getChartOfAccouts();
  }, [block, lancamento, page]);

  function handleCreateChartOfAccouts(id) {
    setOpennedID(id);
    setEdit(true);
    setBlock(false);
    setCreateChartOfAccouts(!createChartOfAccouts);
  }

  function handleOpenCreateChartOfAccouts(id, edit) {
    setOpennedID(id);
    setEdit(edit);
    setBlock(false);
    setCreateChartOfAccouts(!createChartOfAccouts);
  }

  function handleLancamento(e) {
    e.preventDefault();
    setBlock(true);
  }

  return (
    <>
      <div className="gradeFiltros">
        {verifyAccess(accesses,'cadastro_plano_de_contas') ?
          <New
            type="button"
            onClick={() => handleCreateChartOfAccouts(null, true)}
          >
            {lang.accounts.header.h1}
          </New>
        : null }
        <FiltroLancamento
          handleLancamento={handleLancamento}
          setFind={setLancamento}
          placeholder={lang.accounts.header.h3}
        />
      </div>
      <table className="gradeTable">
        <thead className="gradeHeader">
          <tr>
            <td>{lang.accounts.titles.t2}</td>
            <td>{lang.accounts.titles.t3}</td>
            <td>{lang.accounts.titles.t4}</td>
          </tr>
        </thead>
        <tbody className="gradeBody">
          {chartOfAccouts && chartOfAccouts.length > 0 ? (
            chartOfAccouts.map((coc, index) => {
              {
                /* const code = breakCode(coc.code); */
              }

              return (
                <tr
                  key={coc.id}
                  id={coc.id}
                  className={`planodecontas ${
                    coc.canceled_at ? "canceled" : null
                  }`}
                  onClick={() => handleDialog(coc, "planodecontas")}
                >
                  <td>{ coc.title }
                    {verifyAccess(accesses,'cadastro_plano_de_contas') ? coc.can_be_editted ? (
                      <ul className="dialog">
                          <li
                            onClick={() =>
                              handleOpenCreateChartOfAccouts(coc.id, false)
                            }
                          >
                            <FaEye size={12} /> Visualizar
                          </li>
                          <li
                            onClick={() =>
                              handleOpenCreateChartOfAccouts(coc.id, true)
                            }
                          >
                            <FaEdit size={12} /> Alterar
                          </li>
                          <li
                            className="trash"
                            onClick={() =>
                              handleBlock(
                                coc.id,
                                coc.canceled_at,
                                "Plano de contas",
                                "planodecontas",
                                setBlock
                              )
                            }
                          >
                            <FaTimes size={12} /> Bloquear
                          </li>
                      </ul>
                    ) : null: null }
                  </td>
                  <td>{coc.tax_nature ? coc.tax_nature.nome : ""}</td>
                  <td>{coc.parent ? profile.language === 'pt-BR' ? coc.parent.pt_name : profile.language === 'en-US' ? coc.parent.en_name : coc.parent.es_name : ""}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>Não há planos de conta.</td>
            </tr>
          )}
        </tbody>
      </table>
      <NavigationButtons setPage={setPage} page={page} pages={pages} />
      {createChartOfAccouts ? (
        <>
          <BgBlack />
          <CreateChartOfAccouts
            handleCreateChartOfAccouts={handleCreateChartOfAccouts}
            opennedID={opennedID}
            edit={edit}
            setBlock={setBlock}
            setChartOfAccouts={setChartOfAccouts}
            chartOfAccouts={chartOfAccouts}
            setCreateChartOfAccouts={setCreateChartOfAccouts}
          />
        </>
      ) : null}
    </>
  );
}
